import { HeartWhiteIcon } from 'app/assets/svg';
import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { api } from 'shared/api';
import { captureError } from 'shared/helpers/captureError';
import { Button, Checkbox, RatingField, TextareaModal } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';
import { Modal } from 'widgets/common';
import { ThanksModal } from 'widgets/modals/thanks-modal';

const achievementsArray: ('promptly' | 'positive' | 'savvy')[] = ['promptly', 'positive', 'savvy'];

type ReviewExpertModalProps = {
  expertId: number;
  userId: number;
  consultationId: number;
};

export const ReviewExpertModal: FC<ReviewExpertModalProps> = ({ expertId, userId, consultationId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('utils');
  const isVisible = useSelector(selectors.consultationsChatRoom.selectIsReviewModalOpen);
  const [rating, setRating] = useState(0);
  const [reviewContent, setReviewContent] = useState('');
  const [returnMoney, setReturnMoney] = useState(false);
  const [achievements, setAchievements] = useState<{ promptly: boolean; positive: boolean; savvy: boolean }>({
    promptly: false,
    positive: false,
    savvy: false,
  });
  const [isSendingReview, setIsSendingReview] = useState(false);

  useEffect(() => {
    if (rating <= 3 && Object.values(achievements).includes(true)) {
      setAchievements({ positive: false, promptly: false, savvy: false });
    }
    if (rating >= 4 && returnMoney) {
      setReturnMoney(false);
    }
  }, [rating]);

  const handleSendReview = async () => {
    try {
      setIsSendingReview(true);
      await api.V1ReviewsApi.reviewsControllerCreateOne({
        clientId: userId,
        expertId,
        rating,
        feedback: reviewContent,
        consultationId,
        isPromptly: achievements.promptly,
        isSavvy: achievements.savvy,
        onPositive: achievements.positive,
        wantRefund: returnMoney,
      });
      await dispatch(actions.consultationsChatRoom.setIsReviewModalOpen(false));
      dispatch(actions.app.setIsVisibleThanksModal(true));
    } catch (e: any) {
      captureError(e);
      toast.error(e.message || t('somethingWrong'));
    } finally {
      setIsSendingReview(false);
    }
  };

  return (
    <>
      {isVisible && (
        <Modal isVisible={isVisible} width="310px">
          <Container>
            <Title>{t('modals.expertReview.title')}</Title>
            <Description>{t('modals.expertReview.description')}</Description>
            <RatingField rating={rating} setRating={setRating} />
            <TextareaModal
              placeholder={t('modals.expertReview.textareaPlaceholder')}
              value={reviewContent}
              onChange={(e) => setReviewContent(e.currentTarget.value)}
            />

            {rating >= 1 && rating <= 3 && (
              <ReturnMoney>
                <Checkbox checked={returnMoney} onChange={setReturnMoney} />
                <span>{t('modals.expertReview.returnMoney')}</span>
              </ReturnMoney>
            )}

            {rating >= 4 && (
              <>
                <GoodTitle>{t('modals.expertReview.whatsCool')}</GoodTitle>
                <AchievementsRow>
                  {achievementsArray.map((ach) => (
                    <Achievement key={ach}>
                      <AchievementButton
                        $isActive={achievements[ach]}
                        onClick={() => setAchievements({ ...achievements, [ach]: !achievements[ach] })}
                      >
                        <HeartWhiteIcon />
                      </AchievementButton>
                      <AchievementText>{t(`modals.expertReview.achievements.${ach}`)}</AchievementText>
                    </Achievement>
                  ))}
                </AchievementsRow>
              </>
            )}

            <SubmitButton
              block
              disabled={!rating || !reviewContent.length || isSendingReview}
              loading={isSendingReview}
              onClick={handleSendReview}
            >
              {t('modals.expertReview.button')}
            </SubmitButton>
          </Container>
        </Modal>
      )}

      <ThanksModal isBad={rating < 4} isReturningMoney={returnMoney} />
    </>
  );
};

const Container = styled.div`
  text-align: center;
`;
const Title = styled.h3`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 15px;
`;
const Description = styled.p`
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 40px;
`;
const ReturnMoney = styled.div`
  margin-top: 15px;
  display: flex;
  font-size: 15px;
  & > span {
    line-height: 20px;
  }
`;
const GoodTitle = styled.h4`
  font-size: 15px;
  font-weight: bold;
  margin: 40px 0 20px;
`;
const AchievementsRow = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: space-between;
`;
const Achievement = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const AchievementButton = styled.div<{ $isActive: boolean }>`
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 50%;
  background-color: var(--purple);
  display: flex;
  justify-content: center;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.2)};
  transition: opacity 0.2s;
  cursor: pointer;
  & > svg {
    margin-top: 13px;
  }
`;
const AchievementText = styled.p`
  font-size: 11px;
`;
const SubmitButton = styled(Button)`
  margin-top: 30px;
`;
