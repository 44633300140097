import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';

export const useScheduler = (isoTime: string | null, callback: CallableFunction) => {
  if (isoTime && !dayjs(isoTime).isValid()) {
    throw new Error(`useScheduler accepts only ISO Date. Check your code`);
  }

  const intervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!isoTime) {
      return;
    }

    intervalRef.current = setInterval(() => {
      const isTimeActual = dayjs(isoTime).isBefore(dayjs());

      if (isTimeActual) {
        callback();
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      }
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isoTime]);
};
