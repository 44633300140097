import { SimpleChat, Time } from 'app/assets/svg';
import { useTranslation } from 'next-i18next';
import { FC, useState, useEffect } from 'react';
import { User } from 'shared/api';
import { Button } from 'shared/ui';
import styled from 'styled-components';
import { scroller } from 'react-scroll';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { actions, selectors } from 'store/ducks';
import { unwrapResult } from '@reduxjs/toolkit';
import { useRouter } from 'next/router';
import { routes } from 'shared/common/routes';
import { captureError } from 'shared/helpers';
import { toast } from 'react-toastify';
import { ENVIRONMENT_TYPE } from 'shared/common/constants';
import ym from 'react-yandex-metrika';

type StartZoomMeetingWidgetProps = {
  expert: User;
  user: User;
};

export const StartZoomMeetingWidget: FC<StartZoomMeetingWidgetProps> = ({ expert, user }) => {
  const { t } = useTranslation('expert.page', { keyPrefix: 'zoomWidget' });
  const { t: u } = useTranslation('utils');
  const dispatch = useAppDispatch();
  const router = useRouter();

  const isUserAuthorized = useAppSelector(selectors.profile.selectIsAuthentication);
  const isFreeChatRedirect = useAppSelector(selectors.app.selectIsFreeChatRedirectInfo);
  const isFreeChatAsk = useAppSelector(selectors.app.selectIsFreeChatAskInfo);

  const categoriesPrices = expert.categories?.map((category) => category.price);
  const minPrice = categoriesPrices && Math.min(...categoriesPrices);
  const hasExpertFreeChat = expert.hasFreeChat;

  const handleSelectDateClick = () => {
    scroller.scrollTo('PublicExpertConsultations', {
      delay: 50,
      smooth: 'easeInOutQuint',
    });
  };

  const goToChatRoomAfterRedirect = async () => {
    try {
      const res = await dispatch(
        actions.chatRooms.createChatRoom({
          expert,
          client: user,
        }),
      );

      const data = unwrapResult(res);

      if (data) {
        dispatch(actions.chatRooms.addChatRoom(data!.room));
        await router.push({
          pathname: routes.chat,
          query: {
            activeTab: 'consult',
            roomId: data!.room.id,
          },
        });
      }
    } catch (e) {
      captureError(e);
      toast.error(u('somethingWrong'));
    }
  };

  useEffect(() => {
    if (isFreeChatRedirect) {
      goToChatRoomAfterRedirect();
    }
  }, [isFreeChatAsk]);

  const sendAnalyticsData = () => {
    if (ENVIRONMENT_TYPE === 'production') {
      ym('reachGoal', ['askfreechat']);
      if (window && window.gtag) {
        window.gtag('event', 'askfreechat');
      }
    }
  };

  const handleCreateChatClick = async () => {
    if (!isUserAuthorized) {
      dispatch(actions.app.setFreeChatRedirectInfo(true));
      return dispatch(actions.app.showAuthModal());
    }

    try {
      const res = await dispatch(
        actions.chatRooms.createChatRoom({
          expert,
          client: user,
        }),
      );

      const data = unwrapResult(res);

      if (data) {
        dispatch(actions.chatRooms.addChatRoom(data!.room));
        sendAnalyticsData();
        await router.push({
          pathname: routes.chat,
          query: {
            activeTab: 'consult',
            roomId: data!.room.id,
          },
        });
      }
    } catch (e) {
      captureError(e);
      toast.error(u('somethingWrong'));
    }
  };

  return (
    <Container>
      <TopContainer>
        <Title>{t('selectDate')}</Title>
        <TitleNote>{t('itsFree')}</TitleNote>
      </TopContainer>
      <ContentContainer>
        <Price>
          {t('priceFrom')} {`${minPrice} \u20bd`}
        </Price>
        <ZoomFeatures>
          <Feature>
            <IconContainer>
              <SimpleChat />
            </IconContainer>
            <FeatureText>{t('zoomMeeting')}</FeatureText>
          </Feature>
          <Feature>
            <IconContainer>
              <Time />
            </IconContainer>
            <FeatureText>{t('meetingDuration')}</FeatureText>
          </Feature>
        </ZoomFeatures>
        <ButtonsContainer>
          <Button onClick={handleSelectDateClick} width="100%">
            {t('selectTime')}
          </Button>
          {hasExpertFreeChat && (
            <Button onClick={handleCreateChatClick} width="100%" bordered>
              {t('askExpert')}
            </Button>
          )}
        </ButtonsContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 12px;
  min-width: 342px;
  background-color: var(--purple);
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  background-image: var(--gradient2);

  @media (max-width: 450px) {
    min-width: 100%;
  }
`;

const TopContainer = styled.div`
  padding: 20px;
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
  margin-bottom: 5px;
`;

const TitleNote = styled.div`
  opacity: 0.7;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
`;

const ContentContainer = styled.div`
  background-color: var(--white);
  border-radius: 12px;
  padding: 45px 20px 40px 20px;
`;

const Price = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 36px;
  line-height: 36px;
  color: var(--black);
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-row-gap: 15px;
`;

const ZoomFeatures = styled.div`
  margin-bottom: 30px;
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 7px;
  }
`;

const IconContainer = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const FeatureText = styled.div`
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color: var(--gray);
`;
