import { unwrapResult } from '@reduxjs/toolkit';
import { Star } from 'app/assets/svg';
import { useAppDispatch } from 'hooks/redux';
import { range } from 'lodash';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Review, User } from 'shared/api';
import { CONDITION_DESKTOP, DEFAULT_AVATAR, HEADER_HEIGHT_DESKTOP } from 'shared/common/constants';
import { Params } from 'shared/common/types';
import { coverImageStyle, getEventDateFromString } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { Button } from 'shared/ui';
import { actions } from 'store/ducks/profile';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

export const LIMIT = 10;

export const EXPERTS_LIMIT = 6;

type Props = {
  userId: User['id'];
};

export enum ReviewsTabs {
  ALL = 'all',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

type ReviewsDataMapping = {
  [key in ReviewsTabs]: {
    name: string;
    reviews: Review[] | null;
    total: number;
    page: number;
  };
};

export const ReviewsList: FC<Props> = ({ userId }) => {
  const { t, i18n } = useTranslation('expert.page');
  const dispatch = useAppDispatch();
  const [reviewsDataMapping, setReviewsDataMapping] = useState<ReviewsDataMapping>({
    [ReviewsTabs.ALL]: { name: t('all'), reviews: null, page: 1, total: 0 },
    [ReviewsTabs.POSITIVE]: { name: t('positive'), reviews: null, page: 1, total: 0 },
    [ReviewsTabs.NEGATIVE]: { name: t('negative'), reviews: null, page: 1, total: 0 },
  });
  const [isLoading, setIsLoading] = useState(false);

  const [activeTab, setActiveTab] = useState<ReviewsTabs>(ReviewsTabs.ALL);

  const reviewsTitleRef = useRef<HTMLDivElement>(null);

  const fetchTabReviews = async (tabToFetch: ReviewsTabs, nextPage?: boolean) => {
    try {
      setIsLoading(true);

      const tabCurrentData = reviewsDataMapping[tabToFetch];

      const filter = [`expertId||$eq||${userId}`, `authorId||$ne||${userId}`];

      if (tabToFetch === ReviewsTabs.POSITIVE) {
        filter.push(`rating||$gt||3`);
      }

      if (tabToFetch === ReviewsTabs.NEGATIVE) {
        filter.push(`rating||$lte||3`);
      }

      const params: Params = {
        join: ['client', 'consultation', 'consultation.category'],
        filter,
        sort: ['createdAt,DESC'],
        limit: LIMIT,
        offset: LIMIT * (nextPage ? tabCurrentData.page : tabCurrentData.page - 1),
      };

      const action = await dispatch(
        actions.getManyReviewsAsync({
          fields: params.fields,
          s: params.s,
          filter: params.filter,
          or: params.or,
          sort: params.sort,
          join: params.join,
          limit: params.limit,
          offset: params.offset,
        }),
      );
      const { data, page, total } = unwrapResult(action);

      const newReviewsInfo: ReviewsDataMapping[typeof tabToFetch] = {
        ...tabCurrentData,
        page,
        reviews: [...(tabCurrentData.reviews ?? []), ...data],
        total,
      };

      setReviewsDataMapping((prev) => ({ ...prev, [tabToFetch]: newReviewsInfo }));

      if (process.browser && window.location.hash === '#reviews' && reviewsTitleRef.current) {
        reviewsTitleRef.current.scrollIntoView(true);
        window.scrollBy(0, -parseInt(HEADER_HEIGHT_DESKTOP, 10));
      }
    } catch (e) {
      captureError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTabReviews(ReviewsTabs.ALL);
    fetchTabReviews(ReviewsTabs.POSITIVE);
    fetchTabReviews(ReviewsTabs.NEGATIVE);
  }, []);

  const handleChangeActiveTab = (tab: ReviewsTabs) => {
    if (activeTab === tab) {
      return;
    }

    setActiveTab(tab);
  };

  const renderReviewsTabs = () =>
    Object.entries(reviewsDataMapping).map(([tabValue, tabData]) => (
      <ReviewTab
        active={activeTab === tabValue}
        onClick={() => handleChangeActiveTab(tabValue as ReviewsTabs)}
        key={tabValue}
      >
        <span>{tabData.name}</span>
        <span>{tabData.total ?? 0}</span>
      </ReviewTab>
    ));

  const renderReviews = () => {
    const activeTabData = reviewsDataMapping[activeTab];

    const { reviews } = activeTabData;

    if (!reviews) {
      return null;
    }

    const reviewsJSX = reviews.map((review) => (
      <CommentContainer key={review.id}>
        <div>
          <Avatar $url={review.client?.avatarUrl} />
        </div>
        <ContentContainer>
          <FeedbackHead>
            <Rating>
              {range(review.rating).map(() => (
                <IconWrap key={uuidv4()}>
                  <Star />
                </IconWrap>
              ))}
            </Rating>
            <Date>{getEventDateFromString(review.createdAt, i18n.language)}</Date>
          </FeedbackHead>
          <FeedbackTheme>{review.consultation?.category?.name}</FeedbackTheme>
          <Feedback>
            <span>{review.feedback}</span>
          </Feedback>
          <AuthorName>{review.client?.firstName}</AuthorName>
        </ContentContainer>
      </CommentContainer>
    ));

    const reviewsLeft = activeTabData.total - reviews.length;
    const moreButtonJSX = reviews.length < activeTabData.total && (
      <ButtonContainer>
        <Button onClick={() => fetchTabReviews(activeTab, true)} size="medium" bordered loading={isLoading}>
          {t('moreReviews', {
            count: reviewsLeft > 10 ? LIMIT : reviewsLeft,
          })}
        </Button>
      </ButtonContainer>
    );

    return (
      <>
        {reviewsJSX}
        {moreButtonJSX}
      </>
    );
  };

  return (
    <Container>
      <Title ref={reviewsTitleRef}>{t('comments')}</Title>
      <ReviewsInfo>{renderReviewsTabs()}</ReviewsInfo>
      {renderReviews()}
    </Container>
  );
};

const Container = styled.div`
  max-width: 660px;
  margin-bottom: 60px;
  ${CONDITION_DESKTOP} {
    margin-bottom: 90px;
  }
`;

const Title = styled.div`
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  padding-bottom: 30px;

  ${CONDITION_DESKTOP} {
    font-size: 25px;
  }
`;

const CommentContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
  gap: 10px;
`;

const Date = styled.div`
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  color: var(--gray9);
`;

interface AvatarProps {
  $url: string | null | undefined;
}

const Avatar = styled.div<AvatarProps>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 3px;
  /* background-color: var(--gray4); */
  ${({ $url }) => ({ ...coverImageStyle($url || DEFAULT_AVATAR) })}
`;

const ContentContainer = styled.div`
  width: 100%;
`;
const FeedbackHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
`;
const Feedback = styled.div`
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  & > span {
    width: 100%;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
`;
const FeedbackTheme = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 5px;
`;
const AuthorName = styled.div`
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--gray);
`;

const ReviewsInfo = styled.div`
  display: flex;
  border-bottom: solid 1px var(--gray6);
  gap: 20px;
  margin-bottom: 30px;
  ${CONDITION_DESKTOP} {
    gap: 30px;
    margin-bottom: 50px;
  }
`;

interface ReviewTabProps {
  active: boolean;
}
const ReviewTab = styled.div<ReviewTabProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  border-bottom: ${({ active }) => (active ? '1px solid var(--purple)' : 'none')};

  & > svg {
    display: unset;
    ${CONDITION_DESKTOP} {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;
    & > span:first-child {
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.01;
      letter-spacing: normal;
      color: var(--text);
    }
  }

  & > span:first-child {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.01;
    letter-spacing: normal;
    color: ${({ active }) => (active ? 'var(--text)' : 'var(--gray3)')};
  }
  & > span:last-child {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.01;
    letter-spacing: normal;
    color: var(--gray3);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const IconWrap = styled.div`
  margin-right: 2px;
`;
