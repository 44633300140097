import { Category, Rubric, UserCategoryMeta } from 'shared/api';

export const getUserConsultationsRubricMap = (
  userCategories: UserCategoryMeta[] | undefined,
  categoriesMap: Map<number, Category>,
) => {
  const consultationsRubrics = new Map<number, UserCategoryMeta[]>();
  userCategories?.forEach((userCategory) => {
    const category = categoriesMap.get(userCategory.categoryId)!;
    if (category.rubrics.length !== 0) {
      category.rubrics.forEach((rubric) => {
        const rubricCategories = consultationsRubrics.get(rubric.id) || [];
        consultationsRubrics.set(rubric.id, [...rubricCategories, userCategory]);
      });
    } else if (category.rootCategory) {
      getRubrics(categoriesMap.get(category.rootCategory.id)!, categoriesMap).forEach((rubric) => {
        const rubricCategories = consultationsRubrics.get(rubric.id) || [];
        consultationsRubrics.set(rubric.id, [...rubricCategories, userCategory]);
      });
    }
  });
  return consultationsRubrics;
};

function getRubrics(category: Category, categoriesMap: Map<number, Category>): Rubric[] {
  if (category.rubrics.length === 0) {
    if (category.rootCategory) {
      return getRubrics(categoriesMap.get(category.rootCategory.id)!, categoriesMap);
    }
    return [];
  }
  return category.rubrics;
}
