import { useAppSelector } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { MessageSystemMessageTypeEnum, User } from 'shared/api';
import { currencies } from 'shared/common/constants';
import { MessageExtended } from 'shared/common/types';
import { selectors } from 'store/ducks';

import { ChatMessage } from '../chat-message';

type ChatSystemMessageProps = {
  message: MessageExtended;
  isUserExpertInRoom: boolean;
  expertId: User['id'];
};

export const ChatSystemMessage: FC<ChatSystemMessageProps> = ({ message, isUserExpertInRoom, expertId }) => {
  const { t } = useTranslation('chat.page');

  const { id: userId } = useAppSelector(selectors.profile.selectUser);

  const renderMessage = () => {
    switch (message.systemMessageType) {
      case MessageSystemMessageTypeEnum.ConsultationInitInvoiceCreated: {
        return null;
      }

      case MessageSystemMessageTypeEnum.ConsultationProlongationRequestCreated: {
        if (isUserExpertInRoom) {
          return (
            <ChatMessage
              key={message.id}
              message={{
                content: t('chatRoom.systemMessages.pendingProlongation'),
                createdAt: message.createdAt,
              }}
              variant="system"
            />
          );
        }

        return null;
      }

      case MessageSystemMessageTypeEnum.ConsultationPendingStart: {
        if (!isUserExpertInRoom) {
          return (
            <ChatMessage
              key={message.id}
              message={{
                content: t('chatRoom.systemMessages.waitStartConsultation', {
                  price: message.systemMessageMetadata.price,
                  currency: currencies.ruble,
                }),
                createdAt: message.createdAt,
              }}
              variant="system"
            />
          );
        }

        return (
          <ChatMessage
            key={message.id}
            message={{
              content: t('chatRoom.systemMessages.paidConsultation', {
                price: message.systemMessageMetadata.price,
                currency: currencies.ruble,
              }),
              createdAt: message.createdAt,
            }}
            variant="system"
          />
        );
      }

      case MessageSystemMessageTypeEnum.ConsultationInitInvoiceTimeExpired: {
        return (
          <ChatMessage
            key={message.id}
            message={{
              content: isUserExpertInRoom
                ? t('chatRoom.systemMessages.initInvoiceExpired.expert')
                : t('chatRoom.systemMessages.initInvoiceExpired.client'),
              createdAt: message.createdAt,
            }}
            variant="system"
          />
        );
      }

      case MessageSystemMessageTypeEnum.ConsultationEnded: {
        return (
          <ChatMessage
            key={message.id}
            message={{
              content:
                message.systemMessageMetadata.enderId === expertId
                  ? t('chatRoom.systemMessages.endByExpert')
                  : t('chatRoom.systemMessages.endByClient'),
              createdAt: message.createdAt,
            }}
            variant="system"
          />
        );
      }

      case MessageSystemMessageTypeEnum.ConsultationOfferRejected: {
        return (
          <ChatMessage
            key={message.id}
            message={{
              content:
                message.systemMessageMetadata.rejectorId === userId
                  ? t('chatRoom.systemMessages.rejectChatOffer.client')
                  : t('chatRoom.systemMessages.rejectChatOffer.expert'),
              createdAt: message.createdAt,
            }}
            variant="system"
          />
        );
      }

      case MessageSystemMessageTypeEnum.ConsultationStarted: {
        if (!isUserExpertInRoom) {
          return (
            <ChatMessage
              key={message.id}
              message={{
                content: t('chatRoom.systemMessages.startConsultation'),
                createdAt: message.createdAt,
              }}
              variant="system"
            />
          );
        }
        return null;
      }

      case MessageSystemMessageTypeEnum.ConsultationMessagesLimitExceeded: {
        if (isUserExpertInRoom) {
          return (
            <ChatMessage
              key={message.id}
              message={{
                content: t('chatRoom.systemMessages.endMessageLimitExceeded.expert'),
                createdAt: message.createdAt,
              }}
              variant="system"
            />
          );
        }

        return (
          <ChatMessage
            key={message.id}
            message={{
              content: t('chatRoom.systemMessages.endMessageLimitExceeded.client'),
              createdAt: message.createdAt,
            }}
            variant="system"
          />
        );
      }

      case MessageSystemMessageTypeEnum.ConsultationTimeLimitExceeded: {
        return (
          <ChatMessage
            key={message.id}
            message={{
              content: t('chatRoom.systemMessages.endTimeExceeded'),
              createdAt: message.createdAt,
            }}
            variant="system"
          />
        );
      }

      case MessageSystemMessageTypeEnum.ConsultationProlongationPayed: {
        return (
          <ChatMessage
            key={message.id}
            message={{
              content: isUserExpertInRoom
                ? t('chatRoom.systemMessages.prolongationSuccess.expert')
                : t('chatRoom.systemMessages.prolongationSuccess.client'),
              createdAt: message.createdAt,
            }}
            variant="system"
          />
        );
      }

      case MessageSystemMessageTypeEnum.ConsultationProlongationRejected: {
        return (
          <ChatMessage
            key={message.id}
            message={{
              content: isUserExpertInRoom
                ? t('chatRoom.systemMessages.prolongationReject.expert')
                : t('chatRoom.systemMessages.prolongationReject.client'),
              createdAt: message.createdAt,
            }}
            variant="system"
          />
        );
      }

      case MessageSystemMessageTypeEnum.ConsultationProlongedFree: {
        return (
          <ChatMessage
            key={message.id}
            message={{
              content: t('chatRoom.systemMessages.prolongationSuccess.free'),
              createdAt: message.createdAt,
            }}
            variant="system"
          />
        );
      }

      case MessageSystemMessageTypeEnum.ConsultationExtraServicePayed: {
        return (
          <ChatMessage
            key={message.id}
            message={{
              content: isUserExpertInRoom
                ? t('chatRoom.systemMessages.extraService.fulfilled.expert', {
                    price: message.systemMessageMetadata.price,
                    name: message.systemMessageMetadata.description,
                  })
                : t('chatRoom.systemMessages.extraService.fulfilled.client', {
                    price: message.systemMessageMetadata.price,
                    name: message.systemMessageMetadata.description,
                  }),
              createdAt: message.createdAt,
            }}
            variant="system"
          />
        );
      }

      case MessageSystemMessageTypeEnum.ConsultationExtraServiceRejected: {
        return (
          <ChatMessage
            key={message.id}
            message={{
              content: isUserExpertInRoom
                ? t('chatRoom.systemMessages.extraService.rejected.expert')
                : t('chatRoom.systemMessages.extraService.rejected.client'),
              createdAt: message.createdAt,
            }}
            variant="system"
          />
        );
      }

      case MessageSystemMessageTypeEnum.ConsultationExtraServiceExpired: {
        return (
          <ChatMessage
            key={message.id}
            message={{
              content: t('chatRoom.systemMessages.extraService.expired'),
              createdAt: message.createdAt,
            }}
            variant="system"
          />
        );
      }

      case MessageSystemMessageTypeEnum.ConsultationExtraServiceOfferCreated: {
        if (isUserExpertInRoom) {
          return (
            <ChatMessage
              key={message.id}
              message={{
                content: t('chatRoom.systemMessages.extraService.pendingPayment', {
                  description: message.systemMessageMetadata.description,
                }),
                createdAt: message.createdAt,
              }}
              variant="system"
            />
          );
        }

        return null;
      }

      case MessageSystemMessageTypeEnum.ConsultationsPayedWithPromocode: {
        return (
          <ChatMessage
            key={message.id}
            message={{
              content: isUserExpertInRoom
                ? t('chatRoom.systemMessages.payedWithPromocode.expert')
                : t('chatRoom.systemMessages.payedWithPromocode.client'),
              createdAt: message.createdAt,
            }}
            variant="system"
          />
        );
      }
      default:
        return null;
    }
  };

  return renderMessage();
};
