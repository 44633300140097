import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PaymentOperationTypeEnum } from 'shared/api';
import { captureError } from 'shared/helpers/captureError';
import { getEventDateFromString } from 'shared/helpers/time';
import { selectors } from 'store/ducks/profile';
import { fetchSelfPayments } from 'store/ducks/profile/actions';
import styled from 'styled-components';

import { Pagination } from '../../pagination';

export const ExpertWalletWithdravals: FC = () => {
  const payments = useSelector(selectors.selectPayments);

  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation('profile.page');

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    getPayments();
  }, [page]);

  useEffect(() => {
    onPage(1);
  }, []);

  const onPage = (page: number) => {
    if (payments.pageCount && page <= payments.pageCount) {
      setPage(page);
    }
  };

  const getPayments = async () => {
    try {
      await dispatch(
        fetchSelfPayments({ page, type: PaymentOperationTypeEnum.Withdrawal as PaymentOperationTypeEnum }),
      );
    } catch (e) {
      captureError(e);
    }
  };

  return (
    <>
      <Block>
        <Table>
          <Row>
            <ColumnName>{t('walletTab.date')}</ColumnName>
            <AmountColumnName>{t('walletTab.withdravalAmount')}</AmountColumnName>
          </Row>

          {payments.data?.map((payment) => (
            <Row key={payment.id}>
              <Column>{getEventDateFromString(payment.createdAt, i18n.language)}</Column>
              <AmountColumn>+ {t('walletTab.count', { count: parseFloat(payment.amount.toFixed(2)) })}</AmountColumn>
            </Row>
          ))}
        </Table>
      </Block>

      {payments.total > 10 && <Pagination page={page} setPage={onPage} pageCount={payments.pageCount || 0} />}
    </>
  );
};

const Block = styled.div`
  margin-bottom: 60px;
`;

const Table = styled.div`
  overflow-x: scroll;
  & > div:first-child {
    border-bottom: none;
  }
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 5px;
  width: 820px;
  border-bottom: 1px solid var(--gray6);
`;
const Column = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.85;
  letter-spacing: normal;

  color: var(--text);
`;

const AmountColumn = styled(Column)`
  color: var(--purple);
  text-align: right;
`;

const ColumnName = styled(Column)`
  text-transform: uppercase;
  color: var(--gray);
`;

const AmountColumnName = styled(ColumnName)`
  text-align: right;
`;
