import { BurgerClose } from 'app/assets/svg';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';
import { PromoModal } from 'routes/landing/pages/Main/PromoModal/PromoModal';
import { CONDITION_DESKTOP, SCROLLABLE_DESKTOP } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { Button } from 'shared/ui';
import styled from 'styled-components';

interface HellobarProps {
  visible: boolean;
  onClose: () => void;
}

export const Hellobar: FC<HellobarProps> = ({ visible, onClose }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation('utils');

  if (!visible) return null;
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <Wrapper>
      <HelloBarRow>
        <Container>
          {/* <Gift src={'/img/gift@3x.png'} width={23} height={23} /> */}
          <Text>{t('helloBar.title')}</Text>
          <HelloButton size="small" color="purple-gradient" onClick={toggleModal}>
            {t('helloBar.buttonText')}
          </HelloButton>
        </Container>
        <CloseBtnContainer>
          <CloseButton fill="#812df6" onClick={onClose} />
        </CloseBtnContainer>
      </HelloBarRow>

      <PromoModal show={showModal} onHide={toggleModal} title={<StyledTitle>{t('helloBar.modal.title')}</StyledTitle>}>
        <PromoModalInner>
          <ModalContent>
            <Note>
              Нам неприятно это осознавать и анонсировать, но с 1 мая мы останавливаем работу проекта в России.
            </Note>
            <Note>
              Consulty создавался до всех мировых событий, как международный проект со стартом в России. В текущей
              ситуации так работать не получается, поэтому мы решили сфокусироваться на рынке США.
            </Note>
            <Note>
              От вас не требуется никаких действий. Если вы хотите удалить аккаунт, напишите нам в поддержку на{' '}
              <a href="mailto:support@consulty.online">support@consulty.online</a> или в{' '}
              <a href="https://t.me/consultyonline">Telegram</a>, но это не обязательно.
            </Note>
            <Note>
              Наша поддержка работает до конца апреля, поэтому если у вас есть нерешенные вопросы, пожалуйста, напишите
              нам до этого времени. Сайт продолжит функционировать и далее, но регистрация и консультации будут
              ограничены. <Link href={routes.oferta}>Здесь</Link> вы можете прочитать юридические подробности
            </Note>
            <Note>
              Мы не успели сделать, все что хотели в России, но очень надеемся вернуться, если ситуация изменится. Если
              вы связаны с IT сферой, работали на рынке США и вам интересен Consulty - пишите нам, возможно сделаем
              что-то вместе.
            </Note>
            <Note>Спасибо всем, кто поверил в нас!</Note>
          </ModalContent>
          <Button type="button" block onClick={toggleModal}>
            {t('helloBar.modal.buttonText')}
          </Button>
        </PromoModalInner>
      </PromoModal>
    </Wrapper>
  );
};

const ModalContent = styled.div`
  padding-top: 20px;
  padding-bottom: 30px;
`;

const PromoModalInner = styled.div`
  padding-bottom: 20px;
`;

const Wrapper = styled.div`
  padding: 8px;
  background-color: #fcfcfc;
  align-items: center;
  //border-top: solid 2px #8b33ff;
  border-bottom: solid 1px #f4f4f5;
  ${`@media(max-width: ${SCROLLABLE_DESKTOP}px)`} {
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
`;

const Note = styled.div`
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  text-align: lefte;
  color: var(--text);
  :not(:last-child) {
    padding-bottom: 10px;
  }

  a {
    color: var(--purple);
    text-decoration: underline;
  }
`;

const BoldNote = styled(Note)`
  font-weight: bold;
  color: var(--text);
`;

const HelloButton = styled(Button)`
  margin-left: 10px;
  ${CONDITION_DESKTOP} {
    white-space: nowrap;
  }
`;

const Container = styled.div`
  display: flex;
  grid-area: InnerContent;
  align-items: center;
  justify-content: center;
  ${`@media(max-width: ${SCROLLABLE_DESKTOP}px)`} {
    flex-direction: column;
    justify-content: center;
  }
`;

const Text = styled.span`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #8b33ff;
  text-align: center;
  ${`@media(max-width: ${SCROLLABLE_DESKTOP}px)`} {
    margin-bottom: 5px;
  }
  ${CONDITION_DESKTOP} {
    white-space: nowrap;
  }
`;

// const Button = styled.div`
//   margin: 0 0 0 10.5px;
//   padding: 7px 15px 8px;
//   border-radius: 15px;
//   font-size: 14px;
//   color: white;
//   cursor: pointer;
//   background-image: linear-gradient(to right, #812df6 5%, #993dfa 48%, #ae4cfe);
// `;

const CloseButton = styled(BurgerClose)`
  cursor: pointer;
`;

const CloseBtnContainer = styled.div`
  grid-area: CloseButton;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  align-items: center;
  ${`@media(max-width: ${SCROLLABLE_DESKTOP}px)`} {
    position: absolute;
    top: 10px;
    right: 5px;
  }
`;

const HelloBarRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  grid-template-areas: 'Spacer InnerContent CloseButton';
  ${CONDITION_DESKTOP} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  line-height: 31px;
  color: '#ffffff';
`;
