import { BackArrowPurple } from 'app/assets/svg';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { ChatRouter } from 'routes/chat/chat';
import { CONDITION_DESKTOP, DEFAULT_CATEGORY } from 'shared/common/constants';
import { chooseEndingWord, coverImageStyle } from 'shared/helpers';
import { selectors } from 'store/ducks';
import styled from 'styled-components';

interface ResponseHeaderProps extends HTMLAttributes<HTMLDivElement> {
  requestId: number | null;
}

export const ResponseHeader: FC<ResponseHeaderProps> = ({ requestId, ...props }) => {
  const { t } = useTranslation('chat.page');
  const router: ChatRouter = useRouter();
  const responseRoom = useSelector(selectors.consultationRequests.selectResponseRoomById(requestId));

  const goBack = () => router.replace({ query: { activeTab: 'responses' } });

  return (
    <Container {...props}>
      <BackButton onClick={goBack} />
      <Image
        $url={
          responseRoom?.category.iconUrl ||
          (responseRoom?.category.rubrics && responseRoom?.category.rubrics[0]?.iconUrl) ||
          DEFAULT_CATEGORY
        }
      />
      <Text>
        <Name>{responseRoom?.category.name}</Name>
        <Description>
          {!!responseRoom &&
            chooseEndingWord(responseRoom.category.expertsCount, [
              t('response.header.subtitle.one', { count: responseRoom.category.expertsCount }),
              t('response.header.subtitle.two', { count: responseRoom.category.expertsCount }),
              t('response.header.subtitle.many', { count: responseRoom.category.expertsCount }),
            ])}
        </Description>
      </Text>
    </Container>
  );
};

const Container = styled.div`
  height: 60px;
  width: 100vw;
  display: flex;
  padding: 0 10px 0 15px;
  align-items: center;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  ${CONDITION_DESKTOP} {
    padding: 0 20px;
    width: 100%;
  }
`;
const BackButton = styled(BackArrowPurple)`
  ${CONDITION_DESKTOP} {
    display: none;
  }
`;
const Image = styled.div<{ $url?: string }>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  flex-shrink: 0;
  margin: 0 10px 0 20px;
  ${({ $url }) => $url && { ...coverImageStyle($url) }}
  ${CONDITION_DESKTOP} {
    display: none;
  }
`;
const Text = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;
const Name = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  ${CONDITION_DESKTOP} {
    font-weight: 500;
    font-size: 13px;
  }
`;
const Description = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: var(--gray);
`;
