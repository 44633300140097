import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { CONDITION_MOBILE } from 'shared/common/constants';
import { Textarea } from 'shared/ui';
import styled from 'styled-components';

import { AboutSelfFormValues } from '../expert-profile/forms/about-self-form/about-self-form';

type AboutSelfInputProps = {
  control: Control<AboutSelfFormValues>;
  value?: string;
};

export const AboutSelfInput: FC<AboutSelfInputProps> = ({ control, value }) => {
  const { t } = useTranslation('profile.page');

  return (
    <TextAreaContainer>
      <TextAreaTitle>{t('aboutSelfTitle')}</TextAreaTitle>
      <TextAreaNoteWrapper>
        <TextAreaNote>{t('aboutSelfNote')}</TextAreaNote>
      </TextAreaNoteWrapper>
      <Controller
        control={control}
        name="aboutSelf"
        rules={{
          minLength: { value: 10, message: t('minLenght') },
        }}
        defaultValue={value || undefined}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextAreaWrapper>
            <Textarea
              onChange={(e) => {
                if (e.target.value.length <= 1000) {
                  onChange(e);
                }
              }}
              value={value}
              error={error?.message && error.message}
              label={t('aboutYourSelf')}
              isShowCounterAndLabel={false}
              placeholder={t('aboutYourSelfPlaceholder')}
            />
          </TextAreaWrapper>
        )}
      />
    </TextAreaContainer>
  );
};

const TextAreaContainer = styled.div`
  padding-top: 40px;
`;

const TextAreaTitle = styled.h3`
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111111;
  margin-bottom: 10px;
`;

const TextAreaNote = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #79818c;
`;

const TextAreaNoteWrapper = styled.div`
  margin-bottom: 30px;

  ${CONDITION_MOBILE} {
    padding-right: 20px;
  }
`;

const TextAreaWrapper = styled.div`
  ${CONDITION_MOBILE} {
    margin-right: 20px;
  }
`;
