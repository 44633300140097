import {
  FastAnswerAchievement,
  FireAchievement,
  LargeAchievement,
  PositiveAchievement,
  SmartAchievement,
  SuperLargeAchievement,
  TargetAchievement,
} from 'app/assets/svg';
import { TFunction } from 'next-i18next';
import { Achievement as ApiAchievement, AchievementNameEnum } from 'shared/api';
import { Achievement } from 'shared/ui';

export const renderAchievement = (ach: ApiAchievement | undefined, t: TFunction) => {
  if (!ach) return null;
  switch (ach.name) {
    case AchievementNameEnum.MaestroOfConsultations:
      return <Achievement key={ach.id} label={t('maestroOfConsultations')} icon={<SuperLargeAchievement />} />;
    case AchievementNameEnum.OnThePositive:
      return <Achievement key={ach.id} label={t('onThePositive')} icon={<PositiveAchievement />} />;
    case AchievementNameEnum.Profi:
      return <Achievement key={ach.id} label={t('profi')} icon={<LargeAchievement />} />;
    case AchievementNameEnum.PromptConsultation:
      return <Achievement key={ach.id} label={t('promptConsultation')} icon={<FireAchievement />} />;
    case AchievementNameEnum.RespondsQuickly:
      return <Achievement key={ach.id} label={t('respondsQuickly')} icon={<FastAnswerAchievement />} />;
    case AchievementNameEnum.Smartest:
      return <Achievement key={ach.id} label={t('smartest')} icon={<SmartAchievement />} />;
    case AchievementNameEnum.SuperExpert:
      return <Achievement key={ach.id} label={t('superExpert')} icon={<TargetAchievement />} />;
    default:
      return null;
  }
};
