/* eslint-disable jsx-a11y/anchor-is-valid */
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ym from 'react-yandex-metrika';
import { ChatRouter } from 'routes/chat/chat';
import { User } from 'shared/api';
import { CONDITION_DESKTOP, DEFAULT_AVATAR, ENVIRONMENT_TYPE } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { captureError } from 'shared/helpers/captureError';
import { chooseEndingWord } from 'shared/helpers/chooseEndingWords';
import { Button } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

type RequestForm = {
  request: string;
};

interface AskExpertFormProps {
  threeBestExpert: User[];
  expertsCount: number;
  categoryId: number;
}

export const AskExpertForm: FC<AskExpertFormProps> = ({ threeBestExpert, expertsCount, categoryId }) => {
  const dispatch = useAppDispatch();
  const router: ChatRouter = useRouter();
  const { t } = useTranslation('ask-expert-form.component');
  const { t: utils } = useTranslation('utils');
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
    getValues,
  } = useForm<RequestForm>();
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isUserAuthorized = useAppSelector(selectors.profile.selectIsAuthentication);
  const isFreeThemeRedirect = useAppSelector(selectors.app.selectIsFreeThemeRedirectInfo);

  const steps = [
    {
      title: t('steps.one.title'),
      text: t('steps.one.text'),
    },
    {
      title: t('steps.two.title'),
      text: t('steps.two.text'),
    },
    {
      title: t('steps.three.title'),
      text: t('steps.three.text'),
      isLast: true,
    },
  ];

  const sendAnalyticsData = () => {
    if (ENVIRONMENT_TYPE === 'production') {
      ym('reachGoal', ['askexpert']);
    }
  };

  const sendRequest = async (content: string) => {
    try {
      setIsSendingRequest(true);
      const actionResult = await dispatch(
        actions.consultationRequests.createConsultationRequest({
          categoryId,
          content,
        }),
      );
      reset({ request: '' });
      unwrapResult(actionResult);
      sendAnalyticsData();
      setShowModal(true);
    } catch (error: any) {
      captureError(error);
      toast.error(error.message || utils('somethingWrong'));
    } finally {
      setIsSendingRequest(false);
    }
  };

  const sendMessagefterRedirect = async () => {
    if (!isSendingRequest) {
      sendRequest(request);
      dispatch(actions.app.setFreeThemeChatRedirectInfo({ isFreeThemeRedirect: false }));
    }
  };

  const onRequestSubmit = async ({ request }: RequestForm) => {
    if (!isUserAuthorized) {
      dispatch(actions.app.setFreeThemeChatRedirectInfo({ isFreeThemeRedirect: true }));
      return dispatch(actions.app.showAuthModal());
    }
    const content = request.trim();
    if (content && !isSendingRequest) {
      sendRequest(content);
    }
  };

  const request = getValues('request');

  useEffect(() => {
    if (isFreeThemeRedirect.isSignedUp && request.trim().length) {
      sendMessagefterRedirect();
    }
  }, [isFreeThemeRedirect.isSignedUp]);

  // const { guardedCallback: guardedOnSubmit } = useIsUserAuthorizedGuard(onRequestSubmit);

  return (
    <Container>
      <Title>{t('whatAsk')}</Title>
      <FlexContainer>
        <Form>
          <Controller
            name="request"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <Textarea value={value} onChange={onChange} placeholder={t('whatAskExample')} />
            )}
          />
          <ActionBlock>
            <Experts>
              <ExpertsText>
                <ExpertsTitle>
                  {chooseEndingWord(expertsCount, [
                    t('experts.one', { count: expertsCount }),
                    t('experts.two', { count: expertsCount }),
                    t('experts.many', { count: expertsCount }),
                  ])}
                </ExpertsTitle>
                <ExpertsDescription>{t('expertsWriteThemselves')}</ExpertsDescription>
              </ExpertsText>
              {threeBestExpert.length > 0 && (
                <ExpertPics>
                  {threeBestExpert.slice(0, 3).map((expert, index) => (
                    <ExpertAvatar key={expert.id} $index={index}>
                      <img src={expert.avatarUrl || DEFAULT_AVATAR} alt="expert avatar" />
                    </ExpertAvatar>
                  ))}
                </ExpertPics>
              )}
            </Experts>
            <Button
              color="red"
              onClick={handleSubmit(onRequestSubmit)}
              disabled={isSendingRequest || !isDirty}
              loading={isSendingRequest}
            >
              {t('askFree')}
            </Button>
          </ActionBlock>
          <BottomBlock>
            <Like src="/img/like.png" srcSet="/img/like@2x.png 2x, /img/like@3x.png 3x" alt="like" />
            <BottomText>
              <BottomTitle>{t('expertHelps')}</BottomTitle>
              <BottomDescription>{t('returnMoney')}</BottomDescription>
            </BottomText>
          </BottomBlock>
        </Form>
        <Steps>
          {steps.map((step, index) => (
            <Step {...step} index={index + 1} key={index} />
          ))}
        </Steps>
      </FlexContainer>
      <Modal isVisible={showModal} onClose={() => setShowModal(false)}>
        <ModalTitle>Отправили вопрос экспертам!</ModalTitle>
        <Note>
          Они откликнутся вам в&nbsp;<a href="/app/chat">мессенджере Consulty</a>.
        </Note>
        <Note>Чтобы перейти в&nbsp;мессенджер, нажмите значок диалога 💬 справа вверху на&nbsp;сайте.</Note>
        <Note>
          А&nbsp;еще можете <a href="/app/mobile">скачать наше приложение</a> и&nbsp;отслеживать отклики там.{' '}
        </Note>
        <ModalButton
          onClick={() => {
            router.push({
              pathname: routes.chat,
              query: {
                activeTab: 'responses',
              },
            });
            setShowModal(false);
          }}
          color="purple"
        >
          Смотреть отклики
        </ModalButton>
        <ModalLink>
          <a href="#" onClick={() => setShowModal(false)}>
            Выбрать эксперта самостоятельно
          </a>
        </ModalLink>
      </Modal>
    </Container>
  );
};

type StepProps = {
  title: string;
  text: string;
  index: number;
  isLast?: boolean;
};

const Step: FC<StepProps> = ({ text, title, index, isLast }) => (
  <StepContainer>
    <IndexContainer>
      <Index>{index}</Index>
      {!isLast && <LineToNext />}
    </IndexContainer>
    <StepText>
      <StepTitle>{title}</StepTitle>
      <StepDescription>{text}</StepDescription>
    </StepText>
  </StepContainer>
);

const Container = styled.div`
  margin: 30px 0;
  ${CONDITION_DESKTOP} {
    margin: 50px 0 60px;
  }
`;
const Title = styled.h4`
  font-size: 17px;
  font-weight: 500;
  line-height: 1.47;
  margin-bottom: 20px;
  ${CONDITION_DESKTOP} {
    font-size: 19px;
    margin-bottom: 15px;
  }
`;
const FlexContainer = styled.div`
  ${CONDITION_DESKTOP} {
    display: flex;
  }
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow: hidden;
  margin-bottom: 40px;
  ${CONDITION_DESKTOP} {
    margin-bottom: 0;
    margin-right: 105px;
    width: 684px;
  }
`;
const Textarea = styled.textarea`
  width: 100%;
  resize: none;
  height: 150px;
  font-size: 14px;
  line-height: 1.43;
  padding: 20px;
  border: none;
  border-radius: 12px 12px 0 0;
  outline: none;
  ${CONDITION_DESKTOP} {
    padding: 25px;
    font-size: 16px;
  }
`;
const ActionBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 15px 20px;
  background-color: var(--white);
  border-top: 1px solid var(--gray6);
  border-radius: 0 0 12px 12px;
  ${CONDITION_DESKTOP} {
    flex-direction: row;
    padding: 20px 25px;
  }
`;
const Experts = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  ${CONDITION_DESKTOP} {
    flex-direction: row-reverse;
    flex-grow: 1;
    justify-content: flex-end;
    margin-bottom: 0;
    align-items: center;
  }
`;
const ExpertsText = styled.div``;
const ExpertsTitle = styled.p`
  font-weight: bold;
  font-size: 15px;
  color: var(--text);
`;
const ExpertsDescription = styled.p`
  font-size: 12px;
  color: var(--gray9);
  ${CONDITION_DESKTOP} {
    font-size: 15px;
  }
`;
const ExpertPics = styled.div`
  position: relative;
  width: 75px;
  height: 30px;
  ${CONDITION_DESKTOP} {
    margin-right: 15px;
    height: 40px;
    width: 100px;
  }
`;
const ExpertAvatar = styled.div<{ $index: number }>`
  position: absolute;
  right: ${({ $index }) => `${(2 - $index) * 22}px`};
  width: 30px;
  height: 30px;
  border: 2px solid var(--white);
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--white);
  display: flex;
  flex-direction: row-reverse;
  img {
    width: 100%;
    object-fit: cover;
  }
  ${CONDITION_DESKTOP} {
    width: 40px;
    height: 40px;
    right: ${({ $index }) => `${(2 - $index) * 30}px`};
  }
`;
const BottomBlock = styled.div`
  padding: 15px 20px 17px 10px;
  display: flex;
  ${CONDITION_DESKTOP} {
    padding: 15px 20px;
  }
`;
const Like = styled.img`
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  margin-top: 3px;
  margin-right: 10px;
  ${CONDITION_DESKTOP} {
    width: 30px;
    height: 30px;
  }
`;
const BottomText = styled.div``;
const BottomTitle = styled.p`
  font-weight: bold;
  font-size: 13px;
  line-height: 1.31;
  margin-bottom: 3px;
`;
const BottomDescription = styled.p`
  font-size: 12px;
  line-height: 1.25;
  opacity: 0.5;
  ${CONDITION_DESKTOP} {
    font-size: 13px;
  }
`;
const Steps = styled.div`
  ${CONDITION_DESKTOP} {
    padding-top: 12px;
  }
`;
const StepContainer = styled.div`
  display: flex;
`;
const IndexContainer = styled.div`
  width: 35px;
  flex-shrink: 0;
  margin-right: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Index = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(225, 225, 225, 0.15);
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
`;
const LineToNext = styled.div`
  width: 2px;
  flex-grow: 1;
  background-color: rgba(225, 225, 225, 0.15);
`;
const StepText = styled.div``;
const StepTitle = styled.p`
  font-weight: bold;
  font-size: 15px;
  ${CONDITION_DESKTOP} {
    margin-bottom: 7px;
  }
`;
const StepDescription = styled.p`
  white-space: pre-line;
  font-size: 13px;
  line-height: 1.38;
  padding-bottom: 30px;
  opacity: 0.5;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black2);
  padding-bottom: 15px;
  font-size: 19px;
`;

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  max-width: 500px;
  letter-spacing: normal;
  color: var(--black2);
  margin-bottom: 15px;

  & > a {
    color: var(--purple);
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`;

const ModalButton = styled(Button)`
  &&& {
    margin-bottom: 15px;
    border: solid 0.5px var(--purple);
    background-color: var(--white);
    color: var(--purple);
    &:hover {
      color: var(--white);
      background-color: var(--purple);
    }
  }
`;

const ModalLink = styled(Note)`
  margin: 0;
  text-align: center;
  & > a {
    text-decoration: none;
  }
`;
