import { Wallet } from 'app/assets/svg';
import InfoIcon from 'app/assets/svg/InfoIcon';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';
import { ExpertService, User } from 'shared/api';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { Button } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import { selectors as profileSelectors } from 'store/ducks/profile';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

import { ServicePaymentModal } from './ui';

interface PublicExpertServicesProps {
  profile: User;
  services: ExpertService[];
}

export const PublicExpertServices: FC<PublicExpertServicesProps> = ({ profile, services }) => {
  const { t } = useTranslation('expert.page');

  const [selectedService, setSelectedService] = useState<ExpertService>({} as ExpertService);

  const isUserAuthorized = useAppSelector(profileSelectors.selectIsAuthentication);
  const isVisibleServicePaymentModal = useAppSelector(selectors.app.selectIsVisibleServicePaymentModal);
  const dispatch = useAppDispatch();
  // const toggleModal = dispatch(actions.app.setIsVisiblePaymentModal(!isVisiblePaymentModal));

  const onPayment = (service: ExpertService) => () => {
    setSelectedService(service);
    if (!isUserAuthorized) {
      return dispatch(actions.app.showAuthModal(true));
    }
    dispatch(actions.app.showServicePaymentModal());
  };

  const onClosePaymentModal = () => {
    dispatch(actions.app.hideServicePaymentModal());
  };

  return (
    <>
      <Container>
        <Title>Услуги</Title>
        {!profile.isContractAccepted && (
          <NotContractInfo>
            <div>
              <InfoIcon />
            </div>
            <div>{t('notContractInfo')}</div>
          </NotContractInfo>
        )}
        {services.map((service) => (
          <ServicesContainer key={service.id}>
            <ServiceContainer>
              <CategoryName>
                <CategoryNameContainer>
                  <Name>{service.title}</Name>
                  <Dots />
                </CategoryNameContainer>
              </CategoryName>

              <ServicePrice>
                <span>{t('averagePrice', { price: service.price })}</span>
                <ButtonContainer>
                  <MobileButton
                    leftIcon={<Wallet />}
                    color="gray"
                    disabled={!profile.isContractAccepted}
                    onClick={onPayment(service)}
                  />

                  <DesktopButton
                    disabled={!profile.isContractAccepted}
                    size="small"
                    bordered
                    color="gray"
                    onClick={onPayment(service)}
                  >
                    {t('pay')}
                  </DesktopButton>
                </ButtonContainer>
              </ServicePrice>
            </ServiceContainer>
          </ServicesContainer>
        ))}
      </Container>
      <Modal isVisible={isVisibleServicePaymentModal} onClose={onClosePaymentModal}>
        <ServicePaymentModal service={selectedService} expert={profile} onRefuse={onClosePaymentModal} />
      </Modal>
    </>
  );
};

const Container = styled.div`
  padding-bottom: 60px;

  ${CONDITION_DESKTOP} {
    max-width: 700px;
  }
`;

const ServicesContainer = styled.div``;

const Title = styled.div`
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--text);
  padding-bottom: 20px;

  ${CONDITION_DESKTOP} {
    /* padding-bottom: 40px; */
    font-size: 22px;
  }
`;

const ServiceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px 0;
  max-width: 80vw;

  ${CONDITION_DESKTOP} {
    max-width: unset;
  }
`;
const CategoryName = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: var(--text);
  display: flex;
  align-items: center;
  width: 60%;
  position: relative;
  background-color: var(--white);

  ${CONDITION_DESKTOP} {
    font-size: 15px;
  }

  & > div {
    overflow: hidden;
  }
`;

const CategoryNameContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ServicePrice = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  gap: 5px;
  justify-content: space-between;

  ${CONDITION_DESKTOP} {
    gap: 20px;
  }

  & > span {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    color: var(--gray);
    width: 60px;
  }
`;
const Dots = styled.span`
  color: var(--gray3);
  width: 100%;
  :before {
    content: '.............................................................................................................................................................';
  }
`;
const Name = styled.span`
  ${CONDITION_DESKTOP} {
    white-space: nowrap;
  }
`;

const DesktopButton = styled(Button)`
  display: none;
  padding: 5px 10px;
  color: var(--purple);
  background-color: var(--white);
  border: 1px solid var(--purple);

  ${CONDITION_DESKTOP} {
    display: unset;
  }
`;
const MobileButton = styled(Button)`
  display: unset;
  padding: 5px 16px;
  justify-content: center;
  height: 25px;
  background-color: var(--gray7);

  :hover {
    background-color: var(--gray3);
  }

  & > div {
    margin: 0;
  }

  & > span {
    display: none;
  }

  ${CONDITION_DESKTOP} {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NotContractInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: var(--gray7);
  padding: 10px;
  border-radius: 8px;
  font-size: 11px;
  margin-bottom: 20px;

  ${CONDITION_DESKTOP} {
    margin-bottom: 40px;
  }
`;
