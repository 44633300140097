import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { UpdateNotificationsSettingDto } from 'shared/api';
import { SwitchButton } from 'shared/ui';
import styled from 'styled-components';

export type Name =
  | 'onConsultRequestTelegram'
  | 'onConsultRequestSMS'
  | 'onConsultRequestPush'
  | 'onConsultStartTelegram'
  | 'onConsultStartSMS'
  | 'onConsultStartPush'
  | 'onConsultMessageTelegram'
  | 'onConsultMessageSMS'
  | 'onConsultMessagePush'
  | 'onNewCategoryTelegram'
  | 'onNewCategorySMS';

interface BlockProps {
  control: Control<UpdateNotificationsSettingDto>;
  title: string;
  names: {
    telegram: Name;
    sms: Name;
    push: Name;
  };
}

export const Block: FC<BlockProps> = ({ control, names, title }) => {
  const { t } = useTranslation('profile.page');

  return (
    <Container>
      <Title>{t(`notificationsTab.${title}`)}</Title>
      {/* TODO: пока что не делаем уведомления в телеграм */}
      {/* <Row>
        <Note>{t('notificationsTab.telegramNotification')}</Note>
        <Controller control={control} name={names.telegram} render={({ field: { value, onChange } }) => (
          <SwitchButton checked={value} onChange={onChange} />
        )} />
      </Row> */}

      <Row>
        <Note>{t('notificationsTab.smsNotification')}</Note>
        <Controller
          control={control}
          name={names.sms}
          render={({ field: { value, onChange } }) => <SwitchButton checked={value} onChange={onChange} />}
        />
      </Row>

      <Row>
        <Note>{t('notificationsTab.pushNotification')}</Note>
        <Controller
          control={control}
          name={names.push}
          render={({ field: { value, onChange } }) => <SwitchButton checked={value} onChange={onChange} />}
        />
      </Row>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 60px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  max-width: 280px;
`;

const Note = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: var(--gray);
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 10px;
`;
