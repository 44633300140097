import { useAppDispatch } from 'hooks/redux';
import { useIsExpertInRoom } from 'hooks/useIsExpertInRoom';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LEFT_MESSAGES_WARNING_COUNT } from 'shared/common/constants';
import { ChatRoomExtended } from 'shared/common/types';
import { actions, selectors } from 'store/ducks';

export const useMessagesLimit = (chatRoom?: ChatRoomExtended) => {
  const { t } = useTranslation('chat.page');
  const dispatch = useAppDispatch();

  const [isMessageCreated, setIsMessageCreated] = useState(false);

  const isUserExpertInRoom = useIsExpertInRoom(chatRoom);
  const leftMessagesCount = useSelector(selectors.consultationsChatRoom.selectLeftMessagesCount);

  useEffect(() => {
    if (isMessageCreated || !leftMessagesCount) {
      return;
    }

    if (leftMessagesCount <= LEFT_MESSAGES_WARNING_COUNT) {
      dispatch(
        actions.chatMessages.addChatMessage({
          type: 'temporary',
          content: isUserExpertInRoom
            ? t('chatRoom.systemMessages.approachingMessageLimitExpert', {
                count: leftMessagesCount,
                endMessage: 'сообщений',
              })
            : t('chatRoom.systemMessages.approachingMessageLimit', {
                count: leftMessagesCount,
                endMessage: 'сообщений эксперта',
              }),
          createdAt: new Date().toISOString(),
        }),
      );
      setIsMessageCreated(true);
    }
  }, [leftMessagesCount, isMessageCreated]);
};
