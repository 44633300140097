import { WarningGrayIcon } from 'app/assets/svg';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ContractFormSteps, StepsState } from 'routes/contract/contract';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { Button, Checkbox } from 'shared/ui';
import { actions } from 'store/ducks';
import styled from 'styled-components';
import { ContractFormValues, ContractFromVariants } from 'widgets/common';
import { WarningModal } from 'widgets/modals';

interface VariantFormProps {
  changeStepHandle: (step: ContractFormSteps) => void;
  changeProgressBarStateHandle: (data: Partial<StepsState>) => void;
  buttonText?: string;
}

export const VariantForm: FC<VariantFormProps> = ({ changeStepHandle, changeProgressBarStateHandle, buttonText }) => {
  const { control, watch } = useFormContext<ContractFormValues>();
  const { t } = useTranslation('contract.page');
  const variantValue = watch('variant');
  const dispatch = useDispatch();
  const [isIndividual, setIsIndividual] = useState(false);

  const showModal = () => {
    dispatch(actions.app.setIsVisibleWarningModal(true));
  };

  const onContinue = () => {
    changeProgressBarStateHandle({
      variant: true,
      props: false,
      offer: false,
    });
    changeStepHandle(ContractFormSteps.offer);
  };

  return (
    <Container>
      <Title>{t('form.selectHowWork')}</Title>
      <PointsContainer>
        <Controller
          control={control}
          name="variant"
          render={({ field: { value, onChange } }) => (
            <>
              <Point>
                <Checkbox
                  checked={value === ContractFromVariants.INDIVIDUAL}
                  onChange={() => {
                    onChange(ContractFromVariants.INDIVIDUAL);
                    setIsIndividual(true);
                    showModal();
                  }}
                />
                <PointName
                  onClick={() => {
                    onChange(ContractFromVariants.INDIVIDUAL);
                    setIsIndividual(true);
                    showModal();
                  }}
                >
                  {t('form.individual')}
                </PointName>
              </Point>
              <Point>
                <Checkbox
                  checked={value === ContractFromVariants.SELF_EMPLOYED}
                  onChange={() => {
                    onChange(ContractFromVariants.SELF_EMPLOYED);
                    setIsIndividual(false);
                    showModal();
                  }}
                />
                <PointName
                  onClick={() => {
                    onChange(ContractFromVariants.SELF_EMPLOYED);
                    setIsIndividual(false);
                    showModal();
                  }}
                >
                  {t('form.selfEmployed')}
                </PointName>
              </Point>

              {/* <Point> */}
              {/*  <Checkbox checked={value === ContractFromVariants.IP} onChange={() => { */}
              {/*    onChange(ContractFromVariants.IP); */}
              {/*  }} /> */}
              {/*  <PointName>{t('form.IP')}</PointName> */}
              {/* </Point> */}

              {/* <Point> */}
              {/*  <Checkbox checked={value === ContractFromVariants.OOO} onChange={() => { */}
              {/*    onChange(ContractFromVariants.OOO); */}
              {/*  }} /> */}
              {/*  <PointName>{t('form.OOO')}</PointName> */}
              {/* </Point> */}
            </>
          )}
        />
      </PointsContainer>

      <Warning>
        <div>
          <WarningGrayIcon fill="#ffcc00" />
        </div>
        <div>
          <div>{t('form.warning')}</div>
          <div>
            {t('form.discontWarning')}
            <a href={`${routes.expertLanding}#SpecialOffer`}>в специальных акциях</a>
            {t('form.discontWarning2')}
          </div>
        </div>
      </Warning>

      <StyledButton disabled={!variantValue} onClick={onContinue} type="button">
        {buttonText || `${t('form.continue')}`}
      </StyledButton>
      <WarningModal isIndividual={isIndividual} />
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 40px;
`;

const PointsContainer = styled.div`
  margin-bottom: 40px;

  & > div:last-child {
    margin: 0;
  }
`;

const Point = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 22px;
`;

const PointName = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: var(--text);

  &:hover {
    cursor: pointer;
  }
`;

const Warning = styled.div`
  padding: 17px 40px 20px 15px;
  border-radius: 8px;
  align-items: center;
  background-color: rgb(255 204 0 / 20%);
  display: flex;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  color: var(--gray2);
  gap: 10px;
  width: 100%;
  margin-bottom: 40px;

  a {
    color: var(--gray2);
    text-decoration: underline;
  }

  ${CONDITION_DESKTOP} {
    width: 70%;
  }
`;

const StyledButton = styled(Button)`
  width: 212px;
`;
