import { Camera } from 'app/assets/svg';
import { useAppDispatch } from 'hooks/redux';
import { usePrepareImageToUpload } from 'hooks/usePrepareImageToUpload';
import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { ChangeEvent, FC } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { UserStepsEnum } from 'shared/api';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { buildRoute, coverImageStyle } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { StarRating } from 'shared/ui';
import { actions } from 'store/ducks';
import { selectors } from 'store/ducks/profile';
import { createContentChangeRequest, fetchUpdateIamProfile } from 'store/ducks/profile/actions';
import { selectContentChangeRequest } from 'store/ducks/profile/selectors';
import styled from 'styled-components';

import { MobileBanner } from '../mobile-profile-banner';

interface SidebarProps {}

const links = [
  { href: `${routes.profile}?activeTab=settings`, label: 'Мои настройки', tab: 'settings' },
  { href: `${routes.profile}?activeTab=expertProfile`, label: 'Профиль эксперта', tab: 'expertProfile' },
  { href: `${routes.profile}?activeTab=wallet`, label: 'Кошелек', tab: 'wallet' },
  { href: `${routes.profile}?activeTab=myConsultations`, label: 'Мои консультации', tab: 'myConsultations' },
  { href: `${routes.profile}?activeTab=support`, label: 'Справка', tab: 'support' },
  { href: `${routes.academyExperts}`, label: 'Академия экспертов', tab: 'academyExperts' },
];

export const Sidebar: FC<SidebarProps> = () => {
  const { t } = useTranslation('profile.page');
  const { query } = useRouter();
  const dispatch = useAppDispatch();
  const { firstName, lastName, isExpert, ratingMetaData, reviewsCount, id, steps, ...user } = useSelector(
    selectors.selectUser,
  );
  const expertRating = ratingMetaData?.InternalRating ? Number(ratingMetaData?.InternalRating) : 0;
  const contentChangeRequest = useSelector(selectContentChangeRequest);
  const { selectFile, uploadFileToCloud } = usePrepareImageToUpload();

  const activeTab = query.activeTab || 'settings';

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    selectFile(files, async ({ file }) => {
      try {
        if (file) {
          const avatarUrl = await uploadFileToCloud(file);
          if (isExpert) {
            await dispatch(createContentChangeRequest({ avatarUrl }));
          } else {
            await dispatch(fetchUpdateIamProfile({ avatarUrl }));
          }
          toast.success(t('requestHasBeenSend'));
        }
      } catch (error: any) {
        captureError(error);
        toast.error(error.message || t('somethingWrong'));
      }
    });
  };

  let avatarUrl: string | null;

  if (contentChangeRequest && contentChangeRequest.avatarUrl) {
    avatarUrl = contentChangeRequest.avatarUrl;
  } else {
    avatarUrl = user.avatarUrl || null;
  }

  const logout = () => {
    dispatch(actions.profile.signOut());
  };

  const allStepsPassed = steps.includes(
    UserStepsEnum._2photo &&
      UserStepsEnum._3about &&
      UserStepsEnum._4theme &&
      UserStepsEnum._5profile &&
      UserStepsEnum._7rek,
  );

  return (
    <Container>
      <Avatar
        htmlFor={isExpert ? undefined : 'avatar'}
        $url={avatarUrl || null}
        $disable={!!contentChangeRequest && !!contentChangeRequest.avatarUrl}
        $isExpert={isExpert}
      >
        {!isExpert && (
          <CameraContainer>
            <Camera />
          </CameraContainer>
        )}
      </Avatar>
      <input id="avatar" onChange={onChange} type="file" accept="image/png, image/jpeg" style={{ display: 'none' }} />
      <Name onClick={() => router.push(`${buildRoute(routes.expert, { id })}`)}>{`${firstName || ''} ${
        lastName || ''
      }`}</Name>
      {isExpert && (
        <Rating>
          <div>{expertRating?.toFixed(1) || 0}</div>
          <StarRating rating={expertRating || 0} />
          <Feedback>
            {reviewsCount === 0 ? (
              <NoReview>{t('noFeedback')}</NoReview>
            ) : (
              <Link href={`${buildRoute(routes.expert, { id })}#reviews`} passHref>
                <ReviewCount>{`${reviewsCount} ${t('reviews')}`}</ReviewCount>
              </Link>
            )}
          </Feedback>
        </Rating>
      )}
      <Links>
        {links.map((val, i) => {
          if (!allStepsPassed && val.tab === 'wallet') {
            return null;
          }
          if (!allStepsPassed && val.tab === 'academyExperts') {
            return null;
          }
          return (
            <Link key={i} href={val.href}>
              <MenuLink tabIndex={0} className={activeTab === val.tab ? 'active' : ''}>
                {t(`${val.tab}`)}
              </MenuLink>
            </Link>
          );
        })}
        <div onClick={logout} role="button" onKeyPress={logout} tabIndex={0}>
          <MenuLink>Выйти</MenuLink>
        </div>
      </Links>

      <MobileBanner />
    </Container>
  );
};

const Container = styled.div`
  display: none;
  flex-direction: row;
  max-width: 240px;
  text-align: left;
  margin-right: 10px;
  width: 100%;

  ${CONDITION_DESKTOP} {
    display: block;
  }
`;

const Avatar = styled.label<{ $url: string | null; $disable?: boolean; $isExpert: boolean }>`
  margin-bottom: 23px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  border-radius: 50%;
  opacity: ${({ $disable }) => ($disable ? '0.5' : '1')};
  background-color: ${({ $url }) => ($url ? 'transparent' : 'transparent')};
  ${({ $url }) => ({ ...coverImageStyle($url) })}
  & > svg {
    transform: scale(2);
  }
  position: relative;
  cursor: ${({ $isExpert }) => ($isExpert ? 'auto' : 'pointer')};
`;

const Name = styled.div`
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const Rating = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text);
`;

const Feedback = styled.div`
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
`;
const ReviewCount = styled.a`
  color: var(--purple);
`;

const NoReview = styled.div`
  color: var(--gray);
`;

const Links = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const MenuLink = styled.p`
  color: var(--gray);
  display: block;
  margin: 20px 0;
  cursor: pointer;

  &.active {
    color: var(--text);
  }

  :hover {
    color: var(--text);
  }
`;

const CameraContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  opacity: 1;
  background-color: var(--purple);
  position: absolute;
  bottom: 0;
  right: 0;
`;
