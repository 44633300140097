import { ChatSmallIcon, GrayWallet, Star } from 'app/assets/svg';
import VerificationMark from 'app/assets/svg/VerificationMark';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { Achievement } from 'shared/api';
import { CONDITION_DESKTOP, CONDITION_MOBILE, DEFAULT_AVATAR } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { buildRoute, coverImageStyle, ellipsizeText } from 'shared/helpers';
import styled from 'styled-components';

import { renderAchievement } from './renderAchievement';

interface ExampleExpertPreviewProps {
  isVerification?: boolean;
  firstName?: string;
  lastName?: string;
  consultationCount: number;
  rating: number;
  reviewCount: number;
  averagePrice: number;
  avatar: string | null;
  videoUrl?: string;
  achievements?: Achievement[];
  id: number;
}

export const ExampleExpertPreview: FC<ExampleExpertPreviewProps> = ({
  isVerification,
  firstName,
  lastName,
  consultationCount,
  reviewCount,
  rating,
  averagePrice,
  avatar,
  videoUrl,
  achievements,
  id,
}) => {
  const { t } = useTranslation('expert.preview');

  return (
    <Container>
      <Link href="#">
        <a>
          <AvatarContainer>
            <GradientBorder isVerification={isVerification}>
              <Avatar onClick={() => {}} $url={avatar || DEFAULT_AVATAR} />
            </GradientBorder>
            <Video isVisible={isVerification && !!videoUrl}>
              <a href={videoUrl} target="_blank" rel="noreferrer">
                {t('videoCard')}
              </a>
            </Video>
          </AvatarContainer>
        </a>
      </Link>
      <Link href={`${buildRoute(routes.expert, { id })}`}>
        <a>
          <NameContainer>
            <FullNameWrapper>
              <span title={firstName}>
                {ellipsizeText(firstName, 18)} {ellipsizeText(lastName, 18)}
              </span>
            </FullNameWrapper>
            <IconWrapper>{isVerification && <VerificationMark width={12} />}</IconWrapper>
          </NameContainer>
        </a>
      </Link>
      <ConsultationCount>{`${consultationCount} ${t('exampleConsultations')}`}</ConsultationCount>
      <FastInfoContainer>
        <Bold>
          <IconContainer>
            <Star />
          </IconContainer>
          <div>{rating !== 0 ? rating.toFixed(1) : 'Нет рейтинга'}</div>
        </Bold>
        <Bold>
          <IconContainer>
            <ChatSmallIcon />
          </IconContainer>
          <FastInfoText>{`${reviewCount} ${t('reviews')}`}</FastInfoText>
        </Bold>
        <Bold>
          <IconContainer>
            <GrayWallet />
          </IconContainer>
          <FastInfoText>{`${Math.round(averagePrice)} \u20bd`}</FastInfoText>
        </Bold>
        <AchievementContainer>{achievements?.map((a) => renderAchievement(a))}</AchievementContainer>
      </FastInfoContainer>
    </Container>
  );
};

const Container = styled.div`
  max-width: 120px;
  pointer-events: none;

  ${CONDITION_DESKTOP} {
    max-width: 160px;
  }
`;

interface AvatarProps {
  $url: string | null;
}

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Avatar = styled.div<AvatarProps>`
  border-radius: 50%;
  width: 122px;
  height: 122px;
  margin: 3px;

  &:hover {
    cursor: pointer;
  }

  ${({ $url }) => ({ ...coverImageStyle($url) })}
  ${CONDITION_DESKTOP} {
    width: 142px;
    height: 142px;
  }

  ${CONDITION_MOBILE} {
    width: 85px;
    height: 85px;
  }
`;

interface GradientBorderProps {
  isVerification?: boolean;
}

const GradientBorder = styled.div<GradientBorderProps>`
  ${({ isVerification }) =>
    isVerification &&
    `
  background:
          linear-gradient(var(--white),var(--white)) padding-box,
          linear-gradient(360deg,#5806c9 16%,#993dfa 27%,#ac45ff 57%) border-box;
  `}
  color: #313149;
  padding: 1px;
  border: 3px solid transparent;
  border-radius: 50%;
`;

interface VideoProps {
  isVisible?: boolean;
}

const Video = styled.div<VideoProps>`
  background-image: linear-gradient(to right, #ae4cfe 5%, #993dfa 51%, #812df6);
  border-radius: 12.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-12px);
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

  & > a {
    color: var(--white);
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 3px 9px;
  }

  text-align: center;
`;

const ConsultationCount = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 5px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 25px;
`;

const NameWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${CONDITION_DESKTOP} {
  }
`;

const IconWrapper = styled.div`
  width: 10px;
  display: flex;
  overflow: hidden;
  transform: scale(1.5);
  margin-left: 8px;

  & > svg {
    width: 100%;
  }
`;

const FastInfoContainer = styled.div`
  padding-bottom: 15px;
`;

const Bold = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--text);
  display: flex;
  padding-bottom: 7px;
  height: 20px;
  align-items: center;
  gap: 7px;
`;

const AchievementContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const IconContainer = styled.div`
  margin: 0;
  width: 19px;
  height: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3.5px;
`;

const FastInfoText = styled.span`
  align-content: center;
`;

const FullNameWrapper = styled.div``;
