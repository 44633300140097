import React, { FC, HTMLAttributes } from 'react';
import { CONDITION_DESKTOP, DEFAULT_CATEGORY } from 'shared/common/constants';
import { coverImageStyle } from 'shared/helpers';
import { ClientRequestType } from 'store/ducks/consultationRequests/slice';
import styled from 'styled-components';

interface ChatSidebarRequestButtonProps extends HTMLAttributes<HTMLDivElement> {
  isActive: boolean;
  request: ClientRequestType;
  onClick: () => void;
  unreadCount: number;
}

export const ChatSidebarRequestButton: FC<ChatSidebarRequestButtonProps> = ({
  isActive,
  request,
  unreadCount,
  ...props
}) => (
  <Container $isActive={isActive} {...props}>
    <Image
      $url={
        request.category.iconUrl ||
        (request.category.rubrics && request.category.rubrics[0]?.iconUrl) ||
        DEFAULT_CATEGORY
      }
    />
    <Name>{request.category.name}</Name>
    {!!unreadCount && <UnreadCount>{unreadCount < 100 ? unreadCount : <MoreCount>99+</MoreCount>}</UnreadCount>}
  </Container>
);

const Container = styled.div<{ $isActive: boolean }>`
  padding: 10px;
  margin: 5px 0;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 60px 1fr auto;
  gap: 10px;
  align-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background-color: ${({ $isActive }) => $isActive && 'rgba(245, 246, 250, 0.9)'};
  ${CONDITION_DESKTOP} {
    grid-template-columns: 50px 1fr auto;
  }
`;
const Image = styled.div<{ $url?: string }>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  align-self: center;
  ${({ $url }) => $url && { ...coverImageStyle($url) }}
  ${CONDITION_DESKTOP} {
    width: 50px;
    height: 50px;
  }
`;
const Name = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: bold;
  align-self: center;
  line-height: 20px;
  ${CONDITION_DESKTOP} {
    font-size: 14px;
  }
`;
const UnreadCount = styled.div`
  font-size: 11px;
  line-height: 22px;
  color: var(--white);
  width: 20px;
  height: 20px;
  align-self: center;
  text-align: center;
  border-radius: 50%;
  background-color: var(--purple);
`;
const MoreCount = styled.span`
  font-size: 9px;
  top: -1px;
  position: relative;
`;
