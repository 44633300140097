import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useAutofocus } from 'hooks/useAutofocus';
import { useTranslation } from 'next-i18next';
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ENVIRONMENT_TYPE } from 'shared/common/constants';
import { clearTel } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { Input } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import { actions as appActions, selectors as appSelectors } from 'store/ducks/app/slice';
import { fetchConsultationsWithoutReview, fetchSelfPromocode, fetchSignInSms } from 'store/ducks/profile/actions';
import styled from 'styled-components';

import { AuthFormSteps, AuthFormValues } from '../../types';
import { Timer } from '../timer';

interface AuthSmsCodeFormProps {
  setStep: Dispatch<SetStateAction<AuthFormSteps>>;
  closeModal: () => void;
}

export const AuthSmsCodeForm: FC<AuthSmsCodeFormProps> = ({ setStep, closeModal }) => {
  const { t } = useTranslation('header.component');
  const dispatch = useAppDispatch();

  const { control, watch, getValues, clearErrors, setError, setFocus, reset } = useFormContext<AuthFormValues>();
  const code = watch('code');

  const isWait = useSelector(appSelectors.selectAuthModalIsWait);
  const counter = useSelector(appSelectors.selectAuthModalCounter);
  useAutofocus<AuthFormValues>('code', setFocus);

  const isRedirectPaymentModal = useAppSelector(selectors.app.selectIsRedirectPaymentModal);
  const isFreeChatRedirect = useAppSelector(selectors.app.selectIsFreeChatRedirectInfo);

  const setWait = (isWait: boolean) => {
    dispatch(appActions.setWait(isWait));
    if (counter !== 60) {
      return;
    }
    if (isWait) {
      dispatch(appActions.setCounter(59));
    }
  };

  useEffect(() => {
    setWait(true);
  }, []);

  useEffect(() => {
    if (code.length === 4) {
      validateCode(code).then(() => {
        reset({ ...getValues(), number: '' });
      });
    }
  }, [code]);

  const onRepeatMessage = async () => {
    try {
      const { number } = getValues();
      const action = await dispatch(actions.profile.sendSmsAsync({ phone: clearTel(number) }));

      const result = unwrapResult(action);
      setWait(true);
      if (ENVIRONMENT_TYPE !== 'production') {
        toast.info(result, { autoClose: false });
      }

      reset({ ...getValues(), number: '' });
    } catch (e: any) {
      if (e.statusCode === 429) {
        setError('code', { message: t('authModal.notSendCode') });
        setWait(true);
      }
    }
  };

  const validateCode = async (code: string) => {
    const { number } = getValues();
    try {
      const res = await dispatch(
        fetchSignInSms({
          smscode: code,
          phone: clearTel(number),
          urlWithUtmMarks: window.location.href,
        }),
      );
      const { firstName, lastName } = unwrapResult(res)!;
      if (firstName !== null && lastName !== null) {
        clearErrors();
        try {
          const promocodesRes = await dispatch(fetchSelfPromocode());
          dispatch(fetchConsultationsWithoutReview());
          dispatch(actions.profile.fetchWallet());
          unwrapResult(promocodesRes);
          setWait(false);
        } catch (e) {
          captureError(e);
          toast.error(t('authModal.dontSuccessFetchPromocodes'));
        }
        setStep(AuthFormSteps.number);
        closeModal();
        if (isRedirectPaymentModal) {
          dispatch(actions.app.showPaymentModal());
        }
        if (isFreeChatRedirect) {
          dispatch(actions.app.setFreeChatAskInfo(true));
        }
        return;
      }
      localStorage.removeItem('phone');
      setStep(AuthFormSteps.promoCode);

      reset({ ...getValues(), number: '' });
    } catch (e: any) {
      captureError(e);
      clearErrors();
      setError('code', { message: e.message });
    }
  };

  const onReturnPrevStep = () => {
    setStep(AuthFormSteps.number);
  };

  return (
    <>
      <Controller
        name="code"
        control={control}
        render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
          <Input
            type="number"
            value={value}
            ref={ref}
            centered
            onChange={(e) => {
              const { value } = e.target;
              if (value.length > 4) {
                return;
              }
              onChange(e);
            }}
            error={error?.message}
            placeholder={t('authModal.smsCodePlaceholder')}
          />
        )}
      />
      <ButtonsContainer>
        {isWait ? <Timer setWait={setWait} /> : <Button onClick={onRepeatMessage}>{t('authModal.sendRepeat')}</Button>}
        <Button onClick={onReturnPrevStep}>{t('authModal.return')}</Button>
      </ButtonsContainer>
    </>
  );
};

const ButtonsContainer = styled.div`
  margin-top: 65px;
`;

const Button = styled.div`
  color: var(--purple);
  padding: 7.5px 0;
  text-align: center;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
