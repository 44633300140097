import styled from 'styled-components';

export const Input = styled.input({
  boxSizing: 'border-box',
  width: '100%',
  height: 50,
  outline: 'none',
  borderRadius: 12,
  border: 'solid 1px #c1c7cd',
  backgroundColor: '#ffffff',
  paddingLeft: 15,
  paddingRight: 15,

  fontSize: 15,
  fontWeight: 500,
  lineHeight: 1.67,
  color: '#232832',

  ':disabled': {
    backgroundColor: '#f9f9f9',
  },
});
