import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import styled from 'styled-components';
import { RubricNew } from 'widgets/common';

interface INewRubric {
  id: string;
  name: string;
}

// TODO remove if not need for profile
export const NewRubricsForm: FC = () => {
  const { t } = useTranslation('profile.page');

  const newRubricsArr: INewRubric[] = [
    {
      id: '1',
      name: 'Спорт',
    },
    {
      id: '2',
      name: 'Красота',
    },
    {
      id: '3',
      name: 'Выбор техники',
    },
    {
      id: '4',
      name: 'Блоггинг',
    },
    {
      id: '5',
      name: 'Страны и путешествия',
    },
    {
      id: '6',
      name: 'IT',
    },
    {
      id: '7',
      name: 'Киберспорт',
    },
    {
      id: '8',
      name: 'Образование',
    },
    {
      id: '9',
      name: 'Косметология',
    },
    {
      id: '10',
      name: 'Психология',
    },
    {
      id: '11',
      name: 'Здоровье',
    },
    {
      id: '12',
      name: 'Финансы',
    },
    {
      id: '13',
      name: 'Авто',
    },
    {
      id: '14',
      name: 'Питомцы и животные',
    },
    {
      id: '15',
      name: 'Налоги и бухгалтерия',
    },
    {
      id: '16',
      name: 'Дома и квартиры',
    },
    {
      id: '17',
      name: 'Дети',
    },
    {
      id: '18',
      name: 'Продукты питания',
    },
    {
      id: '19',
      name: 'Сад и огород',
    },
    {
      id: '20',
      name: 'Хобби и рукоделие Мамочкам',
    },
  ];

  return (
    <Container>
      <Title>{t('newRubrics.title')}</Title>
      <Note style={{ color: '#79818c' }}>{t('newRubrics.note')}</Note>
      {newRubricsArr.map((rubric) => (
        <RubricNew rubric={rubric} key={rubric.id} />
      ))}
    </Container>
  );
};

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  max-width: 560px;
  letter-spacing: normal;
  padding-bottom: 20px;
  color: var(--gray);

  & > a {
    color: var(--purple);
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`;

const Container = styled.div`
  margin-top: 90px;

  ${CONDITION_DESKTOP} {
    width: 560px;
  }

  input {
    width: 100%;
    ${CONDITION_DESKTOP} {
      width: 60%;
    }
  }
  button {
    margin-top: 0;
    width: 100%;
    ${CONDITION_DESKTOP} {
      width: unset;
    }
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black2);
  padding-bottom: 10px;
  padding-top: 17px;
`;
