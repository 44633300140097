import { SUPPORT_EMAIL, SUPPORT_TELEGRAM } from 'shared/common/constants';
import { routes } from 'shared/common/routes';

export const supportContent = [
  {
    answer: `Во вкладке <a href="${routes.topics}">Найти эксперта</a> выберите эксперта в подходящей теме и договоритесь с ним о консультации. Или задайте в этой теме вопрос и выбирайте эксперта по откликам на него во вкладке <a href="${routes.responses}">Отклики</a> вашего мессенджера 💬`,
    question: 'Как заказать консультацию?',
    id: 'howToOrder',
  },
  {
    answer: `Обращайте внимание на рейтинг эксперта, отзывы о нем и наличие синей галочки. Посмотрите видеовизитку. Если не можете выбрать между несколькими экспертами, задайте каждому вопрос и закажите консультацию у того, чей ответ вам больше понравился 👍 Но это работает только с экспертами, у которых включен бесплатный чат.`,
    question: 'Как выбрать эксперта?',
    id: 'howToChooseExpert',
  },
  {
    answer: `Консультация закончится через 1 час или после того, как эксперт напишет 20 сообщений. Ее можно продлить платно или бесплатно по согласованию с экспертом.`,
    question: 'Сколько длится консультация?',
    id: 'howLong',
  },
  {
    answer: `Возможно, что-то помешало ему заняться вашим вопросом, у всех случаются непредвиденные ситуации. Вы можете подождать ответа или заказать консультацию у другого эксперта. Если вы уже оплатили консультацию, то мы вернем деньги в течение двух часов в рабочее время. Для этого обратитесь в поддержку через <a href="https://t.me/${SUPPORT_TELEGRAM}">Telegram</a>, чат на сайте или напишите на <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>.`,
    question: 'Если эксперт не отвечает?',
    id: 'ignor',
  },
  {
    answer: `Любую консультацию можно продлить платно или бесплатно. Если эксперт не успел помочь вам в рамках одной консультации, договоритесь о бесплатном продлении. Если у вас появились дополнительные вопросы, продлите консультацию платно.`,
    question: 'Что если я не успею получить все ответы за время консультации?',
    id: 'extend',
  },
  {
    answer: `Если консультация вам не понравилась, это можно сделать несколькими способами: <br/><br/>1. Можете нажать кнопку «Проблема с консультацией» внутри чата с экспертом прямо во время консультации. <br/><br/>2. После завершения консультации, если вы оценили ее на 1 или 2 звезды, можете поставить в форме оценки галочку «Хочу вернуть деньги». <br/><br/>3. Можете написать нам: чат справа внизу, <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a> или <a href="https://t.me/${SUPPORT_TELEGRAM}">Telegram</a>.<br/><br/>Возврат занимает не более двух часов в рабочее время.
				`,
    question: 'Как вернуть деньги за консультацию?',
    id: 'howToMoneyBack',
  },
  {
    answer: `Да. Вы можете вернуться к чату консультации через неделю или месяц — он никуда не денется 😉`,
    question: 'Сохранится ли консультация после завершения?',
    id: 'saveData',
  },
  {
    answer: `После консультации Consulty сам предложит оставить отзыв. Не переживайте, если случайно закроете страницу, мы напомним вам об отзыве в следующий раз 🙌
				`,
    question: 'Как оставить отзыв?',
    id: 'howToReview',
  },
  {
    answer: `Никак. Отправленный экспертам вопрос нельзя редактировать. Вы можете отправить еще один, если хотите его дополнить.`,
    question: 'Как отредактировать свой вопрос?',
    id: 'howToEdit',
  },
  {
    answer: `Вопрос нельзя удалить. Если он уже не актуален, просто не начинайте предложенные по нему консультации. Он станет неактивным сам через 72 часа. Consulty будет развиваться, и мы обязательно добавим функцию удаления 😌
				`,
    question: 'Как удалить свой вопрос экспертам?',
    id: 'howToDelete',
  },
  {
    answer: `Запросы на консультацию видят только эксперты и только в той теме, в которой вы сделали запрос на консультацию. Всему интернету они не видны.`,
    question: 'Кто может узнать о моих вопросах эксперту?',
    id: 'privacy',
  },
  {
    answer: `Не переживайте, мы обязательно вернем вам деньги. Если эксперт долго не отвечает, и вы решили отказаться от его консультации, напишите в поддержку через чат справа внизу, <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a> или <a href="https://t.me/${SUPPORT_TELEGRAM}">Telegram</a>`,
    question: 'Деньги списались, а эксперт так и не начал консультацию. Что делать?',
    id: 'dontStart',
  },
  {
    answer: `Сейчас Consulty работает с физическими лицами и самозанятыми. <br/><br/>Самозанятые эксперты выгружают чек в течение 30 дней с момента оказания консультации.<br/><br/>Он будет доступен вам в разделе «Мои консультации».<br/><br/>Физлица не выдают чеки, для них это не обязательно. Закон не обязывает их это делать 🤷‍♂️`,
    question: 'Как получить чек об оплате консультации?',
    id: 'check',
  },
  {
    answer: `Имя, фамилию, дату рождения, аватарку и e-mail можете изменить в настройках профиля. Новые данные вступят в силу после модерации.<br/><br/>Телефон можно изменить только через поддержку: чат справа внизу, <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a> или <a href="https://t.me/${SUPPORT_TELEGRAM}">Telegram</a>.
				`,
    question: 'Как поменять свои данные в аккаунте?',
    id: 'changePersonalData',
  },
  {
    answer: `Не все эксперты консультируют за жетоны. Если эксперт принимает жетоны, рядом с оплатой \nконсультации будет соответствующая иконка жетона. Нажмите «Оплатить» и выберите «Оплатить жетоном».<br/><br/>Жетон можно получить при регистрации по промокоду от эксперта. `,
    question: 'Как оплатить консультацию жетоном?',
    id: 'payToken',
  },
  {
    answer: `Вы можете предложить свою тему! Напишите нам в <a href="https://t.me/${SUPPORT_TELEGRAM}">Telegram</a> или  на <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>. Можете также воспользоваться специальной формой на <a href="${routes.topics}">странице Тем</a>, она там в самом низу.`,
    question: 'Почему для консультаций открыта только тема Бизнеса?',
    id: 'onlyBusiness',
  },
  {
    answer: `Эксперты готовы ответить на все ваши вопросы по указанным темам.<br/><br/>Если нет подходящей вам темы, вы можете ее предложить. Напишите в поддержку: чат слева \nвнизу, <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a> или <a href="https://t.me/${SUPPORT_TELEGRAM}">Telegram</a>. Или нажмите на соответствующую кнопку баннера в \nразделе <a href="${routes.topics}">Найти эксперта</a>, он внизу страницы.
				`,
    question: 'Как получить ответ на вопрос, которого нет в списке тем?',
    id: 'answerWithoutList',
  },
];
