import { useAppDispatch } from 'hooks/redux';
import { useAutofocus } from 'hooks/useAutofocus';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { routes } from 'shared/common/routes';
import { commaNumberFormat, onKeyDownNumber } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { Button, Input, TextareaModal } from 'shared/ui';
import { selectors } from 'store/ducks';
import { actions } from 'store/ducks/consultations';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

const currencyCode = '\u20bd';

type FormType = {
  description: string;
  price: string;
};

interface ProlongationModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export const ExtraServiceModal: FC<ProlongationModalProps> = ({ isVisible, onClose }) => {
  if (!isVisible) {
    return null;
  }
  const dispatch = useAppDispatch();
  const { t } = useTranslation('chat.page');
  const activeConsultation = useSelector(selectors.consultationsChatRoom.selectActiveConsultation);
  const [isSendingForm, setIsSendingForm] = useState(false);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    setFocus,
  } = useForm<FormType>({
    defaultValues: { price: '', description: '' },
    mode: 'onChange',
  });

  useAutofocus<FormType>('price', setFocus, 100);

  const onSubmit = async (values: FormType) => {
    if (!!values.price && !!values.description) {
      try {
        setIsSendingForm(true);
        if (activeConsultation) {
          await dispatch(
            actions.createExtraServiceAsync({
              id: activeConsultation.id,
              dto: {
                clientId: activeConsultation.clientId,
                description: values.description,
                price: parseInt(values.price.replace(/' '/g, ''), 10),
                redirectUrl: `${window.location.origin}${routes.chat}?activeTab=consult&roomId=${activeConsultation.chatRoomId}`,
              },
            }),
          );
        } else {
          toast.info('errors.noActiveConsultation');
        }
        onClose();
      } catch (error: any) {
        captureError(error);
        toast.error(error.message || t('errors.somethingWrong'));
      } finally {
        setIsSendingForm(false);
      }
    }
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>{t('modals.extraService.title')}</FormTitle>
        <FormDescription>{t('modals.extraService.description')}</FormDescription>
        <ModalInputContainer>
          <Controller
            control={control}
            name="price"
            rules={{
              validate: {
                isNumber: (v) => !!parseInt(v, 10) || !v || 'errors.mustBeANumber',
                maxLength: (v) => v.length < 7 || 'errors.tooLong',
                minLength: (v) => v.length > 1 || 'errors.tooSmall',
              },
            }}
            render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
              <Input
                value={value}
                onChange={(e) => onChange(commaNumberFormat(e.currentTarget.value))}
                placeholder={t('modals.extraService.pricePlaceholder')}
                error={error?.message && t(error?.message)}
                ref={ref}
                onKeyDown={onKeyDownNumber}
              />
            )}
          />
          <Currency>{currencyCode}</Currency>
        </ModalInputContainer>
        <Controller
          control={control}
          name="description"
          rules={{ required: true, maxLength: { value: 300, message: t('errors.tooLongCount', { count: 300 }) } }}
          render={({ field: { value, onChange } }) => (
            <TextareaModal
              value={value}
              onChange={onChange}
              placeholder={t('modals.extraService.descriptionPlaceholder')}
              error={errors.description?.message}
            />
          )}
        />

        <StyledButton
          disabled={
            !getValues().price.length ||
            !getValues().description.length ||
            !!errors.price?.message ||
            !!errors.description?.message ||
            isSendingForm
          }
          loading={isSendingForm}
          block
          type="submit"
        >
          {t('modals.extraService.button')}
        </StyledButton>
      </Form>
    </Modal>
  );
};

const Form = styled.form``;
const FormTitle = styled.h3`
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 15px;
`;
const FormDescription = styled.p`
  text-align: center;
  white-space: pre-wrap;
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 30px;
`;
const ModalInputContainer = styled.div`
  margin-bottom: 15px;
  position: relative;
`;
const Currency = styled.div`
  position: absolute;
  color: var(--gray);
  right: 15px;
  top: 0;
  height: 50px;
  line-height: 50px;
`;
const StyledButton = styled(Button)`
  margin-top: 20px;
`;
