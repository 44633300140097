import type { FC } from 'react';
import React from 'react';
import { Transition } from 'react-transition-group';

const defaultStyle = {
  opacity: 0,
  top: -100,
  transition: 'all 300ms linear',
};

const transitionStyles = {
  entering: {
    opacity: 1,
    top: 0,
  },
  entered: {
    opacity: 1,
    top: 0,
  },
  exiting: {
    opacity: 0,
  },
  exited: {
    opacity: 0,
  },
};

export const Fade: FC<{
  [key: string]: any;
}> = ({ children, ...rest }) => (
  <Transition {...rest} timeout={200}>
    {(
      // @ts-ignore
      status,
      // @ts-ignore
      innerProps,
      // @ts-ignore
    ) =>
      // @ts-ignore
      React.cloneElement(children, {
        ...innerProps,
        style: {
          ...defaultStyle,
          // @ts-ignore
          ...transitionStyles[status],
        },
        // @ts-ignore
        className: children.props.className,
      })
    }
  </Transition>
);

const defaultBackdropStyle = {
  opacity: 0,
  transition: 'opacity 300ms linear',
};

const backdropTransitionStyles = {
  entering: { opacity: 0.7 },
  entered: { opacity: 0.7 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const FadeBackdrop: FC<{
  [key: string]: any;
}> = ({ children, ...rest }) => (
  <Transition {...rest} timeout={200}>
    {(
      // @ts-ignore
      status,
      // @ts-ignore
      innerProps,
      // @ts-ignore
    ) =>
      // @ts-ignore
      React.cloneElement(children, {
        ...innerProps,
        style: {
          ...defaultBackdropStyle,
          // @ts-ignore
          ...backdropTransitionStyles[status],
        },
        // @ts-ignore
        className: children.props.className,
      })
    }
  </Transition>
);
