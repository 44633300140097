import { unwrapResult } from '@reduxjs/toolkit';
import { DislikeSmallFilled, LikeSmallFilled, StarSmallIcon } from 'app/assets/svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ChatRouter } from 'routes/chat/chat';
import { Category, ConsultationOffer, InvoiceStatusEnum, User, UserCategoryMeta } from 'shared/api';
import { CONDITION_DESKTOP, DEFAULT_AVATAR } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import {
  buildRoute,
  captureError,
  checkCanUseFreeToken,
  chooseEndingWord,
  coverImageStyle,
  findFirstNotUsedPromocode,
} from 'shared/helpers';
import { Button, ImageWithRetina, Modal } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';
import { ConsultationPaymentModal } from 'widgets/common/public-expert-consultations/ui';

type PartialCategory = Pick<Category, 'id' | 'name'>;

interface ResponseMessageProps {
  offer: ConsultationOffer;
  user: User;
  category: PartialCategory;
}

interface PaymentModalState {
  price: number;
  theme: string;
  userCategoryMeta: Partial<UserCategoryMeta>;
}

export const ResponseMessage: FC<ResponseMessageProps> = ({ offer, user, category }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('chat.page');
  const { t: tUtils } = useTranslation('utils');

  const router: ChatRouter = useRouter();
  const [canUseFreeToken, setCanUseFreeToken] = useState(false);
  const chatRooms = useAppSelector(selectors.chatRooms.selectChatRooms);
  const creatingChatRoom = useAppSelector(selectors.chatRooms.selectCreatingChatRoom);
  const userPromocodes = useAppSelector(selectors.profile.selectUserPromocodes);
  const isVisiblePaymentModal = useAppSelector(selectors.app.selectIsVisiblePaymentModal);
  const handleGoExpertProfile = () => {
    router.push(buildRoute(routes.expert, { id: offer.expertId }));
  };
  const hasFreeConsultations = user.hasFreeConsultations && user.freeConsultationsRemain > 0;

  const handleNavigateToFreeChat = async () => {
    try {
      const existRoom = chatRooms.find((room) => room.expertId === offer.expertId);
      if (existRoom) {
        router.push({ query: { activeTab: 'consult', roomId: existRoom.id } });
      } else {
        const tempRoomId: number = Date.now();
        const actionResult = await dispatch(
          actions.chatRooms.createTempChatRoom({ client: user, expert: offer.expert, tempRoomId }),
        );
        unwrapResult(actionResult);
        router.push({ query: { activeTab: 'consult', roomId: tempRoomId } });
      }
    } catch (error: any) {
      captureError(error);
      toast.error(error.message);
    }
  };

  const handlePay = async () => {
    onClosePaymentModal();
    try {
      const chatRoomWithThisExpert = chatRooms?.find((room) => room.expertId === offer.expertId);
      const redirectUrl = chatRoomWithThisExpert
        ? `${window.location.origin}${routes.chat}?activeTab=consult&roomId=${chatRoomWithThisExpert.id}`
        : `${window.location.origin}${routes.chat}?activeTab=consult&expertId=${offer.expertId}`;

      const action = await dispatch(
        actions.consultations.acceptConsultationOfferAsync({
          id: offer.id,
          dto: {
            redirectUrl,
          },
        }),
      );
      const data = unwrapResult(action);

      const paymentUrl = data.invoices.find((invoice) => invoice.invoice?.status === InvoiceStatusEnum.Pending)?.invoice
        ?.checkoutUrl;

      if (paymentUrl) {
        setTimeout(() => {document.location.href = paymentUrl;},250);
      }
    } catch (error: any) {
      onClosePaymentModal();
      captureError(error);
      toast.error(error.message || tUtils('somethingWrong'));
    }
  };

  const handlePayWithToken = async () => {
    onClosePaymentModal();
    try {
      const notUsedPromocode = findFirstNotUsedPromocode(userPromocodes);
      if (notUsedPromocode) {
        const action = await dispatch(
          actions.consultations.acceptConsultationOfferWithPromocodeAsync({
            id: offer.id,
            dto: { userPromocodeId: notUsedPromocode.id.toString() },
          }),
        );
        const data = unwrapResult(action);

        dispatch(actions.chatRooms.addChatRoom(data.chatRoom));
        dispatch(actions.profile.fetchSelfPromocode());
        await router.push({
          pathname: routes.chat,
          query: {
            activeTab: 'consult',
            roomId: data.chatRoomId,
          },
        });
      }
    } catch (error: any) {
      onClosePaymentModal();
      captureError(error);
      toast.error(error.message || tUtils('somethingWrong'));
    }
  };
  const [paymentModalState, setPaymentModalState] = useState<PaymentModalState>({} as PaymentModalState);

  const onPayment = (state: PaymentModalState) => () => {
    setPaymentModalState({ ...state });
    dispatch(actions.app.showPaymentModal());
  };

  const onClosePaymentModal = () => {
    dispatch(actions.app.hidePaymentModal());
  };

  const expertRating = offer?.expert?.ratingMetaData?.InternalRating
    ? Number(offer?.expert?.ratingMetaData?.InternalRating)
    : 0;

  useEffect(() => {
    setCanUseFreeToken(checkCanUseFreeToken(userPromocodes, user));
  }, [userPromocodes]);

  return (
    <>
      <OfferMessageContainer>
        <Title>{t('response.body.consultForMoney', { price: offer.price, currency: 'P' })}</Title>
        <SubTitleWrapper>{/* <SubTitle>{t('response.body.subTitle')}</SubTitle> */}</SubTitleWrapper>
        <OfferMessage>{offer.description}</OfferMessage>

        <ExpertInfo>
          <ExpertAvatar $url={offer.expert?.avatarUrl} />
          <Info>
            <Name>
              {offer.expert?.firstName} {offer.expert?.lastName}
            </Name>
            <ConsultCount>
              {chooseEndingWord(offer.expert?.expertConsultationsCount, [
                t('response.body.countExpertConsult.one', { count: offer.expert?.expertConsultationsCount }),
                t('response.body.countExpertConsult.two', { count: offer.expert?.expertConsultationsCount }),
                t('response.body.countExpertConsult.many', { count: offer.expert?.expertConsultationsCount }),
              ])}
            </ConsultCount>
            <Rate>
              <StarSmallIcon />
              <span>{expertRating?.toFixed(2).replace(/'.'/g, ',') || '0,00'}</span>
            </Rate>
          </Info>

          <Reviews>
            <LikeCount>{offer.expert?.expertLikesCount}</LikeCount>
            <LikeSmallFilled />
            <DislikeCount>{offer.expert?.expertDislikesCount}</DislikeCount>
            <DislikeSmallFilled />
          </Reviews>
        </ExpertInfo>

        <ButtonsRow>
          <NewStyledBorderedButton
            bordered
            fontSize="13px"
            hasPaddingHorizontal={false}
            onClick={handleGoExpertProfile}
            disabled={creatingChatRoom === 'pending'}
          >
            {t('response.body.button.expertProfile')}
          </NewStyledBorderedButton>
          <NewStyledBorderedButton
            bordered
            fontSize="13px"
            hasPaddingHorizontal={false}
            onClick={handleNavigateToFreeChat}
            disabled={creatingChatRoom === 'pending' || !offer.expert?.hasFreeChat}
          >
            {t('response.body.button.askExpert')}
          </NewStyledBorderedButton>
        </ButtonsRow>
        <PaymentButton
          fontSize="13px"
          block
          onClick={onPayment({
            price: offer.price,
            theme: category.name,
            userCategoryMeta: { categoryId: category.id, userId: user.id },
          })}
          disabled={creatingChatRoom === 'pending'}
          loading={creatingChatRoom === 'pending'}
        >
          {t('pay')}
          {!!hasFreeConsultations && canUseFreeToken && <CoinIcon src="/img/coin.png" alt="coin" />}
        </PaymentButton>

        <NoteTextWrapper>
          <NoteTextTopTextWrapper>
            <NoteTextTop>{t('response.body.topNote')}</NoteTextTop>
          </NoteTextTopTextWrapper>
          <NoteTextBottomTextWrapper>
            <NoteTextBottom>{t('response.body.bottomNote')}</NoteTextBottom>
          </NoteTextBottomTextWrapper>
        </NoteTextWrapper>
      </OfferMessageContainer>
      <Modal isVisible={isVisiblePaymentModal} onClose={onClosePaymentModal}>
        <ConsultationPaymentModal
          price={offer.price}
          theme={paymentModalState.theme}
          onRefuse={onClosePaymentModal}
          onPayWithMoney={handlePay}
        />
      </Modal>
    </>
  );
};

const OfferMessageContainer = styled.div`
  width: 100%;
  max-width: 450px;
  padding: 20px 15px;
  background-color: var(--gray10);
  border-radius: 12px;
  margin-bottom: 30px;

  ${CONDITION_DESKTOP} {
    margin-left: 55px;
    margin-bottom: 40px;
  }
`;
const Title = styled.h3`
  font-size: 17px;
  font-weight: bold;

  ${CONDITION_DESKTOP} {
    font-size: 15px;
  }
`;
const OfferMessage = styled.div`
  font-size: 13px;
  color: var(--gray);
  margin-top: 10px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;
const ExpertInfo = styled.div`
  display: grid;
  margin-top: 35px;
  margin-bottom: 26px;
  gap: 0 10px;
  grid-template-columns: 1fr 70px;
  grid-template-rows: auto auto;
  grid-template-areas:
    'info avatar'
    'review review';

  ${CONDITION_DESKTOP} {
    grid-template-columns: 50px 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'avatar info review';
  }
`;
const ExpertAvatar = styled.div<{ $url?: string }>`
  grid-area: avatar;
  width: 70px;
  height: 70px;
  border-radius: 50%;

  ${({ $url }) => ({ ...coverImageStyle($url || DEFAULT_AVATAR) })}
  ${CONDITION_DESKTOP} {
    width: 50px;
    height: 50px;
  }
`;
const Info = styled.div`
  grid-area: info;
  font-size: 15px;

  ${CONDITION_DESKTOP} {
    font-size: 13px;
  }
`;
const Name = styled.div`
  font-weight: bold;
  line-height: 20px;

  ${CONDITION_DESKTOP} {
    line-height: 13px;
  }
`;
const ConsultCount = styled.div`
  color: var(--gray);
`;
const Rate = styled.div`
  display: flex;
  align-items: center;
  height: 19px;

  & > span {
    align-self: baseline;
    line-height: 19px;
    margin-left: 3px;
  }
`;
const Reviews = styled.div`
  grid-area: review;
  font-size: 26px;
  line-height: 26px;
  display: flex;
  justify-self: flex-start;

  & > svg {
    margin-top: 2px;
  }

  ${CONDITION_DESKTOP} {
    justify-self: flex-end;
  }
`;
const LikeCount = styled.span`
  color: var(--green);
  margin-right: 3px;
`;
const DislikeCount = styled.span`
  color: var(--red);
  margin-right: 3px;
  margin-left: 13px;
`;
const ButtonsRow = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 15px;
`;
const StyledBorderedButton = styled(Button)`
  &:disabled {
    opacity: 0.2;
    border: 1px solid var(--purple);
  }
`;
const PaymentButton = styled(Button)`
  display: flex;

  span {
    display: flex;

    img {
      margin-left: 5px;
    }
  }
`;

const CoinIcon = styled(ImageWithRetina)`
  width: 15px;
  height: 15px;
`;

const SubTitleWrapper = styled.div`
  margin-top: 10px;
`;

const NoteTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const NoteTextTopTextWrapper = styled.div``;

const NoteTextBottomTextWrapper = styled.div``;

const NoteTextTop = styled.span`
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray2);
`;

const NoteTextBottom = styled.span`
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray2);
`;

const NewStyledBorderedButton = styled(StyledBorderedButton)`
  &:hover {
    border: 1px solid var(--purple);
    background-color: transparent;
    color: var(--purple);
  }
`;
