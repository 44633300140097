import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChatRoomExtended } from 'shared/common/types';
import { selectors } from 'store/ducks';

export const useIsExpertInRoom = (chatRoom: ChatRoomExtended | undefined) => {
  const user = useSelector(selectors.profile.selectUser);
  const [isExpertInRoom, setIsExpertInRoom] = useState(false);

  useEffect(() => {
    setIsExpertInRoom(user.id === chatRoom?.expertId);
  }, [user, chatRoom]);

  return isExpertInRoom;
};
