import type { FC } from 'react';
import React from 'react';
import { useField } from 'react-final-form';

import { FieldWrapper } from '../components/FieldWrapper';
import { Input } from '../components/Input';
import type { BaseFieldProps } from './types';

type InputFieldProps = BaseFieldProps & {
  placeholder?: string;
};

export const InputField: FC<InputFieldProps> = ({ name, label, required, placeholder }) => {
  const {
    input: { value, onChange },

    meta,
  } = useField<string>(name);

  return (
    <FieldWrapper label={label} required={required} meta={meta}>
      <Input disabled={meta.submitting} name={name} value={value} onChange={onChange} placeholder={placeholder} />
    </FieldWrapper>
  );
};
