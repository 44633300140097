import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/redux';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { UpdateNotificationsSettingDto } from 'shared/api';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { captureError } from 'shared/helpers/captureError';
import { Button } from 'shared/ui';
import { selectors } from 'store/ducks/profile';
import { fetchGetNotificationSettings, fetchUpdateNotificationSettings } from 'store/ducks/profile/actions';
import styled from 'styled-components';

import { Block, Name } from './ui';

interface IBlock {
  title: string;
  names: {
    telegram: Name;
    sms: Name;
    push: Name;
  };
}
// TODO: onNewRubricsPush
const blocks: IBlock[] = [
  {
    title: 'requestConsult',
    names: {
      telegram: 'onConsultRequestTelegram',
      sms: 'onConsultRequestSMS',
      push: 'onConsultRequestPush',
    },
  },
  {
    title: 'consultationBegin',
    names: {
      telegram: 'onConsultStartTelegram',
      sms: 'onConsultStartSMS',
      push: 'onConsultStartPush',
    },
  },
  {
    title: 'newMessageInConsultation',
    names: {
      telegram: 'onConsultMessageTelegram',
      sms: 'onConsultMessageSMS',
      push: 'onConsultMessagePush',
    },
  },
  {
    title: 'launchNewRubrics',
    names: {
      telegram: 'onNewCategoryTelegram',
      sms: 'onNewCategorySMS',
      push: 'onNewCategorySMS',
    },
  },
];

export const NotificationSettings: FC = () => {
  const dispatch = useAppDispatch();
  const notificationSetting = useSelector(selectors.selectNotificationSetting);

  const { t } = useTranslation('profile.page');

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<UpdateNotificationsSettingDto>({
    defaultValues: {
      onConsultMessagePush: notificationSetting.onConsultMessagePush || false,
      onConsultMessageSMS: notificationSetting.onConsultMessageSMS || false,
      onConsultMessageTelegram: notificationSetting.onConsultMessageTelegram || false,
      onConsultRequestPush: notificationSetting.onConsultRequestPush || false,
      onConsultRequestSMS: notificationSetting.onConsultRequestSMS || false,
      onConsultRequestTelegram: notificationSetting.onConsultRequestTelegram || false,
      onConsultStartPush: notificationSetting.onConsultStartPush || false,
      onConsultStartSMS: notificationSetting.onConsultStartSMS || false,
      onConsultStartTelegram: notificationSetting.onConsultStartTelegram || false,
      onNewCategorySMS: notificationSetting.onNewCategorySMS || false,
      onNewCategoryTelegram: notificationSetting.onNewCategoryTelegram || false,
    },
  });

  const resetValues = async () => {
    try {
      const res = await dispatch(fetchGetNotificationSettings());
      const setting = unwrapResult(res);
      reset(setting);
    } catch (e) {
      captureError(e);
      toast.error(t('dontGiveData'));
    }
  };

  useEffect(() => {
    resetValues();
  }, []);

  const onSubmit = async (values: UpdateNotificationsSettingDto) => {
    try {
      const res = await dispatch(fetchUpdateNotificationSettings(values));
      if (unwrapResult(res)) {
        toast.success(t('saveInfo'));
        await resetValues();
      }
    } catch (e) {
      captureError(e);
      toast.error(t('notSuccessSaveInfo'));
    }
  };

  return (
    <Container>
      <MainTitle>{t('notificationsTab.title')}</MainTitle>
      <MainNote>
        <div>{t('notificationsTab.NotificationInTelegram')}</div>
        <div>
          <span>{t('notificationsTab.subscribeOnBot')}</span>
          <Link href="/">@consultybot.</Link>
        </div>
      </MainNote>

      {blocks.map(({ title, names }) => (
        <Block control={control} title={title} names={names} key={title} />
      ))}

      <Button disabled={!isDirty} type="submit" onClick={handleSubmit(onSubmit)}>
        {t('save')}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  padding-left: 0;
  padding-bottom: 90px;

  ${CONDITION_DESKTOP} {
    padding-left: 95px;
  }
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 10px;
`;

const Note = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: var(--gray);
`;

const MainTitle = styled(Title)`
  font-size: 25px;
  margin-bottom: 10px;
`;
const MainNote = styled(Note)`
  font-size: 15px;
  margin-bottom: 40px;
  max-width: 400px;

  & > div > a {
    color: var(--purple);

    :hover {
      text-decoration: underline;
    }
  }
`;
