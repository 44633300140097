import { useAppDispatch } from 'hooks/redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, HTMLAttributes, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ChatRouter, ChatTabs } from 'routes/chat/chat';
import { CONDITION_DESKTOP, HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from 'shared/common/constants';
import SimpleBar from 'simplebar-react';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';

import { consultyChannel } from './data/consultChannel';
import {
  ChannelSidebarRoomButton,
  ChatSidebarRequestButton,
  ChatSidebarResponseButton,
  ChatSidebarRoomButton,
  ChatSidebarTabButton,
} from './ui';

interface ChatSidebarProps extends HTMLAttributes<HTMLDivElement> {}

export const ChatSidebar: FC<ChatSidebarProps> = ({ ...props }) => {
  const { t } = useTranslation('chat.page');
  const router: ChatRouter = useRouter();
  const dispatch = useAppDispatch();
  const user = useSelector(selectors.profile.selectUser);
  const chatRooms = useSelector(selectors.chatRooms.selectChatRooms);
  const myRequests = useSelector(selectors.consultationRequests.selectAllMyConsultationRequests);
  const clientRequests = useSelector(selectors.consultationRequests.selectAllClientRequests);
  const unreadChatRoomsCount = useSelector(selectors.chatRooms.selectUnreadRoomsIds).length;
  const responsesUnreadCount = useSelector(selectors.consultationRequests.selectResponseUnreadCount);
  const requestUnreadCount = useSelector(selectors.consultationRequests.selectRequestUnreadCount);
  const isShowHelloBar = useSelector(selectors.profile.selectIsShowHelloBar);

  const activeTabString = useMemo(() => router.query.activeTab, [router.query.activeTab]);
  const activeRoomId = useMemo(() => router.query.roomId, [router.query.roomId]);

  const handleClickTabButton = (activeTab: ChatTabs) => {
    router.push({ query: { activeTab } });
  };

  const handleClickRoomButton = (activeTab: ChatTabs, roomId: number) => {
    if (roomId.toString() !== activeRoomId) {
      router.push({ query: { activeTab, roomId } });
    }
  };

  const handleClickChannelNewsButton = (activeTab: ChatTabs, roomId: number) => {
    dispatch(actions.channelNews.setShowUnreadCount(false));
    if (roomId.toString() !== router.query.roomId) {
      router.push({ query: { activeTab, roomId } });
    }
  };

  useEffect(() => {
    if (activeTabString && !activeRoomId) {
      handleClickTabButton(activeTabString);
    }
    if (activeTabString && activeRoomId) {
      handleClickRoomButton(activeTabString, Number(activeRoomId));
    }
  }, []);

  const renderRoomsList = useMemo(() => {
    switch (router.query.activeTab) {
      case 'requests':
        return clientRequests.map((request) => (
          <ChatSidebarRequestButton
            key={request.categoryId}
            request={request}
            unreadCount={requestUnreadCount[request.categoryId]}
            isActive={activeRoomId === request.categoryId.toString()}
            onClick={() => handleClickRoomButton('requests', request.categoryId)}
          />
        ));
      case 'responses':
        return myRequests.map((request) => (
          <ChatSidebarResponseButton
            key={request.id}
            request={request}
            unreadCount={responsesUnreadCount[request.id]}
            isActive={activeRoomId === request.id.toString()}
            onClick={() => handleClickRoomButton('responses', request.id)}
          />
        ));
      default:
        return (
          <>
            <ChannelSidebarRoomButton
              key={consultyChannel.id}
              chatRoom={consultyChannel}
              isExpertInRoom={consultyChannel.expertId === user.id}
              isActive={router.query.roomId === consultyChannel.id.toString()}
              onClick={() => handleClickChannelNewsButton('channel', consultyChannel.id)}
              unreadCount={3}
            />
            {chatRooms.map((room) => (
              <ChatSidebarRoomButton
                key={room.id}
                chatRoom={room}
                isExpertInRoom={room.expertId === user.id}
                isActive={activeRoomId === room.id.toString()}
                onClick={() => handleClickRoomButton('consult', room.id)}
              />
            ))}
          </>
        );
    }
  }, [chatRooms, myRequests, clientRequests, router, responsesUnreadCount, requestUnreadCount]);

  return (
    <Container {...props} $isShowHelloBar={isShowHelloBar}>
      <SidebarHeader $isExpert={user.isExpert}>
        <ChatSidebarTabButton
          text={t('sidebar.consultations')}
          isActive={activeTabString === 'consult' || activeTabString === 'channel'}
          unreadCount={unreadChatRoomsCount}
          onClick={() => handleClickTabButton('consult')}
        />
        {user.isExpert && (
          <ChatSidebarTabButton
            text={t('sidebar.requests')}
            isActive={activeTabString === 'requests'}
            unreadCount={Object.keys(requestUnreadCount).length}
            onClick={() => handleClickTabButton('requests')}
          />
        )}
        <ChatSidebarTabButton
          text={t('sidebar.responses')}
          isActive={activeTabString === 'responses'}
          unreadCount={Object.keys(responsesUnreadCount).length}
          onClick={() => handleClickTabButton('responses')}
        />
      </SidebarHeader>

      <ChatRoomsList>{renderRoomsList}</ChatRoomsList>
    </Container>
  );
};

const Container = styled.div<{ $isShowHelloBar: boolean }>`
  border-left: 1px solid var(--gray6);
  border-right: 1px solid var(--gray6);
  height: calc(100vh - ${HEADER_HEIGHT_MOBILE});
  height: ${({ $isShowHelloBar }) =>
    $isShowHelloBar
      ? `calc(var(--v-height) - ${HEADER_HEIGHT_MOBILE} - 67.34px)`
      : `calc(var(--v-height) - ${HEADER_HEIGHT_MOBILE})`};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px calc(100% - 60px);
  ${CONDITION_DESKTOP} {
    height: calc(100vh - ${HEADER_HEIGHT_DESKTOP});
    height: ${({ $isShowHelloBar }) =>
      $isShowHelloBar
        ? `calc(var(--v-height) - ${HEADER_HEIGHT_DESKTOP} - 46.25px)`
        : `calc(100vh - ${HEADER_HEIGHT_DESKTOP})`};
  }
`;
const SidebarHeader = styled.div<{ $isExpert: boolean }>`
  display: grid;
  grid-template-columns: ${({ $isExpert }) => ($isExpert ? '1fr 1fr 1fr' : '1fr 1fr')};
  top: 0;
  height: 60px;
  background-color: var(--white);
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
`;
const ChatRoomsList = styled(SimpleBar)`
  padding: 5px;
`;
