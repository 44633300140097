import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/redux';
import React, { ChangeEvent, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { captureError } from 'shared/helpers/captureError';
import { ReceiptInput } from 'shared/ui';
import { createReceipt } from 'store/ducks/consultationRequests/actions';
import styled from 'styled-components';

interface UploadReceiptFormProps {
  consultationId: number;
  closeModal: () => void;
  getConsultations: () => void;
}

export const UploadReceiptForm: FC<UploadReceiptFormProps> = ({ consultationId, closeModal, getConsultations }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('profile.page');

  const { handleSubmit, control, setValue, clearErrors } = useForm<{ receipt: File }>({
    mode: 'onChange',
  });

  const onChangePhoto = (name: 'receipt') => (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      const file = files.item(0);
      if (file) {
        setValue(name, file);
        clearErrors(name);
      }
    }
  };

  const onSubmit = async (values: { receipt: File }) => {
    try {
      const res = await dispatch(createReceipt({ consultationId, receipt: values.receipt }));
      unwrapResult(res);
      closeModal();
      toast.success(t('notificationsTab.receiptSended'));
      getConsultations();
    } catch (e: any) {
      toast.error(e.message || 'Что-то пошло не так...');
      captureError(e);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Title>Загрузить чек</Title>
      <Controller
        control={control}
        name="receipt"
        rules={{
          required: { value: true, message: 'Обязательно' },
        }}
        render={() => (
          <ReceiptInput
            prefix="receipt"
            width="100"
            type="file"
            label="Выбрать"
            sizeLimitMb={5}
            accept="image/png, image/jpeg, application/pdf"
            onChange={onChangePhoto('receipt')}
          />
        )}
      />
      <SubmitButton type="submit">Отправить</SubmitButton>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SubmitButton = styled.button`
  user-select: none;
  padding-top: 13px;
  width: 100%;
  padding-bottom: 13px;
  background-color: var(--purple);
  color: var(--white);
  border-color: var(--white);
  padding-left: 20px;
  padding-right: 20px;
  transition: all 0.2s;
  align-items: center;
  border-radius: 28px;
  border-width: 1px;
  border-style: solid;
  opacity: 1;
  outline: none;
  cursor: pointer;
  justify-content: center;
  flex-direction: row;
  display: flex;
  max-height: 46px;

  &:hover {
    background-color: var(--white);
    color: var(--purple);
    border-color: var(--purple);
  }
`;

const Title = styled.span`
  font-size: 20px;
  align-self: center;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--text);
  display: block;
`;
