import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractSignSmsCodePayload } from 'shared/common/types';
import { Button, Input } from 'shared/ui';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

type Props = {
  isVisible: boolean;
  onSubmitSmsCode: (payload: ContractSignSmsCodePayload) => void;
  number?: string;
};

export const ContractSignCodeModal: FC<Props> = ({ isVisible, number, onSubmitSmsCode }) => {
  const { t } = useTranslation('contract.page');

  const [smsCode, setSmsCode] = useState('');

  const handleChangeSmsCode = (newCode: string) => {
    setSmsCode(newCode);
  };

  const handleSubmitSmsCode = () => {
    const timestamp = new Date().toISOString();

    onSubmitSmsCode({ smsCode, timestampEnterSmsCode: timestamp });
  };

  return (
    <Modal isVisible={isVisible}>
      <Title>{t('contractSignModal.title', { phone: number })}</Title>
      <Note>{t('contractSignModal.description')}</Note>
      <Input
        value={smsCode}
        type="number"
        onChange={(e) => handleChangeSmsCode(e.target.value)}
        placeholder={t('contractSignModal.placeholder')}
      />
      <ButtonContainer>
        <Button type="button" onClick={handleSubmitSmsCode} disabled={smsCode.length === 0}>
          Подписать договор
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

const Title = styled.div`
  white-space: pre-line;
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  padding-bottom: 15px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Note = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  padding-bottom: 30px;
  max-width: 260px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  & > button {
    margin: 0px;
    width: 100%;
  }
`;
