import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { ExpertStepDtoStepEnum } from 'shared/api';
import { CONDITION_DESKTOP, CONDITION_MOBILE, DEFAULT_AVATAR } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { coverImageStyle } from 'shared/helpers';
import { Button } from 'shared/ui';
import { selectors } from 'store/ducks/profile';
import { asyncAddUserStep } from 'store/ducks/profile/actions';
import styled from 'styled-components';

import { ExpertMobileBanner } from './ui';

export const ContractFormFinalStep: FC = () => {
  const { t } = useTranslation('contract.page');
  const router = useRouter();
  const user = useAppSelector(selectors.selectUser);
  const dispatch = useAppDispatch();

  const onContinue = () => {
    router.push(`${routes.profile}?activeTab=expertProfile`);
    dispatch(asyncAddUserStep({ step: ExpertStepDtoStepEnum._8done }));
  };

  return (
    <Container>
      <AvatarContainer>
        <Avatar $url={user.avatarUrl} />
      </AvatarContainer>
      <Title>{t('form.almostDone')}</Title>
      <TextContainer>
        <TopText>{`${t('form.weReview')}`}</TopText>
        <Text>{`${t('form.weReviewSubNote')}`}</Text>
      </TextContainer>
      <Button onClick={onContinue}>{t('form.goExpert')}</Button>
      <ExpertMobileBannerWrapper>
        <ExpertMobileBanner />
      </ExpertMobileBannerWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 80%;
  margin: 40px auto;

  & > div > div {
    margin-bottom: 10px;
  }

  & > div > div:last-child {
    margin-bottom: 0;
  }

  & > button {
    margin-top: 40px;
  }

  ${CONDITION_DESKTOP} {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  ${CONDITION_MOBILE} {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  margin-bottom: 40px;
  margin-top: 40px;
  color: var(--paleText);
`;

const SmallTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--text);
`;

const Text = styled(SmallTitle)`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  line-height: 1.43;
`;

const TopText = styled(SmallTitle)`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  line-height: 1.43;
  margin-bottom: 0 !important;
`;

const TextContainer = styled.div`
  max-width: 500px;
  text-align: center;
`;

const Avatar = styled.div<{ $url?: string }>`
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0 auto 20px;
  ${({ $url }) => ({ ...coverImageStyle($url || DEFAULT_AVATAR) })}
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 275px;
  background-image: url('/img/confeti@3x.jpg');
  background-size: cover;

  ${CONDITION_MOBILE} {
    margin-top: 40px;
  }
`;

const ExpertMobileBannerWrapper = styled.div`
  margin-top: 80px;
  max-width: 560px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${CONDITION_MOBILE} {
    padding-right: 20px;
    padding-left: 20px;
  }
`;
