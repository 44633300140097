import { useTranslation } from 'next-i18next';
import React, { FC, HTMLAttributes } from 'react';
import { ConsultationRequest } from 'shared/api';
import { CONDITION_DESKTOP, DEFAULT_CATEGORY } from 'shared/common/constants';
import { chooseEndingWord, coverImageStyle, makeMessageTime } from 'shared/helpers';
import styled from 'styled-components';

interface ChatSidebarResponseButtonProps extends HTMLAttributes<HTMLDivElement> {
  isActive: boolean;
  request: ConsultationRequest;
  unreadCount: number | undefined;
  onClick: () => void;
}

export const ChatSidebarResponseButton: FC<ChatSidebarResponseButtonProps> = ({
  isActive,
  request,
  unreadCount,
  ...props
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('chat.page');

  return (
    <Container $isActive={isActive} {...props}>
      <Image
        $url={
          request.category.iconUrl ||
          (request.category.rubrics && request.category.rubrics[0]?.iconUrl) ||
          DEFAULT_CATEGORY
        }
      />
      <Middle>
        <Name>{request.category.name}</Name>
        <Message>{request.content}</Message>
        <Footer $close={request.status !== 'active'}>
          {request.status !== 'active' && <Close>{t('response.close')}</Close>}
          <span>
            {
              (t('response.count'),
              chooseEndingWord(request.offers?.length || 0, [
                t('response.count.one', { count: request.offers?.length || 0 }),
                t('response.count.two', { count: request.offers?.length || 0 }),
                t('response.count.many', { count: request.offers?.length || 0 }),
              ]))
            }
          </span>
        </Footer>
      </Middle>
      <Right>
        <Time>{makeMessageTime(request.createdAt, language)}</Time>
        {!!unreadCount && <UnreadCount>{unreadCount < 100 ? unreadCount : <MoreCount>99+</MoreCount>}</UnreadCount>}
      </Right>
    </Container>
  );
};

const Container = styled.div<{ $isActive: boolean }>`
  padding: 10px;
  margin: 5px 0;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 60px 1fr 45px;
  align-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background-color: ${({ $isActive }) => $isActive && 'rgba(245, 246, 250, 0.9)'};
  ${CONDITION_DESKTOP} {
    grid-template-columns: 50px 190px 45px;
  }
`;
const Image = styled.div<{ $url?: string }>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  align-self: top;
  ${({ $url }) => $url && { ...coverImageStyle($url) }}
  ${CONDITION_DESKTOP} {
    width: 50px;
    height: 50px;
  }
`;
const Middle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 5px 0 10px;
`;
const Name = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  ${CONDITION_DESKTOP} {
    font-size: 14px;
  }
`;
const Message = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 40px;
  font-size: 15px;
  line-height: 1.23;
  color: var(--gray);
  ${CONDITION_DESKTOP} {
    font-size: 13px;
    height: 32px;
  }
`;
const Footer = styled.div<{ $close: boolean }>`
  margin-top: 5px;
  font-size: 15px;
  color: var(--gray);
  line-height: 1.23;
  opacity: ${({ $close }) => ($close ? 0.6 : 1)};
  ${CONDITION_DESKTOP} {
    font-size: 13px;
  }
`;

const Close = styled.span`
  margin-right: 10px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;
const Time = styled.span`
  font-size: 12px;
  color: var(--gray3);
`;
const UnreadCount = styled.div`
  font-size: 11px;
  line-height: 22px;
  color: var(--white);
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--purple);
`;

const MoreCount = styled.span`
  font-size: 9px;
  top: -1px;
  position: relative;
`;
