import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/redux';
import { useChatPrivateCannel } from 'hooks/useChatPrivateChannel';
import { useIsExpertInRoom } from 'hooks/useIsExpertInRoom';
import router from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CONDITION_DESKTOP, HEADER_HEIGHT_DESKTOP } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { captureError } from 'shared/helpers/captureError';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';

import { ExtraServiceModal } from '../../modals/extra-service-modal/extra-service-modal';
import { FreeAskModal } from '../../modals/free-ask-modal/free-ask-modal';
import { ProlongationModal } from '../../modals/prolongation-modal';
import {
  ChatInputMessage,
  ReviewClientModal,
  ReviewExpertModal,
  ReviewMobileModal,
  RoomAside,
  RoomBody,
  RoomHeader,
} from './ui';

export const ChatRoom: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('utils');
  const user = useSelector(selectors.profile.selectUser);
  const chatRoomId = useSelector(selectors.chatRooms.selectCurrentChatRoomId);
  const chatRoom = useSelector(selectors.chatRooms.selectChatRoomById(chatRoomId));
  const activeConsultation = useSelector(selectors.consultationsChatRoom.selectActiveConsultation);
  const [consultationIdForReview, setConsultationIdForReview] = useState(0);
  const isProlongationModalOpen = useSelector(selectors.app.selectIsVisibleProlongationModal);
  const isExtraServiceModalOpen = useSelector(selectors.app.selectIsVisibleExtraServiceModal);
  const isExpertInRoom = useIsExpertInRoom(chatRoom);
  const lastMessageId = useRef<number | null>(null);

  useChatPrivateCannel();

  useEffect(
    () => () => {
      dispatch(actions.consultationsChatRoom.setIsReviewModalOpen(false));
    },
    [chatRoomId, dispatch],
  );

  useEffect(() => {
    if (chatRoom) {
      dispatch(actions.consultationsChatRoom.setConsultationsChatRoom(chatRoom));
    }
  }, [chatRoom?.id, chatRoom?.consultations]);

  useEffect(() => {
    if (chatRoom?.lastMessageId) {
      lastMessageId.current = chatRoom?.lastMessageId;
    }
  }, [chatRoom?.lastMessageId]);

  useEffect(() => {
    if (chatRoom && !chatRoom.isTempRoom) {
      dispatch(actions.chatRooms.resetUnreadMark({ chatRoomId: chatRoom.id }));
    }
    return () => {
      if (chatRoom?.id && !chatRoom.isTempRoom && lastMessageId.current) {
        dispatch(
          actions.chatRooms.updateLastReadMessageAsync({ id: chatRoom.id, dto: { messageId: lastMessageId.current } }),
        );
        dispatch(actions.chatRooms.resetUnreadMark({ chatRoomId: chatRoom.id }));
        dispatch(
          actions.chatRooms.changeLastReadMessage({
            chatRoomId: chatRoom.id,
            isExpertInRoom: user.id === chatRoom.expertId,
            lastMessageId: lastMessageId.current,
          }),
        );
      }
    };
  }, [chatRoom?.id, chatRoom?.isTempRoom]);

  useEffect(() => {
    if (activeConsultation) {
      setConsultationIdForReview(activeConsultation.id);
    }
  }, [activeConsultation]);

  const onHide = () => dispatch(actions.app.setIsVisibleFreeAskModal(false));
  const isShow = useSelector(selectors.app.selectIsVisibleFreeAskModal);

  const onSendMessage = async ({ message = '', attachments = [] }: { message?: string; attachments?: string[] }) => {
    const content = message.trim();
    if (!content && !attachments.length) {
      return;
    }

    if (chatRoom?.isTempRoom) {
      const actionResult = await dispatch(
        actions.chatRooms.replaceTempToRealChatRoom({
          client: user,
          expert: chatRoom.expert,
          initialMessage: { content: content || '', ...(!!attachments.length && { attachments }) },
          tempRoomId: chatRoom.id,
        }),
      );
      const newRoom = unwrapResult(actionResult);

      if (newRoom) {
        router.replace({ query: { activeTab: 'consult', roomId: newRoom.room.id } });
      }
      return;
    }

    const actionResult = await dispatch(
      actions.chatMessages.sendChatMessage({
        chatRoomId: chatRoomId!,
        content: content || '',
        ...(!!attachments.length && { attachments }),
        consultationId: activeConsultation?.id,
      }),
    );
    const newMessage = unwrapResult(actionResult);

    if (newMessage) {
      dispatch(actions.chatRooms.updateLastMessage({ message: newMessage, user }));
    }
  };

  const onProlongation = async ({ price }: { price: number }) => {
    if (activeConsultation) {
      await dispatch(
        actions.consultations.createConsultationProlongationRequestAsync({
          id: activeConsultation.id,
          dto: {
            price,
            redirectUrl: `${window.location.origin}${routes.chat}?activeTab=consult&roomId=${chatRoom?.id}`,
          },
        }),
      ).catch((error) => {
        captureError(error);
        toast.error(error.message || t('somethingWrong'));
      });
    }
  };

  const description = isExpertInRoom
    ? t('modals.mobileModal.descriptionExpert')
    : t('modals.mobileModal.descriptionClient');

  if (!chatRoom) {
    return (
      <Container>
        <Wrapper>
          <StyledRoomHeader chatRoomId={chatRoomId} />
          <StyledRoomBody chatRoomId={chatRoomId} />
          <StyledRoomAside chatRoomId={chatRoomId} activeConsultation={activeConsultation} />
          <RoomFooter />
        </Wrapper>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <Wrapper>
          <StyledRoomHeader chatRoomId={chatRoomId} />
          <StyledRoomBody chatRoomId={chatRoomId} />
          <StyledRoomAside chatRoomId={chatRoomId} activeConsultation={activeConsultation} />
          <RoomFooter>
            <ChatInputMessage
              onSendMessage={onSendMessage}
              disabled={!chatRoom?.expert?.hasFreeChat && !activeConsultation}
            />
          </RoomFooter>
        </Wrapper>
      </Container>

      {chatRoom &&
        (isExpertInRoom ? (
          <ReviewClientModal userId={user.id} clientId={chatRoom.clientId} consultationId={consultationIdForReview} />
        ) : (
          <ReviewExpertModal expertId={chatRoom.expertId} userId={user.id} consultationId={consultationIdForReview} />
        ))}

      <ProlongationModal
        isVisible={isProlongationModalOpen}
        onClose={() => dispatch(actions.app.setIsVisibleProlongationModal(false))}
        handleProlongation={onProlongation}
      />

      <ExtraServiceModal
        isVisible={isExtraServiceModalOpen}
        onClose={() => dispatch(actions.app.setIsVisibleExtraServiceModal(false))}
      />
      <ReviewMobileModal description={description} />
      <FreeAskModal show={isShow} onHide={onHide} />
    </>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  overflow: hidden;
  background-color: var(--white);
  ${CONDITION_DESKTOP} {
    z-index: 0;
  }
`;
const Wrapper = styled.div`
  height: 100%;
  display: grid;
  align-content: space-around;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr minmax(60px, auto);
  grid-template-areas:
    'header'
    'aside'
    'body'
    'footer';
  ${CONDITION_DESKTOP} {
    grid-template-columns: 1fr 320px;
    grid-template-rows: 60px 1fr minmax(75px, auto);
    grid-template-areas:
      'header header'
      'body aside'
      'footer aside';
  }
`;
const StyledRoomHeader = styled(RoomHeader)`
  grid-area: header;
`;
const StyledRoomBody = styled(RoomBody)`
  grid-area: body;
  overflow-y: auto;
  /* for Safari browser */
  /* max-height: calc(100vh - 60px - 60px); */
  ${CONDITION_DESKTOP} {
    /* max-height: calc(100vh - ${HEADER_HEIGHT_DESKTOP} - 60px - 75px); */
  }
`;
const StyledRoomAside = styled(RoomAside)`
  grid-area: aside;
  display: flex;
  ${CONDITION_DESKTOP} {
    overflow-y: auto;
    /* for Safari browser */
    /* max-height: calc(100vh - ${HEADER_HEIGHT_DESKTOP} - 60px); */
  }
`;
const RoomFooter = styled.div`
  grid-area: footer;
  padding: 12px 15px;
  ${CONDITION_DESKTOP} {
    padding: 10px 30px 20px;
  }
`;
