import {
  Copy,
  Dislike,
  Like,
  Odnoklassniki,
  Star,
  Telegram,
  VerificationMark,
  Vkontakte,
  Youtube,
} from 'app/assets/svg';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, useMemo, useState } from 'react';
import { User } from 'shared/api';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { commaNumberFormat, coverImageStyle } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { Tip } from 'shared/ui';
import styled from 'styled-components';

import { hardCodeExperts } from './utils/hardcode';
import { renderAchievement } from './utils/renderAchievement';

interface ExpertPublicCardProps {
  profile: User;
}

export const ExpertPublicCard: FC<ExpertPublicCardProps> = ({ profile }) => {
  const { t } = useTranslation('expert.page');
  const router = useRouter();
  const [copyTip, setCopyTip] = useState<any>(t('copyLinkOnProfile'));

  const {
    id,
    isProfileVerified,
    avatarUrl,
    firstName,
    lastName,
    expertConsultationsCount,
    reviewsCount,
    expertLikesCount,
    expertDislikesCount,
    consultationAveragePrice,
    achievements,
    personalSite,
    videoUrl,
    youtubeUrl,
    vKontakteUrl,
    odnoklassnikiUrl,
    telegramUrl,
    expertRatingTable,
  } = profile;

  const expertRating = expertRatingTable?.internalRating ? Number(expertRatingTable?.internalRating) : 0;
  const onCopyLink = async () => {
    try {
      // eslint-disable-next-line no-restricted-globals
      await navigator.clipboard.writeText(location.href);
      setCopyTip(t('linkIsCopied'));
      setTimeout(() => {
        setCopyTip(t('copyLinkOnProfile'));
      }, 2000);
    } catch (error: any) {
      captureError(error);
      console.warn(error);
    }
  };

  const consultationCountOrMock = useMemo(() => {
    const index = hardCodeExperts.findIndex((expert) => expert.expertId === id);
    if (index !== -1) {
      return hardCodeExperts[index].consultationCount ?? expertConsultationsCount;
    }
    return expertConsultationsCount;
  }, [expertConsultationsCount]);

  const reviewsCountOrMock = useMemo(() => {
    const index = hardCodeExperts.findIndex((expert) => expert.expertId === id);
    if (index !== -1) {
      return hardCodeExperts[index].reviewsCount ?? reviewsCount;
    }
    return reviewsCount;
  }, [expertConsultationsCount]);

  const ratingOrMock = useMemo(() => {
    const index = hardCodeExperts.findIndex((expert) => expert.expertId === id);
    if (index !== -1 && hardCodeExperts[index].rating) {
      return hardCodeExperts[index].rating ?? expertRating;
    }
    return expertRating;
  }, [expertConsultationsCount]);

  const expertLikesCountOrMock = useMemo(() => {
    const index = hardCodeExperts.findIndex((expert) => expert.expertId === id);
    if (index !== -1) {
      const countLikes = hardCodeExperts[index].reviewsCount
        ? hardCodeExperts[index].reviewsCount! - expertDislikesCount
        : undefined;
      return countLikes ?? expertLikesCount;
    }
    return expertLikesCount;
  }, [expertConsultationsCount]);

  return (
    <Container>
      <AvatarContainer>
        <div>
          <Avatar $url={avatarUrl || null} />
          <Video isVisible={isProfileVerified && videoUrl !== null}>
            <a href={videoUrl} target="_blank" rel="noreferrer">
              {t('videoCard')}
            </a>
          </Video>
        </div>
      </AvatarContainer>

      <Content>
        <MainInfo>
          {firstName && lastName && (lastName + firstName).length < 16 && (
            <NameContainerWithoutWordWrap>
              <Name>{`${firstName} ${lastName}`}</Name>
              {isProfileVerified && <VerificationMark />}
            </NameContainerWithoutWordWrap>
          )}
          {firstName && lastName && (lastName + firstName).length >= 16 && (
            <NameContainerWithWordWrap>
              <Name>{`${firstName} ${lastName}`}</Name>
              {isProfileVerified && <VerificationMark />}
            </NameContainerWithWordWrap>
          )}
        </MainInfo>

        <WrapperConsultations>
          <Consultations>
            <ConsultationsCount>{consultationCountOrMock}</ConsultationsCount>
            <ConsultationsCountText>{t('consultations')}</ConsultationsCountText>
          </Consultations>

          <Reviews>
            <div>{t('reviews', { count: reviewsCountOrMock })}</div>

            <div>
              <ReviewText>
                <Like />
                <span>{t('positiveReviews', { count: expertLikesCountOrMock })}</span>
              </ReviewText>
              <ProgressBar $color="green" $progress={(expertLikesCountOrMock * 100) / reviewsCountOrMock} />
            </div>

            <div>
              <ReviewText>
                <Dislike />
                <span>{t('negativeReviews', { count: expertDislikesCount })}</span>
              </ReviewText>
              <ProgressBar $color="red" $progress={(expertDislikesCount * 100) / reviewsCountOrMock} />
            </div>
          </Reviews>
        </WrapperConsultations>

        <WrapperStatistic>
          <Property>
            <PropertyTitle>
              {ratingOrMock?.toFixed(1)}
              <Star />
            </PropertyTitle>
            <PropertyNote>{t('expertRating')}</PropertyNote>
          </Property>

          <Property>
            <PropertyTitle>
              {t('averagePrice', {
                price: consultationAveragePrice
                  ? commaNumberFormat(Math.floor(consultationAveragePrice).toString())
                  : 0,
              })}
            </PropertyTitle>
            <PropertyNote>{t('coupConsultationsPrice')}</PropertyNote>
          </Property>
        </WrapperStatistic>

        <Achievements>{achievements?.map((a) => renderAchievement(a.achievement, t))}</Achievements>

        {personalSite !== null && personalSite !== undefined && (
          <LinkContainer>
            <LinkTitle>{t('website')}</LinkTitle>
            <Link href={personalSite} target="_blank" $bordered>
              {personalSite}
            </Link>
          </LinkContainer>
        )}

        <LinkContainer>
          <LinkTitle>{t('socialMedia')}</LinkTitle>
          <SocialMedia>
            {vKontakteUrl && <Vkontakte onClick={() => router.push(vKontakteUrl)} />}
            {telegramUrl && <Telegram onClick={() => router.push(telegramUrl)} />}
            {youtubeUrl && <Youtube onClick={() => router.push(youtubeUrl)} />}
            {odnoklassnikiUrl && <Odnoklassniki onClick={() => router.push(odnoklassnikiUrl)} />}
          </SocialMedia>
        </LinkContainer>
      </Content>

      <ControlButtons>
        {/* <Tip text={t('shareInSocialMedia')}>
          <ControlButton onClick={onShareProfile}>
            <Share />
          </ControlButton>
        </Tip> */}

        <Tip text={copyTip}>
          <ControlButton onClick={onCopyLink}>
            <Copy />
          </ControlButton>
        </Tip>
      </ControlButtons>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  max-width: 730px;
  position: relative;
  padding: 25px 9px 40px 20px;
  margin-bottom: 60px;

  ${CONDITION_DESKTOP} {
    display: grid;
    grid-template-columns: min-content 330px auto;
    padding: 18px 27px 24px 23px;
    margin-bottom: 90px;
    width: 620px;
  }
`;
const AvatarContainer = styled.div`
  display: flex;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

interface AvatarProps {
  $url: string | null;
}

const Avatar = styled.div<AvatarProps>`
  width: 128px;
  height: 128px;
  border-radius: 15px;
  margin: 3px;
  /* background-color: var(--gray4); */
  ${({ $url }) => ({ ...coverImageStyle($url) })}
`;

// TODO: remove next component
/*
interface GradientBorderProps {
  isVerification?: boolean;
}

const GradientBorder = styled.div<GradientBorderProps>`
  ${({ isVerification }) => isVerification && `
  background:
          linear-gradient(var(--white),var(--white)) padding-box,
          linear-gradient(360deg,#5806c9 16%,#993dfa 27%,#ac45ff 57%) border-box;
  `}
  color: #313149;
  padding: 1px;
  border: 3px solid transparent;
  border-radius: 50%;
`; */
const Content = styled.div`
  margin: 0 25px;
`;

const MainInfo = styled.div`
  margin-bottom: 40px;
`;
const NameContainerWithoutWordWrap = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
`;

const NameContainerWithWordWrap = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
`;

const Name = styled.h1`
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--text);
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;

  ${CONDITION_DESKTOP} {
    font-size: 25px;
  }
`;

const WrapperConsultations = styled.div`
  display: flex;
  margin-bottom: 40px;
`;
const WrapperStatistic = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-wrap: wrap;
  max-width: 320px;

  ${CONDITION_DESKTOP} {
    column-gap: 40px;
    flex-wrap: nowrap;
    justify-content: unset;
    max-width: unset;
  }
`;
const Consultations = styled.div`
  padding: 10px;
  background-color: var(--purple);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;
const ConsultationsCount = styled.span`
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
  color: var(--white);
  margin-bottom: 8px;

  ${CONDITION_DESKTOP} {
    font-size: 48px;
  }
`;
const ConsultationsCountText = styled.span`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: var(--white);
`;

const Reviews = styled.div`
  font-size: 13px;
  color: var(--black2);
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  margin-left: 40px;

  ${CONDITION_DESKTOP} {
    flex-grow: 0.3;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }

  & > div {
    margin-bottom: 10px;
  }

  ${CONDITION_DESKTOP} {
    font-size: 15px;
  }
`;

const Property = styled.div`
  width: 48%;

  ${CONDITION_DESKTOP} {
    max-width: 140px;
    width: 100%;
  }
`;
const PropertyTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.79;
  letter-spacing: normal;
  color: var(--text);
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & > svg {
    margin-left: 3px;
  }
`;
const PropertyNote = styled.span`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  color: var(--gray);
`;

const ReviewText = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  white-space: nowrap;
`;

interface ProgressBarProps {
  $color: 'red' | 'green';
  $progress: number;
}

const ProgressBar = styled.div<ProgressBarProps>`
  background-color: var(--gray2);
  height: 3px;
  position: relative;
  margin-top: 4px;

  &:before {
    content: '';
    height: 3px;
    position: absolute;
    background-color: ${({ $color }) => ($color === 'red' ? 'var(--red)' : 'var(--green)')};
    width: ${({ $progress }) => $progress}%;
  }
`;

const Achievements = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ControlButtons = styled.div`
  position: absolute;
  right: 20px;
  top: 30px;
  display: flex;
  column-gap: 10px;

  ${CONDITION_DESKTOP} {
    right: 27px;
    top: 37px;
  }
`;

const ControlButton = styled.div`
  transition: 0.3s ease background-color;
  border-radius: 50%;
  padding: 11px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    /* background-color: var(--gray4); */
    cursor: pointer;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface LinkProps {
  $bordered?: boolean;
}

const Link = styled.a<LinkProps>`
  font-size: 13px;
  position: relative;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;
  color: var(--purple);

  ${CONDITION_DESKTOP} {
    flex-grow: 0.5;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:before {
    content: '';
    position: absolute;
    height: ${({ $bordered }) => ($bordered ? '1' : '0')}px;
    width: 100%;
    /* background-color: var(--gray4); */
    bottom: -15px;
  }
`;
const LinkTitle = styled.div`
  flex-basis: 100px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.46;
  letter-spacing: normal;
  color: var(--gray);
  margin-right: 6px;
`;
const SocialMedia = styled.div`
  display: flex;
  column-gap: 15px;

  & > svg {
    transition: 0.3s ease opacity;
    opacity: 0.3;

    &:hover {
      opacity: 0.4;
      cursor: pointer;
    }
  }
`;

interface VideoProps {
  isVisible?: boolean;
}

const Video = styled.div<VideoProps>`
  background-image: linear-gradient(to right, #ae4cfe 5%, #993dfa 51%, #812df6);
  border-radius: 12.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-12px);
  width: 70%;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

  & > a {
    color: var(--white);
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 3px 9px;
  }

  text-align: center;
`;
