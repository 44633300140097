import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ContractFormSteps, StepsState } from 'routes/contract/contract';
import styled from 'styled-components';
import { ContractFormValues } from 'widgets/common';

import { renderContractForm } from '../../renderContractForm';

interface ContractFormProps {
  step: ContractFormSteps;
  changeStepHandle: (step: ContractFormSteps) => void;
  changeProgressBarStateHandle: (data: Partial<StepsState>) => void;
}

export const ContractForm: FC<ContractFormProps> = ({ step, changeProgressBarStateHandle, changeStepHandle }) => {
  const methods = useForm<ContractFormValues>({
    defaultValues: {},
  });

  return (
    <Container>
      <FormProvider {...methods}>
        {renderContractForm(step, changeStepHandle, changeProgressBarStateHandle)}
      </FormProvider>
    </Container>
  );
};

const Container = styled.form`
  width: 80%;
  margin: 0 auto;
`;
