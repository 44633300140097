export const parseUrlConfig = [
  {
    regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
    fn: (key: string, result: string) => (
      <span key={key}>
        <a target="_blank" href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`} rel="noreferrer">
          {result[2]}.{result[3]}
          {result[4]}
        </a>
        {result[5]}
      </span>
    ),
  },
  {
    regex: /(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
    fn: (key: string, result: string) => (
      <span key={key}>
        <a target="_blank" href={`http://${result[1]}.${result[2]}${result[3]}`} rel="noreferrer">
          {result[1]}.{result[2]}
          {result[3]}
        </a>
        {result[4]}
      </span>
    ),
  },
];
