import { useAppDispatch } from 'hooks/redux';
import { usePrepareImageToUpload } from 'hooks/usePrepareImageToUpload';
import { isEqual } from 'lodash';
import { useTranslation } from 'next-i18next';
import React, { ChangeEvent, FC, useState } from 'react';
import { toast } from 'react-toastify';
import { Portfolio, PortfolioStatusEnum } from 'shared/api';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { captureError } from 'shared/helpers/captureError';
import { Button } from 'shared/ui';
import { actions } from 'store/ducks/profile';
import styled from 'styled-components';
import { Modal as DeleteModal, PortfolioCarousel } from 'widgets/common';

interface PortfolioFormProps {
  portfolio: Portfolio;
}

const getPhotosArrayForCarousel = (images: string[]) => images.map((image) => ({ source: image }));

export const PortfolioForm: FC<PortfolioFormProps> = ({ portfolio }) => {
  const { adminComment, imageUrls, status, id } = portfolio;
  const { t } = useTranslation('profile.page');
  const [showLightbox, setShowLightbox] = useState(false);
  const carouselPhotos = getPhotosArrayForCarousel(imageUrls);
  const dispatch = useAppDispatch();

  const [photos, setPhotos] = useState(carouselPhotos);
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageIdToDelete, setImageIdToDelete] = useState<number | null>(null);
  const [isDisabledAfterDelete, setIsDisabledAfterDelete] = useState<boolean>(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { selectFile, uploadFileToCloud } = usePrepareImageToUpload();

  const onChangePhoto = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    try {
      selectFile(
        files,
        async ({ file }) => {
          try {
            if (file) {
              const avatarUrl = await uploadFileToCloud(file);
              if (avatarUrl) {
                setPhotos([...photos, { source: avatarUrl }]);
                setIsDisabledAfterDelete(false);
              }
            }
          } catch (error: any) {
            captureError(error);
          }
        },
        false,
      );
      e.target.value = '';
    } catch (error: any) {
      toast.error(error.message);
      e.target.value = '';
    }
  };

  const removePhoto = () => {
    setPhotos(photos.filter((_, index) => index !== imageIdToDelete));
    if (typeof imageIdToDelete === 'number') {
      onDelete(imageIdToDelete);
    }
    setIsDisabledAfterDelete(true);
    setImageIdToDelete(null);
  };

  const onSubmit = async () => {
    try {
      const imageUrls: string[] = [];
      photos.forEach((item) => {
        imageUrls.push(item.source);
      });
      dispatch(actions.addPortfolioImageUrlsAsync({ id, dto: { imageUrls } }));

      setShowSuccessModal(true);
    } catch (e) {
      captureError(e);
    }
  };

  const onDelete = async (index: number) => {
    const indexDto = { index };
    try {
      await dispatch(actions.fetchDeletePortfoliosOneImageRequests({ id, indexDto }));
    } catch (e) {
      captureError(e);
    }
  };

  return (
    <Container>
      <Title>{t('portfolio')}</Title>
      <Note>{t('portfolioNote')}</Note>

      {photos.length > 0 && (
        <PortfolioContainer>
          <PortfolioImagesContainer>
            {photos.slice(0, 10).map((photo, index) => (
              <PortfolioImageWrapper
                key={index}
                onClick={() => {
                  if (!showModal) {
                    setCurrentIndex(index);
                    setShowLightbox(true);
                  }
                }}
              >
                <PortfolioStyledImage src={photo.source} alt="" />
                <DeleteButtonWrapper
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowModal(true);
                    setCurrentIndex(index);
                    setImageIdToDelete(index);
                  }}
                >
                  <TimesWrapper>&#215;</TimesWrapper>
                </DeleteButtonWrapper>
              </PortfolioImageWrapper>
            ))}
          </PortfolioImagesContainer>

          {status === PortfolioStatusEnum.Rejected && (
            <ErrorMessage>{adminComment || 'Не прошло проверку'}</ErrorMessage>
          )}

          <PortfolioCarousel
            currentIndex={currentIndex}
            images={photos}
            showModal={showLightbox}
            setCurrentIndex={setCurrentIndex}
            onClose={() => {
              setShowLightbox(false);
            }}
          />
        </PortfolioContainer>
      )}

      {photos.length < 10 && (
        <PhotoUploadContainer>
          <PhotoText htmlFor="photosInput">Добавить</PhotoText>
        </PhotoUploadContainer>
      )}
      <input
        id="photosInput"
        onChange={onChangePhoto}
        type="file"
        accept="image/png, image/jpeg"
        style={{ display: 'none' }}
      />
      <ButtonWrapper>
        <StyledButton
          onClick={onSubmit}
          disabled={isEqual(carouselPhotos, photos) || !photos.length || isDisabledAfterDelete}
        >
          {t('sendPortfolio')}
        </StyledButton>
      </ButtonWrapper>
      {status === PortfolioStatusEnum.InReview && (
        <CheckInfo>Мы уже проверяем. Если нужно, то вы можете обновить информацию.</CheckInfo>
      )}
      {status === PortfolioStatusEnum.Rejected && (
        <CheckInfo>Профиль не опубликован. Исправьте ошибки и отправте на проверку.</CheckInfo>
      )}
      <DeleteModal
        isVisible={showModal}
        onClose={() => {
          setShowLightbox(false);
          setShowModal(false);
        }}
      >
        <ModalNoteWrapper>
          <ModalNote>Удалить изображение?</ModalNote>
        </ModalNoteWrapper>
        <ModalButtonWrapper>
          <StyledModalButton
            onClick={() => {
              removePhoto();
              setShowLightbox(false);
              setShowModal(false);
            }}
          >
            Удалить
          </StyledModalButton>
          <StyledModalButton
            bordered
            onClick={() => {
              setShowLightbox(false);
              setShowModal(false);
            }}
          >
            Отмена
          </StyledModalButton>
        </ModalButtonWrapper>
      </DeleteModal>
      <SuccessModal
        isVisible={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
        }}
      >
        <ModalNoteWrapper>
          <ModalNote>Уже проверяем заявку!</ModalNote>
        </ModalNoteWrapper>
        <ModalSubNote>
          Это займет полчаса в рабочее время с 10:00 до 20:00. После проверки портфолио появится в вашем профиле.{' '}
        </ModalSubNote>
        <SuccessModalButtonWrapper>
          <StyledSuccessModalButton
            bordered
            onClick={() => {
              setShowSuccessModal(false);
            }}
          >
            Понятно
          </StyledSuccessModalButton>
        </SuccessModalButtonWrapper>
      </SuccessModal>
    </Container>
  );
};

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  max-width: 560px;
  letter-spacing: normal;
  padding-bottom: 20px;
  color: var(--gray);

  & > a {
    color: var(--purple);
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`;

const Container = styled.div`
  margin-top: 90px;

  input {
    width: 100%;
    ${CONDITION_DESKTOP} {
      width: 60%;
    }
  }
  button {
    margin-top: 20px;
    width: 100%;
    ${CONDITION_DESKTOP} {
      width: unset;
    }
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black2);
  padding-bottom: 10px;
  padding-top: 17px;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  width: 220px;
`;

const StyledButton = styled(Button)`
  span {
    padding: 2px 4px;
    margin: 0;
  }
`;

const PortfolioContainer = styled.div`
  margin-bottom: 20px;
  width: 610px;

  @media (max-width: 450px) {
    width: 100%;
  }
`;

const PortfolioImageWrapper = styled.div`
  cursor: zoom-in;
  width: 100px;
  height: 100px;

  @media (max-width: 450px) {
    width: 130px;
    height: 130px;
  }

  position: relative;
`;

const PortfolioStyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PortfolioImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

const PhotoUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PhotoText = styled.label`
  padding: 11px 17px 11px 17px;
  border-radius: 20px;
  background-color: #f7f6ff;
  color: var(--purple);
  cursor: pointer;
  font-size: 15px;
  transition: all 0.2s;

  &:hover {
    background-color: var(--purple);
    color: var(--white);
    border-color: var(--white);
  }

  @media (max-width: 450px) {
    margin-left: 0;
  }
`;

const DeleteButtonWrapper = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const TimesWrapper = styled.div`
  color: #f7f6ff;
  opacity: 0.8;
`;

const ModalNoteWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const ModalNote = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: #333;
`;

const ModalSubNote = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.54;
  color: #232832;
  margin-top: 15px;
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
`;

const SuccessModalButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`;

const StyledModalButton = styled(Button)`
  span {
    padding: 2px 4px;
    margin: 0;
  }
  width: 120px;
`;

const StyledSuccessModalButton = styled(Button)`
  span {
    padding: 2px 4px;
    margin: 0;
  }
  width: 100%;
`;

const CheckInfo = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #8b33ff;
  margin-top: 10px;
`;

const ErrorMessage = styled.div`
  color: #ff3366;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  margin-top: 10px;
`;

const SuccessModal = styled(DeleteModal)``;
