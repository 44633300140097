import { Camera, StoreApple, StoreGoogle } from 'app/assets/svg';
import { useAppDispatch } from 'hooks/redux';
import { useUserPromocodes } from 'hooks/useUserPromocodes';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ym from 'react-yandex-metrika';
import { ENVIRONMENT_TYPE } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { coverImageStyle } from 'shared/helpers';
import { Button } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';
import { HeaderFreeConsult } from 'widgets/common';

import { authMenuListClient, authMenuListExpert, notAuthMenuList } from './menu-list';

interface HeaderMobileMenuProps {
  onCloseMenu: () => void;
}

const sendDataToMetrics = () => {
  if (ENVIRONMENT_TYPE === 'production') {
    ym('reachGoal', ['Support']);
    if (window && window.gtag) {
      window.gtag('event', 'Support');
    }
    // window.fbq('trackCustom', 'Support');
  }
};

export const HeaderMobileMenu: FC<HeaderMobileMenuProps> = ({ onCloseMenu }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('header.component');
  const router = useRouter();
  const user = useSelector(selectors.profile.selectUser);
  const wallet = useSelector(selectors.profile.selectWallet);
  const isAuthenticate = useSelector(selectors.profile.selectIsAuthentication);
  const [menuList, setMenuList] = useState<{ textKey: string; route: string }[]>([]);
  const { freeConsultCount, freeConsultDays } = useUserPromocodes();

  useEffect(() => {
    setMenuList(isAuthenticate ? (user.isExpert ? authMenuListExpert : authMenuListClient) : notAuthMenuList);
  }, [isAuthenticate]);

  const logout = () => {
    dispatch(actions.profile.signOut());
    onCloseMenu();
  };

  const onClickNavigateMenuButton = (route: string) => {
    router.push(route);
    onCloseMenu();
  };
  return (
    <Container>
      {isAuthenticate && <AvatarContainer $url={user.avatarUrl}>{user.avatarUrl ? null : <Camera />}</AvatarContainer>}
      {isAuthenticate && (
        <NameContainer>
          <Name>{`${user.firstName || ''} ${user.lastName || ''}`}</Name>
          {user.isExpert && (
            <Money>{t('dropdown.money', { quantity: wallet?.balance || 0, currency: '\u20bd' })}</Money>
          )}
        </NameContainer>
      )}
      <StyledButton block onClick={() => onClickNavigateMenuButton(routes.topics)}>
        {t('mobileMenu.findExperts')}
      </StyledButton>
      {freeConsultCount > 0 && <HeaderFreeConsult freeCount={freeConsultCount} freeDays={freeConsultDays} />}

      <MenuList>
        {menuList.map((item, index) => (
          <MenuItem key={index} onClick={() => onClickNavigateMenuButton(item.route)}>
            {t(item.textKey)}
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            onClickNavigateMenuButton(`${routes.faq}`);
            sendDataToMetrics();
          }}
        >
          {t('dropdown.support')}
        </MenuItem>
        {isAuthenticate ? (
          <MenuItem onClick={logout}>{t('dropdown.logout')}</MenuItem>
        ) : (
          <MenuItem onClick={() => onClickNavigateMenuButton(`${routes.about}`)}>{t('dropdown.about')}</MenuItem>
        )}
      </MenuList>
      <StopInfoBisContainer>
        <Link href={`${routes.stopInfoBis}`}>
          <a>
            <img src="/img/header-add-logo-gypsy-2.png" srcSet="/img/header-add-logo-gypsy-2@2x.png 2x" alt="" />
          </a>
        </Link>
      </StopInfoBisContainer>

      <Footer>
        <FooterText>{t('mobileMenu.downloadApp')}</FooterText>
        <FooterStores>
          {/* TODO: add link to apple store */}
          <a href="https://apps.apple.com/ru/app/consulty/id1590830075?l=en" target="_blank" rel="noreferrer">
            <StoreApple />
          </a>
          <StoreGap />
          <a href="https://play.google.com/store/apps/details?id=com.consulty" target="_blank" rel="noreferrer">
            <StoreGoogle />
          </a>
        </FooterStores>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: calc(100% + 1px);
  width: 100vw;
  background-color: var(--white);
  overflow-y: auto;
  height: calc(100vh - 60px);
  padding: 30px 20px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AvatarContainer = styled.div<{ $url?: string }>`
  width: 60px;
  height: 60px;
  //background-color: var(--purple);
  border-radius: 15px 15px 3px 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;

  ${({ $url }) => $url && { ...coverImageStyle($url) }}
  img {
    max-width: 100%;
  }
`;

const NameContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
  max-width: 100%;
`;

const Name = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Money = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: var(--gray);
`;

const StyledButton = styled(Button)`
  flex-shrink: 0;
`;

const MenuList = styled.div`
  margin-top: 30px;
  border-top: 1px solid var(--gray5);
  width: 100%;
`;

const MenuItem = styled.div`
  font-size: 16px;
  line-height: 50px;
  border-bottom: 1px solid var(--gray5);
`;

const Footer = styled.div`
  margin-top: 60px;
`;

const FooterText = styled.p`
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
`;

const FooterStores = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const StoreGap = styled.div`
  width: 15px;
`;

const StopInfoBisContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0;
`;
