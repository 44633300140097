import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import ym from 'react-yandex-metrika';
import { ExpertService, InvoiceStatusEnum, User } from 'shared/api';
import { ENVIRONMENT_TYPE } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { captureError } from 'shared/helpers/captureError';
import { Button } from 'shared/ui';
import { selectors } from 'store/ducks';
import { actions } from 'store/ducks/consultations';
import styled from 'styled-components';

interface ServicePaymentModalProps {
  // userCategoryMeta: UserCategoryMeta;
  // price: number;
  // theme: string;
  // hasFreeConsultations: boolean;
  service: ExpertService;
  expert: User;
  onRefuse: () => void;
}

const sendAnalyticsData = () => {
  if (ENVIRONMENT_TYPE === 'production') {
    ym('reachGoal', ['uslug_buy']);
    if (window && window.gtag) {
      window.gtag('event', 'uslug_buy');
    }
  }
};

export const ServicePaymentModal: FC<ServicePaymentModalProps> = ({ service, expert, onRefuse }) => {
  const { title, price } = service;
  const { t } = useTranslation('expert.page');
  const dispatch = useAppDispatch();
  const chatRooms = useAppSelector(selectors.chatRooms.selectChatRooms);

  const redirectUrl = useMemo(() => {
    const chatRoomWithThisExpert = chatRooms?.find((room) => room.expertId === expert.id);
    const redirectUrl = chatRoomWithThisExpert
      ? `${window.location.origin}${routes.chat}?activeTab=consult&roomId=${chatRoomWithThisExpert.id}`
      : `${window.location.origin}${routes.chat}?activeTab=consult&expertId=${expert.id}`;
    return redirectUrl;
  }, [chatRooms, expert]);

  const onPayWithMoney = async () => {
    try {
      const action = await dispatch(
        actions.createConsultationFromExpertServicesAsync({
          expertServiceId: service.id,
          redirectUrl,
        }),
      );
      const data = unwrapResult(action);

      const paymentUrl = data.invoices.find((invoice) => invoice.invoice?.status === InvoiceStatusEnum.Pending)?.invoice
        ?.checkoutUrl;
      if (paymentUrl) {
        sendAnalyticsData();
        setTimeout(() => {document.location.href = paymentUrl;},250);
      }
      onRefuse();
    } catch (e: any) {
      captureError(e);
      toast.error(e.message || t('somethingWrong'));
    }
  };

  return (
    <Container>
      <Title>Оплата услуги</Title>
      <Note>{t('ifConsultationDidntLike')}</Note>

      <Content>
        <ContentItem>
          <PropertyLabel>{t('theme')}</PropertyLabel>
          <PropertyValue>{title}</PropertyValue>
        </ContentItem>

        <ContentItem>
          <PropertyLabel>{t('price')}</PropertyLabel>
          <PropertyValue>{t('priceNote', { price })}</PropertyValue>
        </ContentItem>

        <ContentItem>
          <PropertyLabel>{t('duration')}</PropertyLabel>
          <PropertyValue>{t('messagesOrHour')}</PropertyValue>
        </ContentItem>
      </Content>

      <ButtonsContainer>
        <Button onClick={onPayWithMoney}>{t('payWithPrice', { price })}</Button>

        <Button bordered onClick={onRefuse}>
          {t('refuse')}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 10px;
`;

const Note = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--text);
`;

const Content = styled.div`
  padding: 30px 0;

  & > div {
    margin-bottom: 15px;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`;

const PropertyLabel = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: var(--gray2);
`;

const PropertyValue = styled(PropertyLabel)`
  color: var(--text);
`;

const ContentItem = styled.div``;

const ButtonsContainer = styled.div`
  & > button {
    margin-bottom: 10px;
    width: 100%;
  }

  & > button:last-child {
    margin-bottom: 0;
  }
`;
