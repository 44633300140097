import * as React from 'react';
import { SVGProps } from 'react';

const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={15} height={15} xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Shape</title>
    <path
      d="M7.5 0A7.498 7.498 0 0 0 0 7.5C0 11.644 3.356 15 7.5 15S15 11.644 15 7.5 11.644 0 7.5 0Zm.75 11.25h-1.5v-4.5h1.5v4.5Zm0-6h-1.5v-1.5h1.5v1.5Z"
      fill="#8B33FF"
      fillRule="nonzero"
    />
  </svg>
);

export default InfoIcon;
