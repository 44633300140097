import type { FC, ReactNode } from 'react';
import React from 'react';
import type { FieldMetaState } from 'react-final-form';
import styled from 'styled-components';

import { CONDITION_DESKTOP } from '../constants/breakpoints';

const StyledWrapper = styled.div({
  '& + &': {
    marginTop: 30,
  },

  [CONDITION_DESKTOP]: {
    '& + &': {
      marginTop: 40,
    },
  },
});

const StyledLabel = styled.div({
  fontSize: 15,
  fontWeight: 500,
  color: '#79818c',
  marginBottom: 10,
});

const StyledRequiredStar = styled.span({
  color: '#8b33ff',
});

const StyledErrors = styled.div({
  fontSize: 15,
  lineHeight: 1.13,
  fontWeight: 500,
  textAlign: 'center',
  color: '#ff3366',
  marginTop: 15,
});

type FieldWrapperProps = {
  label?: ReactNode;
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: FieldMetaState<any>;
  children: ReactNode;
};

export const FieldWrapper: FC<FieldWrapperProps> = ({ label, required, meta, children }) => {
  const errors: string[] = meta ? (meta.dirtySinceLastSubmit ? null : meta.submitError) : null;

  return (
    <StyledWrapper>
      <StyledLabel>
        {label}
        {required && <StyledRequiredStar>{' *'}</StyledRequiredStar>}
      </StyledLabel>

      {children}

      {errors && (
        <StyledErrors>
          {errors.map((errorMessage, index) => (
            <div key={index}>{errorMessage}</div>
          ))}
        </StyledErrors>
      )}
    </StyledWrapper>
  );
};
