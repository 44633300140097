import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useState } from 'react';
import { Category, User } from 'shared/api';
import { CONDITION_DESKTOP, CONDITION_MOBILE } from 'shared/common/constants';
import { Button, TabButton } from 'shared/ui';
import { actions } from 'store/ducks/rubrics';
import styled from 'styled-components';

import { ExpertPreviewCard } from '../expert-preview-card';
import { EXPERTS_LIMIT } from '../reviews-list/reviews-list';

interface ExpertsInCategoryProps {
  sortCriteria: SortCriteria;
  title: string;
  limit: number;
  categoryName: string;
  categoryId: number;
  subCategories: Category[];
}

type SortCriteria = 'rating' | 'countOfConsultationExpert' | 'totalProceeds';

// api.V1CategoriesApi.categoriesControllerGetExpertsByCategory(
//   id,
//   sortCriteria,
//   'DESC',
//   limit,
//   page,
//   consultationsFilter,
//   subcategoriesFilter,
// );

enum ExpertTab {
  ALL,
  WITH_FREE_CONSULT,
}

export const ExpertsInCategory: FC<ExpertsInCategoryProps> = ({
  sortCriteria,
  title,
  limit,
  categoryName,
  subCategories,
  categoryId,
}) => {
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  const [experts, setExperts] = useState<User[]>([]);
  const [freePage, setFreePage] = useState<number>(1);
  const [freeTotal, setFreeTotal] = useState<number>(0);

  const [freeExperts, setFreeExperts] = useState<User[]>([]);
  const [tab, setTab] = useState<ExpertTab>(ExpertTab.ALL);
  const router = useRouter();
  const { query } = router;
  const { t } = useTranslation('category.page');
  const dispatch = useAppDispatch();

  const getExperts = async (
    id: number,
    sortCriteria: SortCriteria,
    page: number,
    limit: number,
    consultationsFilter?: 'freeConsultations',
    subcategoriesFilter?: 'allCategories' | 'rootSubcategories',
  ) =>
    dispatch(
      actions.getExpertsByCategoryAsync({
        id,
        params: { sortCriteria, direction: 'DESC', limit, page, consultationsFilter, subcategoriesFilter },
      }),
    );

  useEffect(() => {
    if (!query.id) {
      return;
    }
    getExperts(Number(query.id), sortCriteria, page, limit, undefined, 'allCategories')
      .then(unwrapResult)
      .then((res) => {
        const { data, total } = res;
        setTotal(total);
        setExperts([...experts, ...data]);
      })
      .catch((e) => {
        throw new Error(e);
      });
  }, [page]);

  useEffect(() => {
    if (!query.id) {
      return;
    }
    getExperts(Number(query.id), sortCriteria, page, limit, undefined, 'allCategories')
      .then(unwrapResult)
      .then((res) => {
        const { data, total } = res;
        setTotal(total);
        setExperts(data);
      })
      .catch((e) => {
        throw new Error(e);
      });
    return () => {
      setPage(1);
      setExperts([]);
    };
  }, [query.id]);

  useEffect(() => {
    if (!query.id) {
      return;
    }
    getExperts(Number(query.id), sortCriteria, freePage, limit, 'freeConsultations', 'allCategories')
      .then(unwrapResult)
      .then((res) => {
        const { data, total } = res;
        setFreeTotal(total);
        setFreeExperts([...freeExperts, ...data]);
      })
      .catch((e) => {
        throw new Error(e);
      });
  }, [freePage]);

  useEffect(() => {
    if (!query.id) {
      return;
    }

    getExperts(Number(query.id), sortCriteria, freePage, limit, 'freeConsultations', 'allCategories')
      .then(unwrapResult)
      .then((res) => {
        const { data, total } = res;
        setFreeTotal(total);

        setFreeExperts(data);
      })
      .catch((e) => {
        throw new Error(e);
      });
    return () => {
      setFreePage(1);
      setFreeExperts([]);
    };
  }, [query.id]);

  const onMoreExperts = () => {
    setPage((l) => l + 1);
  };

  const onMoreFreeExperts = () => {
    setFreePage((l) => l + 1);
  };

  if (freeExperts.length <= 0 && experts.length <= 0) return null;

  return (
    <Container className="container" id="experts">
      <Title>{title}</Title>
      {/* <Note>{note}</Note> */}

      <ToggleContainer>
        <TabButton index={ExpertTab.ALL} activeIndex={tab} setActiveIndex={setTab}>
          {t('experts.allExperts')}
        </TabButton>
        <TabButton
          index={ExpertTab.WITH_FREE_CONSULT}
          icon="/img/coin.png"
          iconRetina="/img/coin.png"
          activeIndex={tab}
          setActiveIndex={setTab}
        >
          {t('experts.freeConsult')}
        </TabButton>
      </ToggleContainer>

      <Table>
        {tab === ExpertTab.ALL
          ? experts.map((expert) => (
              <ExpertPreviewCard
                key={expert.id}
                expert={expert}
                categoryName={categoryName}
                subCategories={subCategories}
                categoryId={categoryId}
              />
            ))
          : freeExperts.map((expert) => (
              <ExpertPreviewCard
                key={expert.id}
                expert={expert}
                categoryName={categoryName}
                subCategories={subCategories}
                categoryId={categoryId}
              />
            ))}
      </Table>

      {tab === ExpertTab.ALL
        ? total - experts.length !== 0 && (
            <ButtonContainer>
              <Button bordered onClick={onMoreExperts}>
                {t('experts.moreExperts', {
                  count: total - experts.length > EXPERTS_LIMIT ? EXPERTS_LIMIT : total - experts.length,
                })}
              </Button>
            </ButtonContainer>
          )
        : freeTotal - freeExperts.length !== 0 && (
            <ButtonContainer>
              <Button bordered onClick={onMoreFreeExperts}>
                {t('experts.moreExperts', {
                  count:
                    freeTotal - freeExperts.length > EXPERTS_LIMIT ? EXPERTS_LIMIT : freeTotal - freeExperts.length,
                })}
              </Button>
            </ButtonContainer>
          )}
    </Container>
  );
};

const Container = styled.div`
  &&& {
    margin-top: 60px;
    margin-bottom: 90px;
  }

  ${CONDITION_MOBILE} {
    &&& {
      margin-top: 40px;
    }
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.6em;
  letter-spacing: normal;
  white-space: pre-wrap;
  color: #111111;
  margin-bottom: 40px;

  ${CONDITION_DESKTOP} {
    font-size: 40px;
  }

  ${CONDITION_MOBILE} {
    font-size: 30px;
  }
`;

const Table = styled.div`
  display: grid;
  justify-items: center;
  margin-bottom: 60px;
  grid-gap: 30px;

  ${CONDITION_DESKTOP} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${CONDITION_MOBILE} {
    grid-template-columns: 1fr;
    margin-bottom: 40px;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  text-align: center;

  span {
    font-size: 13px;
  }

  img {
    display: none;
  }

  & > div {
    padding: 10px 4px;
  }

  ${CONDITION_DESKTOP} {
    img {
      display: unset;
    }

    span {
      font-size: 15px;
    }

    & > div {
      padding: 10px 15px;
    }

    margin-bottom: 60px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
