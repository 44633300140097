import {
  FastAnswerAchievement,
  FireAchievement,
  LargeAchievement,
  PositiveAchievement,
  SmartAchievement,
  SuperLargeAchievement,
  TargetAchievement,
} from 'app/assets/svg';
import { Achievement, AchievementNameEnum } from 'shared/api';

export const renderAchievement = (ach: Achievement | undefined) => {
  if (!ach) return null;
  switch (ach.name) {
    case AchievementNameEnum.MaestroOfConsultations:
      return <SuperLargeAchievement key={ach.id} />;
    case AchievementNameEnum.OnThePositive:
      return <PositiveAchievement key={ach.id} />;
    case AchievementNameEnum.Profi:
      return <LargeAchievement key={ach.id} />;
    case AchievementNameEnum.PromptConsultation:
      return <FireAchievement key={ach.id} />;
    case AchievementNameEnum.RespondsQuickly:
      return <FastAnswerAchievement key={ach.id} />;
    case AchievementNameEnum.Smartest:
      return <SmartAchievement key={ach.id} />;
    case AchievementNameEnum.SuperExpert:
      return <TargetAchievement key={ach.id} />;
    default:
      return null;
  }
};
