import { routes } from 'shared/common/routes';

export const authMenuListExpert = [
  {
    textKey: 'dropdown.settings',
    route: `${routes.profile}?activeTab=settings`,
  },
  {
    textKey: 'dropdown.profile',
    route: `${routes.profile}?activeTab=expertProfile`,
  },
  {
    textKey: 'dropdown.wallet',
    route: `${routes.profile}?activeTab=wallet`,
  },
  {
    textKey: 'dropdown.academyExperts',
    route: `${routes.academyExperts}`,
  },
  {
    textKey: 'dropdown.myConsultations',
    route: `${routes.profile}?activeTab=myConsultations`,
  },
  // {
  //   textKey: 'dropdown.notifications',
  //   route: `${routes.profile}?activeTab=notifications`,
  // },
];

export const authMenuListClient = [
  {
    textKey: 'dropdown.settings',
    route: `${routes.profile}?activeTab=settings`,
  },
  {
    textKey: 'dropdown.profile',
    route: `${routes.profile}?activeTab=expertProfile`,
  },
  {
    textKey: 'dropdown.myConsultations',
    route: `${routes.profile}?activeTab=myConsultations`,
  },
  // {
  //   textKey: 'dropdown.notifications',
  //   route: `${routes.profile}?activeTab=notifications`,
  // },
];

export const notAuthMenuList = [
  {
    textKey: 'mobileMenu.becomeExpert',
    route: `${routes.expertLanding}`,
  },
];
