import React, { ChangeEvent, FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractFormSteps } from 'routes/contract/contract';
import { digitsPattern, emailPattern, fioPattern } from 'shared/helpers/patterns';
import { Button, Input, PhotoInput } from 'shared/ui';
import { ContractFormValues } from 'widgets/common';

interface OooFormProps {
  changeStepHandle: (step: ContractFormSteps) => void;
  changeStep?: () => void;
}

export const OooForm: FC<OooFormProps> = ({ changeStepHandle, changeStep }) => {
  const { control, handleSubmit, setValue, clearErrors } = useFormContext<ContractFormValues>();

  const onSubmit = () => {
    // TODO: insert logic
    if (changeStep) {
      changeStep();
    }
    changeStepHandle(ContractFormSteps.final);
  };

  type FileInputsName = 'certificateOfRegistrationPhoto' | 'EQURL';

  const onChangePhoto = (name: FileInputsName) => (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      const file = files.item(0);
      if (file) {
        setValue(name, file);
        clearErrors(name);
      }
    }
  };

  return (
    <>
      <Controller
        control={control}
        rules={{ required: { value: true, message: 'Обязательно' } }}
        name="fullCompanyName"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            label="Полное наименование организации"
            required
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
          pattern: { value: digitsPattern, message: 'Только цифры' },
        }}
        name="INN"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input onlyNumbers label="ИНН" required value={value} onChange={onChange} error={error?.message} />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
          pattern: { value: digitsPattern, message: 'Только цифры' },
        }}
        defaultValue=""
        name="KPP"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input label="КПП" required value={value} onChange={onChange} error={error?.message} />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
          pattern: { value: fioPattern, message: 'Некорректный формат' },
        }}
        defaultValue=""
        name="directorFullNameNominativeCase"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            label="ФИО руководителя (Именительный падеж)"
            required
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
          pattern: { value: fioPattern, message: 'Некорректный формат' },
        }}
        defaultValue=""
        name="directorFullNameParentCase"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            label="ФИО руководителя (Родительный падеж)"
            required
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        rules={{ required: { value: true, message: 'Обязательно' } }}
        defaultValue=""
        name="directorPost"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input label="Должность руководителя" required value={value} onChange={onChange} error={error?.message} />
        )}
      />

      <Controller
        control={control}
        rules={{ required: { value: true, message: 'Обязательно' } }}
        defaultValue=""
        name="legalAddress"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input label="Юридический адрес" required value={value} onChange={onChange} error={error?.message} />
        )}
      />

      <Controller
        control={control}
        name="physicalAddress"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input label="Физический адрес" value={value} onChange={onChange} error={error?.message} />
        )}
      />

      <Controller
        control={control}
        rules={{ required: { value: true, message: 'Обязательное поле' } }}
        defaultValue=""
        name="phone"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input label="Контактный номер телефона" required value={value} onChange={onChange} error={error?.message} />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: emailPattern, message: 'Некорректный Email' },
        }}
        defaultValue=""
        name="email"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            label="Электронная почта для уведомлений"
            required
            value={value ? value.replace(/\s/g, '').trim() : value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      {/* <Controller
        control={control}
        rules={{ required: { value: true, message: 'Обязательно' } }}
        defaultValue={''}
        name={'bankName'}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input label={'Наименование банка'} required value={value} onChange={onChange} error={error?.message} />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
          pattern: { value: digitsPattern, message: 'Только цифры' },
        }}
        name={'BIK'}
        defaultValue={''}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input label={'БИК'} required value={value} onChange={onChange} error={error?.message} />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
          pattern: { value: digitsPattern, message: 'Только цифры' },
        }}
        name={'correspondedAccount'}
        defaultValue={''}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input label={'Корреспондентский счет'} required value={value} onChange={onChange} error={error?.message} />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
          pattern: { value: digitsPattern, message: 'Только цифры' },
        }}
        name={'paymentAccount'}
        defaultValue={''}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input label={'Расчетный счет'} required value={value} onChange={onChange} error={error?.message} />
        )}
      /> */}

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
        }}
        name="certificateOfRegistrationPhoto"
        render={({ fieldState: { error } }) => (
          <PhotoInput
            prefix="certificateOfRegistrationPhoto"
            label="Выбрать"
            width="50%"
            title="Свидетельство о государственной регистрации юридического лица"
            onChange={onChangePhoto('certificateOfRegistrationPhoto')}
            sizeLimitMb={15}
            required
            error={error?.message}
            largeSizeError="Файл больше 15 МБ"
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
        }}
        name="EQURL"
        render={({ fieldState: { error } }) => (
          <PhotoInput
            prefix="EQURL"
            label="Выбрать"
            width="50%"
            required
            title="Свидетельство о постановке на учет или лист записи ЕГРЮЛ"
            error={error?.message}
            onChange={onChangePhoto('EQURL')}
            sizeLimitMb={15}
            largeSizeError="Файл больше 15 МБ"
          />
        )}
      />

      <Button onClick={handleSubmit(onSubmit)}>Продолжить</Button>
    </>
  );
};
