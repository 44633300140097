import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { actions, selectors } from 'store/ducks/app/slice';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

import { renderAuthForm } from './renderAuthForm';
import { renderAuthNote } from './renderAuthNote';
import { renderAuthTitle } from './renderAuthTitle';
import { AuthFormSteps, AuthFormValues } from './types';

type AuthModalProps = {
  initStep: AuthFormSteps;
};

export const AuthModal: FC<AuthModalProps> = ({ initStep }) => {
  const { t } = useTranslation('header.component');
  const [step, setStep] = useState<AuthFormSteps>(initStep);
  const [currentPhone, setCurrentPhone] = useState<string>('');
  const authForm = useForm<AuthFormValues>({
    defaultValues: {
      number: '',
      agreement: false,
      code: '',
      promoCode: '',
    },
  });
  const dispatch = useAppDispatch();

  const isVisible = useSelector(selectors.selectAuthModalIsVisible);

  const isCanCloseModal = [AuthFormSteps.number, AuthFormSteps.smsCode].includes(step);
  const onCloseModal = useCallback(() => {
    authForm.reset({
      number: '',
      agreement: false,
      code: '',
      promoCode: '',
    });
    setStep(AuthFormSteps.number);
    dispatch(actions.hideAuthModal());
  }, [step, isCanCloseModal]);

  const { getValues } = authForm;

  const isWait = useSelector(selectors.selectAuthModalIsWait);
  const counter = useSelector(selectors.selectAuthModalCounter);

  useEffect(() => {
    setStep(initStep);
  }, [initStep]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (counter > 0 && isWait && step === AuthFormSteps.smsCode) {
      timer = setTimeout(() => {
        dispatch(actions.setCounter(counter - 1));
      }, 1000);
    } else {
      dispatch(actions.setWait(false));
      dispatch(actions.setCounter(60));
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);

  return (
    <Modal isVisible={isVisible} onClose={isCanCloseModal ? onCloseModal : undefined}>
      {step !== AuthFormSteps.final && (
        <>
          <Title>{renderAuthTitle(step, t)}</Title>
          <Note>{renderAuthNote(step, getValues().number, t)}</Note>
        </>
      )}
      <FormProvider {...authForm}>
        {renderAuthForm(step, setStep, onCloseModal, currentPhone, setCurrentPhone)}
      </FormProvider>
    </Modal>
  );
};

const Title = styled.div`
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  padding-bottom: 15px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Note = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  padding-bottom: 30px;
  max-width: 260px;
  white-space: pre-line;
`;
