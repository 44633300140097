import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Message } from 'shared/api';
import { pusherEvents } from 'shared/common/constants';
import { PusherService } from 'shared/lib';
import { actions, selectors } from 'store/ducks';

import { useAppDispatch } from './redux';

const createPrivateChannel = (chatRoomId: number) => `private-chat_${chatRoomId}`;

export const useChatPrivateCannel = () => {
  const dispatch = useAppDispatch();
  const accessToken = useSelector(selectors.profile.selectToken);
  const chatRoomId = useSelector(selectors.chatRooms.selectCurrentChatRoomId);
  const currentChat = useSelector(selectors.chatRooms.selectChatRoomById(chatRoomId));

  useEffect(() => {
    const pusher = PusherService.init(accessToken);
    const privateChatCannel = chatRoomId && createPrivateChannel(chatRoomId);

    if (pusher && privateChatCannel) {
      const privateChannel = pusher.subscribe(privateChatCannel);

      privateChannel.bind(pusherEvents.messages.received, (data: { message: Message }) => {
        dispatch(actions.chatMessages.receiveChatMessage(data.message));

        if (data.message.consultationId && currentChat?.expertId === data.message.authorId) {
          dispatch(actions.consultationsChatRoom.incrementExpertMessagesCount(data.message.consultationId));
        }
      });

      return () => {
        privateChannel.unbind();
        pusher.unsubscribe(privateChatCannel);
      };
    }
  }, [accessToken, chatRoomId, currentChat?.id]);
};
