import { ReceiptIcon } from 'app/assets/svg';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { Consultation, Receipt, ReceiptStatusEnum } from 'shared/api';
import { getConsultationFieldSum } from 'shared/helpers';
import { getEventDateAndMonthFromString } from 'shared/helpers/time';
import styled from 'styled-components';

interface ConsultationRowProps {
  consultation: Consultation;
}

export const MyConsultationsRow: FC<ConsultationRowProps> = ({ consultation }) => {
  const { t, i18n } = useTranslation('profile.page');

  const getReceipt = (receipts: Receipt[]) => {
    const passed = receipts.find(({ status }) => status === ReceiptStatusEnum.PassedModeration);

    if (passed) {
      return (
        <a href={passed.receiptFileUrl}>
          <ReceiptIconCentered />
        </a>
      );
    }

    const onModeration = receipts.find(({ status }) => status === ReceiptStatusEnum.WaitingModeration);
    if (onModeration) {
      return (
        <StatusContainer>
          <StatusText status={ReceiptStatusEnum.WaitingModeration}>На модерации</StatusText>
        </StatusContainer>
      );
    }

    return (
      <StatusContainer>
        <StatusText status={ReceiptStatusEnum.WaitingModeration}>-</StatusText>
      </StatusContainer>
    );
  };

  const getConsultationStartTime = () => getEventDateAndMonthFromString(consultation.createdAt, i18n.language);

  return (
    <Row key={consultation.id}>
      <LeftRow>
        <Column>{t('walletTab.index', { index: consultation.id })}</Column>
        <Column>{getConsultationStartTime()}</Column>
        <Column>{consultation.category?.name || '-'}</Column>
      </LeftRow>
      <RightRow>
        <Column>{t('walletTab.count', { count: getConsultationFieldSum(consultation.invoices, 'amount') })}</Column>
        <Column>{consultation.receipts ? getReceipt(consultation.receipts) : '-'}</Column>
      </RightRow>
    </Row>
  );
};

const ReceiptIconCentered = styled(ReceiptIcon)`
  vertical-align: middle;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  width: 750px;
  border-bottom: 1px solid var(--gray6);
`;

const LeftRow = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr;
  width: 40%;
  column-gap: 6px;
`;

const RightRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 30%;
  column-gap: 6px;
`;

const Column = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.85;
  letter-spacing: normal;
  color: var(--text);
`;

const StatusText = styled.span<{ status: ReceiptStatusEnum }>`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.85;
  letter-spacing: normal;
  color: ${({ status }) => {
    switch (status) {
      case ReceiptStatusEnum.ModerationRejected:
        return 'var(--red)';
      case ReceiptStatusEnum.WaitingModeration:
        return 'var(--text)';
      case ReceiptStatusEnum.PassedModeration:
        return 'var(--green)';
      default:
        break;
    }
  }};
`;

const StatusContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
