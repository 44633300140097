import { useCallback } from 'react';

export const useVkMetrics = () => {
  if (typeof window !== 'object') {
    return;
  }

  const goal = useCallback(
    (conversionName: string, params?: unknown) => {
      window?.VK?.Goal(conversionName, params);
    },
    [window?.VK],
  );

  return { goal } as const;
};
