import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { api, Wallet } from 'shared/api';
import { captureError } from 'shared/helpers/captureError';
import { Badge, Button } from 'shared/ui';
import { updateDefaultPaymentMethod } from 'store/ducks/profile/actions';
import styled from 'styled-components';

interface CardsForWithdrawalProps {
  wallet: Wallet;
}

export const CardsForWithdrawal: FC<CardsForWithdrawalProps> = ({ wallet }) => {
  const { t } = useTranslation('profile.page');

  const dispatch = useAppDispatch();

  const onAddCard = async () => {
    try {
      const {
        data: { attachUrl },
      } = await api.V1PaymentMethodsApi.paymentMethodsControllerGetAttachCardUrl();

      window.open(attachUrl, '_self');
    } catch (e) {
      captureError(e);
      toast.error(t('somethingWrong'));
    }
  };

  const onUpdateDefaultMethod = (methodId: number) => async () => {
    try {
      const res = await dispatch(updateDefaultPaymentMethod({ paymentMethodId: methodId }));
      if (!unwrapResult(res)) {
        toast.error(t('walletTab.failedUpdateDefaultPaymentMethod'));
      }
    } catch (e) {
      captureError(e);
      toast.error(t('walletTab.failedUpdateDefaultPaymentMethod'));
    }
  };

  const renderPaymentMethodStatus = (methodId: number, defaultMethodId?: number) => {
    // TODO убрать !
    if (defaultMethodId === methodId) {
      return <Badge label={t('walletTab.default')} />;
    }
    return (
      <Button size="small" bordered hasBottomRadius={false} onClick={onUpdateDefaultMethod(methodId)}>
        {t('walletTab.selectCard')}
      </Button>
    );
  };

  return (
    <div>
      <Title>{t('walletTab.linkedCards')}</Title>

      <div>
        {wallet.paymentMethods?.map((method) => (
          <Row key={method.id}>
            <PanColumn>{method.pan}</PanColumn>
            <StatusColumn>{renderPaymentMethodStatus(method.id, wallet.defaultPaymentMethodId)}</StatusColumn>
          </Row>
        ))}
      </div>

      <Button bordered onClick={onAddCard}>
        {t('walletTab.addCard')}
      </Button>
    </div>
  );
};

const Title = styled.div`
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  font-size: 17px;
  margin-bottom: 10px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

const PanColumn = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  max-width: 500px;
  color: var(--gray);
  margin-right: 20px;
`;

const StatusColumn = styled.div`
  display: flex;
  align-items: center;
`;
