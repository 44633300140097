import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={15} height={15} viewBox="0 0 15 15" {...props}>
      <defs>
        <path
          id="check_mark_svg"
          d="M8.57 6.9L1.91.24A.811.811 0 001.329 0 .814.814 0 00.75.24L.26.73a.818.818 0 000 1.158l5.594 5.594-5.6 5.6a.817.817 0 000 1.159l.49.49c.154.154.36.239.58.239a.808.808 0 00.578-.24L8.57 8.064a.815.815 0 00.24-.58.814.814 0 00-.24-.582z"
        />
      </defs>
      <g transform="translate(3)" fill="none" fillRule="evenodd">
        <mask id="check_mark_svg_1" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <use fill="#79818C" fillRule="nonzero" xlinkHref="#check_mark_svg" />
        <path fill="#79818C" d="M-3 0h15v15H-3z" mask="url(#check_mark_svg_1)" />
      </g>
    </svg>
  );
}

export default SvgComponent;
