import styled from 'styled-components';

import { containImageStyle } from '../utils/containImageStyle';

type ButtonColor = 'violet' | 'violet-opacity' | 'violet-gradient' | 'pink' | 'white' | 'transparent-white';
type ButtonSize = 'default' | 'big' | 'medium' | '50';

export type ButtonProps = {
  $block?: boolean;
  $bordered?: boolean;
  $color?: ButtonColor;
  $size?: ButtonSize;
  $icon?: string;
  isShadow?: boolean;
};

const boxShadowImageMap: Record<ButtonColor, string> = {
  white: '0 0 30px 0 rgba(255, 255, 255, 0.44)',
  violet: 'box-shadow: rgba(255, 51, 102, 0.2) 0px 0px 15px 0px',
  'violet-gradient': '0 0 30px 0 rgba(255, 255, 255, 0.44)',
  'violet-opacity': 'none',
  pink: 'none',
  'transparent-white': 'none',
};

const backgroundImageMap: Record<ButtonColor, string> = {
  white: 'none',
  violet: 'none',
  'violet-gradient': 'linear-gradient(to right,#862fff 10%,#9c3efe 40%,#AE4CFE 90%)',
  'violet-opacity': 'none',
  pink: 'none',
  'transparent-white': 'none',
};

const colorsMap: Record<ButtonColor, string> = {
  white: '#fff',
  violet: '#8b33ff',
  'violet-gradient': '#8b33ff',
  'violet-opacity': 'rgba(255, 255, 255, 0.15)',
  pink: '#f36',
  'transparent-white': 'transparent',
};

const hoverColorsMap: Record<ButtonColor, string> = {
  white: '#8b33ff',
  violet: '#fff',
  'violet-gradient': '#fff',
  'violet-opacity': 'rgba(255, 255, 255, 0.3)',
  pink: '#f36',
  'transparent-white': '#8b33ff',
};

const hoverTextColorsMap: Record<ButtonColor, string> = {
  white: '#fff',
  violet: '#8b33ff',
  'violet-gradient': '#8b33ff',
  'violet-opacity': '#fff',
  pink: '#f36',
  'transparent-white': 'transparent',
};

const textColorsMap: Record<ButtonColor, string> = {
  white: '#8b33ff',
  violet: '#fff',
  'violet-gradient': '#fff',
  'violet-opacity': '#fff',
  pink: '#fff',
  'transparent-white': '#fff',
};

const borderColorsMap: Record<ButtonColor, string> = {
  white: '#fff',
  violet: '#8b33ff',
  'violet-gradient': '#8b33ff',
  'violet-opacity': 'transparent',
  pink: '#fff',
  'transparent-white': '#fff',
};

const horizontalPaddingMap: Record<ButtonColor, number> = {
  white: 30,
  violet: 30,
  'violet-gradient': 30,
  'violet-opacity': 30,
  pink: 30,
  'transparent-white': 0,
};

const horizontalPaddingWithIconMap: Record<ButtonColor, number> = {
  white: 55,
  violet: 55,
  'violet-gradient': 55,
  'violet-opacity': 55,
  pink: 55,
  'transparent-white': 25,
};

const iconLeftMap: Record<ButtonColor, number> = {
  white: 30,
  violet: 30,
  'violet-gradient': 30,
  'violet-opacity': 30,
  pink: 30,
  'transparent-white': 0,
};

const verticalPaddingMap: Record<ButtonSize, number> = {
  default: 9,
  50: 14,
  medium: 13,
  big: 22,
};

const borderRadiusMap: Record<ButtonSize, number> = {
  default: 20,
  50: 25,
  medium: 28,
  big: 33,
};

const borderHover: Record<string, string> = {
  '#fff': '#771cef',
  '#771cef': '#fff',
  transparent: '#fff',
  '#8b33ff': '#fff',
};

export const Button = styled.button<ButtonProps>(
  ({ $block, $bordered, $color = 'violet', $size = 'default', $icon, disabled, isShadow }) => {
    const baseColor = colorsMap[$color];
    const baseHoverColor = isShadow ? '#771cef' : hoverColorsMap[$color];
    const borderColor = isShadow ? 'transparent' : borderColorsMap[$color];
    const hoverTextColor = $bordered ? borderHover[borderColor] : hoverTextColorsMap[$color];
    const boxShadowColor = boxShadowImageMap[$color];

    return {
      display: $block ? 'block' : 'inline',
      position: 'relative',
      boxSizing: 'border-box',
      width: $block ? '100%' : 'auto',

      fontSize: 15,
      fontWeight: 100,
      boxShadow: 'none',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '20px',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: $bordered ? baseColor : textColorsMap[$color],
      paddingTop: verticalPaddingMap[$size],
      paddingBottom: verticalPaddingMap[$size],
      paddingLeft: $icon ? horizontalPaddingWithIconMap[$color] : horizontalPaddingMap[$color],
      paddingRight: horizontalPaddingMap[$color],
      borderRadius: borderRadiusMap[$size],
      backgroundColor: $bordered ? 'transparent' : baseColor,
      backgroundImage: backgroundImageMap[$color],
      // boxShadow: boxShadowImageMap[$color],
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: $bordered ? baseColor : 'transparent',
      opacity: disabled ? 0.2 : 1,
      outline: 'none',
      cursor: disabled ? 'default' : 'pointer',
      textDecoration: 'none',
      transition: 'all 0.2s',
      userSelect: 'none',

      '&::before': $icon
        ? {
            content: '""',
            position: 'absolute',
            top: 0,
            height: '100%',
            left: iconLeftMap[$color],
            width: 18,
            ...containImageStyle($icon),
          }
        : '',

      '&:hover': {
        backgroundColor: $bordered ? baseColor : baseHoverColor,
        color: isShadow ? '#fff' : hoverTextColor,
        borderColor: $bordered ? baseHoverColor : borderColor,
        boxShadow: isShadow ? boxShadowColor : 'none',
        backgroundImage: 'none',
      },
    };
  },
);
