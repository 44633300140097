import { useWindowWidth } from '@react-hook/window-size';
import type { FC, ReactNode } from 'react';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Modal } from '../../../components/Modal';

const StyledHeader = styled.div({
  paddingTop: 30,
  paddingLeft: 30,
  paddingRight: 30,
  paddingBottom: 20,
  color: '#fff',
  backgroundImage: 'linear-gradient(to right, #812df6 5%, #993dfa 48%, #ae4cfe)',
  borderTopLeftRadius: 6,
  borderTopRightRadius: 6,
});

const StyledContent = styled.div({
  paddingLeft: 30,
  paddingRight: 30,
  paddingBottom: 20,
});

type PromoModalProps = {
  show: boolean;
  onHide: () => void;
  title: ReactNode;
  children: ReactNode;
};

export const PromoModal: FC<PromoModalProps> = ({ show, onHide, title, children }) => {
  const windowWidth = useWindowWidth();
  const modalWidth = useMemo(() => Math.min(windowWidth - 40, 420), [windowWidth]);

  return (
    <Modal width={modalWidth} show={show} onHide={onHide}>
      <>
        <StyledHeader>{title}</StyledHeader>

        <StyledContent>{children}</StyledContent>
      </>
    </Modal>
  );
};
