import { TFunction } from 'i18next';
import { toast } from 'react-toastify';
import { api } from 'shared/api';
import { httpClient } from 'shared/api/httpClient';

import { getUrl } from './getUrl';

export async function uploadFile(file: File, t: TFunction) {
  const { size, type } = file;
  if (size > 5242880) {
    toast.error(t('largeFile'));
  }
  if (type !== 'image/jpeg' && type !== 'image/png') {
    toast.error(t('incorrectFileType'));
  }
  const signRes = await api.V1StorageApi.storageControllerSignFileUrl({
    contentType: file.type,
    fileKey: file.name,
  });

  await httpClient.put(signRes.data, file, {
    headers: {
      'Content-Type': file.type,
      'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS, DELETE',
      'Access-Control-Allow-Origin': '*',
    },
  });

  return getUrl(signRes.data);
}
