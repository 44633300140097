export const getRandomIntInclusive = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomAvatar = (): string => {
  const randomFromOneToSix = getRandomIntInclusive(1, 6);
  switch (randomFromOneToSix) {
    case 1:
      return 'https://storage.yandexcloud.net/consulty-stage/consulty-stage/public/default%20avatars/smile%201.png';
    case 2:
      return 'https://storage.yandexcloud.net/consulty-stage/consulty-stage/public/default%20avatars/smile%202.png';
    case 3:
      return 'https://storage.yandexcloud.net/consulty-stage/consulty-stage/public/default%20avatars/smile%203.png';
    case 4:
      return 'https://storage.yandexcloud.net/consulty-stage/consulty-stage/public/default%20avatars/smile%204.png';
    case 5:
      return 'https://storage.yandexcloud.net/consulty-stage/consulty-stage/public/default%20avatars/smile%205.png';
    case 6:
    default:
      return 'https://storage.yandexcloud.net/consulty-stage/consulty-stage/public/default%20avatars/smile%206.png';
  }
};
