import { unwrapResult } from '@reduxjs/toolkit';
import imageCompression from 'browser-image-compression';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-toastify';
import { httpClient } from 'shared/api';
import { MAX_FILE_SIZE } from 'shared/common/constants';
import { getUrl } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { actions } from 'store/ducks/app';

import { useAppDispatch } from './redux';

const imageTypes = ['image/jpeg', 'image/png'];

export const usePrepareImageToUpload = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('utils');
  const selectFile = (
    files: FileList | null,
    onloadCallback: ({ readerResult, file }: { readerResult?: string; file?: File }) => void,
    compressFile?: boolean,
  ) => {
    if (files) {
      const File = files.item(0);
      if (File) {
        const reader = new FileReader();
        const { size, type } = File;

        if (size > MAX_FILE_SIZE) {
          throw new Error('Превышен допустимый размер файла');
        }
        if (!imageTypes.includes(type)) {
          throw new Error('Изображение должно иметь тип jpeg или png');
        }

        reader.onload = async () => {
          if (typeof reader.result === 'string') {
            const resizedFile = compressFile ? await resizeImageFn(File) : File;
            onloadCallback({ readerResult: reader.result, file: resizedFile });
          }
        };
        reader.readAsDataURL(File);
      }
    }
  };

  const uploadFileToCloud = async (file: File) => {
    try {
      const action = await dispatch(
        actions.signFileUrlAsync({
          contentType: file.type,
          fileKey: file.name,
        }),
      );
      const signUrl = unwrapResult(action);

      await httpClient.put(signUrl, file, {
        headers: {
          'Content-Type': file.type,
          'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS, DELETE',
          'Access-Control-Allow-Origin': '*',
        },
      });
      return getUrl(signUrl);
    } catch (error) {
      captureError(error);
      toast.error(t('somethingWrong'));
    }
  };

  const resizeImageFn = async (file: File) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 490,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);

      return compressedFile;
    } catch (error) {
      toast.error(t('somethingWrong'));
    }
  };

  return { selectFile, uploadFileToCloud };
};
