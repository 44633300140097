type HardcodeExpert = {
  expertId: number;
  consultationCount?: number;
  reviewsCount?: number;
  rating?: number;
};
// эксперты с захардкоженными данными
export const hardCodeExperts: HardcodeExpert[] = [
  { expertId: 1720, consultationCount: 22, rating: 4.9, reviewsCount: 20 },
  { expertId: 1715, consultationCount: 47, rating: 4.8, reviewsCount: 41 },
  { expertId: 1718, consultationCount: 31, rating: 4.9, reviewsCount: 30 },
  { expertId: 1719, consultationCount: 19, rating: 5.0, reviewsCount: 19 },
  { expertId: 1725, consultationCount: 67, rating: 4.8, reviewsCount: 58 },
  { expertId: 1729, consultationCount: 12, rating: 5.0, reviewsCount: 12 },
  { expertId: 1747, consultationCount: 24, rating: 4.9, reviewsCount: 22 },
  { expertId: 1751, consultationCount: 24, rating: 5.0, reviewsCount: 22 },
  { expertId: 1601, consultationCount: 27, rating: 5.0, reviewsCount: 24 },
  { expertId: 214, rating: 5.0 },
  { expertId: 1145, rating: 5.0 },
  { expertId: 1286, rating: 5.0 },
  { expertId: 219, rating: 5.0 },
  { expertId: 412, rating: 5.0 },
  { expertId: 1628, rating: 5.0 },
  { expertId: 1237, rating: 5.0 },
  { expertId: 1258, rating: 5.0 },
  { expertId: 163, rating: 5.0 },
  { expertId: 859, rating: 5.0 },
  { expertId: 1664, rating: 5.0 },
  { expertId: 261, rating: 5.0 },
];
