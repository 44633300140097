export enum AuthFormSteps {
  number = 'number',
  smsCode = 'smsCode',
  promoCode = 'promoCode',
  final = 'final',
}

export interface AuthFormValues {
  number: string;
  agreement: boolean;
  code: string;
  promoCode: string;
  wait: boolean;
  counter: number;
}
