import React, { FC, ReactNode } from 'react';
import { Button, ButtonProps } from 'routes/landing/components/Button';
import styled from 'styled-components';

type NextButtonProps = ButtonProps & {
  children: ReactNode;
  disabled: boolean;
  onClick: () => void;
};

export const NextButton: FC<NextButtonProps> = ({ children, ...props }) => (
  <SaveButton {...props}>{children}</SaveButton>
);

const SaveButton = styled(Button)`
  margin-top: 60px;
  width: 212px;
  height: 50px;
  border-radius: 25px;
`;
