import { ClockIcon, ServiceIcon, UserGrayIcon, WarningGrayIcon } from 'app/assets/svg';
import { useAppDispatch } from 'hooks/redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { api } from 'shared/api';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { buildRoute } from 'shared/helpers';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';

interface ChatMenuProps {
  chatRoomId: number;
  isExpertInRoom: boolean;
  expertId?: number;
  isMuteChat: boolean;
}

export const ChatMenu: FC<ChatMenuProps> = ({ chatRoomId, isExpertInRoom, expertId, isMuteChat }) => {
  const { t } = useTranslation('chat.page');
  const dispatch = useAppDispatch();
  const router = useRouter();
  const isComplainModalOpen = useSelector(selectors.app.selectIsVisibleComplainModal);
  const activeConsultation = useSelector(selectors.consultationsChatRoom.selectActiveConsultation);

  const toggleMuteChat = () => {
    api.V1ChatsApi.chatRoomsControllerToggleMuteOne(chatRoomId);
    dispatch(actions.chatRooms.toggleChatNotification({ roomId: chatRoomId, isMuted: !isMuteChat, isExpertInRoom }));
  };

  const toggleComplainModal = () => {
    dispatch(actions.app.setIsVisibleComplainModal(!isComplainModalOpen));
  };

  const handleClickProlongation = () => {
    dispatch(actions.app.setIsVisibleProlongationModal(true));
  };

  const handleClickExtraService = () => {
    dispatch(actions.app.setIsVisibleExtraServiceModal(true));
  };

  return (
    <>
      <Wrapper />
      <Container>
        {!isExpertInRoom && (
          <ChatMenuItem onClick={() => router.push(buildRoute(routes.expert, { id: expertId }))}>
            <IconMobile>
              <UserGrayIcon />
            </IconMobile>
            {t('chatRoom.menu.goToExpert')}
          </ChatMenuItem>
        )}
        {isExpertInRoom && !!activeConsultation && (
          <>
            <ChatMenuItemOnlyMobile onClick={handleClickProlongation}>
              <IconMobile>
                <ClockIcon />
              </IconMobile>
              {t('chatRoom.menu.prolongation')}
            </ChatMenuItemOnlyMobile>
            <ChatMenuItemOnlyMobile onClick={handleClickExtraService}>
              <IconMobile>
                <ServiceIcon />
              </IconMobile>
              {t('chatRoom.menu.extraService')}
            </ChatMenuItemOnlyMobile>
          </>
        )}
        <ChatMenuItemOnlyMobile onClick={toggleComplainModal}>
          <IconMobile>
            <WarningGrayIcon />
          </IconMobile>
          {t('chatRoom.menu.complain')}
        </ChatMenuItemOnlyMobile>
        <ChatMenuItemOnlyDesktop onClick={toggleMuteChat}>
          {isMuteChat ? t('chatRoom.menu.enableNotification') : t('chatRoom.menu.disableNotification')}
        </ChatMenuItemOnlyDesktop>
      </Container>
    </>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: default;
`;
const Container = styled.ul`
  position: absolute;
  top: calc(100% + 10px);
  right: -10px;
  min-width: 212px;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray3);
  border-top: 1px solid var(--gray3);
  padding: 5px 0;
  width: 100vw;
  ${CONDITION_DESKTOP} {
    width: auto;
    top: calc(100% + 10px);
    right: 0;
    border-radius: 5px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    border: none;
  }
`;
const ChatMenuItem = styled.li`
  font-size: 16px;
  padding: 16px 20px;
  display: flex;
  &:hover {
    background-color: #f2f3f4;
  }
  ${CONDITION_DESKTOP} {
    font-size: 13px;
    padding: 10px 15px;
  }
`;
const ChatMenuItemOnlyDesktop = styled(ChatMenuItem)`
  display: none;
  ${CONDITION_DESKTOP} {
    display: flex;
  }
`;
const ChatMenuItemOnlyMobile = styled(ChatMenuItem)`
  ${CONDITION_DESKTOP} {
    display: none;
  }
`;
const IconMobile = styled.div`
  margin-right: 20px;
  ${CONDITION_DESKTOP} {
    display: none;
  }
`;
