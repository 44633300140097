import { useTranslation } from 'next-i18next';
import React, { memo } from 'react';
import { Modal } from 'routes/landing/components/Modal';
import styled from 'styled-components';

type IFreeAskModalProps = {
  show: boolean;
  onHide: () => void;
};

export const FreeAskModal = memo<IFreeAskModalProps>(({ show, onHide }) => {
  const { t } = useTranslation('utils');

  return (
    <Modal show={show} onHide={onHide}>
      <StyledContainer>
        <StyledTitle>{t('modals.freeAskModal.title')}</StyledTitle>
        <Text>{t('modals.freeAskModal.description')}</Text>
        <StyleButton onClick={() => onHide()}>Хорошо</StyleButton>
      </StyledContainer>
    </Modal>
  );
});

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '25px 20px',
  textAlign: 'center',
});

const StyledTitle = styled.span({
  fontSize: 23,
  fontWeight: 'bold',
  color: '#232832',
  marginBottom: '10px',
  lineHeight: '1.05',
  letterSpacing: 'normal',
});

const Text = styled.span({
  fontSize: 13,
  color: '#232832',
  marginBottom: '30px',
  marginTop: '20px',
  lineHeight: '1.05',
  letterSpacing: 'normal',
});

const StyleButton = styled.button({
  width: '100%',
  height: 50,
  borderRadius: '24px',
  border: 'solid 0.5px #8b33ff;',
  backgroundColor: '#fff',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#8b33ff',
    color: '#fff',
  },
});
