import CheckMarkGrey from 'app/assets/svg/CheckMarkGrey';
import Pen from 'app/assets/svg/Pen';
import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { Category, UserCategoryMeta } from 'shared/api';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { captureError } from 'shared/helpers/captureError';
import { Checkbox, StyledCheckbox } from 'shared/ui';
import { fetchDeleteUserFromCategory } from 'store/ducks/profile/actions';
import styled from 'styled-components';

import { CategoryActions } from '../rubrics';

interface RubricOptionProps {
  category: Category;
  subCategoryMap: Map<number, Category[]>;
  onOpenModal: (i: number, n: Category, a: CategoryActions) => () => void;
  userCategoryMap: Map<number, UserCategoryMeta>;
}

export const RubricOption: FC<RubricOptionProps> = ({ category, subCategoryMap, onOpenModal, userCategoryMap }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const subCategory = subCategoryMap.get(category.id);

  const { t } = useTranslation('profile.page');

  const onDeleteCategory = (id: number) => async () => {
    try {
      await dispatch(fetchDeleteUserFromCategory(id));
    } catch (e) {
      captureError(e);
      toast.error(t('notSuccessSaveInfo'));
    }
  };

  return (
    <Container>
      <Header>
        <TitleContainer onClick={() => setIsOpen((state) => !state)}>
          <Checkbox
            checked={userCategoryMap.has(category.id)}
            onChange={
              userCategoryMap.has(category.id)
                ? onDeleteCategory(userCategoryMap.get(category.id)!.id)
                : onOpenModal(category.id, category, CategoryActions.add)
            }
          />
          <Title>
            <span>{category.name}</span>
          </Title>
        </TitleContainer>
        <InfoContainer>
          <PriceContainer>
            {userCategoryMap.has(category.id) && (
              <Price onClick={onOpenModal(category.id, category, CategoryActions.update)}>
                <span>
                  {userCategoryMap.get(category.id)?.price}
                  {' \u20bd'}
                </span>
                <Pen />
              </Price>
            )}
          </PriceContainer>
          <OpenHandlerContainer>
            {subCategory && (
              <OpenHandler $isOpen={isOpen} onClick={() => setIsOpen((state) => !state)}>
                <CheckMarkGrey width={13} height={15} />
              </OpenHandler>
            )}
          </OpenHandlerContainer>
        </InfoContainer>
      </Header>
      {isOpen && (
        <ItemsContainer>
          {subCategory &&
            subCategory.map((cat) => (
              <React.Fragment key={cat.id}>
                {subCategoryMap.has(cat.id) ? (
                  <RubricOption
                    key={cat.id}
                    category={cat}
                    onOpenModal={onOpenModal}
                    subCategoryMap={subCategoryMap}
                    userCategoryMap={userCategoryMap}
                  />
                ) : (
                  <Item key={cat.id}>
                    <TitleContainer>
                      <StyledCheckbox
                        checked={userCategoryMap.has(cat.id)}
                        onChange={
                          userCategoryMap.has(cat.id)
                            ? onDeleteCategory(userCategoryMap.get(cat.id)!.id)
                            : onOpenModal(cat.id, cat, CategoryActions.add)
                        }
                      />
                      <Title>{cat.name}</Title>
                    </TitleContainer>
                    <InfoContainer>
                      <CountContainer />
                      <PriceContainer>
                        {userCategoryMap.has(cat.id) && (
                          <Price onClick={onOpenModal(cat.id, cat, CategoryActions.update)}>
                            <span>
                              {userCategoryMap.get(cat.id)?.price}
                              {' \u20bd'}
                            </span>
                            <Pen />
                          </Price>
                        )}
                      </PriceContainer>
                      <OpenHandlerContainer />
                    </InfoContainer>
                  </Item>
                )}
              </React.Fragment>
            ))}
        </ItemsContainer>
      )}
    </Container>
  );
};

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease;
  border-radius: 4px;
  padding: 10px;
  gap: 10px;
  ${CONDITION_DESKTOP} {
    padding: 10px 24px;
  }
  :hover {
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  }
`;
const Item = styled.div`
  display: flex;
  justify-content: space-between;
  transition: 0.3s ease;
  border-radius: 4px;
  padding: 10px;
  gap: 10px;
  ${CONDITION_DESKTOP} {
    padding: 10px 24px;
  }
  :hover {
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  }
`;
const TitleContainer = styled.div`
  display: flex;

  :hover {
    cursor: pointer;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const Price = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: var(--purple);
  display: flex;
  align-items: center;
  white-space: nowrap;
  ${CONDITION_DESKTOP} {
    min-width: 70px;
  }

  :hover {
    cursor: pointer;
  }
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--text);
  margin-left: 0;
  display: flex;
  align-items: center;
  ${CONDITION_DESKTOP} {
    margin-left: 5px;
  }
`;

const OpenHandler = styled.div<{ $isOpen: boolean }>`
  display: flex;
  align-items: center;
  transition: 0.3s ease;
  width: 30px;
  height: 26px;
  justify-content: center;

  ${({ $isOpen }) => ($isOpen ? `transform: rotate(90deg);` : `transform: rotate(0deg);`)}
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

const ItemsContainer = styled.div`
  padding-left: 10px;
  ${CONDITION_DESKTOP} {
    padding-left: 30px;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${CONDITION_DESKTOP} {
    min-width: 70px;
  }
`;
const CountContainer = styled.div`
  display: flex;
  justify-content: center;

  ${CONDITION_DESKTOP} {
    min-width: 70px;
  }
`;
const OpenHandlerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${CONDITION_DESKTOP} {
    min-width: 30px;
  }
`;
