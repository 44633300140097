import { usePrepareImageToUpload } from 'hooks/usePrepareImageToUpload';
import { useTranslation } from 'next-i18next';
import { ChangeEvent, useState } from 'react';
import { coverImageStyle } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import styled from 'styled-components';

type PhotoInputProps = {
  setValue: (v: string) => void;
  avatarUrl: string | null;
  title?: string;
};

export const PhotoInput = ({ title, setValue, avatarUrl }: PhotoInputProps) => {
  const { t } = useTranslation('profile.page');

  const [photo, setPhoto] = useState(avatarUrl);

  const { selectFile, uploadFileToCloud } = usePrepareImageToUpload();

  const onChangePhoto = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    selectFile(files, async ({ file }) => {
      try {
        if (file) {
          const avatarUrl = await uploadFileToCloud(file);
          if (avatarUrl) {
            setValue(avatarUrl);
            setPhoto(avatarUrl);
          }
        }
      } catch (error: any) {
        captureError(error);
      }
    });
  };

  return (
    <PhotoContainer>
      <PhotoTitle>{title || `${t('photo')}`}</PhotoTitle>
      <PhotoNote>{t('photoDescription')}</PhotoNote>
      <PhotoUploadAvatarContainer>
        <PhotoAvatar htmlFor="avatar1" $url={photo || avatarUrl || null} $disable={!!avatarUrl} />
        <PhotoText htmlFor="avatar1">Загрузить фото</PhotoText>
      </PhotoUploadAvatarContainer>
      <input
        id="avatar1"
        onChange={onChangePhoto}
        type="file"
        accept="image/png, image/jpeg"
        style={{ display: 'none' }}
      />
    </PhotoContainer>
  );
};

const PhotoContainer = styled.div`
  margin-top: 40px;

  input {
    width: 60%;
  }
  button {
    margin-top: 40px;
  }
`;
const PhotoTitle = styled.h3`
  margin: 0;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black2);
  padding-bottom: 10px;
`;

const PhotoUploadAvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PhotoAvatar = styled.label<{ $url: string | null; $disable?: boolean }>`
  margin-bottom: 0;
  border-radius: 15px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  opacity: ${({ $disable }) => ($disable ? '0.5' : '1')};
  background-color: ${({ $url }) => ($url ? 'transparent' : 'var(--purple)')};
  ${({ $url }) => ({ ...coverImageStyle($url) })}
  :hover {
    cursor: pointer;
  }
  & > svg {
    transform: scale(2);
  }

  @media (max-width: 450px) {
    margin-bottom: 20px;
  }
`;

const PhotoText = styled.label`
  padding: 11px 17px 11px 17px;
  border-radius: 20px;
  background-color: #f7f6ff;
  color: var(--purple);
  cursor: pointer;
  margin-left: 30px;
  font-size: 15px;
  transition: all 0.2s;

  &:hover {
    background-color: var(--purple);
    color: var(--white);
    border-color: var(--white);
  }

  @media (max-width: 450px) {
    margin-left: 0;
  }
`;

const PhotoNote = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  max-width: 500px;
  letter-spacing: normal;
  padding-bottom: 30px;
  color: var(--gray);

  & > a {
    color: var(--purple);
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`;
