import SvgProfileVerification from 'app/assets/svg/ProfileVerification';
import SvgSmallVideoCamera from 'app/assets/svg/SmallVideoCamera';
import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ym from 'react-yandex-metrika';
import { Achievement, AchievementNameEnum } from 'shared/api';
import { CONDITION_DESKTOP, CONDITION_MOBILE, ENVIRONMENT_TYPE } from 'shared/common/constants';
import { IErrors } from 'shared/common/types';
import { captureError } from 'shared/helpers/captureError';
import { linkPattern } from 'shared/helpers/patterns';
import { ActionButtonStatusNames, Button, Input } from 'shared/ui';
import { selectors } from 'store/ducks/profile';
import { fetchSelfVerificationRequests, sendVerificationRequest } from 'store/ducks/profile/actions';
import { selectLastVerificationRequest } from 'store/ducks/profile/selectors';
import styled from 'styled-components';
import { ExampleExpertPreview } from 'widgets/common';

interface ExpertVerificationFormValues {
  tg: string;
  vk: string;
  ok: string;
  youtube: string;
  videoCard: string;
  instagram: string;
  facebook: string;
  website: string;
  diplomas: string;
  reviews: string;
}

const achievements: Achievement[] = [
  {
    id: 1,
    name: AchievementNameEnum.Profi,
    updatedAt: '',
    deletedAt: '',
    createdAt: '',
  },
  {
    id: 2,
    name: AchievementNameEnum.OnThePositive,
    updatedAt: '',
    deletedAt: '',
    createdAt: '',
  },
  {
    id: 3,
    name: AchievementNameEnum.Smartest,
    updatedAt: '',
    deletedAt: '',
    createdAt: '',
  },
  {
    id: 4,
    name: AchievementNameEnum.RespondsQuickly,
    updatedAt: '',
    deletedAt: '',
    createdAt: '',
  },
];

interface ExpertVerificationFormProps {
  status: ActionButtonStatusNames;
  verificationRequestErrorsArr: IErrors[];
}

export const ExpertVerificationForm: FC<ExpertVerificationFormProps> = ({
  status = ActionButtonStatusNames.no,
  verificationRequestErrorsArr,
}) => {
  const { t } = useTranslation('profile.page');

  const [isShowReviewsInput, setIsShowReviewsInput] = useState(false);
  const [isShowDiplomasInput, setIsShowDiplomasInput] = useState(false);

  let commentDiplomasUrlArr = [] as IErrors[];
  // let commentFacebookUrlArr = [] as IErrors[];
  // let commentInstagramUrlArr = [] as IErrors[];
  let commentTelegramUrlArr = [] as IErrors[];
  let commentYoutubeUrlArr = [] as IErrors[];
  let commentVkontakteUrlArr = [] as IErrors[];
  let commentOdnoklassnikiUrlArr = [] as IErrors[];
  let commentPersonalSiteArr = [] as IErrors[];
  let commentReviewsUrlArr = [] as IErrors[];
  let commentVideoUrlArr = [] as IErrors[];

  if (verificationRequestErrorsArr && verificationRequestErrorsArr.length) {
    commentDiplomasUrlArr = verificationRequestErrorsArr.filter((item) => item.title === 'commentDiplomasUrl');
    // commentFacebookUrlArr = verificationRequestErrorsArr.filter((item) => item.title === 'commentFacebookUrl');
    // commentInstagramUrlArr = verificationRequestErrorsArr.filter((item) => item.title === 'commentInstagramUrl');
    commentTelegramUrlArr = verificationRequestErrorsArr.filter((item) => item.title === 'commentTelegramUrl');
    commentYoutubeUrlArr = verificationRequestErrorsArr.filter((item) => item.title === 'commentYoutubeUrl');
    commentVkontakteUrlArr = verificationRequestErrorsArr.filter((item) => item.title === 'commentVKontakteUrl');
    commentOdnoklassnikiUrlArr = verificationRequestErrorsArr.filter(
      (item) => item.title === 'commentOdnoklassnikiUrl',
    );
    commentPersonalSiteArr = verificationRequestErrorsArr.filter((item) => item.title === 'commentPersonalSite');
    commentReviewsUrlArr = verificationRequestErrorsArr.filter((item) => item.title === 'commentReviewsUrl');
    commentVideoUrlArr = verificationRequestErrorsArr.filter((item) => item.title === 'commentVideoUrl');
  }

  const { t: error } = useTranslation('errors.messages');

  const verificationRequest = useSelector(selectLastVerificationRequest);

  const user = useSelector(selectors.selectUser);

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<ExpertVerificationFormValues>({
    defaultValues: {
      tg: verificationRequest?.telegramUrl || user.telegramUrl || '',
      vk: verificationRequest?.vKontakteUrl || user.vKontakteUrl || '',
      ok: verificationRequest?.odnoklassnikiUrl || user.odnoklassnikiUrl || '',
      youtube: verificationRequest?.youtubeUrl || user.youtubeUrl || '',
      videoCard: verificationRequest?.videoUrl || user.videoUrl || '',
      instagram: verificationRequest?.instagramUrl || user.instagramUrl || '',
      facebook: verificationRequest?.facebookUrl || user.facebookUrl || '',
      website: verificationRequest?.personalSite || user.personalSite || '',
      diplomas: verificationRequest?.diplomasUrl || user.diplomasUrl || '',
      reviews: verificationRequest?.reviewsUrl || '',
    },
  });

  const sendAnalyticsData = () => {
    if (ENVIRONMENT_TYPE === 'production') {
      ym('reachGoal', ['sendverify']);
    }
  };

  const onSubmit = async ({
    videoCard,
    reviews,
    diplomas,
    website,
    // facebook,
    // instagram,
    vk,
    ok,
    youtube,
    tg,
  }: ExpertVerificationFormValues) => {
    try {
      await dispatch(
        sendVerificationRequest({
          diplomasUrl: diplomas.length !== 0 ? diplomas : undefined,
          videoUrl: videoCard.length !== 0 ? videoCard : undefined,
          // instagramUrl: instagram.length !== 0 ? instagram : undefined,
          // facebookUrl: facebook.length !== 0 ? facebook : undefined,
          youtubeUrl: youtube.length !== 0 ? youtube : undefined,
          vKontakteUrl: vk.length !== 0 ? vk : undefined,
          odnoklassnikiUrl: ok.length !== 0 ? ok : undefined,
          telegramUrl: tg.length !== 0 ? tg : undefined,
          reviewsUrl: reviews.length !== 0 ? reviews : undefined,
          personalSite: website.length !== 0 ? website : undefined,
        }),
      );
      sendAnalyticsData();
      dispatch(fetchSelfVerificationRequests());
      toast.success(t('requestHasBeenSend'));
    } catch (e) {
      captureError(e);
      toast.error(t('notSuccessSaveInfo'));
    }
  };

  return (
    <Container>
      <TopContentContainer>
        <TitleWrapper>
          <Title>{t('verificationProfile')}</Title>
        </TitleWrapper>
        <ProfileVerificationIconWrapper>
          <SvgProfileVerification />
        </ProfileVerificationIconWrapper>
      </TopContentContainer>
      <Note>{t('verificationProfileNote')}</Note>
      <SubNoteWrapper>
        <Note>{t('verificationProfileSubNote')}</Note>
      </SubNoteWrapper>

      <ExampleContainerWrapper>
        <ExampleTopTitleWrapper>
          <ExampleTopTitleText>{t('exampleTopTitleText')}</ExampleTopTitleText>
        </ExampleTopTitleWrapper>
        <ExampleContainer>
          <LeftExampleWrapper>
            <ExampleTitleWrapper>
              <ExampleTitleBefore>{t('beforeVerification')}</ExampleTitleBefore>
            </ExampleTitleWrapper>
            <ExampleExpertPreview
              isVerification={false}
              rating={4.75}
              consultationCount={14}
              reviewCount={12}
              averagePrice={600}
              firstName="Александра"
              avatar="/img/verificationProfileExampleAvatar.png"
              id={1}
              achievements={[]}
              videoUrl=""
            />
          </LeftExampleWrapper>
          <CenterLineWrapper>
            <VerticalLine />
          </CenterLineWrapper>
          <RightExampleWrapper>
            <ExampleTitleWrapper>
              <ExampleTitleAfter>{t('afterVerification')}</ExampleTitleAfter>
            </ExampleTitleWrapper>
            <ExampleExpertPreview
              isVerification
              rating={4.75}
              firstName="Александра"
              consultationCount={14}
              reviewCount={12}
              averagePrice={600}
              avatar="/img/verificationProfileExampleAvatar.png"
              id={1}
              achievements={achievements}
              videoUrl=""
            />
            <VideoCardWrapper>
              <StyledVideoCardText>{t('videoCard')}</StyledVideoCardText>
            </VideoCardWrapper>
          </RightExampleWrapper>
        </ExampleContainer>
      </ExampleContainerWrapper>

      <VideoCardFieldContainer>
        <VideoCardFieldWrapper>
          <VideoCard>
            <VideoCardText>{t('videoCard')}</VideoCardText>
            <SvgSmallVideoCamera />
          </VideoCard>
          <VideoCardNote>{t('videoCardNote')}</VideoCardNote>
          <Controller
            name="videoCard"
            rules={{
              pattern: { value: linkPattern, message: error('incorrectLink') },
            }}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                type="url"
                value={value}
                onChange={onChange}
                error={error?.message}
                placeholder={t('videoCardNotePlaceholder')}
                style={{ maxWidth: 420 }}
              />
            )}
          />
          {commentVideoUrlArr && commentVideoUrlArr.length > 0 && (
            <ErrorMessagesWrapper>
              <ErrorMessageText>{commentVideoUrlArr[0].note}</ErrorMessageText>
            </ErrorMessagesWrapper>
          )}
        </VideoCardFieldWrapper>
      </VideoCardFieldContainer>

      <FieldContainer>
        <FieldTitle>{t('linksOnYou')}</FieldTitle>
        <FieldNote>{t('shareLinks')}</FieldNote>

        <Controller
          name="vk"
          control={control}
          rules={{
            pattern: { value: linkPattern, message: error('incorrectLink') },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              label="Вконтакте"
              value={value}
              onChange={onChange}
              error={error?.message}
              placeholder="Ссылка на вашу страницу Вконтакте"
              style={{ maxWidth: 420 }}
            />
          )}
        />

        {commentVkontakteUrlArr && commentVkontakteUrlArr.length > 0 && (
          <ErrorMessagesWrapper>
            <ErrorMessageText>{commentVkontakteUrlArr[0].note}</ErrorMessageText>
          </ErrorMessagesWrapper>
        )}

        <InputContainer>
          <Controller
            name="tg"
            control={control}
            rules={{
              pattern: { value: linkPattern, message: error('incorrectLink') },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label="Telegram канал"
                value={value}
                onChange={onChange}
                error={error?.message}
                placeholder="Ссылка на вашу страницу Telegram"
                style={{ maxWidth: 420 }}
              />
            )}
          />
        </InputContainer>
        {commentTelegramUrlArr && commentTelegramUrlArr.length > 0 && (
          <ErrorMessagesWrapper>
            <ErrorMessageText>{commentTelegramUrlArr[0].note}</ErrorMessageText>
          </ErrorMessagesWrapper>
        )}

        <InputContainer>
          <Controller
            name="youtube"
            control={control}
            rules={{
              pattern: { value: linkPattern, message: error('incorrectLink') },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label="Youtube"
                value={value}
                onChange={onChange}
                error={error?.message}
                placeholder="Ссылка на ваш канал в Youtube"
                style={{ maxWidth: 420 }}
              />
            )}
          />
        </InputContainer>
        {commentYoutubeUrlArr && commentYoutubeUrlArr.length > 0 && (
          <ErrorMessagesWrapper>
            <ErrorMessageText>{commentYoutubeUrlArr[0].note}</ErrorMessageText>
          </ErrorMessagesWrapper>
        )}

        <InputContainer>
          <Controller
            name="ok"
            control={control}
            rules={{
              pattern: { value: linkPattern, message: error('incorrectLink') },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label="Одноклассники"
                value={value}
                onChange={onChange}
                error={error?.message}
                placeholder="Ссылка на вашу страницу Одноклассники"
                style={{ maxWidth: 420 }}
              />
            )}
          />
        </InputContainer>
        {commentOdnoklassnikiUrlArr && commentOdnoklassnikiUrlArr.length > 0 && (
          <ErrorMessagesWrapper>
            <ErrorMessageText>{commentOdnoklassnikiUrlArr[0].note}</ErrorMessageText>
          </ErrorMessagesWrapper>
        )}
        {/*
        <InputContainer>
          <Controller
            name={'facebook'}
            control={control}
            rules={{
              pattern: { value: linkPattern, message: error('incorrectLink') },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label={'Facebook'}
                value={value}
                onChange={onChange}
                error={error?.message}
                placeholder={'Ссылка на вашу страницу Facebook'}
                style={{ width: 361 }}
              />
            )}
          />
        </InputContainer>
        {commentFacebookUrlArr && commentFacebookUrlArr.length > 0 && (
          <ErrorMessagesWrapper>
            <ErrorMessageText>
              {commentFacebookUrlArr[0].note}
            </ErrorMessageText>
          </ErrorMessagesWrapper>
        )} */}
        {/* <InputContainer>
          <Controller
            name={'instagram'}
            control={control}
            rules={{
              pattern: { value: linkPattern, message: error('incorrectLink') },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label={'Instagram'}
                value={value}
                onChange={onChange}
                error={error?.message}
                placeholder={'Ссылка на вашу страницу Instagram'}
                style={{ width: 361 }}
              />
            )}
          />
        </InputContainer>
        {commentInstagramUrlArr && commentInstagramUrlArr.length > 0 && (
          <ErrorMessagesWrapper>
            <ErrorMessageText>
              {commentInstagramUrlArr[0].note}
            </ErrorMessageText>
          </ErrorMessagesWrapper>
        )} */}
        <InputContainer>
          <Controller
            name="website"
            control={control}
            rules={{
              pattern: { value: linkPattern, message: error('incorrectLink') },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label={t('selfWebsite')}
                value={value}
                onChange={onChange}
                placeholder="Ссылка на ваш сайт"
                error={error?.message}
                style={{ maxWidth: 420 }}
              />
            )}
          />
        </InputContainer>
        {commentPersonalSiteArr && commentPersonalSiteArr.length > 0 && (
          <ErrorMessagesWrapper>
            <ErrorMessageText>{commentPersonalSiteArr[0].note}</ErrorMessageText>
          </ErrorMessagesWrapper>
        )}
      </FieldContainer>

      <FieldContainer>
        <FieldTitle>{t('reviewsOnYou')}</FieldTitle>
        <FieldNote>{t('reviewsOnYouNote')}</FieldNote>
        {!isShowReviewsInput && (
          <AddButton onClick={() => setIsShowReviewsInput(true)}>{t('addButtonLabel')}</AddButton>
        )}
        {isShowReviewsInput && (
          <>
            <Controller
              name="reviews"
              control={control}
              rules={{
                pattern: { value: linkPattern, message: error('incorrectLink') },
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Input value={value} onChange={onChange} error={error?.message} placeholder="Ссылка на архив" />
              )}
            />
            {commentReviewsUrlArr && commentReviewsUrlArr.length > 0 && (
              <ErrorMessagesWrapper>
                <ErrorMessageText>{commentReviewsUrlArr[0].note}</ErrorMessageText>
              </ErrorMessagesWrapper>
            )}
            <InstructionNote>{t('diplomasInstruction')}</InstructionNote>
          </>
        )}
      </FieldContainer>

      <FieldContainer>
        <FieldTitle>{t('diplomasTitle')}</FieldTitle>
        <FieldNote style={{ color: '#79818c' }}>{t('diplomasTitleNote')}</FieldNote>
        {!isShowDiplomasInput && (
          <AddButton onClick={() => setIsShowDiplomasInput(true)}>{t('addButtonLabel')}</AddButton>
        )}
        {isShowDiplomasInput && (
          <>
            <Controller
              name="diplomas"
              control={control}
              rules={{
                pattern: { value: linkPattern, message: error('incorrectLink') },
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  placeholder="Ссылка на архив"
                  style={{ maxWidth: 420 }}
                />
              )}
            />
            {commentDiplomasUrlArr && commentDiplomasUrlArr.length > 0 && (
              <ErrorMessagesWrapper>
                <ErrorMessageText>{commentDiplomasUrlArr[0].note}</ErrorMessageText>
              </ErrorMessagesWrapper>
            )}
            <InstructionNote>{t('diplomasInstruction2')}</InstructionNote>
          </>
        )}
      </FieldContainer>

      <ButtonWrapper>
        <StyledButton disabled={!isDirty} onClick={handleSubmit(onSubmit)}>
          {t('sendVerification2')}
        </StyledButton>
      </ButtonWrapper>
      {/* {!!verificationRequest && <StatusVerificationRequest verificationRequest={verificationRequest} />} */}
      <VerificationRequestInfo>
        {status === ActionButtonStatusNames.processing && (
          <VerificationInfoMessage>{t('verificationInfoMessage.processing')}</VerificationInfoMessage>
        )}
        {status === ActionButtonStatusNames.success && (
          <VerificationInfoMessage>{t('verificationInfoMessage.success')}</VerificationInfoMessage>
        )}
        {status === ActionButtonStatusNames.fail && (
          <VerificationInfoMessage>{t('verificationInfoMessage.fail')}</VerificationInfoMessage>
        )}
      </VerificationRequestInfo>
    </Container>
  );
};

const Container = styled.div`
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);

  ${CONDITION_DESKTOP} {
    width: 560px;
  }

  ${CONDITION_MOBILE} {
    padding: 20px 20px 30px;
  }

  & > button {
    margin-top: 40px;
    width: 100%;
    ${CONDITION_DESKTOP} {
      width: unset;
    }
  }
`;

const Title = styled.div`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black2);
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  & > svg {
    margin-left: 3px;
  }
`;

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  max-width: 500px;
  letter-spacing: normal;
  color: var(--gray);
  & > a {
    color: var(--purple);
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }
`;

const ExampleContainerWrapper = styled.div`
  background: var(--aquaSpring);
  border-radius: 15px;
  padding-top: 15px;
`;

const ExampleContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 30px;
  justify-content: space-around;
  flex-direction: row;
  align-items: flex-start;
`;

const VideoCard = styled.div`
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 15px;
  }
`;

const VideoCardText = styled.span`
  background: linear-gradient(to right, #812df6 5%, #993dfa 48%, #ae4cfe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  margin-right: 10px;
`;

const VideoCardNote = styled(Note)`
  padding-bottom: 20px;
`;

const FieldTitle = styled(Title)`
  font-size: 17px;
`;

const FieldNote = styled(Note)`
  font-size: 14px;
  padding-bottom: 30px;
`;

const InstructionNote = styled(Note)`
  font-size: 14px;
  padding-top: 10px;
`;

const FieldContainer = styled.div`
  padding-top: 40px;
`;
const InputContainer = styled.div`
  padding-top: 30px;
`;

const ProfileVerificationIconWrapper = styled.div`
  margin-left: 10px;
  padding-bottom: 6px;
`;

const TitleWrapper = styled.div``;

const TopContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const SubNoteWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
`;

const ExampleTitleBefore = styled(Note)`
  margin-bottom: 8px;
  color: var(--white);
  background: var(--red);
  text-align: center;
  border-radius: 10px;
  font-size: 10px;
  width: 140px;
  padding: 2px;

  ${CONDITION_MOBILE} {
    width: 110px;
    height: 20px;
  }
`;
const ExampleTitleAfter = styled(Note)`
  margin-bottom: 8px;
  color: var(--white);
  background: var(--purple);
  text-align: center;
  border-radius: 10px;
  font-size: 10px;
  width: 140px;
  padding: 2px;

  ${CONDITION_MOBILE} {
    width: 110px;
    height: 20px;
  }
`;

const ExampleTopTitleWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const ExampleTopTitleText = styled.span`
  font-weight: bold;
`;

const RightExampleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const LeftExampleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CenterLineWrapper = styled.div``;

const VerticalLine = styled.div`
  border-left: 1px solid #c1c7cd;
  height: 332px;
  opacity: 0.2;
`;

const ExampleTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const VideoCardWrapper = styled.div`
  position: absolute;
  bottom: 150px;
  left: 28px;

  ${CONDITION_MOBILE} {
    left: 9px;
  }
`;

const StyledVideoCardText = styled(Note)`
  margin-bottom: 8px;
  color: var(--white);
  background: var(--purple);
  text-align: center;
  border-radius: 10px;
  font-size: 10px;
  width: 100px;

  @media (max-width: 600px) {
    margin-bottom: 14px;
  } ;
`;

const VideoCardFieldWrapper = styled.div`
  background: var(--whiteLilac);
  padding: 30px 20px;
  border-radius: 12px;
`;

const VideoCardFieldContainer = styled.div`
  padding-top: 40px;
`;

const AddButton = styled(Button)`
  width: 104px;
  color: var(--purple);
  cursor: pointer;
  background-color: var(--whiteLilac);
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    color: var(--purple);
    background-color: var(--whiteLilac);
    opacity: 0.75;
  }
`;

const VerificationRequestInfo = styled.div`
  margin-top: 8px;
`;

const VerificationInfoMessage = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--purple);
`;

const ErrorMessagesWrapper = styled.div`
  margin-top: 10px;
`;

const ErrorMessageText = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ff3366;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  max-width: 250px;
`;

const StyledButton = styled(Button)`
  span {
    padding: 2px 2px;
    margin: 0;
  }
`;
