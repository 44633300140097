import { unwrapResult } from '@reduxjs/toolkit';
import { PlaneIcon } from 'app/assets/svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ym from 'react-yandex-metrika';
import { User } from 'shared/api';
import { CONDITION_DESKTOP, ENVIRONMENT_TYPE } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { coverImageStyle } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { Button, TextareaModal } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';

interface StartConsultationsWidgetProps {
  expert: User;
  user: User;
}

interface StartConsultationsWidgetFormValues {
  initialMessage: string;
}

export const StartConsultationsWidget: FC<StartConsultationsWidgetProps> = ({ user, expert }) => {
  const { t } = useTranslation('expert.page');
  const { t: u } = useTranslation('utils');
  const isUserAuthorized = useAppSelector(selectors.profile.selectIsAuthentication);
  const isFreeChatRedirect = useAppSelector(selectors.app.selectIsFreeChatRedirectInfo);
  const isFreeChatAsk = useAppSelector(selectors.app.selectIsFreeChatAskInfo);
  const [askText, setAskText] = useState('');
  const router = useRouter();

  const dispatch = useAppDispatch();

  const goToChatRoomAfterRedirect = async () => {
    try {
      const res = await dispatch(
        actions.chatRooms.createChatRoom({
          expert,
          client: user,
          initialMessage: {
            content: askText,
          },
        }),
      );

      setShow(true);

      const data = unwrapResult(res);

      if (data) {
        dispatch(actions.chatRooms.addChatRoom(data!.room));
        await router.push({
          pathname: routes.chat,
          query: {
            activeTab: 'consult',
            roomId: data!.room.id,
          },
        });
      }
    } catch (e) {
      captureError(e);
      toast.error(u('somethingWrong'));
    }
  };

  useEffect(() => {
    if (isFreeChatRedirect && askText.trim().length) {
      goToChatRoomAfterRedirect();
    }
  }, [isFreeChatAsk]);

  const setShow = (v: boolean) => {
    dispatch(actions.app.setIsVisibleFreeAskModal(v));
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm<StartConsultationsWidgetFormValues>({
    defaultValues: {
      initialMessage: '',
    },
  });

  const sendAnalyticsData = () => {
    if (ENVIRONMENT_TYPE === 'production') {
      ym('reachGoal', ['askfreechat']);
      if (window && window.gtag) {
        window.gtag('event', 'askfreechat');
      }
    }
  };

  const onSubmit = async ({ initialMessage }: StartConsultationsWidgetFormValues) => {
    if (!isUserAuthorized) {
      dispatch(actions.app.setFreeChatRedirectInfo(true));
      return dispatch(actions.app.showAuthModal());
    }
    if (initialMessage.trim().length === 0) {
      reset();
      return;
    }
    try {
      const res = await dispatch(
        actions.chatRooms.createChatRoom({
          expert,
          client: user,
          initialMessage: {
            content: initialMessage,
          },
        }),
      );

      setShow(true);

      const data = unwrapResult(res);

      if (data) {
        dispatch(actions.chatRooms.addChatRoom(data!.room));
        sendAnalyticsData();
        await router.push({
          pathname: routes.chat,
          query: {
            activeTab: 'consult',
            roomId: data!.room.id,
          },
        });
      }
    } catch (e) {
      captureError(e);
      toast.error(u('somethingWrong'));
    }
  };

  if (!expert.hasFreeChat) {
    return (
      <WhiteContainer>
        <ImageContainer>
          <Image src="https://storage.yandexcloud.net/consulty-stage/consulty-stage/public/soputstvyushie-uslugi.png" />
        </ImageContainer>

        <NoFreeChatTextContainer>
          <NoFreeChatTitle>{t('expertDisableFreeChat')}</NoFreeChatTitle>

          <NoFreeChatNote>{t('forStartCommunication')}</NoFreeChatNote>
        </NoFreeChatTextContainer>
      </WhiteContainer>
    );
  }

  return (
    <Container>
      <TopContainer>
        <Title>{t('startConsultationFromAnswer')}</Title>
        <TitleNote>{t('itsFree')}</TitleNote>
      </TopContainer>

      <ContentContainer>
        <MessageContainer>
          {isUserAuthorized && (
            <MessageAuthor>
              <AuthorAvatar $url={user.avatarUrl || null} />
              <AuthorName>{user.firstName}</AuthorName>
            </MessageAuthor>
          )}

          <Controller
            control={control}
            name="initialMessage"
            render={({ field: { value, onChange } }) => (
              <MessageContent>
                <TextareaModal
                  placeholder={t('question')}
                  value={value}
                  onChange={(e) => {
                    setAskText(e.target.value);
                    onChange(e);
                  }}
                />
              </MessageContent>
            )}
          />
        </MessageContainer>

        <Line />

        <ButtonContainer>
          <Button disabled={!isDirty || isSubmitting} rightIcon={<PlaneIcon />} onClick={handleSubmit(onSubmit)}>
            {t('ask')}
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 12px;
  min-width: 330px;
  background-color: var(--purple);
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  background-image: var(--gradient2);
`;

const TopContainer = styled.div`
  padding: 20px;
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
  margin-bottom: 5px;
`;

const TitleNote = styled.div`
  opacity: 0.7;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
`;

const MessageContainer = styled.div`
  padding: 25px 20px 5px;
`;

interface AuthorAvatarProps {
  $url: string | null;
}

const AuthorAvatar = styled.div<AuthorAvatarProps>`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 3px;
  background-color: var(--gray4);
  ${({ $url }) => ({ ...coverImageStyle($url) })}
`;

const AuthorName = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: var(--text);
`;

const MessageAuthor = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & > div {
    margin-left: 10px;
  }
`;

const ContentContainer = styled.div`
  background-color: var(--white);
  border-radius: 12px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray10);
`;

const MessageContent = styled.div`
  & > textarea {
    border: 0;
  }
`;

const ButtonContainer = styled.div`
  border-radius: 0 0 12px 12px;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
`;

const WhiteContainer = styled.div`
  border-radius: 12px;
  background-color: var(--white);
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  width: 315px;
  padding: 30px 30px 45px;

  ${CONDITION_DESKTOP} {
    width: 335px;
  }
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 auto;
`;

const Image = styled.img`
  width: 100%;
`;

const NoFreeChatTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  margin-top: 25px;
`;

const NoFreeChatNote = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray);
  margin-top: 10px;
`;

const NoFreeChatTextContainer = styled.div``;
