import { DislikeBigIcon, LikeBigIcon } from 'app/assets/svg';
import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { api } from 'shared/api';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';
import { Modal } from 'widgets/common';
import { ThanksModal } from 'widgets/modals/thanks-modal';

type ReviewClientModalProps = {
  userId: number;
  clientId: number;
  consultationId: number;
};

export const ReviewClientModal: FC<ReviewClientModalProps> = ({ clientId, userId, consultationId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('utils');
  const isVisible = useSelector(selectors.consultationsChatRoom.selectIsReviewModalOpen);
  const contract = useSelector(selectors.profile.selectContract);

  const handleReview = async (rating: number) => {
    api.V1ReviewsApi.reviewsControllerCreateOne({
      clientId,
      expertId: userId,
      rating,
      consultationId,
      isPromptly: false,
      isSavvy: false,
      onPositive: false,
    });
    await dispatch(actions.consultationsChatRoom.setIsReviewModalOpen(false));
    dispatch(actions.app.setIsVisibleThanksModal(true));
  };

  return (
    <>
      {isVisible && (
        <Modal isVisible={isVisible}>
          <Container>
            <Title>{t('modals.clientReview.title')}</Title>
            <Description>{t('modals.clientReview.description')}</Description>
            <ActionBlock>
              <LikeButton onClick={() => handleReview(5)}>
                <LikeBigIcon />
              </LikeButton>
              <DislikeButton onClick={() => handleReview(1)}>
                <DislikeBigIcon />
              </DislikeButton>
            </ActionBlock>
            {contract.type !== 'individual' && (
              <Warning>
                <strong>Внимание! </strong>
                {t('modals.clientReview.warning')}
              </Warning>
            )}
          </Container>
        </Modal>
      )}

      <ThanksModal />
    </>
  );
};

const Container = styled.div`
  text-align: center;
`;
const Title = styled.h3`
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 15px;
`;
const Description = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 30px;
`;

const Warning = styled(Description)`
  margin-bottom: 0;
  margin-top: 30px;
`;

const ActionBlock = styled.div`
  display: flex;
  justify-content: space-around;
`;
const ActionButton = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const DislikeButton = styled(ActionButton)`
  border: 1px solid rgba(255, 51, 102, 0.15);
  &:hover {
    background-color: rgba(255, 51, 102, 0.15);
    border: none;
  }
`;
const LikeButton = styled(ActionButton)`
  border: 1px solid rgba(36, 175, 30, 0.15);
  &:hover {
    background-color: rgba(36, 175, 30, 0.15);
    border: none;
  }
`;
