import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AUDIO_MESSAGE_EXT } from 'shared/common/constants';
import { captureError } from 'shared/helpers/captureError';
import { selectors } from 'store/ducks';

const formattingRecordTime = (value: number) => {
  const sec = Math.floor(value);
  const minutes = Math.floor(sec / 60);
  const seconds = sec - 60 * minutes;
  const formatted = [minutes.toString().padStart(2, '0'), seconds.toString().padStart(2, '0')];
  return formatted.join(':');
};

type RecordAudioProps = {
  disabled?: boolean;
  isUploadingFile?: boolean;
};

export const useRecordAudio = ({ disabled, isUploadingFile }: RecordAudioProps) => {
  const { t } = useTranslation('utils');
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioFile, setAudioFile] = useState<Blob | null>(null);
  const [recordTime, setRecordTime] = useState<number>(0);
  const currentChatRoomId = useSelector(selectors.chatRooms.selectCurrentChatRoomId);

  useEffect(() => {
    if (isRecording) {
      const interval = setInterval(() => {
        setRecordTime((prev) => prev + 1);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isRecording]);

  useEffect(() => {
    onStopRecord();
    setTimeout(() => {
      setAudioFile(null);
      setRecordTime(0);
      setIsRecording(false);
    }, 0);
    return () => {
      setMediaRecorder(null);
    };
  }, [currentChatRoomId]);

  const onStartRecord = async () => {
    try {
      if (!isRecording && !isUploadingFile && !disabled) {
        setRecordTime(0);
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        await setMediaRecorder(new MediaRecorder(stream));
        setIsRecording(true);
      }
    } catch (error) {
      captureError(error);
      toast.error(t('noAudioDevices'));
    }
  };

  const onStopRecord = () => {
    if (mediaRecorder?.state === 'recording') {
      mediaRecorder?.stop();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
    }
  };

  const clearAudioFile = () => {
    setAudioFile(null);
  };

  const onDataAvailable = useCallback(
    async (e: BlobEvent) => {
      try {
        const file = new Blob([e.data], { type: `audio/${AUDIO_MESSAGE_EXT}` });
        setAudioFile(file);
      } catch (error: any) {
        captureError(error);
        toast.error(error.message || t('somethingWrong'));
      }
    },
    [t, setAudioFile],
  );

  const onErrorRecord = useCallback(() => {
    toast.error(t('somethingWrong'));
  }, []);

  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.addEventListener('dataavailable', onDataAvailable);
      mediaRecorder.addEventListener('error', onErrorRecord);

      mediaRecorder.state !== 'recording' && mediaRecorder.start();

      return () => {
        mediaRecorder.removeEventListener('dataavailable', onDataAvailable);
        mediaRecorder.removeEventListener('error', onErrorRecord);
      };
    }
  }, [mediaRecorder, onDataAvailable, onErrorRecord]);

  return {
    isRecording,
    audioFile,
    onStartRecord,
    onStopRecord,
    clearAudioFile,
    recordTime: formattingRecordTime(recordTime),
  };
};
