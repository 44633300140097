import { AuthFormSteps } from './types';

export function renderAuthNote(step: AuthFormSteps, number: string, t: any) {
  switch (step) {
    case AuthFormSteps.number:
      return t('authModal.enterNumber');
    case AuthFormSteps.smsCode:
      return `${t('authModal.smsNote')} ${number}`;
    case AuthFormSteps.promoCode:
      return t('authModal.enterPromoCode');
    default:
  }
}
