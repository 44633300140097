import Link from 'next/link';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';
import { CONDITION_DESKTOP, DEFAULT_AVATAR } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { MessageExtended, MessageVariants } from 'shared/common/types';
import { coverImageStyle, makeMessageTime } from 'shared/helpers';
import styled from 'styled-components';

interface FirstPostProps {
  message?: MessageExtended;
  variant?: MessageVariants;
  avatar?: string;
  createdAt?: string;
}

export const FirstPost: FC<FirstPostProps> = ({ message, variant, avatar, createdAt }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { showNewMessages } = useIntercom();

  const renderMessage = useMemo(
    () => (
      <Container $variant="companion">
        <MessageContainer $variant="companion">
          <Avatar $url="/img/ava-consulty-support-user_square.png" />
          <MessageBody $variant="companion">
            <MessageContent>
              <PostTextContainer>
                <StyledImage $variant="companion" src="/img/mail-onboard1.jpg" />
                <PostTextWrapper>
                  <p>Рады, что вы с Consulty!</p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <BoldText>Если вам нужна консультация</BoldText>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    1. Зайдите во вкладку меню <Link href={routes.topics}>Найти эксперта</Link>.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>2. Выберите нужную вам тему.</p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    3. Выберите эксперта в подходящей теме и договоритесь с ним о консультации. Или задайте в этой теме
                    вопрос и выбирайте эксперта по откликам на него. Они появятся во вкладке{' '}
                    <Link href={`${routes.chat}?activeTab=responses`}>Отклики</Link> мессенджера Consulty.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>Чтобы получить консультацию в любое время и в любом месте, скачайте наше приложение:</p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    <Link href="https://apps.apple.com/ru/app/consulty/id1590830075?l=en">App Store</Link>,{' '}
                    <Link href="https://play.google.com/store/apps/details?id=com.consulty">Google Play</Link>. Через
                    него можно отслеживать отклики экспертов и общаться с ними.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    Подробнее, о том, как работает сервис всегда можно почитать в разделе{' '}
                    <Link href={`${routes.profile}?activeTab=expertProfile`}>Справка</Link>.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    Если будут вопросы, смело обращайтесь в поддержку!{' '}
                    <a href="https://t.me/consultyonline">Telegram</a>,{' '}
                    <a href="mailto:support@consulty.online">support@consulty.online</a> или чат справа внизу{' '}
                    <span onClick={() => showNewMessages()}>на сайте</span>. В рабочие дни с 10:00 до 20:00 по МСК.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <BoldText>Consulty в режиме бета-теста</BoldText>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>Мы запустили Consulty совсем недавно, и до конца июня сервис находится в режиме бета-теста.</p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    Вам доступны все функции сервиса, а мы продолжаем собирать обратную связь от пользователей и делать
                    Consulty удобнее и полезнее.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <BoldText>Если вы хотите консультировать</BoldText>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    1. Оформите <Link href={`${routes.profile}?activeTab=expertProfile`}>профиль эксперта</Link> и
                    заключите с нами договор по SMS. Понадобится ваше фото для аватара и селфи с паспортом для договора.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    Договор — это допсоглашение к оферте, которую вы приняли. Consulty берет комиссию с консультаций,
                    которые эксперт оказал на сервисе. Для этого нам нужно заключить договор.{' '}
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>Обратите внимание, если прервать оформление на каком-то шаге, то информация не сохранится.</p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    2. Если у вас больше 1000 подписчиков в соцсети, вы можете получить промокод. Для этого укажите
                    ссылку на вашу соцсеть в профиле эксперта, после его оформления, и отправьте нам на проверку.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    Промокод дает право на одну бесплатную консультацию. Дарите его подписчикам и продвигайте свою
                    страницу эксперта Consulty в соцсетях на других интернет-ресурсах.{' '}
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>3. После оформления профиля, скачайте наше приложение:</p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    <Link href="https://apps.apple.com/ru/app/consulty/id1590830075?l=en">App Store</Link>,{' '}
                    <Link href="https://play.google.com/store/apps/details?id=com.consulty">Google Play</Link>. Через
                    него удобно получать заявки на консультации и проводить консультации в мессенджере Consulty.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    Обратите внимание, оформить профиль эксперта можно только на сайте. Но приложение поможет вам
                    консультировать в любое время и в любом месте.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    Подробнее о том, как работает сервис и как оказывать классные консультации читайте в{' '}
                    <Link href={routes.academyExperts}>Академии экспертов</Link>. Если будут вопросы, поможет поддержка:{' '}
                    <a href="https://t.me/consultyonline">Telegram</a>,{' '}
                    <a href="mailto:support@consulty.online">support@consulty.online</a> или чат справа внизу{' '}
                    <span onClick={() => showNewMessages()}>на сайте</span>. В рабочие дни с 10:00 до 20:00 по МСК.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>Удачных консультаций 🙌</p>
                </PostTextWrapper>
              </PostTextContainer>
            </MessageContent>
            <MessageStatusContainer>
              <MessageTime>{makeMessageTime(createdAt, language)}</MessageTime>
            </MessageStatusContainer>
            <ClearBoth />
          </MessageBody>
        </MessageContainer>
      </Container>
    ),
    [message, variant, avatar],
  );

  return renderMessage;
};

const Container = styled.div<{ $variant: MessageVariants }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  user-select: text;
  align-items: ${({ $variant }) => ($variant === 'me' ? 'flex-end' : 'flex-start')};
  margin-top: 15px;
  ${CONDITION_DESKTOP} {
    margin-top: 20px;
  }
  &:last-child {
    padding-top: 15px;
    ${CONDITION_DESKTOP} {
      padding-top: 35px;
    }
  }
`;
const MessageContainer = styled.div<{ $variant: MessageVariants }>`
  max-width: ${({ $variant }) => ($variant === 'me' ? '80%' : '90%')};
  position: relative;
`;
const Avatar = styled.div<{ $url?: string }>`
  position: absolute;
  bottom: 0;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 18px;
  ${({ $url }) => ({ ...coverImageStyle($url || DEFAULT_AVATAR) })}
  ${CONDITION_DESKTOP} {
    border-radius: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;
const MessageBody = styled.div<{ $variant: MessageVariants }>`
  color: ${({ $variant }) => ($variant === 'me' ? 'var(--white)' : 'var(--text)')};
  background-color: ${({ $variant }) => ($variant === 'me' ? 'var(--purple)' : 'var(--gray10)')};
  margin-left: ${({ $variant }) => ($variant === 'me' ? 0 : '40px')};
  padding: 7px 12px;
  border-radius: 20px;
  ${CONDITION_DESKTOP} {
    padding: 10px 8px 10px 10px;
    margin-left: ${({ $variant }) => ($variant === 'me' ? 0 : '58px')};
    border-radius: ${({ $variant }) => ($variant === 'me' ? '10px 10px 2px 10px' : '10px 10px 10px 2px')};
  }
`;
const MessageContent = styled.span`
  position: relative;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 1.43;
  & a {
    color: inherit;
    text-decoration: underline;
  }
`;
const MessageStatusContainer = styled.div`
  float: right;
  margin-left: 10px;
  height: 20px;
  display: flex;
  align-items: center;
`;
const MessageTime = styled.span`
  margin-left: 3px;
  font-size: 10px;
  opacity: 0.66;
  line-height: 12px;
  min-width: 30px;
  ${CONDITION_DESKTOP} {
    font-style: italic;
    padding-right: 2px;
  }
`;

const ClearBoth = styled.div`
  clear: both;
`;

const PostTextWrapper = styled.div`
  margin-bottom: 10px;
`;

const BoldText = styled.p`
  font-weight: bold;
`;

const PostTextContainer = styled.div`
  a {
    color: var(--purple);
  }
  span {
    color: var(--purple);
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
`;

const StyledImage = styled.img<{ $variant: MessageVariants }>`
  max-width: ${({ $variant }) => ($variant === 'me' ? '70%' : '80%')};
  border-radius: 20px;
`;
