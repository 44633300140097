import { ArrowRightIcon } from 'app/assets/svg';
import React, { FC } from 'react';
import styled from 'styled-components';

interface LinkButtonProps {
  text: string;
  icon: React.ReactNode;
  onClick: () => void;
}

export const LinkButton: FC<LinkButtonProps> = ({ text, icon, onClick }) => (
  <Container onClick={onClick}>
    <IconContainer>{icon}</IconContainer>
    <Text>{text}</Text>
    <ArrowRightIcon width={8} height={12} />
  </Container>
);

const Container = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border-top: solid 1px var(--gray6);
`;
const IconContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Text = styled.span`
  flex-grow: 1;
  margin-left: 12px;
`;
