import {
  ChatSmallIcon,
  ClockIcon,
  LikeSmallIcon,
  ServiceIcon,
  StarSmallIcon,
  UnLikeSmallIcon,
  WarningGrayIcon,
} from 'app/assets/svg';
import { useAppDispatch } from 'hooks/redux';
import { useIsExpertInRoom } from 'hooks/useIsExpertInRoom';
import useWindowWidth from 'hooks/useWindowWidth';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useIntercom } from 'react-use-intercom';
import ym from 'react-yandex-metrika';
import { api, Consultation, ConsultationStatusEnum, InvoiceStatusEnum, UserCategoryMeta } from 'shared/api';
import { CONDITION_DESKTOP, DEFAULT_AVATAR, ENVIRONMENT_TYPE, XPlatformHeader } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { buildRoute, chooseEndingWord, coverImageStyle } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { Button } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';
import { Modal } from 'widgets/common';
import { ComplainModal, DisableOrderModal } from 'widgets/modals';
import { CreateConsultationModal } from 'widgets/modals/create-consultation-modal';

import { LinkButton } from './ui/link-button';

interface RoomAsideProps extends HTMLAttributes<HTMLDivElement> {
  chatRoomId: number | null;
  activeConsultation?: Consultation;
}

const sendCreateOfferAnalyticsData = () => {
  if (ENVIRONMENT_TYPE === 'production') {
    ym('reachGoal', ['cons_fromchat']);
  }
};

const sendRequestConsultationAnalyticsData = () => {
  if (ENVIRONMENT_TYPE === 'production') {
    ym('reachGoal', ['cons_need']);
  }
};

export const RoomAside: FC<RoomAsideProps> = ({ chatRoomId, activeConsultation, ...props }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('chat.page');
  const { t: tUtils } = useTranslation('utils');
  const width = useWindowWidth();
  const router = useRouter();
  const user = useSelector(selectors.profile.selectUser);
  const chatRoom = useSelector(selectors.chatRooms.selectChatRoomById(chatRoomId));
  const consultations = useSelector(selectors.consultationsChatRoom.selectConsultations);
  const isExpertInRoom = useIsExpertInRoom(chatRoom);
  const [isCreateConsultModalOpen, setIsCreateConsultModalOpen] = useState(false);
  const [isChannelSupportModalOpen, setChannelSupportModalOpen] = useState(false);
  const [isEndConsultModalOpen, setIsEndConsultModalOpen] = useState(false);
  const [isShowDisableOrderModal, setIsShowDisableOrderModal] = useState(false);
  const isComplainModalOpen = useSelector(selectors.app.selectIsVisibleComplainModal);
  const [expertCategories, setExpertCategories] = useState<UserCategoryMeta[]>([]);

  const isPendingConsultationStatus = useMemo(
    () =>
      consultations.some((c) =>
        [ConsultationStatusEnum.PendingPayment, ConsultationStatusEnum.PendingStart].includes(c.status),
      ),
    [consultations],
  );

  const isDeletedCompanion = useMemo(() => {
    if (chatRoom) {
      return Boolean(chatRoom.client?.deletedAt) || Boolean(chatRoom.expert?.deletedAt);
    }
  }, [chatRoom]);

  const consultationDuration = useMemo(() => {
    if (activeConsultation) {
      return t('aside.howLong', {
        count: activeConsultation.messagesLimit,
        endMessage: chooseEndingWord(activeConsultation.messagesLimit, [
          t('endMessage.one'),
          t('endMessage.two'),
          t('endMessage.many'),
        ]),
        time: activeConsultation.messagesLimit / 20,
        endHours: chooseEndingWord(activeConsultation.messagesLimit / 20, [
          t('endHours.one'),
          t('endHours.two'),
          t('endHours.many'),
        ]),
      });
    }
  }, [t, activeConsultation]);

  const renderRequestButtonText = useCallback(() => {
    if (isPendingConsultationStatus) {
      return isExpertInRoom ? t('aside.buttons.requestSends.expert') : t('aside.buttons.requestSends.user');
    }
    return isExpertInRoom ? t('aside.buttons.request.expert') : t('aside.buttons.request.user');
  }, [isExpertInRoom, isPendingConsultationStatus]);

  const renderNotContractInfo = () => (isExpertInRoom ? t('aside.notContractInfoExpert') : t('aside.notContractInfo'));

  // intercom
  const { showNewMessages, update } = useIntercom();

  // end consultation handlers
  const handleEndConsultation = () => {
    setIsEndConsultModalOpen(true);
  };

  const confirmEndConsult = async () => {
    try {
      setIsEndConsultModalOpen(false);
      if (activeConsultation) {
        await api.V1ConsultationsApi.consultationsControllerStopConsultation(activeConsultation.id, {
          headers: {
            'X-Platform': XPlatformHeader,
          },
        });
      }
    } catch (error: any) {
      captureError(error);
      toast.error(error.message || tUtils('somethingWrong'));
    }
  };

  // request consultation handlers
  const handleRequestButtonClick = async () => {
    setIsCreateConsultModalOpen(true);
    if (!isExpertInRoom) {
      const { data } = await api.V1UsersApi.usersControllerGetUserOne(chatRoom!.expertId);
      setExpertCategories(data.categories || []);
    }
  };

  // request consultation handlers for mobile
  const handleRequestMobileButtonClick = async () => {
    if (!chatRoom?.expert?.isContractAccepted) {
      setIsShowDisableOrderModal(true);
    } else {
      setIsCreateConsultModalOpen(true);
    }
    if (!isExpertInRoom) {
      const { data } = await api.V1UsersApi.usersControllerGetUserOne(chatRoom!.expertId);
      setExpertCategories(data.categories || []);
    }
  };

  const handleChannelSupportButtonClick = async () => {
    setChannelSupportModalOpen(true);
  };

  const handleSupportTelegram = () => {
    setChannelSupportModalOpen(false);
    // router.push('https://t.me/consultyonline');
    window.open('https://t.me/consultyonline');
  };
  const handleSupportChat = () => {
    setChannelSupportModalOpen(false);
    showNewMessages();
  };
  const handleSupportMail = () => {
    setChannelSupportModalOpen(false);
    // router.push('mailto:support@consulty.online');
    window.open('mailto:support@consulty.online', '_blank');
  };

  const createConsultationChatOffer = async (userCategoryMetaId: number) => {
    try {
      const { data } = await api.V1ChatsApi.chatRoomsControllerCreateChatConsultationOffer(chatRoomId!, {
        userCategoryMetaId,
        redirectUrl: `${window.location.origin}${routes.chat}?activeTab=consult&roomId=${chatRoom?.id}`,
      });
      dispatch(actions.consultationsChatRoom.changeConsultationByIdOrAdd(data.consultation));
      dispatch(actions.chatRooms.addUnreadMark({ roomId: data.chatRoomId }));
      dispatch(
        actions.chatRooms.changeConsultationStatus({
          roomId: data.chatRoomId,
          consultationId: data.consultationId,
          status: ConsultationStatusEnum.PendingPayment,
        }),
      );
      sendCreateOfferAnalyticsData();
    } catch (error: any) {
      captureError(error);
      toast.error(error.message || tUtils('somethingWrong'));
    }
  };

  const requestingConsultation = async (userCategoryMetaId: number) => {
    try {
      const { data } = await api.V1ConsultationsApi.consultationsControllerCreateOneFromUserCategoryMeta(
        {
          userCategoryMetaId,
          redirectUrl: `${window.location.origin}${routes.chat}?activeTab=consult&roomId=${chatRoom?.id}`,
        },
        {
          headers: {
            'X-Platform': XPlatformHeader,
          },
        },
      );
      sendRequestConsultationAnalyticsData();
      const redirectUrl = data.invoices.find((invoice) => invoice.invoice?.status === InvoiceStatusEnum.Pending)
        ?.invoice?.checkoutUrl;
      if (redirectUrl) {
        setTimeout(() => {document.location.href = redirectUrl;},250);
      }
    } catch (error: any) {
      captureError(error);
      toast.error(error.message || tUtils('somethingWrong'));
    }
  };

  const handleClickProlongation = () => {
    dispatch(actions.app.setIsVisibleProlongationModal(true));
  };

  const handleClickExtraService = () => {
    dispatch(actions.app.setIsVisibleExtraServiceModal(true));
  };

  useEffect(() => {
    if (window.location.search.includes('consult') && width < 600) {
      update({ hideDefaultLauncher: true });
      return () => update({ hideDefaultLauncher: false });
    }
  }, [width]);

  useEffect(() => {
    update({ alignment: 'right', verticalPadding: 100 });
  }, []);
  const expertRating = chatRoom?.expert?.ratingMetaData?.InternalRating
    ? Number(chatRoom?.expert?.ratingMetaData?.InternalRating)
    : 0;
  return (
    <>
      <div {...props}>
        <DesktopContainer>
          {chatRoomId !== null && +chatRoomId === 0 ? (
            <TopBlock>
              <ChannelAvatar $url="/img/ava-consulty-support-user_square.png" />
              <SubTitle>{t('aside.channel.newsChannel')}</SubTitle>
              <Name>{t('aside.channel.consulty')}</Name>
            </TopBlock>
          ) : (
            <TopBlock>
              <Avatar $url={isExpertInRoom ? chatRoom?.client?.avatarUrl : chatRoom?.expert?.avatarUrl}>
                {!isExpertInRoom && (
                  <Rate>
                    <StarSmallIcon />
                    <span>{expertRating?.toFixed(1).replace(/'.'/g, ',') || '0,00'}</span>
                  </Rate>
                )}
              </Avatar>
              <SubTitle>
                {isDeletedCompanion
                  ? tUtils('userIsDeleted')
                  : isExpertInRoom
                  ? t('aside.youConsult')
                  : t('aside.expertConsultYou')}
              </SubTitle>
              <Name>
                {isExpertInRoom ? (
                  `${chatRoom?.client?.firstName || ''} ${chatRoom?.client?.lastName || ''}`
                ) : (
                  <Link href={buildRoute(routes.expert, { id: chatRoom?.expertId || '' })} passHref>
                    <a>
                      {chatRoom?.expert?.firstName || ''} {chatRoom?.expert?.lastName || ''}
                    </a>
                  </Link>
                )}
              </Name>
              {!isExpertInRoom ? (
                <>
                  <TableRow>
                    <TableName>{t('aside.allConsultation')}</TableName>
                    <TableValue>{chatRoom?.expert?.expertConsultationsCount}</TableValue>
                  </TableRow>
                  <TableRow>
                    <TableName>{t('aside.reviews')}</TableName>
                    <TableValue>
                      <span>{chatRoom?.expert?.expertLikesCount}</span>
                      <LikeSmallIcon />
                      <span>{chatRoom?.expert?.expertDislikesCount}</span>
                      <UnLikeSmallIcon />
                    </TableValue>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableName>{t('aside.reviews')}</TableName>
                  <TableValue>
                    <span>{chatRoom?.client?.clientLikesCount}</span>
                    <LikeSmallIcon />
                    <span>{chatRoom?.client?.clientDislikesCount}</span>
                    <UnLikeSmallIcon />
                  </TableValue>
                </TableRow>
              )}
            </TopBlock>
          )}

          {!isDeletedCompanion &&
            chatRoomId !== null &&
            +chatRoomId !== 0 &&
            (activeConsultation ? (
              <>
                <Separator />
                <StyledConsultation>
                  <BlockTitle>{t('aside.thisConsult')}</BlockTitle>
                  <ConsultInfo>
                    <Label>{t('aside.theme')}</Label>
                    <ConsultValue>{activeConsultation.category?.name}</ConsultValue>
                  </ConsultInfo>
                  <ConsultInfo>
                    <Label>{t('aside.duration')}</Label>
                    <ConsultValue>{consultationDuration}</ConsultValue>
                  </ConsultInfo>
                  <ConsultButton>
                    <Button bordered block onClick={handleEndConsultation}>
                      {t('aside.buttons.endConsult')}
                    </Button>
                  </ConsultButton>
                </StyledConsultation>
                <Separator />

                {isExpertInRoom && (
                  <>
                    <Additional>
                      <BlockTitle>{t('aside.additionally')}</BlockTitle>
                      <LinkButton
                        icon={<ClockIcon />}
                        text={t('aside.extendConsult')}
                        onClick={handleClickProlongation}
                      />
                      <LinkButton
                        icon={<ServiceIcon />}
                        text={t('aside.extraService')}
                        onClick={handleClickExtraService}
                      />
                    </Additional>
                    <Separator />
                  </>
                )}
              </>
            ) : (
              <>
                <RequestButtonContainer>
                  <Button
                    bordered
                    block
                    disabled={!chatRoom?.expert?.isContractAccepted || isPendingConsultationStatus}
                    fontSize="14px"
                    hasPaddingHorizontal={false}
                    onClick={handleRequestButtonClick}
                  >
                    {renderRequestButtonText()}
                  </Button>
                </RequestButtonContainer>
                {!chatRoom?.expert?.isContractAccepted && <NotContractText>{renderNotContractInfo()}</NotContractText>}
              </>
            ))}

          {chatRoomId !== null && +chatRoomId === 0 && (
            <RequestButtonContainer>
              <Button
                bordered
                block
                disabled={isPendingConsultationStatus}
                fontSize="14px"
                hasPaddingHorizontal={false}
                onClick={handleChannelSupportButtonClick}
              >
                {t('aside.channel.support')}
              </Button>
            </RequestButtonContainer>
          )}
          <MobileBanner onClick={() => router.push({ pathname: routes.mobile })}>
            <MobileBannerTitle>{t('aside.mobileBannerTitle')}</MobileBannerTitle>
            <MobileBannerContent>
              <MobileBannerContentInner>
                <MobileBannerText>{t('aside.mobileBannerText')}</MobileBannerText>
                <MobileBannerText>{t('aside.mobileBannerTex2')}</MobileBannerText>
              </MobileBannerContentInner>
              <MobileBannerImg>
                <Image src="/img/hand_app_right.png" layout="fill" />
              </MobileBannerImg>
            </MobileBannerContent>
          </MobileBanner>
          <Support>
            <BlockTitle>{t('aside.support')}</BlockTitle>
            <LinkButton
              icon={<WarningGrayIcon />}
              text={t('aside.problems')}
              onClick={() => {
                showNewMessages();
              }}
            />
            <LinkButton
              icon={<ChatSmallIcon />}
              text={t('aside.answers')}
              onClick={() => router.push({ pathname: routes.profile, query: { activeTab: 'support' } })}
            />
          </Support>
        </DesktopContainer>

        <MobileContainer>
          <PaymentConsult>
            {isDeletedCompanion ? tUtils('userIsDeleted') : t('aside.mobilePaymentConsult')}
          </PaymentConsult>
          {!isDeletedCompanion && (
            <Button
              size="small"
              fontSize="12px"
              disabled={isPendingConsultationStatus}
              onClick={activeConsultation ? handleEndConsultation : handleRequestMobileButtonClick}
            >
              {activeConsultation
                ? t('aside.buttons.mobile.endConsult')
                : isExpertInRoom
                ? t('aside.buttons.mobile.request.expert')
                : t('aside.buttons.mobile.request.client')}
            </Button>
          )}
        </MobileContainer>
      </div>
      {isEndConsultModalOpen && (
        <Modal isVisible={isEndConsultModalOpen} onClose={() => setIsEndConsultModalOpen(false)}>
          <ConfirmEndTitle>{t('modals.confirmEndConsultation.title')}</ConfirmEndTitle>
          <ConfirmEndAction>
            <Button hasPaddingHorizontal={false} width="120px" onClick={confirmEndConsult}>
              {t('modals.confirmEndConsultation.yes')}
            </Button>
            <Button bordered hasPaddingHorizontal={false} width="120px" onClick={() => setIsEndConsultModalOpen(false)}>
              {t('modals.confirmEndConsultation.no')}
            </Button>
          </ConfirmEndAction>
        </Modal>
      )}

      {isChannelSupportModalOpen && (
        <Modal isVisible={isChannelSupportModalOpen} onClose={() => setChannelSupportModalOpen(false)}>
          <SupportTitle>{t('aside.channel.supportTitle')}</SupportTitle>
          <SupportSubTitle>{t('aside.channel.supportSubTitle')}</SupportSubTitle>
          <SupportChoose>{t('aside.channel.supportChoose')}</SupportChoose>
          <SupportAction>
            <SupportButtonWrapper>
              <Button bordered hasPaddingHorizontal={false} width="100%" onClick={handleSupportTelegram}>
                {t('aside.channel.telegram')}
              </Button>
            </SupportButtonWrapper>
            {/* <SupportButtonWrapper>
              <Button bordered hasPaddingHorizontal={false} width="100%" onClick={handleSupportChat}>
                {t('aside.channel.chat')}
              </Button>
            </SupportButtonWrapper> */}
            <Button bordered hasPaddingHorizontal={false} width="100%" onClick={handleSupportMail}>
              {t('aside.channel.mail')}
            </Button>
          </SupportAction>
        </Modal>
      )}

      <ComplainModal
        isVisible={isComplainModalOpen}
        onClose={() => dispatch(actions.app.setIsVisibleComplainModal(false))}
      />
      <CreateConsultationModal
        isVisible={isCreateConsultModalOpen}
        onClose={() => setIsCreateConsultModalOpen(false)}
        IsExpertInRoom={isExpertInRoom}
        expertCategories={isExpertInRoom ? user.categories : expertCategories}
        handleSubmitModal={isExpertInRoom ? createConsultationChatOffer : requestingConsultation}
      />
      <DisableOrderModal show={isShowDisableOrderModal} onHide={() => setIsShowDisableOrderModal(false)} />
    </>
  );
};

// container
const DesktopContainer = styled.div`
  display: none;

  ${CONDITION_DESKTOP} {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 100%;
    border-left: 1px solid var(--gray6);
    border-right: 1px solid var(--gray6);
  }
`;
const TopBlock = styled.div`
  padding: 31px 20px 26px;
  text-align: center;
`;
const StyledConsultation = styled.div`
  padding: 20px;
`;
const Additional = styled.div`
  padding: 20px;
`;
const Support = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
`;
const Separator = styled.div`
  width: 100%;
  height: 3px;
  flex-shrink: 0;
  background-color: #ebebeb;
`;
const BlockTitle = styled.h3`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 17px;
`;

// top-block
const Avatar = styled.div<{ $url?: string }>`
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 15px;
  margin: 0 auto 20px;
  ${({ $url }) => ({ ...coverImageStyle($url || DEFAULT_AVATAR) })}
`;

const ChannelAvatar = styled.div<{ $url?: string }>`
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0 auto 20px;
  ${({ $url }) => ({ ...coverImageStyle($url || DEFAULT_AVATAR) })}
`;

const Rate = styled.div`
  position: absolute;
  height: 22px;
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  padding: 0 13px 0 6px;
  border-radius: 11px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  font-size: 13px;
  color: #2a0065;

  & > span {
    margin-left: 5px;
  }
`;
const SubTitle = styled.p`
  font-size: 13px;
  color: rgba(60, 60, 67, 0.6);
`;
const Name = styled.p`
  margin: 10px 0 30px;
  font-size: 17px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;

  & > a {
    &:hover {
      text-decoration: underline;
    }
  }
`;
const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  line-height: 20px;
  align-items: center;
  color: var(--text);
`;
const TableName = styled.p`
  font-size: 14px;
`;
const TableValue = styled.div`
  font-size: 15px;
  display: flex;
  align-items: center;

  & > span {
    margin-right: 4px;
    margin-left: 10px;
  }

  & > svg {
    margin-top: -2px;
  }
`;

// consultation
const ConsultInfo = styled.div`
  margin-bottom: 20px;
`;
const Label = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: var(--gray2);
`;
const ConsultValue = styled.div`
  font-size: 13px;
  line-height: 20px;
`;
const ConsultButton = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
`;
const RequestButtonContainer = styled.div`
  margin: 0 20px;
`;

// mobile container
const MobileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 15px;
  width: 100%;
  height: 50px;
  background-color: var(--white);
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  ${CONDITION_DESKTOP} {
    display: none;
  }
`;
const PaymentConsult = styled.p`
  font-size: 14px;
  line-height: 20px;
`;

// modals
const ConfirmEndTitle = styled.h3`
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
`;
const ConfirmEndAction = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MobileBanner = styled.div`
  width: 277px;
  min-height: 184px;
  margin: 50px auto 0 auto;
  cursor: pointer;
  background-color: #f7f6ff;
  border-radius: 12px;
  padding-top: 22px;
  padding-left: 20px;
`;

const MobileBannerTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 12px;
`;

const MobileBannerContent = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`;

const MobileBannerContentInner = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileBannerText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  margin-bottom: 12px;
  color: #79818c;
  white-space: pre-wrap;
`;

const MobileBannerImg = styled.div`
  position: relative;
  margin-top: 12px;
  height: 100%;

  img {
    border-radius: 12px;
  }
`;

const SupportTitle = styled.p`
  font-size: 17px;
  font-weight: bold;
  text-align: start;
  margin-bottom: 10px;
`;

const SupportSubTitle = styled.p`
  font-size: 15px;
  text-align: start;
`;

const SupportChoose = styled.p`
  font-size: 15px;
  text-align: start;
  margin-bottom: 20px;
`;

const SupportAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const SupportButtonWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const NotContractText = styled.span`
  text-align: center;
  padding: 30px 20px 0 17px;
  color: var(--gray);
  font-size: 11px;
`;
