import { Camera } from 'app/assets/svg';
import { useAppDispatch } from 'hooks/redux';
import { usePrepareImageToUpload } from 'hooks/usePrepareImageToUpload';
import { useTranslation } from 'next-i18next';
import React, { ChangeEvent, FC } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { coverImageStyle } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { selectors } from 'store/ducks/profile';
import { createContentChangeRequest, fetchUpdateIamProfile } from 'store/ducks/profile/actions';
import { selectContentChangeRequest } from 'store/ducks/profile/selectors';
import styled from 'styled-components';

export const PhotoUploadForm: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('profile.page');

  const { isExpert, ...user } = useSelector(selectors.selectUser);

  const contentChangeRequest = useSelector(selectContentChangeRequest);
  const { selectFile, uploadFileToCloud } = usePrepareImageToUpload();
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    selectFile(files, async ({ file }) => {
      try {
        if (file) {
          const avatarUrl = await uploadFileToCloud(file);
          if (isExpert) {
            await dispatch(createContentChangeRequest({ avatarUrl }));
          } else {
            await dispatch(fetchUpdateIamProfile({ avatarUrl }));
          }
          toast.success(t('requestHasBeenSend'));
        }
      } catch (error: any) {
        captureError(error);
        toast.error(error.message || t('somethingWrong'));
      }
    });
  };

  let avatarUrl: string | null;

  if (contentChangeRequest && contentChangeRequest.avatarUrl) {
    avatarUrl = contentChangeRequest.avatarUrl;
  } else {
    avatarUrl = user.avatarUrl || null;
  }

  return (
    <Container>
      <Title>{t('photo')}</Title>
      <Note>{t('photoDescription')}</Note>
      <UploadAvatarContainer>
        <Avatar
          htmlFor="avatar"
          $url={avatarUrl || null}
          $disable={!!contentChangeRequest && !!contentChangeRequest.avatarUrl}
        >
          {!avatarUrl && <Camera />}
        </Avatar>
        <Text htmlFor="avatar">Загрузить фото</Text>
      </UploadAvatarContainer>
      <input id="avatar" onChange={onChange} type="file" accept="image/png, image/jpeg" style={{ display: 'none' }} />
    </Container>
  );
};

const Avatar = styled.label<{ $url: string | null; $disable?: boolean }>`
  margin-bottom: 0;
  border-radius: 15px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  opacity: ${({ $disable }) => ($disable ? '0.5' : '1')};
  background-color: ${({ $url }) => ($url ? 'transparent' : 'var(--purple)')};
  ${({ $url }) => ({ ...coverImageStyle($url) })}
  :hover {
    cursor: pointer;
  }
  & > svg {
    transform: scale(2);
  }
`;

const Text = styled.label`
  padding: 11px 17px 11px 17px;
  border-radius: 20px;
  background-color: #f7f6ff;
  color: var(--purple);
  cursor: pointer;
  margin-left: 30px;
  font-size: 15px;
`;

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  max-width: 500px;
  letter-spacing: normal;
  padding-bottom: 30px;
  color: var(--gray);

  & > a {
    color: var(--purple);
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`;
const Container = styled.div`
  margin-top: 40px;

  input {
    width: 60%;
  }
  button {
    margin-top: 40px;
  }
`;
const Title = styled.h2`
  margin: 0;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black2);
  padding-bottom: 10px;
`;

const UploadAvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
