import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

interface IWarningModalProps {
  isIndividual: boolean;
}

export const WarningModal: FC<IWarningModalProps> = ({ isIndividual }) => {
  const isVisible = useSelector(selectors.app.selectIsVisibleWarningModal);
  const dispatch = useDispatch();
  const { t } = useTranslation('utils');

  const onClose = () => {
    dispatch(actions.app.setIsVisibleWarningModal(false));
  };
  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <Container>
        <Description>
          {isIndividual
            ? t('modals.warningModal.descriptionIndividual')
            : t('modals.warningModal.descriptionSelfEmployed')}
        </Description>
        <SubmitButton block onClick={onClose}>
          {t('modals.warningModal.button')}
        </SubmitButton>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  text-align: center;
`;
const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
  text-align: left;
  white-space: break-spaces;
`;

const SubmitButton = styled(Button)`
  margin-top: 30px;
`;
