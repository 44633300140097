import { useTranslation } from 'next-i18next';
import React, { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { commaNumberFormat, onKeyDownNumber } from 'shared/helpers';
import { Button, Input, Select } from 'shared/ui';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

type ProlongationType = 'paid' | 'free';
type FormType = {
  type: { value: ProlongationType; label: string };
  price: string;
};

interface ProlongationModalProps {
  isVisible: boolean;
  onClose: () => void;
  handleProlongation: ({ price }: { price: number }) => void;
}

export const ProlongationModal: FC<ProlongationModalProps> = ({ isVisible, onClose, handleProlongation }) => {
  if (!isVisible) {
    return null;
  }

  const { t } = useTranslation('chat.page');
  const { control, handleSubmit, setError, watch } = useForm<FormType>({ mode: 'onChange' });
  const watchTypeProlongation = watch('type');
  const watchPrice = watch('price');

  const selectOptions = useMemo(
    () => [
      { value: 'paid', label: t('modals.prolongation.selectValues.paid') },
      { value: 'free', label: t('modals.prolongation.selectValues.free') },
    ],
    [t],
  );

  const onSubmit = (data: FormType) => {
    if (data.type.value === 'free') {
      handleProlongation({ price: 0 });
    } else {
      if (!data.price) {
        setError('price', t('errors.mustBeANumber'));
      }
      handleProlongation({ price: parseInt(data.price.replace(/' '/g, ''), 10) });
    }
    onClose();
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <Title>{t('modals.prolongation.title')}</Title>
        <Description>{t('modals.prolongation.description')}</Description>
        <Controller
          control={control}
          name="type"
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              options={selectOptions}
              placeholder={t('modals.prolongation.selectPlaceholder')}
              instanceId="prolongation-type-select"
              maxMenuHeight={100}
            />
          )}
        />
        {watchTypeProlongation?.value === 'paid' && (
          <ModalInputContainer>
            <Controller
              control={control}
              name="price"
              defaultValue=""
              rules={{
                validate: {
                  isNumber: (v) => !!parseInt(v, 10) || 'errors.mustBeANumber',
                  maxLength: (v) => v.length < 7 || 'errors.tooLong',
                  minLength: (v) => v.length > 1 || 'errors.tooSmall',
                },
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Input
                  value={value}
                  onChange={(e) => onChange(commaNumberFormat(e.currentTarget.value))}
                  placeholder={t('modals.prolongation.pricePlaceholder')}
                  error={error?.message && t(error?.message)}
                  onKeyDown={onKeyDownNumber}
                />
              )}
            />
            <Currency>{t('modals.prolongation.priceCurrency')}</Currency>
          </ModalInputContainer>
        )}

        <SubmitButton
          block
          type="submit"
          disabled={!watchTypeProlongation || (watchTypeProlongation?.value === 'paid' && !watchPrice)}
        >
          {t('modals.prolongation.button')}
        </SubmitButton>
      </Container>
    </Modal>
  );
};

const Container = styled.form`
  text-align: center;
`;
const Title = styled.h3`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 15px;
`;
const Description = styled.p`
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 20px;
`;
const ModalInputContainer = styled.div`
  margin-top: 20px;
  position: relative;
`;
const Currency = styled.div`
  position: absolute;
  color: var(--gray);
  right: 21px;
  top: 0;
  height: 50px;
  line-height: 50px;
`;
const SubmitButton = styled(Button)`
  margin-top: 20px;
`;
