import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { About } from 'shared/api';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { captureError } from 'shared/helpers/captureError';
import { Button } from 'shared/ui';
import { actions } from 'store/ducks/app';
import styled from 'styled-components';

import { supportContent } from './support-content';

export const Support: FC = () => {
  const { t } = useTranslation('profile.page');
  const dispatch = useAppDispatch();

  const [about, setAbout] = useState<About | null>(null);

  const getAbout = async () => {
    await dispatch(actions.getInfoAboutServiceAsync())
      .then(unwrapResult)
      .then((res) => setAbout(res))
      .catch((e) => captureError(e));
  };

  useEffect(() => {
    getAbout();
  }, []);

  if (!about) {
    return null;
  }

  return (
    <Container>
      <MainTitle>{t('supportTab.title')}</MainTitle>
      <Title>{t('supportTab.mostValued')}</Title>

      <ItemsContainer>
        {supportContent.map((item, index) => (
          <Item key={index}>
            <ScrollLink to={index.toString()} smooth offset={-100} duration={500}>
              {item.question}
            </ScrollLink>
          </Item>
        ))}
      </ItemsContainer>

      <Block $mb={90}>
        <Title>{t('supportTab.connectWithSupport')}</Title>
        <Note>{t('supportTab.connectWithSupportNote')}</Note>
        <a href="mailto:support@consulty.online" target="_blank" rel="noreferrer">
          <Button bordered>{t('supportTab.write')}</Button>
        </a>
      </Block>

      {supportContent.map((item, index) => (
        <Block $mb={60} key={index} id={index.toString()}>
          <Title>{item.question}</Title>
          <Note dangerouslySetInnerHTML={{ __html: item.answer }} />
          {/* {item.answer.map((a, i) => (
            <Note key={i}>{a}</Note>
          ))} */}
        </Block>
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 90px;

  ${CONDITION_DESKTOP} {
    padding-left: 0;
  }
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 20px;
`;

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gray);
  margin-bottom: 20px;
  max-width: 600px;
  a {
    color: #8b33ff;
  }
`;

const MainTitle = styled(Title)`
  font-size: 25px;
  margin-bottom: 40px;
`;

const Item = styled.div`
  & > a {
    color: var(--purple);

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const ItemsContainer = styled.div`
  margin-bottom: 60px;

  & > div {
    margin-bottom: 15px;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`;

const Block = styled.div<{ $mb: number }>`
  margin-bottom: ${({ $mb }) => $mb}px;
`;
