import * as React from 'react';

function SvgProfileVerification(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="uzpnnk7hba">
          <stop stopColor="#1FABED" offset="0%" />
          <stop stopColor="#1B6DED" offset="100%" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          d="m27.946 7.486-3.61-1.606a.521.521 0 0 1-.265-.265l-1.606-3.61-3.93.41a.525.525 0 0 1-.36-.096L14.975 0l-3.2 2.319a.525.525 0 0 1-.358.097l-3.931-.411-1.606 3.61a.517.517 0 0 1-.265.264l-3.61 1.607.41 3.93a.522.522 0 0 1-.096.36L0 14.976l2.319 3.199c.075.104.11.232.096.36l-.41 3.93 3.61 1.606a.521.521 0 0 1 .265.264l1.606 3.61 3.93-.41c.018 0 .036-.003.052-.003.11 0 .217.035.306.1l3.201 2.318 3.2-2.318a.52.52 0 0 1 .36-.097l3.93.41 1.606-3.61a.519.519 0 0 1 .264-.264l3.61-1.607-.41-3.93a.52.52 0 0 1 .097-.359l2.318-3.2-2.318-3.2a.52.52 0 0 1-.096-.359l.41-3.93z"
          fill="url(#uzpnnk7hba)"
        />
        <path
          d="M23.251 11.283 12.466 21.59a.563.563 0 0 1-.77 0L7.07 17.17a.51.51 0 0 1-.16-.369.51.51 0 0 1 .16-.369l1.541-1.468a.563.563 0 0 1 .77 0l2.699 2.575 8.858-8.467a.563.563 0 0 1 .77 0l1.542 1.473a.504.504 0 0 1 0 .737z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default SvgProfileVerification;
