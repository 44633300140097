import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store/ducks';

type useCompletedInfoProfileProps = {
  shownOnlyEmptyInfo?: boolean;
};

export const useCompletedInfoProfile = ({
  shownOnlyEmptyInfo = false,
}: useCompletedInfoProfileProps): [boolean, boolean, (v: boolean) => void] => {
  const user = useSelector(selectors.profile.selectUser);
  const isCompletedInfoProfile = useMemo(() => user.aboutSelf && user.avatarUrl, [user]);
  const isExistContract = useMemo(() => user.isContractAccepted, [user]);

  const [isShowBanner, setIsShowBanner] = useState(false);

  useEffect(() => {
    if (shownOnlyEmptyInfo) {
      setIsShowBanner(!isCompletedInfoProfile);
    } else {
      setIsShowBanner(!isCompletedInfoProfile || !isExistContract);
    }
  }, [isCompletedInfoProfile]);

  return [!!isCompletedInfoProfile, isShowBanner, setIsShowBanner];
};
