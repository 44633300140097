import { BackArrowPurple, MoreMenuButton } from 'app/assets/svg';
import { useIsExpertInRoom } from 'hooks/useIsExpertInRoom';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, HTMLAttributes, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CONDITION_DESKTOP, DEFAULT_AVATAR } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { coverImageStyle, makeLastExitTimeStatus } from 'shared/helpers';
import { selectors } from 'store/ducks';
import styled from 'styled-components';

import { ChatMenu } from '../chat-menu';

interface RoomHeaderProps extends HTMLAttributes<HTMLDivElement> {
  chatRoomId: number | null;
}

export const RoomHeader: FC<RoomHeaderProps> = ({ chatRoomId, ...props }) => {
  const { t } = useTranslation('chat.page');
  const { t: tUtils } = useTranslation('utils');
  const router = useRouter();
  const chatRoom = useSelector(selectors.chatRooms.selectChatRoomById(chatRoomId));
  const onlineMembers = useSelector(selectors.chatRooms.selectOnlineMembersIds);
  const isExpertInRoom = useIsExpertInRoom(chatRoom);
  const [isChatMenuOpen, setIsChatMenuOpen] = useState(false);
  const chatMenuButtonRef = useRef<HTMLDivElement>(null);

  useOutsideClick({ ref: chatMenuButtonRef, onOutsideClick: () => setIsChatMenuOpen(false) });

  const companion = isExpertInRoom ? chatRoom?.client : chatRoom?.expert;

  const goBack = () => router.replace(routes.chat);

  const toggleChatMenu = () => {
    setIsChatMenuOpen(!isChatMenuOpen);
  };

  const renderNetworkStatus = () => {
    if (!chatRoom || !companion) {
      return;
    }

    return onlineMembers.includes(companion.id)
      ? t('networkStatus.online')
      : makeLastExitTimeStatus({
          date: companion.lastExitTime,
          t: tUtils,
        });
  };

  return (
    <Container {...props}>
      <BackButton onClick={goBack} />
      <Avatar $url={companion?.avatarUrl} />
      {chatRoomId !== null && +chatRoomId === 0 ? (
        <Text>
          <Name>{t('aside.channel.consulty')}</Name>
          <NetworkStatus>{t('aside.channel.newsChannel')}</NetworkStatus>
        </Text>
      ) : (
        <Text>
          <Name>{`${companion?.firstName || ''} ${companion?.lastName || ''}`}</Name>
          <NetworkStatus>{chatRoom?.isCompanionWritten ? t('isWritten') : renderNetworkStatus()}</NetworkStatus>
        </Text>
      )}
      {chatRoomId !== null && +chatRoomId !== 0 && (
        <MenuButton onClick={toggleChatMenu} ref={chatMenuButtonRef}>
          <MoreMenuButton />
          {isChatMenuOpen && (
            <ChatMenu
              chatRoomId={chatRoomId!}
              isExpertInRoom={isExpertInRoom}
              expertId={chatRoom?.expertId}
              isMuteChat={Boolean(isExpertInRoom ? chatRoom?.isExpertMuteChat : chatRoom?.isClientMuteChat)}
            />
          )}
        </MenuButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 60px;
  display: flex;
  padding: 0 10px 0 15px;
  align-items: center;
  width: 100vw;
  position: relative;
  z-index: 1;
  ${CONDITION_DESKTOP} {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    padding: 0 20px;
    width: 100%;
  }
`;
const BackButton = styled(BackArrowPurple)`
  ${CONDITION_DESKTOP} {
    display: none;
  }
`;
const Avatar = styled.div<{ $url?: string }>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: var(--gray3);
  margin: 0 10px 0 20px;
  ${({ $url }) => ({ ...coverImageStyle($url || DEFAULT_AVATAR) })}
  ${CONDITION_DESKTOP} {
    display: none;
  }
`;
const Text = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;
const Name = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  ${CONDITION_DESKTOP} {
    font-weight: 500;
    font-size: 13px;
  }
`;
const NetworkStatus = styled.div`
  font-size: 14px;
  line-height: 15px;
  color: var(--gray);
  ${CONDITION_DESKTOP} {
    font-size: 12px;
  }
`;
const MenuButton = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  ${CONDITION_DESKTOP} {
    background-color: #f4f3f9;
  }
  &:hover {
    background-color: #f4f3f9;
  }
`;
