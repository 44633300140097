import React, { FC, useState } from 'react';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { Checkbox } from 'shared/ui';
import styled from 'styled-components';

interface RubricNewProps {
  rubric: any;
}

export const RubricNew: FC<RubricNewProps> = ({ rubric }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Container>
      <Header>
        <TitleContainer>
          <Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
          <Title>
            <span>{rubric.name}</span>
          </Title>
        </TitleContainer>
      </Header>
    </Container>
  );
};

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  transition: 0.3s ease;
  border-radius: 4px;
  padding: 10px;
  gap: 10px;
  ${CONDITION_DESKTOP} {
    padding: 10px 24px 10px 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;

  :hover {
    cursor: pointer;
  }
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--text);
  margin-left: 0;
  display: flex;
  align-items: center;
  ${CONDITION_DESKTOP} {
    margin-left: 5px;
  }
`;
