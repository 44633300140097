import { useRouter } from 'next/router';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';
import { CONDITION_DESKTOP, DEFAULT_AVATAR } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { MessageExtended, MessageVariants } from 'shared/common/types';
import { coverImageStyle, makeMessageTime } from 'shared/helpers';
import { Button } from 'shared/ui';
import styled from 'styled-components';

interface ThirdPostProps {
  message?: MessageExtended;
  variant?: MessageVariants;
  avatar?: string;
  createdAt?: string;
}

export const ThirdPost: FC<ThirdPostProps> = ({ message, variant, avatar, createdAt }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { showNewMessages } = useIntercom();

  const router = useRouter();

  const renderMessage = useMemo(
    () => (
      <Container $variant="companion">
        <MessageContainer $variant="companion">
          <Avatar $url="/img/ava-consulty-support-user_square.png" />
          <MessageBody $variant="companion">
            <MessageContent>
              <PostTextContainer>
                <StyledImage $variant="companion" src="/img/mail-onboard3.jpg" />
                <PostTextWrapper>
                  <p>
                    Напоминаем, что сейчас ваш профиль не видят другие пользователи. Они не смогут обратиться к вам за
                    консультацией.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    Чтобы профиль появился на сайте Consulty, и вы смогли зарабатывать, нужно заключить с нами договор
                    (допсоглашение к оферте). Это можно сделать в личном кабинете за 5 минут. Без подписания бумажных
                    документов, просто по SMS.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <BoldText>Зачем нужен договор</BoldText>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    По закону нам нужно основание для выплаты денег, которые вы заработаете через Consulty. Таким
                    основанием служит договор.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    При оформлении договора мы должны вас идентифицировать, поэтому потребуется ваше селфи с паспортом,
                    где видно 2 и 3 страницы (главный разворот). Фото увидят только сотрудники сервиса. Не переживайте,
                    ваши личные данные в безопасности.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    Благодаря селфи вы заключаете электронный договор без бумаги, просто по SMS. Сможете оказывать
                    платные консультации и получать деньги.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <ButtonWrapper>
                    <Button
                      bordered
                      block
                      disabled={false}
                      fontSize="14px"
                      hasPaddingHorizontal={false}
                      onClick={() => router.push(routes.contract)}
                    >
                      Заключить договор по SMS
                    </Button>
                  </ButtonWrapper>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>
                    Если будут вопросы, смело обращайтесь в поддержку!{' '}
                    <a href="https://t.me/consultyonline">Telegram</a>,{' '}
                    <a href="mailto:support@consulty.online">support@consulty.online</a> или чат справа внизу{' '}
                    <span onClick={() => showNewMessages()}>на сайте</span>. С 10:00 до 20:00 по МСК.
                  </p>
                </PostTextWrapper>
                <PostTextWrapper>
                  <p>Удачных консультаций 🙌</p>
                </PostTextWrapper>
              </PostTextContainer>
            </MessageContent>
            <MessageStatusContainer>
              <MessageTime>{makeMessageTime(createdAt, language)}</MessageTime>
            </MessageStatusContainer>
            <ClearBoth />
          </MessageBody>
        </MessageContainer>
      </Container>
    ),
    [message, variant, avatar],
  );

  return renderMessage;
};

const Container = styled.div<{ $variant: MessageVariants }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  user-select: text;
  align-items: ${({ $variant }) => ($variant === 'me' ? 'flex-end' : 'flex-start')};
  margin-top: 15px;
  ${CONDITION_DESKTOP} {
    margin-top: 20px;
  }
  &:last-child {
    padding-top: 15px;
    ${CONDITION_DESKTOP} {
      padding-top: 35px;
    }
  }
`;
const MessageContainer = styled.div<{ $variant: MessageVariants }>`
  max-width: ${({ $variant }) => ($variant === 'me' ? '80%' : '90%')};
  position: relative;
`;
const Avatar = styled.div<{ $url?: string }>`
  position: absolute;
  bottom: 0;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 18px;
  ${({ $url }) => ({ ...coverImageStyle($url || DEFAULT_AVATAR) })}
  ${CONDITION_DESKTOP} {
    border-radius: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;
const MessageBody = styled.div<{ $variant: MessageVariants }>`
  color: ${({ $variant }) => ($variant === 'me' ? 'var(--white)' : 'var(--text)')};
  background-color: ${({ $variant }) => ($variant === 'me' ? 'var(--purple)' : 'var(--gray10)')};
  margin-left: ${({ $variant }) => ($variant === 'me' ? 0 : '40px')};
  padding: 7px 12px;
  border-radius: 20px;
  ${CONDITION_DESKTOP} {
    padding: 10px 8px 10px 10px;
    margin-left: ${({ $variant }) => ($variant === 'me' ? 0 : '58px')};
    border-radius: ${({ $variant }) => ($variant === 'me' ? '10px 10px 2px 10px' : '10px 10px 10px 2px')};
  }
`;
const MessageContent = styled.span`
  position: relative;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 1.43;
  & a {
    color: inherit;
    text-decoration: underline;
  }
`;
const MessageStatusContainer = styled.div`
  float: right;
  margin-left: 10px;
  height: 20px;
  display: flex;
  align-items: center;
`;
const MessageTime = styled.span`
  margin-left: 3px;
  font-size: 10px;
  opacity: 0.66;
  line-height: 12px;
  min-width: 30px;
  ${CONDITION_DESKTOP} {
    font-style: italic;
    padding-right: 2px;
  }
`;

const ClearBoth = styled.div`
  clear: both;
`;

const PostTextWrapper = styled.div`
  margin-bottom: 10px;
`;

const BoldText = styled.p`
  font-weight: bold;
`;

const PostTextContainer = styled.div`
  a {
    color: var(--purple);
  }
  span {
    color: var(--purple);
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
`;

const StyledImage = styled.img<{ $variant: MessageVariants }>`
  max-width: ${({ $variant }) => ($variant === 'me' ? '70%' : '80%')};
  border-radius: 20px;
`;

const ButtonWrapper = styled.p`
  span {
    color: var(--purple);
    text-decoration: none;

    &:hover {
      cursor: pointer;
      color: var(--white);
    }
  }
  &:hover {
    span {
      color: var(--white);
      text-decoration: none;
    }
  }
`;
