import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, HTMLAttributes, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ym from 'react-yandex-metrika';
import { CONDITION_DESKTOP, ENVIRONMENT_TYPE } from 'shared/common/constants';
import { commaNumberFormat, onKeyDownNumber } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { Button, Input } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';
import { Modal } from 'widgets/common';
import { NotContractModal } from 'widgets/modals/not-contract-modal';

import { RequestMessage } from '../request-message';

const currencyCode = '\u20bd';

type ModalForm = {
  price: string;
  theme: string;
};

interface RequestBodyProps extends HTMLAttributes<HTMLDivElement> {
  categoryId: number | null;
}

const sendRequestAnalyticsData = () => {
  if (ENVIRONMENT_TYPE === 'production') {
    ym('reachGoal', ['cons_otklik']);
  }
};

export const RequestBody: FC<RequestBodyProps> = ({ categoryId, ...props }) => {
  const { t } = useTranslation('chat.page');
  const dispatch = useAppDispatch();
  const requestCategory = useSelector(selectors.consultationRequests.selectRequestRoomById(categoryId));
  const activeRequestMessageId = useSelector(selectors.consultationRequests.selectActiveRequestMessageId);
  const user = useSelector(selectors.profile.selectUser);
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const [isSendingOffer, setIsSendingOffer] = useState(false);
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<ModalForm>({ mode: 'onChange', defaultValues: { price: '', theme: '' } });

  const toggleModal = () => {
    setIsVisibleModal(!isVisibleModal);
  };

  const sendResponse = async (values: ModalForm) => {
    try {
      setIsSendingOffer(true);
      const actionResult = await dispatch(
        actions.consultationRequests.createOffer({
          consultationRequestId: activeRequestMessageId!,
          description: values.theme,
          price: parseInt(values.price.replace(/' '/g, ''), 10),
        }),
      );
      unwrapResult(actionResult);
      sendRequestAnalyticsData();
      toast.success(t('offerForm.offerSendMessage'));
      reset();
    } catch (error: any) {
      captureError(error);
      toast.error(error.message);
    } finally {
      setIsSendingOffer(false);
      toggleModal();
    }
  };

  const renderMessages = useMemo(
    () =>
      requestCategory?.requests.map((request) => (
        <RequestMessage key={request.id} message={request} onClick={toggleModal} />
      )),
    [requestCategory],
  );

  return (
    <>
      <Container {...props}>{renderMessages} </Container>
      {isVisibleModal &&
        (user.isContractAccepted ? (
          <Modal isVisible={isVisibleModal} onClose={toggleModal}>
            <Form onSubmit={handleSubmit(sendResponse)}>
              <FormTitle>{t('offerForm.title')}</FormTitle>
              <FormDescription>{t('offerForm.description')}</FormDescription>
              <ModalInputContainer>
                <Controller
                  control={control}
                  name="theme"
                  rules={{
                    validate: {
                      isRequired: (v) => (v && v.trim() !== '') || 'errors.required',
                    },
                  }}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder={t('offerForm.themePlaceholder')}
                      error={error?.message && t(error?.message)}
                    />
                  )}
                />
                {/* <Currency>{currencyCode}</Currency> */}
              </ModalInputContainer>
              <ModalInputContainer>
                <Controller
                  control={control}
                  name="price"
                  rules={{
                    validate: {
                      isNumber: (v) => !!parseInt(v, 10) || !v || 'errors.mustBeANumber',
                      maxLength: (v) => v.length < 7 || 'errors.tooLong',
                      minLength: (v) => v.length > 1 || 'errors.tooSmall',
                    },
                  }}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Input
                      value={value}
                      onChange={(e) => onChange(commaNumberFormat(e.currentTarget.value))}
                      placeholder={t('offerForm.pricePlaceholder')}
                      error={error?.message && t(error?.message)}
                      onKeyDown={onKeyDownNumber}
                    />
                  )}
                />
                <Currency>{currencyCode}</Currency>
              </ModalInputContainer>
              {/* <Controller
              control={control}
              name="description"
              render={({ field: { value, onChange } }) => (
                <ModalTextareaModal
                  value={value}
                  onChange={onChange}
                  placeholder={t('offerForm.descriptionPlaceholder')}
                />
              )}
            /> */}

              <Button
                disabled={!getValues().price.length || !!errors.price?.message || isSendingOffer}
                loading={isSendingOffer}
                block
                type="submit"
              >
                {t('offerForm.button')}
              </Button>
            </Form>
          </Modal>
        ) : (
          <NotContractModal show={isVisibleModal} onHide={toggleModal} />
        ))}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 0 15px 15px;

  ${CONDITION_DESKTOP} {
    padding: 0 35px 35px;
  }
`;

const Form = styled.form``;
const FormTitle = styled.h3`
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 15px;
`;
const FormDescription = styled.p`
  text-align: center;
  white-space: pre-wrap;
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 30px;
`;
const ModalInputContainer = styled.div`
  margin-bottom: 15px;
  position: relative;
`;
const Currency = styled.div`
  position: absolute;
  color: var(--gray);
  right: 15px;
  top: 0;
  height: 50px;
  line-height: 50px;
`;
