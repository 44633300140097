import { CheckWhite } from 'app/assets/svg';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { ContractFormSteps, StepsState } from 'routes/contract/contract';
import { CONDITION_DESKTOP, CONDITION_MOBILE } from 'shared/common/constants';
import styled from 'styled-components';

interface ContractFormProgressBarProps {
  step: ContractFormSteps;
  stepsState: StepsState;
  setStepsState: (data: Partial<StepsState>) => void;
  setStep: (s: ContractFormSteps) => void;
}

export const ContractFormProgressBar: FC<ContractFormProgressBarProps> = ({
  step,
  stepsState,
  setStepsState,
  setStep,
}) => {
  const { t } = useTranslation('contract.page');

  const onPointClick = (desiredStep: ContractFormSteps) => () => {
    if (step > desiredStep) {
      switch (desiredStep) {
        case ContractFormSteps.variant:
          setStepsState({
            variant: false,
            offer: false,
            props: false,
          });
          setStep(desiredStep);
          break;
        case ContractFormSteps.offer:
          setStepsState({
            variant: true,
            offer: false,
            props: false,
          });
          setStep(desiredStep);
          break;
        case ContractFormSteps.props:
          setStepsState({
            variant: true,
            offer: true,
            props: false,
          });
          setStep(desiredStep);
          break;
        default:
          break;
      }
    }
  };

  return (
    <Container>
      <Progress>
        <Point onClick={onPointClick(ContractFormSteps.variant)}>
          <PointName $active={step === ContractFormSteps.variant}>{t('form.steps.variant')}</PointName>
          <Circle $active={stepsState.variant}>{stepsState.variant && <CheckWhite />}</Circle>
        </Point>

        <Point onClick={onPointClick(ContractFormSteps.offer)}>
          <PointName $active={step === ContractFormSteps.offer}>{t('form.steps.offer')}</PointName>
          <Circle $active={stepsState.offer}>{stepsState.offer && <CheckWhite />}</Circle>
        </Point>

        <Point onClick={onPointClick(ContractFormSteps.props)}>
          <PointName $active={step === ContractFormSteps.props}>{t('form.steps.props')}</PointName>
          <Circle $active={stepsState.props}>{stepsState.props && <CheckWhite />}</Circle>
        </Point>

        <Point onClick={onPointClick(ContractFormSteps.props)}>
          <PointName $active={step === ContractFormSteps.final}>{t('form.steps.final')}</PointName>
          <Circle $active={false}>{false && <CheckWhite />}</Circle>
        </Point>

        <Line />
      </Progress>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto 40px;
  width: 100%;
  max-width: 385px;
  ${CONDITION_DESKTOP} {
    width: 50%;
    max-width: unset;
    margin: 0 auto 90px;
  }
`;

const Progress = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px;
  border-radius: 12px;
  transition: 0.3s ease background-color;

  &:hover {
    background-color: #f7f6ff;
    cursor: pointer;
  }
`;

const PointName = styled.div<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 30px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({ $active }) => ($active ? 'var(--purple)' : 'var(--text)')};
  margin-bottom: 13px;
`;

const Circle = styled.div<{ $active?: boolean }>`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: 2px solid var(--purple);
  background-color: ${({ $active }) => ($active ? 'var(--purple)' : 'var(--white)')};
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Line = styled.div`
  position: absolute;
  height: 1px;
  background-color: var(--purple);
  opacity: 0.3;
  width: 65%;
  left: 51%;
  bottom: 22px;
  transform: translateX(-50%);

  ${CONDITION_DESKTOP} {
    width: 80%;
  }

  ${CONDITION_MOBILE} {
    width: 76%;
  }
`;
