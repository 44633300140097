import { useAppDispatch } from 'hooks/redux';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

export const DefaultThanksModal: FC<unknown> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('utils');
  const isVisible = useSelector(selectors.app.selectIsVisibleDefaultThanksModal);

  const onClose = useCallback(() => {
    if (isVisible) {
      dispatch(actions.app.setIsVisibleDefaultThanksModal(false));
    }
  }, [dispatch, isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <Container>
        <Title>{t('modals.thanksModal.title')}</Title>
        <Description>{t('modals.thanksModal.description')}</Description>
        <Image src="/img/thanks-modal-image.png" width={260} height={194} />
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  text-align: center;
`;
const Title = styled.h3`
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 15px;
  white-space: pre-line;
`;
const Description = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 25px;
`;
