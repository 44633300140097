import * as React from 'react';

function SvgSmallVideoCamera(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="5.442%" y1="35.24%" x2="100%" y2="35.24%" id="xqe0qss4za">
          <stop stopColor="#812DF6" offset="0%" />
          <stop stopColor="#993DFA" offset="45.328%" />
          <stop stopColor="#AE4CFE" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle fill="url(#xqe0qss4za)" cx="15" cy="15" r="15" />
        <g fill="#FFF" fillRule="nonzero">
          <path d="M16.808 10.517H9.905a1.66 1.66 0 0 0-1.655 1.655v5.578c0 .91.745 1.655 1.655 1.655h6.903a1.66 1.66 0 0 0 1.655-1.655v-5.578a1.65 1.65 0 0 0-1.655-1.655zM22.154 11.427a.843.843 0 0 0-.28.116l-2.583 1.49v3.84l2.599 1.49a.984.984 0 0 0 1.357-.365c.083-.148.132-.314.132-.496v-5.115a.996.996 0 0 0-1.225-.96z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgSmallVideoCamera;
