import type { HTMLProps, ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';

const StyledBackdrop = styled.div({
  position: 'fixed',
  zIndex: 1030,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#0c001d',
  opacity: 0.7,
});

export const renderBackdrop = (props: HTMLProps<HTMLDivElement>): ReactNode => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <StyledBackdrop {...props} />
);
