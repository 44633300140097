import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

export const MobileBanner: FC = () => (
  <Container>
    <StyledTitle>Скачайте приложение</StyledTitle>
    <Description>
      Проводите консультации, как в обычном мессенджере. <br /> 2000 экспертов уже консультируют на бизнес-темы. Скоро
      запустим еще более 25 тем.
    </Description>
    <StyledLinksWrapper>
      <StyledAppLink href="/app/mobile">
        <StyledAppImage src="/landing/store-apple.svg" alt="IOs" />
      </StyledAppLink>

      <StyledAppLink href="/app/mobile">
        <StyledAppImage src="/landing/store-google.svg" alt="Android" />
      </StyledAppLink>
    </StyledLinksWrapper>
    <ImageWrapper />
  </Container>
);

const Container = styled.div`
  width: 100%;
  background-color: var(--gray7);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  border-radius: 6px;
  margin-bottom: 30px;
`;
const StyledTitle = styled.span`
  color: #232832;
  font-weight: bold;
  font-size: 16px;
`;

const Description = styled.div`
  margin: 10px 0;
  color: #79818c;
  font-size: 12px;
`;

const StyledLinksWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  column-gap: 10px;
  flex-wrap: nowrap;
`;

const StyledAppLink = styled.a`
  display: inline-flex;
  flex-direction: row;
  vertical-align: bottom;
`;

const StyledAppImage = styled.img`
  height: 30px;
`;

const ImageWrapper = styled.div`
  height: 130px;
  width: 114px;
  background-image: url('/img/2000experts/pic-hand-app@3x.png');
  background-repeat: no-repeat;
  background-size: cover;
`;
