import React, { FC, HTMLAttributes } from 'react';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import styled from 'styled-components';

interface ChatSidebarTabButtonProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  unreadCount?: number;
  isActive: boolean;
  onClick: () => void;
}

export const ChatSidebarTabButton: FC<ChatSidebarTabButtonProps> = ({
  text,
  isActive,
  unreadCount,
  onClick,
  ...props
}) => (
  <Container $isActive={isActive} onClick={onClick} {...props}>
    <span>{text}</span>
    {!!unreadCount && (
      <UnreadCount $isActive={isActive}>{unreadCount < 100 ? unreadCount : <MoreCount>99+</MoreCount>}</UnreadCount>
    )}
  </Container>
);

const Container = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 5px;
  font-size: 15px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border-bottom: ${({ $isActive }) => ($isActive ? '3px solid var(--purple)' : '3px solid transparent')};
  transition: all 0.2s;
  color: ${({ $isActive }) => ($isActive ? 'var(--purple)' : 'var(--gray)')};
  ${CONDITION_DESKTOP} {
    color: ${({ $isActive }) => ($isActive ? 'var(--text)' : 'var(--gray)')};
  }
`;

const UnreadCount = styled.span<{ $isActive: boolean }>`
  margin-left: 5px;
  font-size: 11px;
  line-height: 22px;
  color: var(--white);
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: ${({ $isActive }) => ($isActive ? 'var(--purple)' : 'var(--gray)')};
`;

const MoreCount = styled.span`
  font-size: 9px;
  top: -1px;
  position: relative;
`;
