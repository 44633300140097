import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { routes } from 'shared/common/routes';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

type INotContractModalProps = {
  show: boolean;
  onHide: () => void;
};

export const NotContractModal: FC<INotContractModalProps> = ({ show, onHide }) => {
  const { t } = useTranslation('utils');
  const router = useRouter();

  return (
    <Modal isVisible={show} onClose={onHide}>
      <StyledContainer>
        <StyledTitle>{t('modals.notContractModal.title')}</StyledTitle>
        <Text>{t('modals.notContractModal.description')}</Text>
        <ContractButton onClick={() => router.push(routes.contract)}>
          {t('modals.notContractModal.buttonContractYes')}
        </ContractButton>
        <StyleButton onClick={() => onHide()}>{t('modals.notContractModal.buttonContractNo')}</StyleButton>
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const StyledTitle = styled.span({
  fontSize: 20,
  fontWeight: 'bold',
  color: '#232832',
  marginBottom: '10px',
  lineHeight: '1.05',
  letterSpacing: 'normal',
});

const Text = styled.span({
  fontSize: 13,
  lineHeight: '20px',
  color: '#232832',
  marginBottom: '30px',
  marginTop: '20px',
  letterSpacing: 'normal',
});

const ContractButton = styled.button({
  width: '100%',
  height: 50,
  borderRadius: '24px',
  backgroundColor: '#8b33ff;',
  border: 'none',
  color: '#fff',
  cursor: 'pointer',
  marginBottom: 20,

  '&:hover': {
    backgroundColor: '#8b33ff',
    color: '#fff',
  },
});

const StyleButton = styled.button({
  width: '100%',
  height: 50,
  borderRadius: '24px',
  border: 'none',
  backgroundColor: '#fff',
  cursor: 'pointer',
  color: '#8b33ff',

  '&:hover': {
    backgroundColor: '#8b33ff',
    color: '#fff',
  },
});
