import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/redux';
import { useIsExpertInRoom } from 'hooks/useIsExpertInRoom';
import { useScheduler } from 'hooks/useScheduler';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import { LEFT_MINUTES_WARNING_COUNT } from 'shared/common/constants';
import { ChatRoomExtended } from 'shared/common/types';
import { actions, selectors } from 'store/ducks';

export const useTimeLimit = (chatRoom?: ChatRoomExtended) => {
  const { t } = useTranslation('chat.page');

  const dispatch = useAppDispatch();
  const activeConsultation = useSelector(selectors.consultationsChatRoom.selectActiveConsultation);
  const isUserExpertInRoom = useIsExpertInRoom(chatRoom);

  const timeLimitMessageCreateOn = activeConsultation
    ? dayjs(activeConsultation?.expiresIn).subtract(LEFT_MINUTES_WARNING_COUNT, 'minutes').toISOString()
    : null;
  useScheduler(timeLimitMessageCreateOn, () => {
    if (!activeConsultation) {
      return;
    }

    const minutesLeft = dayjs(activeConsultation.expiresIn).diff(dayjs(), 'minutes') + 1;

    dispatch(
      actions.chatMessages.addChatMessage({
        type: 'temporary',
        content: isUserExpertInRoom
          ? t('chatRoom.systemMessages.approachingTimeLimitExpert', {
              count: minutesLeft,
            })
          : t('chatRoom.systemMessages.approachingTimeLimit', {
              count: minutesLeft,
            }),
        createdAt: new Date().toISOString(),
      }),
    );
  });
};
