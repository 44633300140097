import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PromocodeTypeEnum } from 'shared/api';
import { selectors } from 'store/ducks';

export const useUserPromocodes = () => {
  const userPromocodes = useSelector(selectors.profile.selectUserPromocodes);
  const [freeConsultCount, setFreeConsultCount] = useState(0);
  const [freeConsultDays, setFreeConsultDays] = useState(0);

  useEffect(() => {
    const freeConsultationPromocodes = userPromocodes.filter(
      (p) => p.promocode.type === PromocodeTypeEnum.FreeConsultation && dayjs(p.promocode.expiresIn).diff(dayjs()) > 0,
    );
    const notUsedFreeCount: number = freeConsultationPromocodes
      .map((p) => {
        if (p.usedPrivileges?.usedFreeConsultations) {
          return p.promocode.privilege.freeConsultationsCount - p.usedPrivileges.usedFreeConsultations;
        }
        return p.promocode.privilege.freeConsultationsCount;
      })
      .reduce((acc, current) => acc + current, 0);

    setFreeConsultCount(notUsedFreeCount);
    setFreeConsultDays(
      Math.max(...freeConsultationPromocodes.map((p) => dayjs(p.promocode.expiresIn).diff(dayjs(), 'day') + 1)),
    );
  }, [userPromocodes]);

  return { freeConsultCount, freeConsultDays };
};
