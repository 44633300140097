import { PauseButtonIcon, PlayButtonIcon } from 'app/assets/svg';
import { useAppDispatch } from 'hooks/redux';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Range } from 'react-range';
import { useSelector } from 'react-redux';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { formattingAudioTime } from 'shared/helpers';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';

type ChatAudioMessageProps = {
  audioSrc: string;
  messageId: number | string;
};

export const ChatAudioMessage: FC<ChatAudioMessageProps> = ({ audioSrc, messageId }) => {
  const dispatch = useAppDispatch();
  const audioElem = useRef<HTMLAudioElement>(null);
  const currentAudioMessageId = useSelector(selectors.audioMessages.selectCurrentAudioId);
  const [duration, setDuration] = useState(0.01);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (currentAudioMessageId !== messageId) {
      audioElem.current!.pause();
      setIsPlaying(false);
    }
  }, [currentAudioMessageId]);

  const togglePlay = () => {
    if (audioElem.current) {
      if (!isPlaying) {
        audioElem.current.play();
        dispatch(actions.audioMessages.setCurrentAudioId({ messageId }));
      } else {
        audioElem.current.pause();
      }
    }
  };

  const onPlaying = () => setIsPlaying(true);

  const onPause = () => setIsPlaying(false);

  const timeUpdate = () => {
    setCurrentTime(audioElem.current!.currentTime);
  };

  const onSeek = (values: number[]) => {
    audioElem.current!.currentTime = values[0];
  };

  const onLoadedMetadata = () => {
    if (audioElem.current) {
      !Number.isNaN(audioElem.current.duration) && setDuration(audioElem.current.duration);
    }
  };

  return (
    <AudioContainer>
      <audio
        ref={audioElem}
        src={audioSrc}
        preload="auto"
        onLoadedMetadata={onLoadedMetadata}
        onTimeUpdate={timeUpdate}
        onPlaying={onPlaying}
        onPause={onPause}
      />
      <AudioInfo>
        <PlayButton onClick={togglePlay}>
          {isPlaying ? <PauseButtonIcon /> : <PlayButtonIcon style={{ marginLeft: '3px' }} />}
        </PlayButton>
        <Range
          min={0}
          max={duration}
          values={[currentTime]}
          onChange={onSeek}
          renderTrack={({ props, children }) => <AudioLine {...props}>{children}</AudioLine>}
          renderThumb={({ props }) => <AudioThumb {...props} />}
        />
      </AudioInfo>
      <AudioTime>
        {formattingAudioTime(currentTime)} / {formattingAudioTime(duration)}
      </AudioTime>
    </AudioContainer>
  );
};

const AudioContainer = styled.div`
  padding: 10px;
  width: 220px;
  margin: -10px -10px 0;
  position: relative;

  ${CONDITION_DESKTOP} {
    width: 280px;
  }
`;
const AudioInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
const PlayButton = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  background-color: var(--purple);
`;
const AudioLine = styled.div`
  height: 4px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--gray2);
  margin-left: 10px;
  margin-right: 5px;
`;
const AudioThumb = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--white);
  border: 1px solid var(--gray2);
  outline: none;
`;
const AudioTime = styled.span`
  position: absolute;
  top: 100%;
  color: var(--text);
  font-weight: 500;
  opacity: 0.5;
  font-size: 12px;
  line-height: 12px;
  background-color: var(--gray10);
  padding: 2px 5px;
  border-radius: 5px;
`;
