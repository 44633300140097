import { ChatStatusErrorIcon, ChatStatusPendingIcon, ChatStatusSendIcon } from 'app/assets/svg';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import processString from 'react-process-string';
import { AUDIO_MESSAGE_EXT, CONDITION_DESKTOP, DEFAULT_AVATAR } from 'shared/common/constants';
import { MessageExtended, MessageVariants } from 'shared/common/types';
import { coverImageStyle, makeMessageTime } from 'shared/helpers';
import { parseUrlConfig } from 'shared/helpers/parseUrlConfig';
import styled from 'styled-components';

import { ChatAudioMessage } from '../chat-audio-message';

interface ChannelMessageProps {
  message: MessageExtended;
  variant: MessageVariants;
  avatar?: string;
}

export const ChannelMessage: FC<ChannelMessageProps> = ({ message, variant, avatar }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const renderMessageStatus = () => {
    switch (message.status) {
      case 'pending':
        return <ChatStatusPendingIcon />;
      case 'error':
        return <ChatStatusErrorIcon />;
      default:
        return <ChatStatusSendIcon />;
    }
  };

  const renderContent = useMemo(() => {
    if (message.attachments && message.attachments[0].split('.').pop() === AUDIO_MESSAGE_EXT) {
      return <ChatAudioMessage audioSrc={message.attachments[0]} messageId={message.id || message.createdAt!} />;
    }
    return processString(parseUrlConfig)(message.content);
  }, [message.content]);

  const renderMessage = useMemo(() => {
    switch (variant) {
      case 'me':
        return (
          <Container $variant="me">
            <MessageContainer $variant="me">
              <MessageBody $variant="me">
                <MessageContent>{renderContent}</MessageContent>
                <MessageStatusContainer>
                  <MessageStatus>{renderMessageStatus()}</MessageStatus>
                  <MessageTime>{makeMessageTime(message.createdAt, language)}</MessageTime>
                </MessageStatusContainer>
                <ClearBoth />
              </MessageBody>
            </MessageContainer>
          </Container>
        );
      case 'companion':
        return (
          <Container $variant="companion">
            <MessageContainer $variant="companion">
              <Avatar $url={avatar} />
              <MessageBody $variant="companion">
                {message?.imageUrl && <MessageImage src={message?.imageUrl} alt="asdas" />}
                <MessageContentWithHtml
                  dangerouslySetInnerHTML={{
                    __html: message.content as string,
                  }}
                />
                <MessageStatusContainer>
                  {/* <MessageStatus>{renderMessageStatus()}</MessageStatus> */}
                  <MessageTime>{makeMessageTime(message.createdAt, language)}</MessageTime>
                </MessageStatusContainer>
                <ClearBoth />
              </MessageBody>
            </MessageContainer>
          </Container>
        );
      case 'system':
        return (
          <SystemMessage>
            <span>{message.content}</span>
            <MessageTime>{makeMessageTime(message.createdAt, language)}</MessageTime>
          </SystemMessage>
        );
      default:
        throw new Error(
          `сообщение с 
          таким variant не предусмотрено рендером, проверьте "components/common/chat-room/components/chatmessage"`,
        );
    }
  }, [message, variant, avatar]);

  return renderMessage;
};

const Container = styled.div<{ $variant: MessageVariants }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  user-select: text;
  align-items: ${({ $variant }) => ($variant === 'me' ? 'flex-end' : 'flex-start')};
  margin-top: 15px;

  ${CONDITION_DESKTOP} {
    margin-top: 20px;
  }

  &:last-child {
    padding-top: 15px;

    ${CONDITION_DESKTOP} {
      padding-top: 35px;
    }
  }
`;
const MessageContainer = styled.div<{ $variant: MessageVariants }>`
  max-width: ${({ $variant }) => ($variant === 'me' ? '80%' : '90%')};
  position: relative;
`;
const Avatar = styled.div<{ $url?: string }>`
  position: absolute;
  bottom: 0;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 18px;

  ${({ $url }) => ({ ...coverImageStyle($url || DEFAULT_AVATAR) })}
  ${CONDITION_DESKTOP} {
    border-radius: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;
const MessageBody = styled.div<{ $variant: MessageVariants }>`
  color: ${({ $variant }) => ($variant === 'me' ? 'var(--white)' : 'var(--text)')};
  background-color: ${({ $variant }) => ($variant === 'me' ? 'var(--purple)' : 'var(--gray10)')};
  margin-left: ${({ $variant }) => ($variant === 'me' ? 0 : '40px')};
  padding: 7px 12px;
  border-radius: 20px;

  ${CONDITION_DESKTOP} {
    padding: 10px 8px 10px 10px;
    margin-left: ${({ $variant }) => ($variant === 'me' ? 0 : '58px')};
    border-radius: ${({ $variant }) => ($variant === 'me' ? '10px 10px 2px 10px' : '10px 10px 10px 2px')};
  }
`;
const MessageContent = styled.span`
  position: relative;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 1.43;

  & a {
    color: inherit;
    text-decoration: underline;
  }
`;

const MessageImage = styled.img`
  width: 100%;
  object-fit: contain;
  max-height: 200px;
`;

const MessageContentWithHtml = styled.div`
  position: relative;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 1.43;

  & a {
    color: inherit;
    text-decoration: underline;
  }

  ul {
    list-style: revert;
    margin: revert;
    padding: revert;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  div,
  a,
  ul,
  li {
    margin: revert;
  }
`;
const MessageStatusContainer = styled.div`
  float: right;
  margin-left: 10px;
  height: 20px;
  display: flex;
  align-items: center;
`;
const MessageTime = styled.span`
  margin-left: 3px;
  font-size: 10px;
  opacity: 0.66;
  line-height: 12px;
  min-width: 30px;

  ${CONDITION_DESKTOP} {
    font-style: italic;
    padding-right: 2px;
  }
`;
const MessageStatus = styled.span``;
const SystemMessage = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 1.25;
  color: var(--gray);

  ${CONDITION_DESKTOP} {
    color: var(--purple);
    font-style: italic;
  }

  & > span {
    text-align: center;
  }
`;
const ClearBoth = styled.div`
  clear: both;
`;
