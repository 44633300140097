import { MessageTypeEnum, UserBlockEnum, UserExpertRejectionEnum } from 'shared/api';

export const consultyChannel: any = {
  createdAt: '2022-04-06T03:22:15.862Z',
  updatedAt: '2022-04-12T05:40:53.594Z',
  deletedAt: '',
  id: 0,
  chatConsultationOffers: [],
  messages: [],
  isCompanionWritten: false,
  isTempRoom: false,
  clientId: 0,
  expertId: 0,
  lastMessageId: 0,
  clientLastReadMessageId: 0,
  expertLastReadMessageId: 0,
  isExpertMuteChat: false,
  isClientMuteChat: false,
  lastActionDate: '2022-04-06T03:23:54.669Z',
  expert: {
    createdAt: '2022-04-06T03:12:27.230Z',
    updatedAt: '2022-05-06T00:10:35.505Z',
    deletedAt: '',
    id: 0,
    phone: '79136008010',
    email: 'mail8010@mail.ru',
    isEmailVerified: false,
    emailVerificationToken: 'vnzytahkm6jodaxkh0qvh3gdb',
    avatarUrl:
      'https://consulty-stage.storage.yandexcloud.net/consulty-stage/user_36/1612173150_942410_1612173448_rrss_normal.jpg',
    firstName: 'Имя8010',
    lastName: 'Фамилия8010',
    aboutSelf: 'Кратко о себе в свободной форме',
    location: undefined,
    birthDate: undefined,
    isProfileVerified: false,
    personalSite: undefined,
    diplomasUrl: undefined,
    videoUrl: 'https://app.zeplin.io/project/5ef5d97e1cb8dea131c4146d/screen/5f57d4a757498c40701d7728',
    instagramUrl: undefined,
    facebookUrl: undefined,
    vKontakteUrl: undefined,
    telegramUrl: undefined,
    youtubeUrl: undefined,
    odnoklassnikiUrl: undefined,
    isExpert: true,
    expertConsultationsCount: 4,
    outgoingReviewCount: 0,
    reviewsCount: 2,
    expertRating: 5,
    ratingPositionWeight: 6.091999999999999,
    outgoingClientRating: 0,
    clientConsultationsCount: 0,
    clientBadReviewsCount: 0,
    expertLevel: undefined,
    hasFreeConsultations: true,
    freeConsultationsRemain: -1,
    hasFreeChat: true,
    consultationAveragePrice: undefined,
    clientLikesCount: 0,
    clientDislikesCount: 0,
    expertLikesCount: 2,
    expertDislikesCount: 0,
    isAdmin: false,
    block: UserBlockEnum.Unblock,
    reasonBlock: undefined,
    isApproved: true,
    lastExitTime: '2022-05-06T00:10:35.000Z',
    tariffPlanId: 1,
    tinkoffSpAccumulationId: undefined,
    isContractAccepted: true,
    fcmTokens: undefined,
    isExpertContacted: false,
    expertRejection: UserExpertRejectionEnum.WhileNotConsulting,
  },
  client: {
    createdAt: '2022-04-06T03:20:59.689Z',
    updatedAt: '2022-04-22T12:32:42.624Z',
    deletedAt: '',
    id: 37,
    phone: '79136008011',
    email: 'mail8011@mail.ru',
    isEmailVerified: false,
    emailVerificationToken: 'upse7594m7l1bjl8jmmfsrgzk',
    avatarUrl: 'https://consulty-stage.storage.yandexcloud.net/consulty-stage/user_37/foto-de-perfil.jpg',
    firstName: 'Имя8011',
    lastName: 'Фамилия8011',
    aboutSelf: undefined,
    location: undefined,
    birthDate: undefined,
    isProfileVerified: false,
    personalSite: undefined,
    diplomasUrl: undefined,
    videoUrl: undefined,
    instagramUrl: undefined,
    facebookUrl: undefined,
    vKontakteUrl: undefined,
    telegramUrl: undefined,
    youtubeUrl: undefined,
    odnoklassnikiUrl: undefined,
    isExpert: true,
    expertConsultationsCount: 0,
    outgoingReviewCount: 0,
    reviewsCount: 0,
    expertRating: undefined,
    ratingPositionWeight: undefined,
    outgoingClientRating: 0,
    clientConsultationsCount: 0,
    clientBadReviewsCount: 0,
    expertLevel: undefined,
    hasFreeConsultations: true,
    freeConsultationsRemain: 3,
    hasFreeChat: true,
    consultationAveragePrice: undefined,
    clientLikesCount: 0,
    clientDislikesCount: 0,
    expertLikesCount: 0,
    expertDislikesCount: 0,
    isAdmin: false,
    block: UserBlockEnum.Unblock,
    reasonBlock: undefined,
    isApproved: true,
    lastExitTime: '2022-04-22T12:32:41.000Z',
    tariffPlanId: 1,
    tinkoffSpAccumulationId: undefined,
    isContractAccepted: false,
    fcmTokens: undefined,
    isExpertContacted: false,
    expertRejection: UserExpertRejectionEnum.WhileNotConsulting,
  },
  lastMessage: {
    createdAt: '2022-04-06T03:23:54.599Z',
    updatedAt: '2022-04-06T03:23:54.599Z',
    deletedAt: '',
    id: 0,
    authorId: 0,
    chatRoomId: 0,
    consultationId: undefined,
    type: MessageTypeEnum.Default,
    content: 'Привет!',
    attachments: undefined,
    systemMessageType: undefined,
    systemMessageMetadata: null,
  },
  consultations: [],
};
