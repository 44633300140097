import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button, ImageWithRetina } from 'shared/ui';
import styled from 'styled-components';

import { User } from '../../../../../shared/api';
import { checkCanUseFreeToken } from '../../../../../shared/helpers';
import { selectors } from '../../../../../store/ducks';

interface ConsultationPaymentModalProps {
  price: number;
  theme: string;
  hasFreeConsultations?: boolean;
  expert?: User;
  onRefuse: () => void;
  onPayWithMoney: () => void;
  onPayWithToken?: () => void;
}

export const ConsultationPaymentModal: FC<ConsultationPaymentModalProps> = ({
  price,
  theme,
  onRefuse,
  onPayWithMoney,
  hasFreeConsultations,
  expert,
  onPayWithToken,
}) => {
  const { t } = useTranslation('expert.page');
  const userPromocodes = useSelector(selectors.profile.selectUserPromocodes);

  return (
    <Container>
      <Title>{t('consultationPayment')}</Title>
      <Note>{t('ifConsultationDidntLike')}</Note>

      <Content>
        <ContentItem>
          <PropertyLabel>{t('theme')}</PropertyLabel>
          <PropertyValue>{theme}</PropertyValue>
        </ContentItem>

        <ContentItem>
          <PropertyLabel>{t('price')}</PropertyLabel>
          <PropertyValue>{t('priceNote', { price })}</PropertyValue>
        </ContentItem>

        <ContentItem>
          <PropertyLabel>{t('duration')}</PropertyLabel>
          <PropertyValue>{t('messagesOrHour')}</PropertyValue>
        </ContentItem>
      </Content>

      <ButtonsContainer>
        {!!hasFreeConsultations && checkCanUseFreeToken(userPromocodes, expert) && (
          <Button onClick={onPayWithToken} rightIcon={<CoinIcon src="/img/coin.png" alt="coin" />}>
            {t('payWithToken')}
          </Button>
        )}

        <Button onClick={onPayWithMoney}>{t('payWithPrice', { price })}</Button>

        <Button bordered onClick={onRefuse}>
          {t('refuse')}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 10px;
`;

const Note = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--text);
`;

const Content = styled.div`
  padding: 30px 0;

  & > div {
    margin-bottom: 15px;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`;

const PropertyLabel = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: var(--gray2);
`;

const PropertyValue = styled(PropertyLabel)`
  color: var(--text);
`;

const ContentItem = styled.div``;

const ButtonsContainer = styled.div`
  & > button {
    margin-bottom: 10px;
    width: 100%;
  }

  & > button:last-child {
    margin-bottom: 0;
  }
`;
const CoinIcon = styled(ImageWithRetina)`
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
`;
