import { useTranslation } from 'next-i18next';
import React, { FC, HTMLAttributes, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ConsultationStatusEnum } from 'shared/api';
import { CONDITION_DESKTOP, DEFAULT_AVATAR } from 'shared/common/constants';
import { ChatRoomExtended } from 'shared/common/types';
import { coverImageStyle, makeMessageContent, makeMessageTime } from 'shared/helpers';
import { selectors } from 'store/ducks';
import styled from 'styled-components';

interface ChatSidebarRoomButtonProps extends HTMLAttributes<HTMLDivElement> {
  isActive: boolean;
  chatRoom: ChatRoomExtended;
  onClick: () => void;
  isExpertInRoom: boolean;
}

export const ChatSidebarRoomButton: FC<ChatSidebarRoomButtonProps> = ({
  isActive,
  chatRoom,
  isExpertInRoom,
  ...props
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('chat.page');
  const { t: tUtils } = useTranslation('utils');
  const onlineMembers = useSelector(selectors.chatRooms.selectOnlineMembersIds);
  const currentChatRoomId = useSelector(selectors.chatRooms.selectCurrentChatRoomId);
  const unreadChatRoomsIds = useSelector(selectors.chatRooms.selectUnreadRoomsIds);
  const consultationsStatuses = useSelector(selectors.chatRooms.selectConsultationStatusesByRoomId(chatRoom.id));
  const user = useSelector(selectors.profile.selectUser);

  const isCompanionOnline = useMemo(
    () => onlineMembers.includes(isExpertInRoom ? chatRoom.clientId : chatRoom.expertId),
    [chatRoom, isExpertInRoom, onlineMembers],
  );

  const renderLastMessage = () => {
    chatRoom.consultations?.forEach(() => {});
    if (chatRoom.isCompanionWritten) {
      return t('isWritten');
    }
    if (consultationsStatuses?.consultations.some((c) => c.status === ConsultationStatusEnum.PendingPayment)) {
      return (
        <SystemMessage>
          {isExpertInRoom
            ? tUtils('messageContent.pendingPayment.expert')
            : tUtils('messageContent.pendingPayment.client')}
        </SystemMessage>
      );
    }
    if (consultationsStatuses?.consultations.some((c) => c.status === ConsultationStatusEnum.PendingStart)) {
      return (
        <SystemMessage>
          {isExpertInRoom ? tUtils('messageContent.pendingStart.expert') : tUtils('messageContent.pendingStart.client')}
        </SystemMessage>
      );
    }
    if (chatRoom.lastMessage) {
      const youMark = user.id === chatRoom.lastMessage.authorId ? `${tUtils('you')}: ` : '';
      return youMark + makeMessageContent(chatRoom.lastMessage, tUtils);
    }
    return t('chatRoom.empty');
  };

  return (
    <Container $isActive={isActive} {...props}>
      <Avatar $url={isExpertInRoom ? chatRoom.client?.avatarUrl : chatRoom.expert?.avatarUrl}>
        {isCompanionOnline && <OnlineDot />}
      </Avatar>
      <Middle>
        <Name>{isExpertInRoom ? chatRoom.client?.firstName : chatRoom.expert?.firstName}</Name>
        <LastMessage>{renderLastMessage()}</LastMessage>
      </Middle>
      <Right>
        <Time>{makeMessageTime(chatRoom.lastMessage?.createdAt || chatRoom.createdAt, language)}</Time>
        {chatRoom.id !== currentChatRoomId && unreadChatRoomsIds.includes(chatRoom.id) && <UnreadCount />}
      </Right>
    </Container>
  );
};

const Container = styled.div<{ $isActive: boolean }>`
  padding: 10px;
  margin: 5px 0;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 60px calc(100% - 105px) 45px;
  align-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background-color: ${({ $isActive }) => $isActive && 'rgba(245, 246, 250, 0.9)'};
  ${CONDITION_DESKTOP} {
    grid-template-columns: 50px 190px 45px;
  }
`;
const Avatar = styled.div<{ $url?: string }>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  align-self: center;
  position: relative;
  ${({ $url }) => ({ ...coverImageStyle($url || DEFAULT_AVATAR) })}
  ${CONDITION_DESKTOP} {
    width: 50px;
    height: 50px;
  }
`;
const OnlineDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid var(--white);
  background-color: var(--purple);
  position: absolute;
  bottom: 2px;
  right: 4px;
  ${CONDITION_DESKTOP} {
    right: 2px;
  }
`;
const Middle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 5px 0 10px;
`;
const Name = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  ${CONDITION_DESKTOP} {
    font-size: 14px;
  }
`;
const LastMessage = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  height: 40px;
  font-size: 15px;
  line-height: 1.33;
  color: var(--gray);
  ${CONDITION_DESKTOP} {
    font-size: 13px;
    height: 32px;
  }
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;
const Time = styled.span`
  font-size: 12px;
  color: var(--gray3);
`;
const UnreadCount = styled.div`
  font-size: 11px;
  line-height: 22px;
  color: var(--white);
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--purple);
`;

const SystemMessage = styled.span`
  color: var(--purple);
  font-style: italic;
`;
