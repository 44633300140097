import { Star } from 'app/assets/svg';
import SvgBadge from 'app/assets/svg/Badge';
import VerificationMark from 'app/assets/svg/VerificationMark';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useState } from 'react';
import { Category, User } from 'shared/api';
import { CONDITION_DESKTOP, DEFAULT_AVATAR } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { buildRoute, chooseEndingWord, coverImageStyle, ellipsizeText } from 'shared/helpers';
import { Button } from 'shared/ui';
import styled from 'styled-components';

interface ExpertPreviewCardProps {
  expert: User;
  isExample?: boolean;
  categoryName: string;
  subCategories: Category[];
  categoryId: number;
}

export const ExpertPreviewCard: FC<ExpertPreviewCardProps> = ({
  expert,
  isExample = false,
  categoryName,
  subCategories,
}) => {
  const { firstName, lastName, isProfileVerified, videoUrl, id, avatarUrl, aboutSelf, expertRatingTable } = expert;
  const expertRating = expertRatingTable?.internalRating ? Number(expertRatingTable?.internalRating) : 0;
  const { t } = useTranslation('expert.preview');
  const router = useRouter();

  const onExpertClick = () => {
    if (isExample) return;
    router.push(buildRoute(routes.expert, { id }));
  };

  const [categoriesArray, setCategoriesArray] = useState([] as { name: string; price: number }[]);

  const fillCategoriesArray = () => {
    const expertCategoriesObjects = expert.categories?.map((category) => ({
      id: category.categoryId,
      price: category.price,
      name: category.category.shortName ?? category.category.name,
    })) as { id: number; price: number; name: string }[];

    setCategoriesArray(expertCategoriesObjects.sort((a, b) => b.price - a.price));
  };

  useEffect(() => {
    fillCategoriesArray();
  }, [expert]);

  const getMinPrice = () => {
    let minPrice = 0;
    if (expert.categories && expert.categories.length) {
      minPrice = expert.categories.sort((a, b) => a.price - b.price)[0].price;
    }
    if (categoriesArray.length && minPrice > categoriesArray[categoriesArray.length - 1].price) {
      minPrice = categoriesArray[categoriesArray.length - 1].price;
    }
    return minPrice;
  };

  return (
    <Container onClick={onExpertClick}>
      <TopContentContainer>
        <Link href={`${buildRoute(routes.expert, { id })}`}>
          <AvatarContainer>
            <GradientBorder isVerification={isProfileVerified && !!videoUrl}>
              <Avatar onClick={onExpertClick} $url={avatarUrl || DEFAULT_AVATAR} />
            </GradientBorder>
            <Video isVisible={isProfileVerified && !!videoUrl}>
              <a href={videoUrl} target="_blank" rel="noreferrer">
                {t('videoCard')}
              </a>
            </Video>
          </AvatarContainer>
        </Link>
        <Link href={`${buildRoute(routes.expert, { id })}`}>
          <NameContainer>
            <Name onClick={onExpertClick}>
              <span title={firstName}>{firstName}</span>
              <Surname title={lastName}>{lastName && lastName[0].toUpperCase()}.</Surname>
              {isProfileVerified && (
                <IconWrapper>
                  <VerificationMark width={12} />
                </IconWrapper>
              )}
            </Name>
          </NameContainer>
        </Link>
        <AboutSelfContaier>
          <AboutSelfText>{ellipsizeText(aboutSelf, 120)}</AboutSelfText>
        </AboutSelfContaier>
        <RatingContainer>
          <Rating>
            <StarContainer>
              <Star />
            </StarContainer>
            <RatingTextWrapper>
              <RatingText>{expertRating && expertRating !== 0 ? expertRating.toFixed(1) : 'Нет рейтинга'}</RatingText>
            </RatingTextWrapper>
          </Rating>
          <PriceWrapper>
            <ExpertPrice>{`от ${Math.round(getMinPrice() || 0)} \u20bd`}</ExpertPrice>
            <TriangleWrapper />
          </PriceWrapper>
        </RatingContainer>

        <CategoriesContainer>
          <CategoriesItems>
            <CategoriesItemsContent>
              <SvgBadge />
              <CategoriesItemsText>{ellipsizeText(categoryName, 24)}</CategoriesItemsText>
            </CategoriesItemsContent>
          </CategoriesItems>
          {categoriesArray.slice(0, categoriesArray.length > 4 ? 3 : 4).map((item, index) => (
            <CategoriesItems key={index}>
              <CategoriesItemsContent>
                <CategoriesItemsText>{ellipsizeText(item.name, 24)}</CategoriesItemsText>
              </CategoriesItemsContent>
            </CategoriesItems>
          ))}
          {categoriesArray.length > 4 && (
            <CategoriesItems>
              <CategoriesItemsContent>
                <CategoriesItemsText>
                  +{categoriesArray.length - 4} {chooseEndingWord(categoriesArray.length - 4, ['тема', 'темы', 'тем'])}
                </CategoriesItemsText>
              </CategoriesItemsContent>
            </CategoriesItems>
          )}
        </CategoriesContainer>
      </TopContentContainer>

      <BottomContentContainer>
        <ButtonWrapper>
          <StyledButton size="small" width="155px" fontSize="13px" onClick={onExpertClick}>
            {t('showExpertButton')}
          </StyledButton>
        </ButtonWrapper>
      </BottomContentContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 360px;
  min-height: 419px;
  padding: 20px 25px;
  border-radius: 10px;
  border: solid 1px var(--purple4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
`;

const TopContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const BottomContentContainer = styled.div``;

interface AvatarProps {
  $url: string | null;
}

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 104px;
  height: 104px;
`;
const Avatar = styled.div<AvatarProps>`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 3px;

  &:hover {
    cursor: pointer;
  }

  ${({ $url }) => ({ ...coverImageStyle($url) })}
  ${CONDITION_DESKTOP} {
    width: 100px;
    height: 100px;
  }
`;

interface GradientBorderProps {
  isVerification?: boolean;
}

const GradientBorder = styled.div<GradientBorderProps>`
  ${({ isVerification }) =>
    isVerification &&
    `
  background:
          linear-gradient(var(--white),var(--white)) padding-box,
          linear-gradient(360deg,#5806c9 16%,#993dfa 27%,#ac45ff 57%) border-box;
  `}
  color: #313149;
  padding: 1px;
  border: 3px solid transparent;
  border-radius: 50%;
`;

interface VideoProps {
  isVisible?: boolean;
}

const Video = styled.div<VideoProps>`
  background-image: linear-gradient(to right, #ae4cfe 5%, #993dfa 51%, #812df6);
  border-radius: 12.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-12px);
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

  & > a {
    color: var(--white);
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 3px 9px;
  }

  text-align: center;
`;

const Name = styled.div`
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  height: 25px;
  letter-spacing: normal;
  color: var(--black2);
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 20px;

  &:hover {
    cursor: pointer;
  }

  & > svg {
    margin-left: 3px;
  }
`;

const NameContainer = styled.div`
  cursor: pointer;
  margin-top: 22px;
`;

const Surname = styled.span`
  font-size: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 135px;
  width: 100%;
  color: var(--black2);
  line-height: 20px;
`;

const IconWrapper = styled.div`
  width: 17px;
  height: 17px;
  display: flex;

  & > svg {
    width: 17px;
    height: 17px;
  }
`;

const StarContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const AboutSelfContaier = styled.div`
  margin-top: 5px;
`;

const AboutSelfText = styled.p`
  font-family: MuseoSansCyrl;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.1px;
  text-align: center;
  color: var(--text);
  height: 52px;
`;

const RatingContainer = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: row;
`;

const Rating = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RatingText = styled.span`
  margin-left: 5px;
  font-size: 21px;
  font-weight: bold;
  line-height: 20px;
  color: #111111;
`;

const RatingTextWrapper = styled.div`
  padding-top: 4px;
`;

const CategoriesContainer = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const PriceWrapper = styled.div`
  margin-left: 16px;
  padding-top: 4px;
  position: relative;
`;

const ExpertPrice = styled.div`
  border: 1px solid #e6d3ff;
  padding: 2px 5px 0 5px;
  border-right-width: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const TriangleWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: -8px;
  border: 1px solid #e6d3ff;
  height: 18.1px;
  width: 18.2px;
  margin-top: 4px;
  border-left-width: 0;
  border-bottom-width: 0;
  transform: rotate(45deg);
  border-radius: 4px;
`;

const StyledButton = styled(Button)`
  height: 35px;
`;

const CategoriesItems = styled.div`
  height: 24px;
  border-radius: 12px;
  border: solid 1px #f4ecff;
  color: #8b33ff;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

const CategoriesItemsContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2px 10px;
`;

const CategoriesItemsText = styled.div`
  margin-left: 4px;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
`;
