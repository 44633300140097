import { LikeSmallIcon, UnLikeSmallIcon } from 'app/assets/svg';
import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { ConsultationRequest } from 'shared/api';
import { CONDITION_DESKTOP, DEFAULT_AVATAR } from 'shared/common/constants';
import { coverImageStyle, diffTimeFromNow } from 'shared/helpers';
import { Button } from 'shared/ui';
import { actions } from 'store/ducks';
import styled from 'styled-components';

interface RequestMessageProps {
  message: ConsultationRequest;
  onClick: () => void;
}

export const RequestMessage: FC<RequestMessageProps> = ({ message, onClick }) => {
  const dispatch = useAppDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation('chat.page');

  const handleButtonClick = () => {
    dispatch(actions.consultationRequests.setActiveRequestMessageId({ requestId: message.id }));
    onClick();
  };

  return (
    <div>
      <Container>
        <Content>{message.content}</Content>
        <Time>{diffTimeFromNow(message.createdAt, language)}</Time>
        <Divider />
        <Avatar $url={message.client.avatarUrl} />
        <NameContainer>
          <Name>{message.client.firstName}</Name>
          <ReviewsContainer>
            {message.client.clientLikesCount > 0 || message.client.clientDislikesCount > 0 ? (
              <>
                <LikeSmallIcon />
                <span>{message.client.clientLikesCount}</span>
                <UnLikeSmallIcon />
                <span>{message.client.clientDislikesCount}</span>
              </>
            ) : (
              <NotRating>{t('request.message.notRating')}</NotRating>
            )}
          </ReviewsContainer>
        </NameContainer>
        <StyledButton bordered fontSize="12px" onClick={handleButtonClick}>
          {t('request.message.button')}
        </StyledButton>
      </Container>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 450px;
  border-radius: 12px;
  background-color: var(--gray10);
  margin-top: 34px;
  padding: 12px 15px 15px;
  display: grid;
  align-items: center;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'avatar name'
    'avatar time'
    'content content'
    'button button';
  ${CONDITION_DESKTOP} {
    padding-top: 20px;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      'content content content'
      'time time time'
      'divider divider divider'
      'avatar name button';
  }
`;
const Content = styled.p`
  grid-area: content;
  font-size: 17px;
  line-height: 1.33;
  white-space: pre-wrap;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  overflow-x: hidden;
  word-wrap: break-word;
  ${CONDITION_DESKTOP} {
    margin-top: 0;
    font-size: 15px;
  }
`;
const Time = styled.p`
  grid-area: time;
  font-size: 13px;
  line-height: 1.5;
  color: var(--gray2);
  align-self: flex-start;
  ${CONDITION_DESKTOP} {
  }
`;
const Divider = styled.hr`
  grid-area: divider;
  display: none;
  ${CONDITION_DESKTOP} {
    display: block;
    background-color: rgba(177, 177, 177, 0.15);
    height: 1px;
    width: 100%;
    border: none;
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;
const Avatar = styled.div<{ $url?: string }>`
  grid-area: avatar;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
  ${({ $url }) => ({ ...coverImageStyle($url || DEFAULT_AVATAR) })}
  ${CONDITION_DESKTOP} {
    width: 40px;
    height: 40px;
  }
`;
const NameContainer = styled.div`
  grid-area: name;
  overflow-x: hidden;
`;
const Name = styled.p`
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 2px;
  width: 100%;
  ${CONDITION_DESKTOP} {
    align-self: center;
    font-size: 13px;
    font-weight: 500;
  }
`;
const ReviewsContainer = styled.div`
  height: 15px;
  font-size: 13px;
  display: flex;
  & > span {
    margin-left: 3px;
    margin-right: 10px;
  }
  & > svg {
    margin-top: -1px;
  }
`;
const NotRating = styled.p`
  color: var(--gray2);
`;
const StyledButton = styled(Button)`
  grid-area: button;

  &:hover {
    background-color: var(--purple);
    border: 1px solid var(--purple);
  }
`;
