import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { api, ConsultationStatusEnum } from 'shared/api';
import { XPlatformHeader } from 'shared/common/constants';
import { captureError } from 'shared/helpers/captureError';
import { actions } from 'store/ducks';

export const useWidgetHandlers = () => {
  const dispatch = useAppDispatch();
  const { t: tUtils } = useTranslation('utils');
  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const handleRejectConsultationOffer = useCallback(
    async (chatId: number, chatConsultationOfferId: number) => {
      if (!isSendingRequest) {
        try {
          setIsSendingRequest(true);
          const { data } = await api.V1ChatsApi.chatRoomsControllerCancelChatConsultationOffer(
            chatId,
            chatConsultationOfferId,
          );
          dispatch(actions.consultationsChatRoom.rejectConsultationOffer(data));
          dispatch(
            actions.chatRooms.changeConsultationStatus({
              roomId: data.chatRoomId,
              consultationId: data.consultationId,
              status: ConsultationStatusEnum.ClientRejectChatOffer,
            }),
          );
        } catch (error: any) {
          captureError(error);
          toast.error(error.message || tUtils('somethingWrong'));
        } finally {
          setIsSendingRequest(false);
        }
      }
    },
    [isSendingRequest],
  );
  const handleRejectProlongation = useCallback(
    async (invoiceId: number) => {
      try {
        setIsSendingRequest(true);
        await api.V1ConsultationInvoicesApi.consultationInvoicesControllerDeclineOne(invoiceId);
      } catch (error: any) {
        captureError(error);
        toast.error(error.message || tUtils('somethingWrong'));
      } finally {
        setIsSendingRequest(false);
      }
    },
    [isSendingRequest],
  );
  const handleRejectExtraService = useCallback(
    async (consultationId: number, serviceId: number) => {
      await dispatch(actions.consultations.rejectConsultationAsync({ consultationId, id: serviceId }))
        .catch((error) => {
          toast.error(error.message || tUtils('somethingWrong'));
        })
        .finally(() => setIsSendingRequest(false));
    },
    [isSendingRequest],
  );
  const handleStartConsultation = useCallback(
    async (consultationId: number) => {
      if (!isSendingRequest) {
        await dispatch(
          actions.consultations.startConsultationAsync({
            id: consultationId,
            options: {
              headers: {
                'X-Source': 'chat',
                'X-Platform': XPlatformHeader,
              },
            },
          }),
        )
          .catch((error) => {
            if (error.message === 'Internal Server Error') {
              toast.error(tUtils('somethingWrongReload'));
            } else {
              toast.error(error.message || tUtils('somethingWrong'));
            }
          })
          .finally(() => setIsSendingRequest(false));
      }
    },
    [isSendingRequest],
  );

  return {
    isSendingRequest,
    handleRejectConsultationOffer,
    handleRejectProlongation,
    handleRejectExtraService,
    handleStartConsultation,
  };
};
