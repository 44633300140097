import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { ContractFormSteps } from 'routes';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { Input } from 'shared/ui';
import styled from 'styled-components';

import { ContractFormValues, ContractFromVariants } from '../contract-form/types';
import { renderPropsForm } from './renderPropsForm';

interface PropsFormProps {
  changeStepHandle: (step: ContractFormSteps) => void;
  buttonText?: string;
  changeStep?: () => void;
}

export const PropsForm: FC<PropsFormProps> = ({ changeStepHandle, changeStep }) => {
  const { t } = useTranslation('contract.page');
  const { watch } = useFormContext<ContractFormValues>();
  const variantValue = watch('variant')!;
  let variant;
  switch (variantValue) {
    case ContractFromVariants.INDIVIDUAL:
      variant = t('form.individual');
      break;
    case ContractFromVariants.OOO:
      variant = t('form.OOO');
      break;
    case ContractFromVariants.IP:
      variant = t('form.IP');
      break;
    case ContractFromVariants.SELF_EMPLOYED:
      variant = t('form.selfEmployed');
      break;
    default:
      variant = '';
      break;
  }

  if (changeStep) {
    return (
      <Container>
        <Title>{t('form.fillBankDetails')}</Title>

        <Block>
          <Input disabled label={t('form.registrationVariant')} value={variant} />
        </Block>

        <FormContainer>{renderPropsForm(variantValue, changeStepHandle, changeStep)}</FormContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Title>{t('form.fillBankDetails')}</Title>

      <Block>
        <Input disabled label={t('form.registrationVariant')} value={variant} />
      </Block>

      <FormContainer>{renderPropsForm(variantValue, changeStepHandle)}</FormContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  input {
    max-width: 420px;
  }

  ${CONDITION_DESKTOP} {
    width: 50%;
  }
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 40px;
`;

const Block = styled.div`
  margin-bottom: 60px;
`;

const FormContainer = styled.div`
  margin-bottom: 60px;

  & > div {
    margin-bottom: 30px;
  }
`;
