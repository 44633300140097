import React, { FC } from 'react';
import styled from 'styled-components';

interface BackgroundProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShowResults: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Background: FC<BackgroundProps> = ({ isVisible, setIsVisible, setIsShowResults }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Container
      onClick={() => {
        setIsVisible(false);
        setIsShowResults(false);
      }}
    />
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 400px) {
    background-color: var(--white);
  }
`;
