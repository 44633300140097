import type { FC, ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';

import CloseButton from './CloseButton';

type StyledDialogProps = {
  $width: number;
};

export const StyledDialog = styled.div<StyledDialogProps>(({ $width }) => ({
  width: $width,
  maxWidth: '96vw',
  position: 'relative',
  margin: '104px auto',
  borderRadius: 8,
  backgroundColor: '#fff',
  outline: 'none',
}));

export type ModalDialogProps = {
  width: number;
  onHide: () => void;
  children?: ReactNode;
};

export const ModalDialog: FC<ModalDialogProps> = ({ width, children, onHide }) => (
  <StyledDialog $width={width}>
    <CloseButton onClick={onHide} />

    {children}
  </StyledDialog>
);

ModalDialog.defaultProps = {
  children: null,
};
