import { useAppDispatch } from 'hooks/redux';
import { useAutofocus } from 'hooks/useAutofocus';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SUPPORT_EMAIL } from 'shared/common/constants';
import { Button, TextareaModal } from 'shared/ui';
import { actions } from 'store/ducks';
import styled from 'styled-components';
import { Modal } from 'widgets/common';
import { ThanksModal } from 'widgets/modals';

type ComplainModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

type FormType = {
  content: string;
};

export const ComplainModal: FC<ComplainModalProps> = ({ isVisible, onClose }) => {
  if (!isVisible) {
    return null;
  }

  const dispatch = useAppDispatch();
  const { t } = useTranslation('utils');
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    setFocus,
  } = useForm<FormType>({ mode: 'onChange' });

  useAutofocus<FormType>('content', setFocus, 100);

  const onSubmit = (data: FormType) => {
    window.open(
      `mailto:${SUPPORT_EMAIL}?subject=${t('modals.complainModal.mailTheme')}&body=${encodeURIComponent(data.content)}`,
    );
    reset();
    onClose();
    dispatch(actions.app.setIsVisibleThanksModal(true));
  };

  return (
    <>
      <Modal isVisible={isVisible} onClose={onClose}>
        <Container onSubmit={handleSubmit(onSubmit)}>
          <Title>{t('modals.complainModal.title')}</Title>
          <Description>{t('modals.complainModal.description')}</Description>
          <Controller
            control={control}
            name="content"
            rules={{ required: true }}
            render={({ field: { value, onChange, ref } }) => (
              <TextareaModal
                value={value}
                onChange={onChange}
                placeholder={t('modals.complainModal.placeholder')}
                ref={ref}
              />
            )}
          />
          <SubmitButton block type="submit" disabled={!isValid}>
            {t('modals.complainModal.button')}
          </SubmitButton>
        </Container>
      </Modal>

      <ThanksModal />
    </>
  );
};

const Container = styled.form`
  text-align: center;
`;
const Title = styled.h3`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 15px;
`;
const Description = styled.p`
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 20px;
`;
const SubmitButton = styled(Button)`
  margin-top: 30px;
`;
