import { ArrowRightIcon } from 'app/assets/svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Contract, ContractTypeEnum } from 'shared/api';
import { captureError } from 'shared/helpers/captureError';
import { selectors } from 'store/ducks/profile';
import { fetchConsultations } from 'store/ducks/profile/actions';
import styled from 'styled-components';

import { Pagination } from '../../pagination';
import ConsultationRow from './components/consultation-row';

interface ExpertWalletProps {}

enum ColumnSortEnum {
  'number' = 'number',
  'date' = 'date',
}

interface Sort {
  column: ColumnSortEnum;
  type: 'ASC' | 'DESC';
}

export const ExpertWalletConsultations: FC<ExpertWalletProps> = () => {
  const consultations = useAppSelector(selectors.selectConsultations);
  const pagination = useAppSelector(selectors.selectConsultations);
  const contract = useSelector(selectors.selectContract);
  const [sort, setSort] = useState<Sort>({ type: 'DESC', column: ColumnSortEnum.date });
  const dispatch = useAppDispatch();

  const { t } = useTranslation('profile.page');

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    getConsultations();
  }, [page]);

  useEffect(() => {
    onPage(1);
  }, []);

  const getConsultations = async () => {
    try {
      await dispatch(fetchConsultations({ page, filter: ['asExpert', 'PAID'] }));
    } catch (e) {
      captureError(e);
    }
  };

  const onPage = (page: number) => {
    if (pagination.pageCount && page <= pagination.pageCount) {
      setPage(page);
    }
  };

  const handleNumberSort = () => {
    if (sort.type === 'ASC') setSort({ type: 'DESC', column: ColumnSortEnum.number });
    else setSort({ type: 'ASC', column: ColumnSortEnum.number });
  };

  const handleDateSort = () => {
    if (sort.type === 'ASC') setSort({ type: 'DESC', column: ColumnSortEnum.date });
    else setSort({ type: 'ASC', column: ColumnSortEnum.date });
  };

  const sortRenderer = () => {
    if (!consultations.data) return;
    if (sort.column === ColumnSortEnum.number) {
      const arr = [...consultations.data];
      return sort.type === 'ASC' ? arr.sort((a, b) => a.id - b.id) : arr.sort((a, b) => b.id - a.id);
    }
    if (sort.column === ColumnSortEnum.date) {
      const arr = [...consultations.data];
      return sort.type === 'ASC'
        ? arr.sort((a, b) => Number(new Date(a.createdAt)) - Number(new Date(b.createdAt)))
        : arr.sort((a, b) => Number(new Date(b.createdAt)) - Number(new Date(a.createdAt)));
    }
  };

  return (
    <>
      <Block>
        <Table>
          <Row contract={contract}>
            <SortableColumn onClick={handleNumberSort}>
              {t('walletTab.no')}
              {sort.column === ColumnSortEnum.number && <SortArrow sort={sort} />}
            </SortableColumn>
            <SortableColumn onClick={handleDateSort}>
              {t('walletTab.date')}
              {sort.column === ColumnSortEnum.date && <SortArrow sort={sort} />}
            </SortableColumn>
            <ColumnName>{t('walletTab.theme')}</ColumnName>
            <ColumnName>{t('walletTab.price')}</ColumnName>
            <ColumnName>{t('walletTab.serviceServices')}</ColumnName>
            <ColumnName>{t('walletTab.income')}</ColumnName>
            <ColumnName>{t('walletTab.return')}</ColumnName>
            {contract.type === ContractTypeEnum.SelfEmployed && <ColumnName>{t('walletTab.status')}</ColumnName>}
            {contract.type === ContractTypeEnum.SelfEmployed && (
              <ReceiptColumnName>{t('walletTab.receipt')}</ReceiptColumnName>
            )}
          </Row>

          {sortRenderer()?.map((consultation, index) => (
            <ConsultationRow
              key={index}
              contract={contract}
              getConsultations={getConsultations}
              consultation={consultation}
            />
          ))}
        </Table>
      </Block>

      {pagination.total > 10 && <Pagination page={page} setPage={onPage} pageCount={pagination.pageCount || 0} />}
    </>
  );
};

const Block = styled.div`
  margin-bottom: 60px;
`;

const SortArrow = styled(ArrowRightIcon)<{ sort: Sort }>`
  transform: ${({ sort }) => (sort.type === 'ASC' ? 'rotate(270deg)' : 'rotate(90deg)')};
  height: 10px;
  width: 10px;
  margin-left: 3px;
`;

const Table = styled.div`
  overflow-x: scroll;

  & > div:first-child {
    border-bottom: none;
  }
`;
const Row = styled.div<{ contract: Contract }>`
  display: grid;
  grid-template-columns: ${({ contract }) =>
    contract.type !== ContractTypeEnum.SelfEmployed
      ? '60px 60px 120px 60px 120px 80px 80px'
      : '60px 60px 120px 60px 120px 110px 80px 60px 60px'};
  padding-bottom: 5px;
  column-gap: 15px;
  border-bottom: 1px solid var(--gray6);
`;
const Column = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.85;
  letter-spacing: normal;
  color: var(--text);
`;

const ColumnName = styled(Column)`
  text-transform: uppercase;
  color: var(--gray);
`;

const ReceiptColumnName = styled(ColumnName)`
  text-align: center;
`;

const SortableColumn = styled(ColumnName)`
  cursor: pointer;
`;
