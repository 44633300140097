import { ReceiptIcon } from 'app/assets/svg';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';
import {
  Consultation,
  ConsultationInvoice,
  Contract,
  ContractTypeEnum,
  InvoiceStatusEnum,
  Receipt,
  ReceiptStatusEnum,
} from 'shared/api';
import { ellipsizeText } from 'shared/helpers';
import { getEventDateAndMonthFromString } from 'shared/helpers/time';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

import { UploadReceiptForm } from './upload-receipt-form';

enum GetSumEnum {
  'amount' = 'amount',
  'commission' = 'commission',
  'revert' = 'revert',
}

interface ConsultationRowProps {
  consultation: Consultation;
  contract: Contract;
  getConsultations: () => void;
}

const ConsultationRow: FC<ConsultationRowProps> = ({ consultation, getConsultations, contract }) => {
  const [addReceiptModal, setAddReceiptModal] = useState(false);
  const [showReceiptInfo, setShowReceiptInfo] = useState(false);

  const { t, i18n } = useTranslation('profile.page');

  const getSum = (invoices: ConsultationInvoice[], target: GetSumEnum) => {
    if (target === GetSumEnum.amount) {
      const sum = invoices.reduce((prev, item) => prev + (item.invoice.amount ? item.invoice.amount : 0), 0);
      return Number(sum.toFixed(2));
    }
    if (target === GetSumEnum.commission) {
      const sum = invoices.reduce((prev, item) => prev + (item.invoice.commission ? item.invoice.commission : 0), 0);
      return Number(sum.toFixed(2));
    }
    if (target === GetSumEnum.revert) {
      const reverted = invoices.filter((item) => item.invoice.status === InvoiceStatusEnum.Reversed);
      const sum = reverted.reduce((prev, item) => prev + (item.invoice.amount ? item.invoice.amount : 0), 0);
      return sum !== 0 ? Number(sum.toFixed(2)) : 0;
    }
    return 0;
  };

  const getReceipt = (receipts?: Receipt[]) => {
    if (!receipts || receipts.length === 0) {
      return <ReceiptText onClick={() => setAddReceiptModal(true)}>{t('walletTab.add')}</ReceiptText>;
    }

    const passed = receipts.find(({ status }) => status === ReceiptStatusEnum.PassedModeration);
    const inModeration = receipts.find(({ status }) => status === ReceiptStatusEnum.WaitingModeration);

    if (!passed && inModeration) {
      return;
    }

    return passed ? (
      <a href={passed.receiptFileUrl}>
        <ReceiptIconCentered />
      </a>
    ) : (
      <ReceiptText onClick={() => setAddReceiptModal(true)}>{t('walletTab.add')}</ReceiptText>
    );
  };

  const getReceiptStatus = (receipts?: Receipt[]) => {
    if (!receipts || receipts.length === 0) {
      return (
        <StatusContainer>
          <StatusText status={ReceiptStatusEnum.WaitingModeration}>Ожидание</StatusText>
        </StatusContainer>
      );
    }

    const passed = receipts.find(({ status }) => status === ReceiptStatusEnum.PassedModeration);
    const inModeration = receipts.find(({ status }) => status === ReceiptStatusEnum.WaitingModeration);
    const rejected = receipts.filter(({ status }) => status === ReceiptStatusEnum.ModerationRejected);
    const rejectedCount = rejected.length;
    if (!passed && inModeration) {
      return (
        <StatusContainer>
          <StatusText status={inModeration.status}>Проверка</StatusText>
        </StatusContainer>
      );
    }
    if (passed) {
      return (
        <StatusContainer>
          <StatusText status={passed.status}>Принят</StatusText>
        </StatusContainer>
      );
    }
    if (!passed && !inModeration && rejectedCount !== 0) {
      return (
        <StatusContainer>
          <StatusText status={rejected[rejectedCount - 1].status}>Отклонен</StatusText>
          <MoreButton onClick={() => setShowReceiptInfo(true)}>Подробнее</MoreButton>
        </StatusContainer>
      );
    }
  };

  const getRejectedReceiptComments = (receipts?: Receipt[] | Receipt) => {
    if (!Array.isArray(receipts)) return null;
    const rejected = receipts.filter(({ status }) => status === ReceiptStatusEnum.ModerationRejected);
    const rejectedCount = rejected.length;
    const lastRejectedReceipt = rejected[rejectedCount - 1];
    const comments = lastRejectedReceipt?.adminComments;
    return comments
      ? comments.map((comment, index) => (
          <ListItem key={index}>{t('receiptRejectionModal.adminComment', { comment })}</ListItem>
        ))
      : null;
  };

  return (
    <Row contract={contract} key={consultation.id}>
      <Column>{t('walletTab.index', { index: consultation.id })}</Column>
      <Column>{getEventDateAndMonthFromString(consultation.createdAt, i18n.language)}</Column>
      <Column title={consultation.category?.name}>{ellipsizeText(consultation.category?.name, 14)}</Column>
      <Column>{t('walletTab.count', { count: getSum(consultation.invoices, GetSumEnum.amount) })}</Column>
      <Column>{t('walletTab.count', { count: getSum(consultation.invoices, GetSumEnum.commission) })}</Column>
      <Column>
        {t('walletTab.count', {
          count:
            getSum(consultation.invoices, GetSumEnum.amount) - getSum(consultation.invoices, GetSumEnum.commission),
        })}
      </Column>
      <Column>{t('walletTab.count', { count: getSum(consultation.invoices, GetSumEnum.revert) })}</Column>
      {contract.type === ContractTypeEnum.SelfEmployed && <Column>{getReceiptStatus(consultation.receipts)}</Column>}
      {contract.type === ContractTypeEnum.SelfEmployed && (
        <ReceiptColumn>{getReceipt(consultation.receipts)}</ReceiptColumn>
      )}
      <Modal isVisible={addReceiptModal} onClose={() => setAddReceiptModal(false)}>
        <UploadReceiptForm
          getConsultations={getConsultations}
          closeModal={() => setAddReceiptModal(false)}
          consultationId={consultation.id}
        />
      </Modal>
      <Modal isVisible={showReceiptInfo} onClose={() => setShowReceiptInfo(false)}>
        <Title>{t('receiptRejectionModal.title')}</Title>
        <Text>{t('receiptRejectionModal.rejectReason')}</Text>
        <List>{getRejectedReceiptComments(consultation.receipts)}</List>
        <ButtonContainer>
          <Button onClick={() => setShowReceiptInfo(false)}>Понятно</Button>
        </ButtonContainer>
      </Modal>
    </Row>
  );
};

export default ConsultationRow;

const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 40px;
`;

const Button = styled.label`
  user-select: none;
  padding-top: 13px;
  width: 100%;
  padding-bottom: 13px;
  padding-left: 20px;
  padding-right: 20px;
  transition: all 0.2s;
  align-items: center;
  color: var(--purple);
  border-radius: 28px;
  background-color: var(--white);
  border-width: 1px;
  border-style: solid;
  border-color: var(--purple);
  opacity: 1;
  outline: none;
  cursor: pointer;
  justify-content: center;
  flex-direction: row;
  display: flex;
  max-height: 46px;

  &:hover {
    background-color: var(--purple);
    color: var(--white);
    border-color: var(--white);
  }
`;

const ButtonContainer = styled.div`
  margin-top: 8px;

  & > button {
    width: 100%;
  }
`;

const ReceiptIconCentered = styled(ReceiptIcon)`
  vertical-align: middle;
`;

const Row = styled.div<{ contract: Contract }>`
  display: grid;
  grid-template-columns: ${({ contract }) =>
    contract.type !== ContractTypeEnum.SelfEmployed
      ? '60px 60px 120px 60px 120px 80px 80px'
      : '60px 60px 120px 60px 120px 110px 80px 60px 60px'};
  padding-bottom: 5px;
  column-gap: 15px;
  border-bottom: 1px solid var(--gray6);
`;
const Column = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.85;
  letter-spacing: normal;
  color: var(--text);
`;

const ReceiptColumn = styled(Column)`
  display: flex;
  justify-content: center;
`;

const ReceiptText = styled.span`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.85;
  letter-spacing: normal;
  color: var(--purple);
  cursor: pointer;
`;

const StatusText = styled.span<{ status: ReceiptStatusEnum }>`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.85;
  letter-spacing: normal;
  color: ${({ status }) => {
    switch (status) {
      case ReceiptStatusEnum.ModerationRejected:
        return 'var(--red)';
      case ReceiptStatusEnum.WaitingModeration:
        return 'var(--text)';
      case ReceiptStatusEnum.PassedModeration:
        return 'var(--green)';
      default:
        break;
    }
  }};
`;

const StatusContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MoreButton = styled.span`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.85;
  cursor: pointer;
  letter-spacing: normal;
  color: var(--purple);
`;

const Text = styled(Title)`
  font-size: 14px;
  font-weight: 700;
  margin: 0;
`;

const List = styled.ul`
  list-style: none;
`;

const ListItem = styled.li`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: var(--text);
`;
