import type { FC, ReactNode } from 'react';
import React, { useCallback } from 'react';
import type { ModalProps as BaseModalProps } from 'react-overlays/Modal';
import BaseModal from 'react-overlays/Modal';
import styled from 'styled-components';

import { Fade, FadeBackdrop } from './Fade';
import { ModalDialog } from './ModalDialog';
import { renderBackdrop } from './renderBackdrop';

export const StyledModal = styled(BaseModal)({
  position: 'fixed',
  zIndex: 1040,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  outline: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'stretch',
});

export const StyledDialogWrapper = styled.div({
  flex: 1,
  overflowY: 'auto',
  outline: 'none',
  position: 'relative',
});

export type ModalProps = BaseModalProps & {
  show: boolean;
  onHide: () => void;
  isHideOnBackdropClick?: boolean;
  children?: ReactNode;
  width?: number;
};

export const Modal: FC<ModalProps> = ({ show, children, onHide, isHideOnBackdropClick, width = 300, ...rest }) => {
  const onWrapperClick = useCallback(
    (event) => {
      if (isHideOnBackdropClick && event.target === event.currentTarget) {
        onHide();
      }
    },
    [onHide, isHideOnBackdropClick],
  );

  return (
    <StyledModal
      show={show}
      renderBackdrop={renderBackdrop}
      transition={Fade}
      backdropTransition={FadeBackdrop}
      onHide={onHide}
      {...rest}
    >
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      <StyledDialogWrapper onClick={onWrapperClick} role="button" tabIndex={0}>
        {/* eslint-enable jsx-a11y/click-events-have-key-events */}
        <ModalDialog width={width} onHide={onHide}>
          {children}
        </ModalDialog>
      </StyledDialogWrapper>
    </StyledModal>
  );
};

Modal.defaultProps = {
  isHideOnBackdropClick: true,
  // @ts-ignore
  children: null,
};
