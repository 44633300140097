import { useAppDispatch } from 'hooks/redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { routes } from 'shared/common/routes';
import { Button } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

interface IReviewMobileModalProps {
  description: string;
}

export const ReviewMobileModal: FC<IReviewMobileModalProps> = ({ description }) => {
  const dispatch = useAppDispatch();
  const isVisible = useSelector(selectors.app.selectIsVisibleReviewMobileModal);
  const { t } = useTranslation('utils');
  const router = useRouter();
  const onClose = React.useCallback(() => {
    if (isVisible) {
      dispatch(actions.app.setIsVisibleReviewMobileModal(false));
    }
  }, [dispatch, isVisible]);

  const handleClick = () => {
    router.push(routes.mobile);
    dispatch(actions.app.setIsVisibleReviewMobileModal(false));
  };
  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <Container>
        <Title>{t('modals.mobileModal.title')}</Title>
        <Description>{description}</Description>
        <SubmitButton block onClick={handleClick}>
          {t('modals.mobileModal.button')}
        </SubmitButton>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  text-align: center;
`;
const Title = styled.h3`
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 15px;
`;
const Description = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 30px;
`;

const SubmitButton = styled(Button)`
  margin-top: 30px;
`;
