import { TFunction } from 'next-i18next';

import { AuthFormSteps } from './types';

export function renderAuthTitle(step: AuthFormSteps, t: TFunction) {
  switch (step) {
    // case AuthFormSteps.number:
    //   return t('authModal.enterTitle');
    // case AuthFormSteps.smsCode:
    //   return t('authModal.enterTitle');
    case AuthFormSteps.promoCode:
      return t('authModal.promoCodeTitle');
    default:
      return t('authModal.enterTitle');
  }
}
