import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/redux';
import { useAutofocus } from 'hooks/useAutofocus';
import { useTranslation } from 'next-i18next';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import ym from 'react-yandex-metrika';
import { ENVIRONMENT_TYPE } from 'shared/common/constants';
import { captureError } from 'shared/helpers/captureError';
import { Input } from 'shared/ui';
import { actions } from 'store/ducks/profile';
import { fetchSelfPromocode } from 'store/ducks/profile/actions';
import styled from 'styled-components';

import { AuthFormSteps, AuthFormValues } from '../../types';

interface AuthPromoCodeFormProps {
  setStep: Dispatch<SetStateAction<AuthFormSteps>>;
}

const PROMOCODE_LENGTH = 4;

const sendDataToMetrics = () => {
  if (ENVIRONMENT_TYPE === 'production') {
    ym('reachGoal', ['Landing_ex-modal_window-promo_code']);
    if (window && window.gtag) {
      window.gtag('event', 'Landing_ex-modal_window-promo_code');
    }
    // window.fbq('trackCustom', 'Landing_ex-modal_window-promo_code');
  }
};

export const AuthPromoCodeForm: FC<AuthPromoCodeFormProps> = ({ setStep }) => {
  const { t } = useTranslation('header.component');
  const { control, setError, clearErrors, setFocus } = useFormContext<AuthFormValues>();
  const dispatch = useAppDispatch();

  useAutofocus<AuthFormValues>('promoCode', setFocus);

  const onNoHave = () => {
    setStep(AuthFormSteps.final);
  };

  const validatePromoCode = async (code: string) => {
    try {
      const action = await dispatch(
        actions.applyPromocodeAsync({
          code,
        }),
      );
      const isAccepted = unwrapResult(action);

      if (isAccepted) {
        sendDataToMetrics();
        toast.success(t('authModal.promoCodeIsAccepted'));
        try {
          const res = await dispatch(fetchSelfPromocode());
          unwrapResult(res);
        } catch (e) {
          toast.error(t('dontSuccessFetchPromocodes'));
        }
        setStep(AuthFormSteps.final);
      } else {
        setError('promoCode', t('authModal.incorrectPromoCode'));
      }
    } catch (e: any) {
      captureError(e);
      clearErrors();
      setError('promoCode', { message: e.message });
    }
  };

  return (
    <>
      <Controller
        name="promoCode"
        control={control}
        render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
          <Input
            onChange={(e) => {
              const { value } = e.target;
              if (value.length > PROMOCODE_LENGTH) {
                return;
              }
              if (value.length === PROMOCODE_LENGTH) {
                validatePromoCode(value);
              }
              onChange(e);
            }}
            centered
            value={value}
            ref={ref}
            error={error?.message}
            placeholder={t('authModal.promoCodePlaceholder')}
          />
        )}
      />
      <ButtonsContainer>
        <Button onClick={onNoHave}>{t('authModal.noHavePromoCode')}</Button>
      </ButtonsContainer>
    </>
  );
};

const ButtonsContainer = styled.div`
  margin-top: 65px;
`;

const Button = styled.div`
  color: var(--purple);
  padding: 7.5px 0;
  text-align: center;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
