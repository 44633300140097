import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { pusherEvents } from 'shared/common/constants';
import { PusherService } from 'shared/lib';
import { selectors } from 'store/ducks';

type useCompanionPrivateChannelProps = {
  isWriting: boolean;
};

const createPrivateChannel = (userId: number) => `private-user_${userId}`;

export const useCompanionPrivateCannel = ({ isWriting }: useCompanionPrivateChannelProps) => {
  const accessToken = useSelector(selectors.profile.selectToken);
  const currentChatRoomId = useSelector(selectors.chatRooms.selectCurrentChatRoomId);
  const chatRoom = useSelector(selectors.chatRooms.selectChatRoomById(currentChatRoomId));
  const user = useSelector(selectors.profile.selectUser);
  const channelRef = useRef<any>(null);

  useEffect(() => {
    if (accessToken && chatRoom && user) {
      const pusher = PusherService.init(accessToken);
      const privateCompanionCannel =
        user.id === chatRoom.expertId
          ? createPrivateChannel(chatRoom.clientId)
          : createPrivateChannel(chatRoom.expertId);

      if (pusher && privateCompanionCannel) {
        const companionChannel = pusher.subscribe(privateCompanionCannel);
        channelRef.current = companionChannel;

        return () => {
          pusher.unsubscribe(privateCompanionCannel);
        };
      }
    }
  }, [accessToken, chatRoom?.expertId, chatRoom?.clientId, user]);

  useEffect(() => {
    if (channelRef.current && chatRoom?.id) {
      if (isWriting) {
        channelRef.current.trigger(pusherEvents.chatRoom.companionWritingStart, { chatRoomId: chatRoom.id });
      } else {
        channelRef.current.trigger(pusherEvents.chatRoom.companionWritingEnd, { chatRoomId: chatRoom.id });
      }
    }
  }, [isWriting, chatRoom?.id]);
};
