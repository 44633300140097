import { Burger, BurgerClose, Chat, LogoDesktopBeta, LogoMobile } from 'app/assets/svg';
import { useAppSelector } from 'hooks/redux';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { TFunction, useTranslation } from 'next-i18next';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ym from 'react-yandex-metrika';
import {
  CONDITION_DESKTOP,
  DESKTOP,
  ENVIRONMENT_TYPE,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE,
} from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { LoginButton, UserMenuButton } from 'shared/ui';
import { selectors } from 'store/ducks';
import { actions } from 'store/ducks/app/slice';
import styled from 'styled-components';
import { AuthFormSteps, AuthModal, DefaultThanksModal } from 'widgets/modals';

import { HeaderDropdownMenu } from '../header-dropdown-menu';
import { HeaderMobileMenu } from '../header-mobile-menu';
import { PermanentReviewExpertModal } from './ui';

const defaultNavbarLinks = (t: TFunction) => [
  {
    text: t('defaultNavbar.findExperts'),
    href: routes.topics,
  },
  {
    text: t('defaultNavbar.becomeExpert'),
    href: `${routes.expertLanding}`,
  },

  {
    text: t('defaultNavbar.aboutConsulty'),
    href: `${routes.about}`,
  },
];

type LinkType = {
  text: string;
  href: string;
};

type HeaderProps = {
  links?: LinkType[];
  fullWidth?: boolean;
  isLoginButtonVisible?: boolean;
};

const sendDataToMetrics = () => {
  if (ENVIRONMENT_TYPE === 'production') {
    ym('reachGoal', ['log_in']);
    if (window && window.gtag) {
      window.gtag('event', 'log_in');
    }
    // window.fbq('trackCustom', 'log_in');
  }
};

const Header: FC<HeaderProps> = ({ links, isLoginButtonVisible = true, fullWidth }) => {
  const { t } = useTranslation('header.component');
  const isAuthentication = useAppSelector(selectors.profile.selectIsAuthentication);
  const user = useAppSelector(selectors.profile.selectUser);
  const unreadChatRoomsCount = useAppSelector(selectors.chatRooms.selectUnreadRoomsIds).length;
  const responsesUnreadCount = useAppSelector(selectors.consultationRequests.selectResponseUnreadCount);
  const requestsUnreadCount = useAppSelector(selectors.consultationRequests.selectRequestUnreadCount);
  const consultationsWithoutReview = useAppSelector(selectors.profile.selectConsultationsWithoutReview);
  const isNeedReview = useAppSelector(selectors.profile.selectIsNeedReview);
  const isModalVisible = useAppSelector(selectors.app.selectIsVisiblePermanentReviewModal);
  const [navbar, setNavbar] = useState<LinkType[]>([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
  const [stepForAuthModal, setStepForAuthModal] = useState(AuthFormSteps.number);
  const dispatch = useDispatch();
  const router = useRouter();

  const handleReviewModal = () => {
    if (isNeedReview && !isModalVisible) {
      dispatch(actions.setIsVisiblePermanentReviewModal(true));
    }
  };

  useEffect(() => {
    handleReviewModal();
  }, [isNeedReview, isModalVisible, user.id]);

  const onOpenAuthModal = () => {
    dispatch(actions.showAuthModal());
    sendDataToMetrics();
  };

  useEffect(() => {
    if (user.id && (!user.firstName || !user.lastName || !user.email)) {
      setStepForAuthModal(AuthFormSteps.promoCode);
      onOpenAuthModal();
    }
  }, [user]);

  useEffect(() => {
    window.addEventListener('resize', closeAllMenu);
    return () => {
      window.removeEventListener('resize', closeAllMenu);
    };
  }, []);

  useEffect(() => {
    if (router.query.window === 'auth') {
      onOpenAuthModal();
    }
  }, [router]);

  useEffect(() => {
    // setNavbar(links || defaultNavbarLinks(t));
    setNavbar(defaultNavbarLinks(t));
  }, [links]);

  const unreadCount = useMemo(
    () => unreadChatRoomsCount + Object.keys(responsesUnreadCount).length + Object.keys(requestsUnreadCount).length,
    [unreadChatRoomsCount, responsesUnreadCount, requestsUnreadCount],
  );

  const closeAllMenu = () => {
    setIsDropdownMenuOpen(false);
    setIsMobileMenuOpen(false);
  };

  const onClickBurger = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const onCloseMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const onOpenUserMenu = () => {
    setIsDropdownMenuOpen(true);
  };

  const onCloseUserMenu = () => {
    setIsDropdownMenuOpen(false);
  };

  return (
    <>
      {isNeedReview && <PermanentReviewExpertModal consultation={consultationsWithoutReview[0]} />}
      <DefaultThanksModal />
      <AuthModal initStep={stepForAuthModal} />
      <Wrapper>
        <Container fullWidth={fullWidth} className="container">
          <BurgerButton onClick={onClickBurger}>{isMobileMenuOpen ? <BurgerClose /> : <Burger />}</BurgerButton>
          <Link href={routes.home}>
            <LogoLink>
              <LogoSmall />
              <LogoBig />
            </LogoLink>
          </Link>
          <Navbar>
            {navbar.map((item, index) => {
              const onPress = () => {
                if (item.text.includes('Найти эксперта')) {
                  if (ENVIRONMENT_TYPE === 'production') {
                    ym('reachGoal', ['Main-find_expert']);
                    if (window && window.gtag) {
                      window.gtag('event', 'Main-find_expert');
                    }
                    // window.fbq('trackCustom', 'Main-find_expert');
                  }
                }
              };

              // if (item.text.includes('Стать экспертом') && user.isExpert) return null;
              return (
                <Link href={item.href} key={index}>
                  <NavLink href={item.href} onClick={onPress}>
                    {item.text}
                  </NavLink>
                </Link>
              );
            })}
            <Link href={`${routes.stopInfoBis}`}>
              <a>
                <img src="/img/header-add-logo-gypsy-2.png" srcSet="/img/header-add-logo-gypsy-2@2x.png 2x" alt="" />
              </a>
            </Link>
          </Navbar>
          {isLoginButtonVisible && isAuthentication && (
            <Link href={routes.chat} passHref>
              <a>
                <MessageContainer>
                  <Chat />
                  {!!unreadCount && (
                    <UnreadCount>{unreadCount < 100 ? unreadCount : <MoreMessage>99+</MoreMessage>}</UnreadCount>
                  )}
                </MessageContainer>
              </a>
            </Link>
          )}
          {isLoginButtonVisible &&
            (isAuthentication ? (
              <AuthUserButton>
                <UserMenuButton profile={user} onClick={onOpenUserMenu} isOpenMenu={isDropdownMenuOpen} />
                {isDropdownMenuOpen && <HeaderDropdownMenu onCloseDropdown={onCloseUserMenu} />}
              </AuthUserButton>
            ) : (
              <LoginButton onClick={onOpenAuthModal} />
            ))}
        </Container>
        {isMobileMenuOpen && <HeaderMobileMenu onCloseMenu={onCloseMobileMenu} />}
      </Wrapper>
    </>
  );
};

export { Header };

const Wrapper = styled.header`
  position: sticky;
  z-index: 998;
  top: 0;
  background-color: var(--white);
  width: 100%;
  border-bottom: 1px solid var(--gray5);

  ${CONDITION_DESKTOP} {
    min-width: ${DESKTOP}px;
  }
`;

const Container = styled.header<{ fullWidth?: boolean }>`
  &&& {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${HEADER_HEIGHT_MOBILE};
    background-color: var(--white);
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'unset')};

    ${CONDITION_DESKTOP} {
      height: ${HEADER_HEIGHT_DESKTOP};
    }
  }
`;

const LogoLink = styled.a`
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const LogoSmall = styled(LogoMobile)`
  ${CONDITION_DESKTOP} {
    display: none;
  }
`;
const LogoBig = styled(LogoDesktopBeta)`
  display: none;

  ${CONDITION_DESKTOP} {
    display: block;
  }
`;

const BurgerButton = styled.div`
  width: 92px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  ${CONDITION_DESKTOP} {
    display: none;
  }
`;

const Navbar = styled.nav`
  display: none;
  align-items: center;

  ${CONDITION_DESKTOP} {
    display: flex;
    flex-grow: 1;
    margin-left: 51px;
  }
`;

const NavLink = styled.a`
  cursor: pointer;
  font-size: 15px;
  color: var(--text);
  margin: 0 20px;
`;

const MessageContainer = styled.div`
  position: relative;
  width: 92px;
  text-align: right;
  margin-right: 25px;
  height: 25px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const UnreadCount = styled.div`
  position: absolute;
  top: -10px;
  right: -12px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: var(--red2);
  border: 2px solid var(--white);
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: var(--white);
  line-height: 19px;
`;

const MoreMessage = styled.span`
  font-size: 8px;
`;

const AuthUserButton = styled.div`
  margin-left: 25px;
  position: relative;
  display: none;

  ${CONDITION_DESKTOP} {
    display: block;
  }
`;
