import { useTranslation } from 'next-i18next';
import React, { FC, useCallback, useState } from 'react';
import { Button } from 'routes/landing/components/Button';
import { Modal } from 'routes/landing/components/Modal';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import styled from 'styled-components';

import { ModalContentExpertProfile } from './ModalContent';

export const NewTopicForm: FC = () => {
  const { t } = useTranslation('profile.page');

  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <Container>
      <Title>{t('newTopicTitle')}</Title>
      <Note style={{ color: '#79818c' }}>{t('newTopicText')}</Note>
      {/* <AddButton onClick={() => {}}>
        {t('newTopicButtonLabel')}
      </AddButton> */}

      <Button $size="50" isShadow type="button" onClick={openModal}>
        Предложите свою тему
      </Button>
      <Modal show={isOpen} onHide={closeModal}>
        <ModalContentExpertProfile />
      </Modal>
      {/* <MailtoWrapper>
        <StyledMailto href="mailto:support@consulty.online" target="_blank">
          {t('newTopicButtonLabel')}
        </StyledMailto>
      </MailtoWrapper> */}
    </Container>
  );
};

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  max-width: 560px;
  letter-spacing: normal;
  padding-bottom: 20px;
  color: var(--gray);

  & > a {
    color: var(--purple);
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`;

const Container = styled.div`
  margin-top: 90px;

  input {
    width: 100%;
    ${CONDITION_DESKTOP} {
      width: 60%;
    }
  }
  button {
    margin-top: 0;
    width: 100%;
    ${CONDITION_DESKTOP} {
      width: unset;
    }
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black2);
  padding-bottom: 10px;
  padding-top: 17px;
`;
