import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { captureError } from 'shared/helpers/captureError';
import { SwitchButton } from 'shared/ui';
import { actions } from 'store/ducks';
import styled from 'styled-components';

interface FreeConsultFormValues {
  value: boolean;
}

interface FreeConsultFormProps {
  isActive: boolean;
  stayCount: number;
}

export const FreeConsultForm: FC<FreeConsultFormProps> = ({ isActive, stayCount }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('profile.page');

  const { control } = useForm<FreeConsultFormValues>({
    defaultValues: {
      value: isActive,
    },
  });

  const handleChange = async (value: boolean) => {
    try {
      await dispatch(actions.profile.fetchUpdateIam({ hasFreeConsultations: value }));
      toast.success(t('saveInfo'));
    } catch (error) {
      captureError(error);
      toast.error(error.message || t('somethingWrong'));
    }
  };

  return (
    <Container>
      <Title>{t('freeConsultTitle')}</Title>
      <Note>{t('freeConsultNote')}</Note>
      <Controller
        name="value"
        render={({ field: { value, onChange } }) => (
          <CheckboxContainer>
            <CheckBoxLabel>{t('getFreeConsult')}</CheckBoxLabel>
            <SwitchButton
              checked={value}
              onChange={(val) => {
                handleChange(val);
                onChange(val);
              }}
            />
          </CheckboxContainer>
        )}
        control={control}
      />
      {/* <CheckBoxLabel>{`${t('stay')} ${stayCount}`}</CheckBoxLabel> */}
    </Container>
  );
};

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  max-width: 560px;
  letter-spacing: normal;
  padding-bottom: 20px;
  color: var(--gray);

  & > a {
    color: var(--purple);
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`;
const Container = styled.div`
  margin-top: 90px;

  input {
    width: 60%;
  }
  button {
    margin-top: 40px;
  }
`;
const Title = styled.h2`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black2);
  padding-bottom: 10px;
  padding-top: 17px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${CONDITION_DESKTOP} {
    width: 50%;
  }
`;

const CheckBoxLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--text);
`;
