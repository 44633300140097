export enum ContractFromVariants {
  INDIVIDUAL = 'INDIVIDUAL',
  IP = 'IP',
  OOO = 'OOO',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
}

export interface ContractFormValues {
  variant: ContractFromVariants;
  agencyAgreement?: boolean;
  agencyAgreementTimestamp?: string;
  dataPolicyAgreement?: boolean;
  dataPolicyAgreementTimestamp?: string;
  fullCompanyName: string;
  INN?: string;
  selfEmployedId: string;
  KPP: string;
  directorFullNameNominativeCase: string;
  directorFullNameParentCase: string;
  directorPost: string;
  legalAddress: string;
  physicalAddress: string;
  phone: string;
  email: string;
  bankName: string;
  BIK: string;
  correspondedAccount: string;
  paymentAccount: string;
  EQRULPhoto: string;
  certificateOfRegistrationPhoto: File;
  fullName: string;
  OGRNIP: string;
  OGRNIPDate: string;
  addressOfRegistration: string;
  passportPhoto: File;
  selfWithPassport: File;
  registrationInPassportPhoto: File;
  EQURL: File;
  birthday: string;
  birthPlace: string;
  passportSeries: string;
  passportNumber: string;
  whenPassportIssued: string;
  whoPassportIssued: string;
}
