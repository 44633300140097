import { useTranslation } from 'next-i18next';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectors } from 'store/ducks/app/slice';
import styled from 'styled-components';

import { AuthFormValues } from '../../types';

interface TimerProps {
  setWait: (v: boolean) => void;
}

export const Timer: FC<TimerProps> = () => {
  const counter = useSelector(selectors.selectAuthModalCounter);
  const { clearErrors } = useFormContext<AuthFormValues>();

  const { t } = useTranslation('header.component');

  useEffect(() => {
    if (counter === 1) {
      clearErrors('code');
    }
  }, [counter]);

  return <Text>{`${t('authModal.timerFirstPart')} ${counter} ${t('authModal.timerSecondPart')}`}</Text>;
};

const Text = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  max-width: 210px;
  margin: 0 auto;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
`;
