import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={26} height={26} viewBox="0 0 26 26" {...props}>
      <path
        d="M18.517 9.547l-1.115 1.115-2.27-2.27-.861.862 2.27 2.27-5.567 5.566-2.27-2.27-.86.861 2.269 2.27-.555.555-.01-.011a.446.446 0 01-.28.202l-2.117.471a.436.436 0 01-.407-.118.438.438 0 01-.118-.407l.471-2.116a.44.44 0 01.202-.28l-.01-.01 8.958-8.96a.34.34 0 01.476 0l1.794 1.794a.337.337 0 010 .476z"
        fill="#79818C"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
