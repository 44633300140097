import { BackArrowPurple } from 'app/assets/svg';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { CONDITION_DESKTOP, DEFAULT_CATEGORY } from 'shared/common/constants';
import { coverImageStyle } from 'shared/helpers';
import { selectors } from 'store/ducks';
import styled from 'styled-components';

interface RequestHeaderProps extends HTMLAttributes<HTMLDivElement> {
  categoryId: number | null;
}

export const RequestHeader: FC<RequestHeaderProps> = ({ categoryId, ...props }) => {
  const { t } = useTranslation('chat.page');
  const router = useRouter();
  const requestRoom = useSelector(selectors.consultationRequests.selectRequestRoomById(categoryId));

  const goBack = () => router.replace({ query: { activeTab: 'requests' } });

  return (
    <Container {...props}>
      <BackButton onClick={goBack} />
      <Image
        $url={
          requestRoom?.category.iconUrl ||
          (requestRoom?.category.rubrics && requestRoom?.category.rubrics[0]?.iconUrl) ||
          DEFAULT_CATEGORY
        }
      />
      <Text>
        <Name>{requestRoom?.category.name}</Name>
        <Description>{t('request.last72hours')}</Description>
      </Text>
    </Container>
  );
};

const Container = styled.div`
  height: 60px;
  width: 100vw;
  display: flex;
  padding: 0 10px 0 15px;
  align-items: center;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  ${CONDITION_DESKTOP} {
    padding: 0 20px;
    width: 100%;
  }
`;
const BackButton = styled(BackArrowPurple)`
  ${CONDITION_DESKTOP} {
    display: none;
  }
`;
const Image = styled.div<{ $url?: string }>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  flex-shrink: 0;
  margin: 0 10px 0 20px;
  ${({ $url }) => $url && { ...coverImageStyle($url) }}
  ${CONDITION_DESKTOP} {
    display: none;
  }
`;
const Text = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;
const Name = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  ${CONDITION_DESKTOP} {
    font-weight: 500;
    font-size: 13px;
  }
`;
const Description = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: var(--gray);
`;
