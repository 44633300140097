import { useTranslation } from 'next-i18next';
import React, { FC, HTMLAttributes, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CONDITION_DESKTOP, DEFAULT_AVATAR } from 'shared/common/constants';
import { coverImageStyle, makeLocaleDate } from 'shared/helpers';
import { selectors } from 'store/ducks';
import styled from 'styled-components';

import { ResponseMessage } from '../response-message';

interface ResponseBodyProps extends HTMLAttributes<HTMLDivElement> {
  requestId: number | null;
}

export const ResponseBody: FC<ResponseBodyProps> = ({ requestId, ...props }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('chat.page');
  const user = useSelector(selectors.profile.selectUser);
  const request = useSelector(selectors.consultationRequests.selectResponseRoomById(requestId));

  const renderMessages = useMemo(
    () =>
      request?.offers?.map((offer) => (
        <ResponseMessage
          key={offer.id}
          offer={offer}
          user={user}
          category={{ id: request.categoryId, name: request.category.name }}
        />
      )),
    [requestId, request],
  );

  return (
    <Container {...props}>
      {renderMessages}
      <QuestionMessage>
        <Avatar $url={request?.client.avatarUrl} />
        <MessageContainer>
          <Message>{request?.content}</Message>
          <Time>{makeLocaleDate(request?.createdAt, language).format('DD MMMM HH:mm')}</Time>
        </MessageContainer>
      </QuestionMessage>
      <SystemMessage>{t('response.body.systemMessage72hours')}</SystemMessage>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 0 15px 15px;
  ${CONDITION_DESKTOP} {
    padding: 0 35px 35px;
  }
`;

const QuestionMessage = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 505px;
  margin-bottom: 40px;
`;
const Avatar = styled.div<{ $url?: string }>`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 18px;
  ${({ $url }) => ({ ...coverImageStyle($url || DEFAULT_AVATAR) })}
  ${CONDITION_DESKTOP} {
    border-radius: 10px;
    width: 40px;
    height: 40px;
  }
`;
const MessageContainer = styled.div`
  background-color: var(--gray10);
  padding: 7px 12px;
  border-radius: 20px;
  ${CONDITION_DESKTOP} {
    padding: 20px 15px 15px;
    border-radius: 10px 10px 10px 2px;
    color: #2a2a2a;
  }
`;
const Message = styled.div`
  ${CONDITION_DESKTOP} {
    font-size: 15px;
    line-height: 20px;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin-bottom: 10px;
  }
`;
const Time = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: var(--gray);
`;
const SystemMessage = styled.div`
  font-size: 14px;
  text-align: center;
  color: var(--gray9);
  margin-bottom: 30px;
  padding-top: 15px;
  ${CONDITION_DESKTOP} {
    padding-top: 35px;
  }
`;
