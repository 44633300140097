import { Category } from 'shared/api';

export function mappingCategory(categories: Category[]) {
  const subCategory = new Map<number, Category[]>();

  categories.forEach((c) => {
    if (c.rootCategory) {
      if (subCategory.has(c.rootCategory.id)) {
        const value = subCategory.get(c.rootCategory.id);
        subCategory.set(c.rootCategory.id, [...value!, c]);
      } else {
        subCategory.set(c.rootCategory.id, [c]);
      }
    }
  });
  return subCategory;
}
