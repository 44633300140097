import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { memo, useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { toast } from 'react-toastify';
import { Button } from 'routes/landing/components/Button';
import { InputField } from 'routes/landing/fields/InputField';
import { CreateSuggestedCategoryDtoSourceEnum } from 'shared/api';
import { captureError } from 'shared/helpers/captureError';
import { actions } from 'store/ducks/rubrics';
import styled from 'styled-components';

const StyledWrapper = styled.div({
  padding: '30px 20px 45px',
});

const StyledTitle = styled.div({
  fontSize: 19,
  fontWeight: 'bold',
  lineHeight: 1.05,
  textAlign: 'center',
  color: '#232832',
  marginBottom: 15,
});

const StyledDescription = styled.div({
  fontSize: 13,
  fontWeight: 500,
  lineHeight: 1.54,
  textAlign: 'center',
  color: '#232832',
});

const StyledForm = styled.form({
  marginTop: 30,
});

const StyledButtonWrapper = styled.div({
  marginTop: 15,
});

export const ModalContentExpertProfile = memo(() => {
  const [isSent, setIsSent] = useState(false);
  const { t } = useTranslation('utils');
  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    async (values: { suggestedCategoryName: string; source: CreateSuggestedCategoryDtoSourceEnum }) => {
      await dispatch(
        actions.createSuggestedCategoryFromUserAsync({
          suggestedCategoryName: values.suggestedCategoryName,
          source: CreateSuggestedCategoryDtoSourceEnum.BecomeAnExpert,
        }),
      )
        .then(() => setIsSent(true))
        .catch((e) => {
          captureError(e);
          toast.error(t('somethingWrong'));
        });
    },
    [isSent],
  );

  if (isSent) {
    return (
      <StyledWrapper>
        <StyledTitle>Спасибо!</StyledTitle>
        <StyledDescription>Обязательно рассмотрим ваше предложение 😇</StyledDescription>
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper>
      <StyledTitle>Предложите свою тему</StyledTitle>

      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, values }) => (
          <StyledForm onSubmit={handleSubmit}>
            <InputField name="suggestedCategoryName" placeholder="Например астрология" />
            <StyledButtonWrapper>
              <Button type="submit" $block disabled={submitting || !values.suggestedCategoryName}>
                Предложить
              </Button>
            </StyledButtonWrapper>
          </StyledForm>
        )}
      </Form>
    </StyledWrapper>
  );
});
