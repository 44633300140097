import { useAppDispatch } from 'hooks/redux';
import { useVkMetrics } from 'hooks/useVkMetrics';
import { useTranslation } from 'next-i18next';
import React, { FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ym from 'react-yandex-metrika';
import { AboutSelfInput, PhotoInput } from 'routes/profile/ui';
import { CONDITION_DESKTOP, CONDITION_MOBILE, ENVIRONMENT_TYPE } from 'shared/common/constants';
import { IErrors } from 'shared/common/types';
import { captureError } from 'shared/helpers/captureError';
import { ActionButtonStatusNames, Button } from 'shared/ui';
import { selectors } from 'store/ducks';
import { createContentChangeRequest } from 'store/ducks/profile/actions';
import { selectContentChangeRequest } from 'store/ducks/profile/selectors';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

export interface AboutSelfFormValues {
  error?: string;
  aboutSelf: string;
  avatarUrl: string;
}

interface AboutSelfFormProps {
  errors?: IErrors[];
  status?: ActionButtonStatusNames;
}

export const AboutSelfForm: FC<AboutSelfFormProps> = ({ errors, status = ActionButtonStatusNames.no }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation('profile.page');

  const [userPhoto, setUserPhoto] = useState('');
  const vkMetrics = useVkMetrics();

  const user = useSelector(selectors.profile.selectUser);
  const contentChangeRequest = useSelector(selectContentChangeRequest);

  const avatarUrl: string | null = useMemo(() => {
    if (contentChangeRequest && contentChangeRequest.avatarUrl) {
      return contentChangeRequest.avatarUrl;
    }
    return user.avatarUrl || null;
  }, [contentChangeRequest, user]);

  let avatarError = [] as IErrors[];
  let aboutError = [] as IErrors[];

  if (errors && errors.length) {
    avatarError = errors.filter((item) => item.title === 'Фото');
    aboutError = errors.filter((item) => item.title === 'О себе');
  }
  let initialAboutSelf = '';

  if (contentChangeRequest && contentChangeRequest.aboutSelf) {
    initialAboutSelf = contentChangeRequest.aboutSelf;
  } else {
    initialAboutSelf = user.aboutSelf || '';
  }

  const dispatch = useAppDispatch();

  const { control, handleSubmit, watch } = useForm<AboutSelfFormValues>({
    defaultValues: {
      aboutSelf: initialAboutSelf,
    },
  });

  const aboutSelf = watch('aboutSelf');

  const sendAnalyticsData = () => {
    if (ENVIRONMENT_TYPE === 'production') {
      vkMetrics?.goal('lead');
      ym('reachGoal', ['createexpert']);
    }
  };

  const onSubmit = async ({ aboutSelf }: AboutSelfFormValues) => {
    try {
      if (userPhoto || avatarUrl) {
        if (userPhoto) {
          await dispatch(createContentChangeRequest({ aboutSelf, avatarUrl: userPhoto }));
        }
        if (!userPhoto && avatarUrl) {
          await dispatch(createContentChangeRequest({ aboutSelf, avatarUrl }));
        }
      }
      sendAnalyticsData();
      // toast.success(t('requestHasBeenSend'));
      setShowModal(true);
    } catch (e) {
      captureError(e);
      toast.error(t('somethingWrong'));
    }
  };

  return (
    <Container>
      <Title>{t('expertProfileTitle')}</Title>
      <Note>
        <NoteText>{t('expertProfileSubTitle')}</NoteText>
      </Note>
      <PhotoInputContainer>
        <PhotoInput setValue={setUserPhoto} avatarUrl={avatarUrl} />
      </PhotoInputContainer>
      {avatarError && avatarError.length > 0 && (
        <ErrorMessagesWrapper>
          <ErrorMessageText>{avatarError[0].note}</ErrorMessageText>
        </ErrorMessagesWrapper>
      )}
      <PhotoInputContainer>
        <AboutSelfInput control={control} />
      </PhotoInputContainer>
      {aboutError && aboutError.length > 0 && (
        <ErrorMessagesWrapper>
          <ErrorMessageText>{aboutError[0].note}</ErrorMessageText>
        </ErrorMessagesWrapper>
      )}
      <ButtonWrapper>
        <StyledButton
          disabled={aboutSelf === initialAboutSelf || (!userPhoto && !avatarUrl) || !aboutSelf}
          onClick={handleSubmit(onSubmit)}
        >
          {t('sendToCheck')}
        </StyledButton>
      </ButtonWrapper>
      <ProcessingMessagesWrapper>
        {status === ActionButtonStatusNames.processing && (
          <ProcessingMessageText>{t('processingMessages.inProgress')}</ProcessingMessageText>
        )}
        {status === ActionButtonStatusNames.fail && (
          <ProcessingMessageText>{t('processingMessages.fail')}</ProcessingMessageText>
        )}
        {status === ActionButtonStatusNames.success && (
          <ProcessingMessageText>{t('processingMessages.success')}</ProcessingMessageText>
        )}
      </ProcessingMessagesWrapper>
      <Modal isVisible={showModal} onClose={() => setShowModal(false)}>
        <ModalTitle>Уже проверяем заявку!</ModalTitle>
        <Note>Это займет полчаса в рабочее время с 10:00 до 20:00.</Note>
        <Note>
          После проверки профиль появится в разделе <a href="/app/topics"> Найти эксперта</a>. Пока можете узнать в
          <a href="/app/academy"> Академии экспертов</a>, как эффективнее работать с сервисом.
        </Note>
        <ModalButton onClick={() => setShowModal(false)} color="purple">
          Понятно
        </ModalButton>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  padding: 30px 50px 40px 29px;
  border-radius: 12px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 90px;

  ${CONDITION_MOBILE} {
    padding: 20px 20px 30px;
  }

  & > button {
    margin-top: 40px;
    width: 100%;

    ${CONDITION_DESKTOP} {
      max-width: 280px;
      width: 560px;
    }
  }
`;

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;

  a {
    color: var(--purple);
  }
`;

const NoteText = styled.span`
  color: #79818c;
`;

const ModalButton = styled(Button)`
  &&& {
    margin-bottom: -20px;
    border: solid 0.5px var(--purple);
    background-color: var(--white);
    color: var(--purple);
    &:hover {
      color: var(--white);
      background-color: var(--purple);
    }
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #232832;
  padding-bottom: 10px;
`;

const ModalTitle = styled(Title)`
  font-size: 19px;
`;

const ProcessingMessagesWrapper = styled.div`
  margin-top: 10px;
`;

const ProcessingMessageText = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #8b33ff;
`;

const ErrorMessagesWrapper = styled.div`
  margin-top: 10px;
`;

const ErrorMessageText = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ff3366;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  width: 220px;
`;

const StyledButton = styled(Button)`
  span {
    padding: 2px 4px;
    margin: 0;
  }
`;

const PhotoInputContainer = styled.div`
  margin-top: 40px;
  h3 {
    font-size: 17px;
  }
`;
