import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CreateCustomRequestDtoTypeEnum } from 'shared/api';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { captureError } from 'shared/helpers/captureError';
import { linkPattern } from 'shared/helpers/patterns';
import { ActionButtonStatusNames, Button, Input } from 'shared/ui';
import { actions } from 'store/ducks/profile';
import styled from 'styled-components';

interface CommissionFormValues {
  link: string;
}

interface CommissionFormProps {
  // errors: IErrors[];
  status: ActionButtonStatusNames;
  comment: string | undefined;
}

export const CommissionForm: FC<CommissionFormProps> = ({ status = ActionButtonStatusNames.no, comment = '' }) => {
  const { t } = useTranslation('profile.page');
  const { t: error } = useTranslation('errors.messages');
  const [localProcessing, setLocalProcessing] = useState(false);
  const dispatch = useAppDispatch();

  const { control, handleSubmit } = useForm<CommissionFormValues>({
    defaultValues: {
      link: '',
    },
  });

  const onSubmit = async ({ link }: CommissionFormValues) => {
    try {
      await dispatch(
        actions.createOneCustomRequestAsync({
          type: CreateCustomRequestDtoTypeEnum.SpecialTariff,
          payload: { socialSiteUrl: link },
        }),
      );

      toast.success(t('requestHasBeenSend'));
      dispatch(actions.fetchGetManyBaseCustomRequest());
      setLocalProcessing(true);
    } catch (e) {
      captureError(e);
      toast.error(t('somethingWrong'));
    }
  };

  return (
    <Container>
      <Label>{t('specialRate')}</Label>
      <Title>{t('commission')}</Title>
      <Note style={{ color: '#79818c' }}>{t('largeSubsCommission')}</Note>
      <Note>{t('forThis')}</Note>
      <Note>{`1. ${t('commission1')}`}</Note>
      <Note>{`2. ${t('commission2')}`}</Note>
      <Note>{`3. ${t('commission3')}`}</Note>
      <Note style={{ color: '#79818c', fontWeight: 'bold', paddingBottom: 30 }}>{t('reqBeforeCommission')}</Note>
      <Controller
        name="link"
        rules={{
          required: { value: true, message: error('required') },
          pattern: { value: linkPattern, message: error('incorrectLink') },
        }}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <Input
              value={value}
              onChange={onChange}
              error={error?.message}
              placeholder="Ссылка на вашу страницу"
              style={{ maxWidth: 420 }}
            />
            {/* {status === ActionButtonStatusNames.fail && (
              <ErrorMessagesWrapper>
                <ErrorMessageText>{t('commissionForm.error')}</ErrorMessageText>
              </ErrorMessagesWrapper>
            )} */}
            <ButtonWrapper>
              <StyledButton onClick={handleSubmit(onSubmit)} disabled={value.length === 0}>
                {t('sendVerification')}
              </StyledButton>
            </ButtonWrapper>
            <ProcessingMessagesWrapper>
              {(status === ActionButtonStatusNames.processing || localProcessing) &&
                status !== ActionButtonStatusNames.fail &&
                status !== ActionButtonStatusNames.success && (
                  <ProcessingMessageText>{t('commissionForm.processing')}</ProcessingMessageText>
                )}
              {status !== ActionButtonStatusNames.processing &&
                status !== ActionButtonStatusNames.success &&
                status === ActionButtonStatusNames.fail && (
                  <ProcessingMessageTextError>
                    {t('promoCodeForm.error')} {comment}
                  </ProcessingMessageTextError>
                )}
              {status !== ActionButtonStatusNames.processing &&
                status !== ActionButtonStatusNames.fail &&
                status === ActionButtonStatusNames.success && (
                  <ProcessingMessageText>{t('commissionForm.success')}</ProcessingMessageText>
                )}
            </ProcessingMessagesWrapper>
          </>
        )}
      />
    </Container>
  );
};

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  max-width: 560px;
  letter-spacing: normal;
  padding-bottom: 20px;
  color: var(--gray);

  & > a {
    color: var(--purple);
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`;

const Container = styled.div`
  margin-top: 90px;

  input {
    width: 100%;
    ${CONDITION_DESKTOP} {
      width: 60%;
    }
  }
  button {
    margin-top: 40px;
    width: 100%;
    ${CONDITION_DESKTOP} {
      width: unset;
    }
  }
`;
const Label = styled.span`
  background-image: linear-gradient(to right, #ae4cfe 5%, #993dfa 51%, #812df6);
  border-radius: 12.5px;
  color: var(--white);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 3px 9px;
  text-align: center;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black2);
  padding-bottom: 10px;
  padding-top: 17px;
`;

const ProcessingMessagesWrapper = styled.div`
  margin-top: 10px;
`;

const ProcessingMessageText = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #8b33ff;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  width: 220px;
`;

const StyledButton = styled(Button)`
  span {
    padding: 2px 4px;
    margin: 0;
  }
`;

const ProcessingMessageTextError = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ff3366;
`;
