import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ContractStatusEnum } from 'shared/api';
import { routes } from 'shared/common/routes';
import { getEventDateFromString } from 'shared/helpers';
import { Badge, Button } from 'shared/ui';
import { selectors } from 'store/ducks';
import styled from 'styled-components';

// TODO удалить
export const ExpertContract: FC = () => {
  const user = useSelector(selectors.profile.selectUser);
  const contract = useSelector(selectors.profile.selectContract);
  const { t, i18n } = useTranslation('profile.page');

  const router = useRouter();
  const onFormalizeContract = () => {
    if (contract.id) {
      router.push(routes.contractCorrect);
    } else {
      router.push(routes.contract);
    }
  };

  return (
    <Block>
      <SmallTitle>{t('walletTab.yourContract')}</SmallTitle>

      {contract.id && (
        <>
          <Note>
            {t('walletTab.yourContractNumber', {
              number: contract.id,
              date: getEventDateFromString(contract.createdAt, i18n.language),
            })}
          </Note>
          {user.tariffPlan && (
            <Note>{t('walletTab.yourContractCommission', { count: user.tariffPlan.commissionPercent })}</Note>
          )}
          {contract.status !== ContractStatusEnum.Accepted && contract.adminComments && (
            <>
              <BoldNote>{t('walletTab.comments')}</BoldNote>
              {contract?.adminComments.slice(-1).map((comment, index) => (
                <Note key={index}>{comment}</Note>
              ))}
            </>
          )}
          <Badge label={t(`walletTab.${contract.status}`)} />
        </>
      )}
      {contract.status !== ContractStatusEnum.Accepted && (
        <Button bordered onClick={onFormalizeContract}>
          {contract.id ? t('walletTab.correctContract') : t('walletTab.formalizeContract')}
        </Button>
      )}
    </Block>
  );
};

const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 40px;
`;

const SmallTitle = styled(Title)`
  font-size: 17px;
  margin-bottom: 10px;
`;

const Block = styled.div`
  margin-bottom: 60px;

  & > button {
    margin-top: 10px;
  }
`;

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  max-width: 500px;
  color: var(--gray);
  margin-bottom: 10px;
`;

const BoldNote = styled(Note)`
  font-weight: bold;
  color: var(--text);
`;
