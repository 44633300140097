import { HeartWhiteIcon } from 'app/assets/svg';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import router from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { api, Consultation } from 'shared/api';
import { routes } from 'shared/common/routes';
import { buildRoute } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { Button, Checkbox, RatingField, TextareaModal } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

const achievementsArray: ('promptly' | 'positive' | 'savvy')[] = ['promptly', 'positive', 'savvy'];

type Props = {
  consultation: Consultation;
};
type Achievements = { promptly: boolean; positive: boolean; savvy: boolean };

export const PermanentReviewExpertModal: FC<Props> = ({ consultation }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('utils');
  const [rating, setRating] = useState(0);
  const [reviewContent, setReviewContent] = useState('');
  const [returnMoney, setReturnMoney] = useState(false);
  const [achievements, setAchievements] = useState<Achievements>({
    promptly: false,
    positive: false,
    savvy: false,
  });
  const [isSendingReview, setIsSendingReview] = useState(false);
  const isModalVisible = useAppSelector(selectors.app.selectIsVisiblePermanentReviewModal);
  const consultationsWithoutReview = useAppSelector(selectors.profile.selectConsultationsWithoutReview);

  const consultationInfo = useMemo(() => {
    const consultationCreatedAt = dayjs(consultation.createdAt).format('DD.MM.YYYY');
    return `${consultationCreatedAt} ${t('modals.expertReview.inCategory')} ${consultation.category?.name}`;
  }, [consultation]);

  const resetReviewContent = async () => {
    setRating(0);
    setAchievements({
      promptly: false,
      positive: false,
      savvy: false,
    });
    setReviewContent('');
  };

  const handleDefaultThanksModal = async () => {
    const isLastReview = consultationsWithoutReview.length <= 1;
    if (isLastReview) dispatch(actions.app.setIsVisibleDefaultThanksModal(true));
  };

  const removeConsultationWithReview = async () => {
    await dispatch(actions.app.setIsVisiblePermanentReviewModal(false));
    await dispatch(actions.profile.removeConsultationWithReview(consultation.id));
    await handleDefaultThanksModal();
  };

  const handleSendReview = async () => {
    try {
      setIsSendingReview(true);

      await api.V1ReviewsApi.reviewsControllerCreateOne({
        clientId: consultation.clientId,
        expertId: consultation.expertId,
        rating,
        feedback: reviewContent,
        consultationId: consultation.id,
        isPromptly: achievements.promptly,
        isSavvy: achievements.savvy,
        onPositive: achievements.positive,
        wantRefund: returnMoney,
      });

      await removeConsultationWithReview();
    } catch (e: any) {
      captureError(e);
      toast.error(e.message || t('somethingWrong'));
    } finally {
      setIsSendingReview(false);
    }
  };

  const onExpertClick = () => {
    router.push(buildRoute(routes.expert, { id: consultation.expertId }));
  };

  useEffect(() => {
    if (rating <= 3 && Object.values(achievements).includes(true)) {
      setAchievements({ positive: false, promptly: false, savvy: false });
    }
    if (rating >= 4 && returnMoney) {
      setReturnMoney(false);
    }
  }, [rating]);

  useEffect(() => {
    resetReviewContent();
  }, [consultation]);

  const renderBottomContent = () => {
    if (rating <= 3) {
      return (
        <ReturnMoney>
          <Checkbox checked={returnMoney} onChange={setReturnMoney} />
          <span>{t('modals.expertReview.returnMoney')}</span>
        </ReturnMoney>
      );
    }

    return (
      rating >= 4 && (
        <>
          <GoodTitle>{t('modals.expertReview.whatsCool')}</GoodTitle>
          <AchievementsRow>
            {achievementsArray.map((ach) => (
              <Achievement key={ach}>
                <AchievementButton
                  $isActive={achievements[ach]}
                  onClick={() => setAchievements({ ...achievements, [ach]: !achievements[ach] })}
                >
                  <HeartWhiteIcon />
                </AchievementButton>
                <AchievementText>{t(`modals.expertReview.achievements.${ach}`)}</AchievementText>
              </Achievement>
            ))}
          </AchievementsRow>
        </>
      )
    );
  };

  return (
    <>
      {isModalVisible && (
        <Modal isVisible={isModalVisible} width="310px" $isMovedToRight={Boolean(router.pathname.includes('expert'))}>
          <Container>
            <Title>{t('modals.expertReview.title')}</Title>
            <ExpertName
              onClick={() => onExpertClick()}
            >{`${consultation.expert.firstName} ${consultation.expert.lastName}`}</ExpertName>
            <Description>{t('modals.expertReview.description')}</Description>
            <RatingField rating={rating} setRating={setRating} />
            <TextareaModal
              placeholder={`${t('modals.expertReview.textareaPlaceholder')} ${consultationInfo}`}
              value={reviewContent}
              onChange={(e) => setReviewContent(e.currentTarget.value)}
            />
            {renderBottomContent()}
            <SubmitButton
              block
              disabled={!rating || !reviewContent.length || isSendingReview}
              loading={isSendingReview}
              onClick={handleSendReview}
            >
              {t('modals.expertReview.button')}
            </SubmitButton>
          </Container>
        </Modal>
      )}
    </>
  );
};

const Container = styled.div`
  text-align: center;
`;

const Title = styled.h3`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const ExpertName = styled(Title)`
  margin-bottom: 15px;
  cursor: pointer;

  &:hover {
    color: #635e5e;
    text-decoration: underline;
  }
`;

const Description = styled.p`
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 40px;
`;

const ReturnMoney = styled.div`
  margin-top: 15px;
  display: flex;
  font-size: 15px;

  & > span {
    line-height: 20px;
  }
`;

const GoodTitle = styled.h4`
  font-size: 15px;
  font-weight: bold;
  margin: 40px 0 20px;
`;

const AchievementsRow = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: space-between;
`;

const Achievement = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AchievementButton = styled.div<{ $isActive: boolean }>`
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 50%;
  background-color: var(--purple);
  display: flex;
  justify-content: center;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.2)};
  transition: opacity 0.2s;
  cursor: pointer;

  & > svg {
    margin-top: 13px;
  }
`;

const AchievementText = styled.p`
  font-size: 11px;
`;

const SubmitButton = styled(Button)`
  margin-top: 30px;
`;
