import { useAppDispatch } from 'hooks/redux';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LIMIT_CHAT_MESSAGES } from 'shared/common/constants';
import { actions, selectors } from 'store/ducks';

interface useChatPaginationProps {
  chatRoomId: number | null;
}

export const useChatPagination = ({ chatRoomId }: useChatPaginationProps) => {
  const dispatch = useAppDispatch();
  const fetchingMoreChatMessageStatus = useSelector(selectors.chatMessages.selectFetchingMoreChatMessagesStatus);
  const isShouldFetchChatMessagesAgain = useSelector(selectors.chatMessages.selectIsShouldFetchChatMessagesAgain);
  const page = useSelector(selectors.chatMessages.selectChatMessagesPage);
  const pageCount = useSelector(selectors.chatMessages.selectChatMessagesPageCount);

  const loadMoreMessages = useCallback(() => {
    if (fetchingMoreChatMessageStatus !== 'pending') {
      dispatch(
        actions.chatMessages.fetchMoreChatMessages({
          chatRoomId: chatRoomId!,
          page: page + 1,
          limit: LIMIT_CHAT_MESSAGES,
        }),
      );
    }
  }, [fetchingMoreChatMessageStatus, chatRoomId, page, dispatch]);

  useEffect(() => {
    if (isShouldFetchChatMessagesAgain) {
      loadMoreMessages();
    }
  }, [loadMoreMessages, isShouldFetchChatMessagesAgain]);

  return { page, pageCount, loadMoreMessages };
};
