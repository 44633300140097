import { useCompletedInfoProfile } from 'hooks/useCompletedInfoProfile';
import { useRouter } from 'next/router';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { User, UserBlockEnum } from 'shared/api';
import { CONDITION_DESKTOP, DESKTOP } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { Spinner } from 'shared/ui';
import styled from 'styled-components';
import { EmailVerifyBanner, NotCompletedProfileBanner, NotCompletedProfileRedBanner, Sidebar } from 'widgets/common';
import { MainLayout } from 'widgets/layouts';

interface ProfileLayoutProps {
  head: {
    title: string;
  };
  profile: User;
}

export const ProfileLayout: FC<ProfileLayoutProps> = ({ children, head, profile }) => {
  const { t } = useTranslation('utils');
  const router = useRouter();

  const isExpert = useMemo(() => profile.isExpert, [profile]);
  const [isCompletedInfoProfile, isShowBanner, setIsShowBanner] = useCompletedInfoProfile({
    shownOnlyEmptyInfo: false,
  });

  const renderBlockUser = useMemo(
    () =>
      [UserBlockEnum.Partial, UserBlockEnum.Topic].includes(profile?.block) ? (
        <BlockUserContainer>
          <BlockUserTitle>{t('block.blockTitle')}</BlockUserTitle>
          <BlockUserContent>
            {profile?.block === UserBlockEnum.Topic
              ? t('block.blockTopics', { reason: profile.reasonBlock })
              : t('block.blockPartial', { reason: profile.reasonBlock })}
          </BlockUserContent>
        </BlockUserContainer>
      ) : null,
    [profile?.block, profile?.reasonBlock],
  );

  const isLocationOnExpertProfile = useMemo(() => router.asPath.includes('expertProfile'), [router]);

  return (
    <MainLayout head={head} hasFooter>
      {profile.id ? (
        <Wrapper>
          {isShowBanner && isCompletedInfoProfile && (
            <NotCompletedProfileBanner
              title={t('banners.notContract.title')}
              description={t('banners.notContract.description')}
              onClose={() => setIsShowBanner(false)}
              action={() => router.replace(routes.contract)}
              buttonText={t('banners.notContract.buttonText')}
            />
          )}
          {isShowBanner && !isCompletedInfoProfile && !isLocationOnExpertProfile && (
            <NotCompletedProfileRedBanner
              title={t('banners.profile.title')}
              description={t('banners.profile.description')}
              action={() => router.replace(`${routes.profile}?activeTab=expertProfile`)}
              buttonText={t('banners.profile.buttonText')}
            />
          )}

          {!profile.isEmailVerified && <EmailVerifyBanner isExpert={isExpert} />}
          <Container className="container">
            <Sidebar />
            <div>{children}</div>
            {renderBlockUser}
          </Container>
        </Wrapper>
      ) : (
        <StyledSpinner />
      )}
    </MainLayout>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const Container = styled.div`
  &&& {
    flex-direction: column-reverse;
    width: 100vw;
    margin: 0 auto;
    display: flex;
    margin-top: 30px;

    ${CONDITION_DESKTOP} {
      max-width: ${DESKTOP}px;
      margin-top: 90px;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
    }
  }
`;

const BlockUserContainer = styled.div`
  padding: 10px;
  background-color: var(--gray6);
  border: 3px solid var(--red);
  border-radius: 10px;
  margin-bottom: 10px;

  ${CONDITION_DESKTOP} {
    margin-left: 20px;
    max-width: 310px;
  }
`;
const BlockUserTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;

  ${CONDITION_DESKTOP} {
    font-size: 17px;
  }
`;
const BlockUserContent = styled.p`
  font-size: 17px;
  white-space: pre-line;

  ${CONDITION_DESKTOP} {
    font-size: 14px;
  }
`;
const StyledSpinner = styled(Spinner)`
  margin-top: 30px;
  margin-bottom: 30px;
`;
