import { useAppDispatch } from 'hooks/redux';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useUserPromocodes } from 'hooks/useUserPromocodes';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, MouseEvent, useRef } from 'react';
import { useSelector } from 'react-redux';
import ym from 'react-yandex-metrika';
import { ENVIRONMENT_TYPE } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';
import { HeaderFreeConsult } from 'widgets/common';

const menuList = [
  {
    textKey: 'dropdown.settings',
    href: `${routes.profile}?activeTab=settings`,
  },
  {
    textKey: 'dropdown.profile',
    href: `${routes.profile}?activeTab=expertProfile`,
  },
  {
    textKey: 'dropdown.wallet',
    href: `${routes.profile}?activeTab=wallet`,
  },
  // {
  //   textKey: 'dropdown.academyExperts',
  //   href: `${routes.academyExperts}`,
  // },
  {
    textKey: 'dropdown.myConsultations',
    href: `${routes.profile}?activeTab=myConsultations`,
  },
  // {
  //   textKey: 'dropdown.notifications',
  //   href: `${routes.profile}?activeTab=notifications`,
  // },
];

interface HeaderDropdownMenuProps {
  onCloseDropdown: () => void;
}

const sendDataToMetrics = () => {
  if (ENVIRONMENT_TYPE === 'production') {
    ym('reachGoal', ['Support']);
    if (window && window.gtag) {
      window.gtag('event', 'Support');
    }
    // window.fbq('trackCustom', 'Support');
  }
};

export const HeaderDropdownMenu: FC<HeaderDropdownMenuProps> = ({ onCloseDropdown }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('header.component');
  const user = useSelector(selectors.profile.selectUser);
  const wallet = useSelector(selectors.profile.selectWallet);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick({ ref: dropdownRef, onOutsideClick: onCloseDropdown });
  const router = useRouter();
  const { freeConsultCount, freeConsultDays } = useUserPromocodes();

  const logout = async () => {
    await router.replace(routes.topics);
    onCloseDropdown();
    dispatch(actions.profile.signOut());
  };

  const onSupport = () => {
    sendDataToMetrics();
    router.push(`${routes.profile}?activeTab=support`);
    onCloseDropdown();
  };

  return (
    <>
      <Wrapper onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()} />
      <Container ref={dropdownRef}>
        {user.isExpert && (
          <Money>
            {t('dropdown.money', {
              quantity: wallet?.balance || 0,
              currency: '\u20bd',
            })}
          </Money>
        )}
        {freeConsultCount > 0 && <HeaderFreeConsult freeCount={freeConsultCount} freeDays={freeConsultDays} />}

        <MenuList>
          {menuList.map((item, index) => {
            // if (!user.isExpert && item.textKey.includes('academyExperts')) {
            //   return null;
            // }
            if (!user.isExpert && item.textKey.toLowerCase().includes('wallet')) {
              return null;
            }
            return (
              <Link href={item.href} key={index} passHref>
                <a onClick={onCloseDropdown}>
                  <MenuItem>{t(item.textKey)}</MenuItem>
                </a>
              </Link>
            );
          })}
        </MenuList>

        <FooterList>
          <MenuItem onClick={onSupport}>
            <a href={`${routes.profile}?activeTab=support`}>{t('dropdown.support')}</a>
          </MenuItem>

          {user.isExpert && (
            <MenuItem>
              <a href={`${routes.academyExperts}`}>{t('dropdown.academyExperts')}</a>
            </MenuItem>
          )}

          <MenuItem onClick={logout}>{t('dropdown.logout')}</MenuItem>
        </FooterList>
      </Container>
    </>
  );
};

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
`;

const Container = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 215px;
  margin: 0;
  padding: 5px 0 10px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
`;

const Money = styled.div`
  padding: 10px 20px;
  font-size: 14px;
  color: var(--gray9);
  border-bottom: 1px solid var(--gray6);
`;
const MenuList = styled.ul`
  padding: 10px 0;
`;
const MenuItem = styled.li`
  padding: 0 20px;
  font-size: 14px;
  line-height: 35px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray8);
  }
`;
const FooterList = styled.ul`
  padding-top: 10px;
  border-top: 1px solid var(--gray6);
`;
