import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/redux';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CONDITION_DESKTOP, HEADER_HEIGHT_DESKTOP } from 'shared/common/constants';
import { captureError } from 'shared/helpers/captureError';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';

import { ResponseBody, ResponseHeader } from './ui';

export const ResponseRoom: FC = () => {
  const dispatch = useAppDispatch();
  const currentResponseRoomId = useSelector(selectors.consultationRequests.selectCurrentResponseRoomId);

  useEffect(() => {
    if (currentResponseRoomId) {
      dispatch(actions.consultationRequests.resetResponseUnreadCountLocal({ requestId: currentResponseRoomId }));
    }
    return () => {
      if (currentResponseRoomId) {
        dispatch(actions.consultationRequests.resetResponseUnreadCountLocal({ requestId: currentResponseRoomId }));
        dispatch(
          actions.consultationRequests.resetResponseUnreadCount({
            requestId: currentResponseRoomId,
            lastReadTime: dayjs().toISOString(),
          }),
        );
      }
    };
  }, [currentResponseRoomId, dispatch]);

  const getUserPromocodesRequest = async () => {
    try {
      await dispatch(actions.profile.fetchSelfPromocode());
    } catch (e) {
      captureError(e);
    }
  };

  useEffect(() => {
    getUserPromocodesRequest();
  });

  return (
    <Container>
      <Wrapper>
        <StyledResponseHeader requestId={currentResponseRoomId} />
        <StyledResponseBody requestId={currentResponseRoomId} />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  overflow: hidden;
  background-color: var(--white);
  ${CONDITION_DESKTOP} {
    z-index: 0;
  }
`;
const Wrapper = styled.div`
  height: 100%;
  display: grid;
  align-content: space-around;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr;
  grid-template-areas:
    'header'
    'body';
`;
const StyledResponseHeader = styled(ResponseHeader)`
  grid-area: header;
`;
const StyledResponseBody = styled(ResponseBody)`
  grid-area: body;
  overflow-y: auto;
  /* for Safari browser */
  /* max-height: calc(100vh - 60px - 60px); */
  ${CONDITION_DESKTOP} {
    /* max-height: calc(100vh - ${HEADER_HEIGHT_DESKTOP} - 60px - 75px); */
  }
`;
