import { useAppDispatch } from 'hooks/redux';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { captureError } from 'shared/helpers/captureError';
import { Button, ImageWithRetina } from 'shared/ui';
import { actions } from 'store/ducks/profile';
import styled from 'styled-components';

type EmailVerifyBannerProps = {
  isExpert: boolean;
};

export const EmailVerifyBanner: FC<EmailVerifyBannerProps> = ({ isExpert }) => {
  const { t } = useTranslation('profile.page');
  const [isSendRepeat, setIsSendRepeat] = useState(false);
  const dispatch = useAppDispatch();

  const onSendRepeat = async () => {
    try {
      await dispatch(actions.sendVerificationEmailAsync());
      setIsSendRepeat(true);
    } catch (error: any) {
      captureError(error);
      toast.error(error.message);
    }
  };

  return (
    <Container className="container">
      <Wrapper>
        <ImageWrapper>
          <ImageWithRetina src="/img/nyan-hamster.png" alt="please" />
        </ImageWrapper>
        <TextWrapper>
          <Title>{t('banners.email.title')}</Title>
          <Description>
            {isExpert ? t('banners.email.descriptionExpert') : t('banners.email.descriptionClient')}
          </Description>
        </TextWrapper>
        <StyledButton color="white" disabled={isSendRepeat} onClick={onSendRepeat}>
          {isSendRepeat ? t('banners.email.buttonSend') : t('banners.email.button')}
        </StyledButton>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div``;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  padding: 20px;
  background-color: var(--gray7);
  border-radius: 12px;
  color: var(--black);
  ${CONDITION_DESKTOP} {
    margin-top: 60px;
    padding: 20px 40px;
    flex-direction: row;
  }
`;
const ImageWrapper = styled.div`
  display: none;
  ${CONDITION_DESKTOP} {
    display: block;
    width: 160px;
    height: 100px;
    margin-right: 45px;
    margin-bottom: -20px;
  }
`;
const TextWrapper = styled.div``;
const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 10px;
  text-align: center;
  ${CONDITION_DESKTOP} {
    text-align: left;
    font-size: 24px;
  }
`;
const Description = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
  ${CONDITION_DESKTOP} {
    font-size: 16px;
    line-height: 20px;
    white-space: pre;
    text-align: left;
  }
`;
const StyledButton = styled(Button)<{ disabled: boolean }>`
  align-self: center;
  color: ${({ disabled }) => (disabled ? 'var(--purple)' : 'var(--purple)')};
  background-color: ${({ disabled }) => (disabled ? 'transparent' : 'var(--white)')};
  opacity: ${({ disabled }) => (disabled ? 0.9 : 1)};
  border: 1px solid var(--purple);
  width: 212px;
  flex-shrink: 0;
  margin-top: 10px;
  ${CONDITION_DESKTOP} {
    margin-left: 15px;
    margin-top: 0;
  }
  &:hover {
    opacity: 0.9;
  }
`;
