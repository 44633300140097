import { useAppDispatch } from 'hooks/redux';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { captureError } from 'shared/helpers/captureError';
import { SwitchButton } from 'shared/ui';
import { actions } from 'store/ducks';
import styled from 'styled-components';

interface ChatSettingsFormValues {
  value: boolean;
}

interface ChatSettingsFormProps {
  isActive: boolean;
}

export const ChatSettingsForm: FC<ChatSettingsFormProps> = ({ isActive }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('profile.page');

  const { control } = useForm<ChatSettingsFormValues>({
    defaultValues: {
      value: isActive,
    },
  });

  const handleChange = async (value: boolean) => {
    try {
      await dispatch(actions.profile.fetchUpdateIam({ hasFreeChat: value }));
      toast.success(t('saveInfo'));
    } catch (error) {
      captureError(error);
      toast.error(error.message || t('somethingWrong'));
    }
  };

  return (
    <Container>
      <Title>{t('chatSettings')}</Title>
      <Note>{t('chatSettingsNote')}</Note>
      <Controller
        name="value"
        render={({ field: { value, onChange } }) => (
          <CheckboxContainer>
            <CheckBoxLabel>{t('freeChat')}</CheckBoxLabel>
            <SwitchButton
              checked={value}
              onChange={(val) => {
                handleChange(val);
                onChange(val);
              }}
            />
          </CheckboxContainer>
        )}
        control={control}
      />
      <BottomNote>
        Чтобы получать заявки на консультации, укажите клиентам информацию о себе и выделитесь среди других экспертов.
        <br />
        <br />
        Информация появится в вашем профиле эксперта после проверки сервисом в течение получаса в рабочее время. После
        этого <Link href={`${window.location.origin}/app/profile?activeTab=wallet`}>заключите электронный договор</Link>
        . <br />
        <br />
        Рекомендуем <Link href={`${window.location.origin}/app/profile?activeTab=support`}>почитать FAQ</Link> - как
        начать оказывать консультацию и привлекать клиентов и{' '}
        <Link href={`${window.location.origin}/app/mobile`}>скачать наше приложение</Link>: клиенты будут писать прямо
        туда.
      </BottomNote>
    </Container>
  );
};

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  max-width: 560px;
  letter-spacing: normal;
  padding-bottom: 20px;
  color: var(--gray);

  & > a {
    color: var(--purple);
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`;
const Container = styled.div`
  margin-top: 90px;

  input {
    width: 60%;
  }
  button {
    margin-top: 40px;
  }
`;
const Title = styled.h2`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black2);
  padding-bottom: 10px;
  padding-top: 17px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${CONDITION_DESKTOP} {
    width: 50%;
  }
`;

const BottomNote = styled(Note)`
  color: var(--black2);
  padding-top: 20px;
`;

const CheckBoxLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--text);
`;
