import SvgCloseNote from 'app/assets/svg/CloseNote';
import * as React from 'react';
import { FC } from 'react';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { Button, ImageWithRetina } from 'shared/ui';
import styled from 'styled-components';

type NotCompletedProfileBannerProps = {
  title: string;
  description: string;
  buttonText: string;
  action: () => void;
  onClose: () => void;
};

export const NotCompletedProfileBanner: FC<NotCompletedProfileBannerProps> = ({
  title,
  description,
  buttonText,
  action,
  onClose,
}) => (
  <Container className="container">
    <Wrapper>
      <ImageWrapper>
        <ImageWithRetina src="/img/not-comp-info/pic-2@3x.png" alt="please" />
      </ImageWrapper>
      <TextWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextWrapper>
      <StyledButton onClick={action}>{buttonText}</StyledButton>
      <IconWrapper onClick={onClose}>
        <SvgCloseNote width="10" height="10" />
      </IconWrapper>
    </Wrapper>
  </Container>
);

const Container = styled.div``;
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  padding: 20px;
  background-color: var(--gray7);
  border-radius: 12px;
  color: var(--black);
  ${CONDITION_DESKTOP} {
    margin-top: 60px;
    padding: 20px 40px;
    flex-direction: row;
  }
`;
const ImageWrapper = styled.div`
  display: none;
  ${CONDITION_DESKTOP} {
    display: block;
    width: 160px;
    height: 80px;
    margin-right: 40px;
    margin-bottom: 0px;
  }

  img {
    width: 160px;
  }
`;
const TextWrapper = styled.div`
  width: 100%;
`;
const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 10px;
  text-align: left;
  ${CONDITION_DESKTOP} {
    text-align: left;
    font-size: 24px;
  }
`;
const Description = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  ${CONDITION_DESKTOP} {
    font-size: 16px;
    line-height: 20px;
    white-space: pre;
    text-align: left;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    opacity: 0.7;
  }
`;

const StyledButton = styled(Button)`
  align-self: center;
  width: 212px;
  height: 50px;
  flex-shrink: 0;
  margin-top: 10px;
  ${CONDITION_DESKTOP} {
    margin-left: 15px;
    margin-top: 0;
  }
`;
