import React from 'react';
import { ContractFormSteps, StepsState } from 'routes/contract/contract';
import { OfferForm, PropsForm, VariantForm } from 'widgets/common';

export function renderContractForm(
  step: ContractFormSteps,
  changeStepHandle: (step: ContractFormSteps) => void,
  changeProgressBarStateHandle: (data: Partial<StepsState>) => void,
) {
  switch (step) {
    case ContractFormSteps.variant:
      return (
        <VariantForm changeProgressBarStateHandle={changeProgressBarStateHandle} changeStepHandle={changeStepHandle} />
      );
    case ContractFormSteps.offer:
      return (
        <OfferForm changeProgressBarStateHandle={changeProgressBarStateHandle} changeStepHandle={changeStepHandle} />
      );
    case ContractFormSteps.props:
      return <PropsForm changeStepHandle={changeStepHandle} />;
    default:
      break;
  }
}
