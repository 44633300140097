import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import styled from 'styled-components';

interface PaginationProps {
  page: number;
  pageCount: number;
  setPage: (page: number) => void;
}

export const Pagination: FC<PaginationProps> = ({ page, setPage, pageCount }) => {
  const { t } = useTranslation('profile.page');

  const onPageClick = (page: number) => () => {
    setPage(page);
  };

  if (pageCount === 0) {
    return null;
  }

  const items = [];

  if (pageCount < 8) {
    for (let i = 2; i <= pageCount; i++) {
      items.push(i);
    }
  } else if (page - 3 <= 1) {
    for (let i = 2; i < 6; i++) {
      items.push(i);
    }
  } else if (page + 3 >= pageCount) {
    for (let i = pageCount - 4; i < pageCount; i++) {
      items.push(i);
    }
  } else {
    for (let i = page - 1; i < page + 2; i++) {
      items.push(i);
    }
  }

  return (
    <Container>
      <PagesContainer>
        <Page $isActive={page === 1} onClick={onPageClick(1)}>
          1
        </Page>

        {page > 4 && <Empty>...</Empty>}

        {items.map((i) => (
          <Page key={i} $isActive={page === i} onClick={onPageClick(i)}>
            {i}
          </Page>
        ))}

        {page + 3 < pageCount && <Empty>...</Empty>}

        {pageCount > 8 && (
          <Page $isActive={page === pageCount} onClick={onPageClick(pageCount)}>
            {pageCount}
          </Page>
        )}

        {(pageCount > 1 ?? page !== pageCount) && <Page onClick={onPageClick(page + 1)}>{t('walletTab.next')}</Page>}
      </PagesContainer>
    </Container>
  );
};

const Container = styled.div``;

const PagesContainer = styled.div`
  width: 260px;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
`;

const Page = styled.div<{ $isActive?: boolean }>`
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ $isActive }) => ($isActive ? 'var(--paleText)' : 'var(--gray3)')};
  &:hover {
    color: var(--paleText);
    cursor: pointer;
  }
`;

const Empty = styled(Page)`
  &:hover {
    color: var(--gray3);
    cursor: default;
  }
`;
