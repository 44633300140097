import React from 'react';
import { ContractFormSteps } from 'routes/contract/contract';

import { ContractFromVariants } from '../contract-form/types';
import { IndividualForm, IpForm, OooForm, SelfEmployedForm } from './ui';

export const renderPropsForm = (
  variant: ContractFromVariants,
  changeStepHandle: (step: ContractFormSteps) => void,
  changeStep?: () => void,
) => {
  switch (variant) {
    case ContractFromVariants.INDIVIDUAL:
      return <IndividualForm changeStepHandle={changeStepHandle} changeStep={changeStep} />;
    case ContractFromVariants.IP:
      return <IpForm changeStepHandle={changeStepHandle} changeStep={changeStep} />;
    case ContractFromVariants.OOO:
      return <OooForm changeStepHandle={changeStepHandle} changeStep={changeStep} />;
    case ContractFromVariants.SELF_EMPLOYED:
      return <SelfEmployedForm changeStepHandle={changeStepHandle} changeStep={changeStep} />;
    default:
      return null;
  }
};
