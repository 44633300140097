export const emailPattern = /.+@.+\..+/i;
export const datePattern = /^([0-9]{2}).([0-9]{2}).([1-2][0-9]{3}$)/;
export const namePattern = /^([A-Za-zА-Яа-я]+-?)+[A-Za-zА-Яа-я]+$/;
export const digitsPattern = /^[0-9]+$/;

export const fioPattern = /^[а-яa-zА-ЯA-Z-]{0,}\s[а-яa-zА-ЯA-Z-]{1,}(\s[а-яa-zА-ЯA-Z-]{1,})?$/;

export const innPathPattern = /^([\d+]{10}$)|([\d+]{12}$)/;
export const passportSeriesPattern = /^[0-9]{4}$/;
export const passportNumberPattern = /^[0-9]{6}$/;

// export const linkPattern = /[-a-zA-Z0-9@:%_\+.~#?&\/=]{2,256}\.[a-z]{2,10}\b(\/[-a-zA-Z0-9@:%_\+.~#?&\/=]*)?/gi;
export const linkPattern = /^(http|https):\/\/[^ "]+$/gi;

export const pricePattern = /^[1-9][0-9]*$/;
