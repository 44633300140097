import { Dispatch, FC, SetStateAction } from 'react';
import Carousel, { CarouselStyles, Modal, ModalGateway, ViewType } from 'react-images';

const customStyles: CarouselStyles = {
  footer: (base) => ({
    ...base,
    justifyContent: 'center',
  }),
  footerCaption: (base) => ({
    ...base,
    display: 'none',
  }),
  navigationPrev: (base) => ({
    ...base,
    background: 'var(--gray)',
    opacity: 0.7,
    '&:hover': {
      opacity: 0.3,
    },
  }),
  navigationNext: (base) => ({
    ...base,
    background: 'var(--gray)',
    opacity: 0.7,
    '&:hover': {
      opacity: 0.3,
    },
  }),
};

interface PortfolioCarouselProps {
  images: ViewType[];
  currentIndex: number;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
  showModal: boolean;
  onClose: () => void;
}
export const PortfolioCarousel: FC<PortfolioCarouselProps> = ({
  images,
  currentIndex,
  setCurrentIndex,
  showModal = false,
  onClose,
}) => (
  <ModalGateway>
    {showModal ? (
      <Modal onClose={onClose} allowFullscreen={false}>
        <Carousel
          views={images.slice(0, 10)}
          currentIndex={currentIndex}
          trackProps={{
            onViewChange: (num) => {
              setCurrentIndex(num);
            },
          }}
          styles={{
            footer: customStyles.footer,
            footerCaption: customStyles.footerCaption,
            navigationPrev: customStyles.navigationPrev,
            navigationNext: customStyles.navigationNext,
          }}
          components={{
            FooterCount: ({ currentIndex, innerProps }) => (
              <span {...innerProps}>
                {currentIndex! + 1} из {images.length}
              </span>
            ),
          }}
        />
      </Modal>
    ) : null}
  </ModalGateway>
);
