import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useState } from 'react';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { captureError } from 'shared/helpers/captureError';
import { selectors } from 'store/ducks/profile';
import { fetchConsultations } from 'store/ducks/profile/actions';
import styled from 'styled-components';

import { Pagination } from '../expert-wallet/pagination';
import { MyConsultationsRow } from './ui';

interface ExpertWalletProps {}

export const MyConsultations: FC<ExpertWalletProps> = () => {
  const { t } = useTranslation('profile.page');
  const dispatch = useAppDispatch();

  const [page, setPage] = useState<number>(1);

  const consultations = useAppSelector(selectors.selectConsultations);

  useEffect(() => {
    getConsultations();
  }, [page]);

  useEffect(() => {
    onPage(1);
  }, []);

  const getConsultations = async () => {
    try {
      await dispatch(fetchConsultations({ page, filter: ['asClient', 'PAID'] }));
    } catch (e) {
      captureError(e);
    }
  };

  const onPage = (page: number) => {
    if (consultations.pageCount && page <= consultations.pageCount) {
      setPage(page);
    }
  };

  return (
    <Container>
      <Title>{t('myConsultationsTab.title')}</Title>
      <Block>
        <Table>
          <Row>
            <LeftRow>
              <ColumnName>{t('walletTab.no')}</ColumnName>
              <ColumnName>{t('walletTab.date')}</ColumnName>
              <ColumnName>{t('walletTab.theme')}</ColumnName>
            </LeftRow>
            <RightRow>
              <ColumnName>{t('walletTab.price')}</ColumnName>
              <ColumnName>{t('walletTab.receipt')}</ColumnName>
            </RightRow>
          </Row>

          {consultations?.data?.map((consultation, index) => (
            <MyConsultationsRow key={index} consultation={consultation} />
          ))}
        </Table>
      </Block>

      {consultations.total > 10 && <Pagination page={page} setPage={onPage} pageCount={consultations.pageCount || 0} />}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 90px;
  padding-left: 0;

  ${CONDITION_DESKTOP} {
    padding-left: 95px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 40px;
`;

const Block = styled.div`
  margin-bottom: 60px;
`;

const Table = styled.div`
  overflow-x: scroll;

  & > div:first-child {
    border-bottom: none;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  width: 750px;
  border-bottom: 1px solid var(--gray6);
`;

const LeftRow = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr;
  width: 40%;
  column-gap: 6px;
`;

const RightRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 30%;
  column-gap: 6px;
`;

const Column = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.85;
  letter-spacing: normal;
  color: var(--text);
`;

const ColumnName = styled(Column)`
  text-transform: uppercase;
  color: var(--gray);
`;
