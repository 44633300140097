import { StoreApple, StoreGoogle } from 'app/assets/svg';
import Link from 'next/link';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CONDITION_DESKTOP, OFERTA_LINK, POLICYWEB_LINK, TRADE_LINK } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { ImageWithRetina } from 'shared/ui';
import styled from 'styled-components';

type FooterProps = {
  isMobilePage?: boolean;
};

export const Footer: FC<FooterProps> = ({ isMobilePage = false }) => {
  const { t } = useTranslation('footer.component');

  return (
    <Wrapper>
      <Container className="container">
        <LinksArea>
          <ColumnLinks>
            <StyledLink href={routes.expertLanding}>{t('links.becomeExpert')}</StyledLink>
            <StyledLink href={routes.about}>{t('links.aboutProject')}</StyledLink>
            <StyledLink href={routes.rules}>{t('links.rules')}</StyledLink>
            <StyledLink href={routes.contacts}>{t('links.contacts')}</StyledLink>
            <Link href={`${routes.stopInfoBis}`}>
              <a>
                <img
                  src="/img/header-add-logo-gypsy-desc.png"
                  srcSet="/img/header-add-logo-gypsy-desc@2x.png 2x"
                  alt=""
                />
              </a>
            </Link>
          </ColumnLinks>
          {!isMobilePage && (
            <ColumnLinks>
              <StyledLink href={`${POLICYWEB_LINK}`} target="_blank">
                {t('links.privacyPolicy')}
              </StyledLink>
              <StyledLink href={`${TRADE_LINK}`} target="_blank">
                {t('links.secureOffer')}
              </StyledLink>
              <StyledLink href={`${OFERTA_LINK}`} target="_blank">
                {t('links.offerAgreement')}
              </StyledLink>
              {/* <StyledLink href="" target="_blank">
                {t('links.personalSecurity')}
              </StyledLink> */}
              <StyledLink href={routes.zoomIntegrationInstructions} target="_blank">
                {t('links.zoomIntegrationInstructions')}
              </StyledLink>
            </ColumnLinks>
          )}
        </LinksArea>

        <MobileAppArea>
          <ImageWrapper>
            <ImageWithRetina src="/img/2000experts/iphone-app@3x.png" alt="phone" />
          </ImageWrapper>
          <MobileAppLinks>
            <MobileAppText>{t('takeMobile')}</MobileAppText>
            {/* TODO: add link to markets */}
            <StoreLinkWrapper href="https://apps.apple.com/ru/app/consulty/id1590830075?l=en" target="_blank">
              <StoreApple />
            </StoreLinkWrapper>
            <StoreLinkWrapper href="https://play.google.com/store/apps/details?id=com.consulty" target="_blank">
              <StoreGoogle />
            </StoreLinkWrapper>
          </MobileAppLinks>
        </MobileAppArea>

        <BottomArea>
          <HelpText>
            {t('support.ifYouHaveAsk')}{' '}
            <span>
              <SupportLink href="mailto:support@consulty.online" target="_blank">
                support@consulty.online
              </SupportLink>{' '}
              {t('support.or')}{' '}
              <SupportLink
              // TODO: add link to telegram
              >
                <SupportLink href="https://t.me/consultyonline" target="_blank">
                  Telegram
                </SupportLink>
              </SupportLink>
            </span>
          </HelpText>
          <Copyright>{t('copyright')}</Copyright>
        </BottomArea>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  background-color: var(--white);
`;
const Container = styled.div`
  &&& {
    display: grid;
    grid-template-areas:
      'links'
      'mobile'
      'bottom';
    padding: 20px;
    ${CONDITION_DESKTOP} {
      padding: 40px 20px 0;
      grid-template-columns: 3fr 2fr;
      grid-template-areas:
        'links mobile'
        'bottom mobile';
    }
  }
`;
const LinksArea = styled.div`
  grid-area: links;
  display: flex;
  flex-direction: column;
  ${CONDITION_DESKTOP} {
    flex-direction: row;
  }
`;
const ColumnLinks = styled.div`
  display: flex;
  flex-direction: column;
  ${CONDITION_DESKTOP} {
    margin-right: 50px;
  }
`;
const StyledLink = styled.a`
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 20px;
  &:hover {
    color: var(--purple3);
  }
  ${CONDITION_DESKTOP} {
    margin-bottom: 15px;
  }
`;
const BottomArea = styled.div`
  grid-area: bottom;
`;
const HelpText = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 20px;
  span {
    display: block;
  }
  ${CONDITION_DESKTOP} {
    margin-top: 10px;
    margin-bottom: 15px;
    span {
      display: inline;
    }
  }
`;
const SupportLink = styled.a`
  color: var(--purple);
`;
const Copyright = styled.p`
  font-size: 13px;
  line-height: 20px;
  color: var(--gray);
`;
const MobileAppArea = styled.div`
  grid-area: mobile;
  display: flex;
  align-items: center;
  margin-top: 30px;
  ${CONDITION_DESKTOP} {
    margin-top: 0;
  }
`;
const ImageWrapper = styled.div`
  width: 33%;
  img {
    width: 100%;
  }
`;
const MobileAppLinks = styled.div`
  flex: 1;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
`;
const MobileAppText = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 16px;
  ${CONDITION_DESKTOP} {
    font-size: 15px;
    line-height: 35px;
  }
`;
const StoreLinkWrapper = styled.a`
  margin-bottom: 11px;
  cursor: pointer;
`;
