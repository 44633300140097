import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { chooseEndingWord } from 'shared/helpers/chooseEndingWords';
import { ImageWithRetina } from 'shared/ui';
import styled from 'styled-components';

type HeaderFreeConsultProps = {
  freeCount: number;
  freeDays: number;
};

const HeaderFreeConsult: FC<HeaderFreeConsultProps> = ({ freeCount }) => {
  const { t } = useTranslation('header.component');

  return (
    <FreeConsult>
      <CoinIcon src="/img/coin.png" alt="coin" />
      <div>
        <FreeConsultTitle>
          {t('dropdown.freeConsult', {
            count: freeCount,
            endingFreeConsult: chooseEndingWord(freeCount, [
              t('endingFreeConsult.one'),
              t('endingFreeConsult.two'),
              t('endingFreeConsult.many'),
            ]),
          })}
        </FreeConsultTitle>
        {/* <FreeConsultSubtitle>
          {chooseEndingWord(freeDays, [
            t('dropdown.freeDays.one', { count: freeDays }),
            t('dropdown.freeDays.two', { count: freeDays }),
            t('dropdown.freeDays.many', { count: freeDays }),
          ])}
        </FreeConsultSubtitle> */}
      </div>
    </FreeConsult>
  );
};

export { HeaderFreeConsult };

const FreeConsult = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  ${CONDITION_DESKTOP} {
    padding: 15px 20px;
    margin-top: 0;
    border-bottom: 1px solid var(--gray6);
  }
`;
const CoinIcon = styled(ImageWithRetina)`
  width: 25px;
  height: 25px;
  margin-right: 14px;
  flex-shrink: 0;
  ${CONDITION_DESKTOP} {
    margin-right: 10px;
    flex-grow: 1;
  }
`;
const FreeConsultTitle = styled.div`
  line-height: 15px;
  font-weight: bold;
  font-size: 13px;
  ${CONDITION_DESKTOP} {
    line-height: 1;
  }
`;
