import { unwrapResult } from '@reduxjs/toolkit';
import { Cross, MicrophoneIcon, PlaneIcon, StopIcon } from 'app/assets/svg';
import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/redux';
import { useCompanionPrivateCannel } from 'hooks/useCompanionPrivateChannel';
import { useRecordAudio } from 'hooks/useRecordAudio';
import { useTranslation } from 'next-i18next';
import React, { ChangeEvent, FC, HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { httpClient } from 'shared/api/httpClient';
import { AUDIO_MESSAGE_EXT, CONDITION_DESKTOP } from 'shared/common/constants';
import { getUrl } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { Spinner } from 'shared/ui';
import { actions } from 'store/ducks/app';
import styled, { keyframes } from 'styled-components';

function textareaAutoResize(el: HTMLTextAreaElement): void {
  el.style.cssText = 'height: auto';
  if (el.scrollHeight > 300) {
    el.style.cssText = 'height: 300px; overflow: auto';
  } else {
    el.style.cssText = `height: ${el.scrollHeight + 5}px; overflow: hidden`;
  }
}

interface ChatInputMessageProps extends HTMLAttributes<HTMLTextAreaElement> {
  onSendMessage: ({ message, attachments }: { message?: string; attachments?: string[] }) => void;
  disabled?: boolean;
}

export const ChatInputMessage: FC<ChatInputMessageProps> = ({ disabled, onSendMessage, ...props }) => {
  const { t } = useTranslation('chat.page');
  const { t: tUtils } = useTranslation('utils');
  const [value, setValue] = useState('');
  const dispatch = useAppDispatch();
  const [isWriting, setIsWriting] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { audioFile, isRecording, onStartRecord, onStopRecord, clearAudioFile, recordTime } = useRecordAudio({
    disabled,
    isUploadingFile,
  });

  useCompanionPrivateCannel({ isWriting });

  useEffect(() => {
    textareaAutoResize(textareaRef.current!);
  }, [value]);

  useEffect(() => {
    if (disabled) {
      setIsWriting(false);
      setValue('');
    }
  }, [disabled]);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.currentTarget.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === 'Enter' && !event.shiftKey) {
      sendMessage();
      event.preventDefault && event.preventDefault();
    }
  };

  const sendMessage = async () => {
    if (audioFile && !disabled) {
      try {
        setIsUploadingFile(true);
        if (audioFile.size > 0) {
          const action = await dispatch(
            actions.signFileUrlAsync({
              contentType: audioFile.type,
              fileKey: `audiomessage_${dayjs().format('YYYY-MM-DD_HHmmssSSS')}.${AUDIO_MESSAGE_EXT}`,
            }),
          );
          const signedUrl = unwrapResult(action);

          await httpClient.put(signedUrl, audioFile, {
            headers: {
              'Content-Type': audioFile.type,
              'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS, DELETE',
              'Access-Control-Allow-Origin': '*',
            },
          });
          onSendMessage({ attachments: [getUrl(signedUrl)] });
        }
      } catch (error: any) {
        captureError(error);
        toast.error(error.message || tUtils('somethingWrong'));
      } finally {
        setIsUploadingFile(false);
        clearAudioFile();
      }
    } else if (Boolean(value) && !disabled) {
      textareaRef.current?.focus();
      onSendMessage({ message: value });
      setValue('');
    }
  };

  const renderSendButton = useMemo(() => {
    if (isRecording) {
      return (
        <AudioButton $disabled={disabled} onClick={onStopRecord}>
          <StopIcon />
        </AudioButton>
      );
    }
    if (!audioFile && !value) {
      return (
        <AudioButton $disabled={disabled} onClick={onStartRecord}>
          <MicrophoneIcon />
        </AudioButton>
      );
    }
    if (isUploadingFile) {
      return (
        <AudioButton $disabled>
          <Spinner color="var(--white)" size="15px" />
        </AudioButton>
      );
    }
    return (
      <SendButton $disabled={disabled || isUploadingFile} onClick={sendMessage}>
        <PlaneIcon />
      </SendButton>
    );
  }, [isRecording, disabled, onStopRecord, onStartRecord, sendMessage, isUploadingFile, audioFile]);

  return (
    <Wrapper>
      <Container>
        <MessageField
          rows={1}
          onKeyDown={handleKeyDown}
          value={value}
          onChange={onChange}
          disabled={disabled || isRecording || Boolean(audioFile)}
          onFocus={() => setIsWriting(true)}
          onBlur={() => setIsWriting(false)}
          {...props}
          ref={textareaRef}
          placeholder={
            isRecording
              ? t('inputPlaceholder.recording', { time: recordTime })
              : audioFile
              ? t('inputPlaceholder.voiceMessage', { time: recordTime })
              : disabled
              ? t('inputPlaceholder.disabled')
              : t('inputPlaceholder.enabled')
          }
        />
        {isRecording && <RecordIcon />}
        {Boolean(audioFile) && (
          <RemoveAudioButton onClick={clearAudioFile}>
            <Cross />
          </RemoveAudioButton>
        )}
      </Container>
      {renderSendButton}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;
const Container = styled.div`
  position: relative;
  flex-grow: 1;
`;
const MessageField = styled.textarea<{ disabled?: boolean }>`
  display: block;
  width: 100%;
  min-height: 36px;
  padding: 7px 15px;
  outline: none;
  border-radius: 22.5px;
  border: solid 1px var(--gray3);
  resize: none;
  font-size: 15px;
  line-height: 20px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  color: var(--text);
  &::placeholder {
    color: var(--gray9);
  }
  ${CONDITION_DESKTOP} {
    min-height: 45px;
    padding: 12px 20px;
    background-color: var(--white);
  }
`;
const ChatButton = styled.button<{ $disabled?: boolean }>`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  background-color: var(--purple);
  opacity: ${({ $disabled }) => ($disabled ? 0.2 : 1)};
  transition: opacity 0.2s;
  border: none;
  outline: none;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  -webkit-tap-highlight-color: transparent;
  position: relative;
  padding: 0;
  bottom: 2px;
  justify-content: center;
  align-self: flex-end;
  margin-left: 5px;
  ${CONDITION_DESKTOP} {
    bottom: 7px;
  }
  &:hover {
    background-color: ${({ $disabled }) => ($disabled ? 'var(--purple)' : 'var(--purple2)')};
  }
`;
const SendButton = styled(ChatButton)`
  svg {
    margin-left: 2px;
  }
`;
const AudioButton = styled(ChatButton)``;
const recordAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`;
const RecordIcon = styled.div`
  position: absolute;
  bottom: 15px;
  right: 17px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--red);
  box-shadow: 0 0 5px 5px var(--red);
  animation: ${recordAnimation} 1.2s linear infinite;
  ${CONDITION_DESKTOP} {
    bottom: 20px;
  }
`;
const RemoveAudioButton = styled.button`
  position: absolute;
  bottom: 12px;
  right: 17px;
  width: 15px;
  height: 15px;
  border: none;
  padding: 0;
  background: transparent;
  ${CONDITION_DESKTOP} {
    bottom: 17px;
  }
`;
