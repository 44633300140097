import { useTranslation } from 'next-i18next';
import React, { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UserCategoryMeta } from 'shared/api';
import { Button, Select } from 'shared/ui';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

type FormType = {
  category: { label: string; value: string };
};

type CreateConsultationModalProps = {
  isVisible: boolean;
  onClose: () => void;
  IsExpertInRoom: boolean;
  expertCategories?: UserCategoryMeta[];
  handleSubmitModal: (userCategoryMetaId: number) => void;
};

export const CreateConsultationModal: FC<CreateConsultationModalProps> = ({
  isVisible,
  onClose,
  IsExpertInRoom,
  expertCategories,
  handleSubmitModal,
}) => {
  if (!isVisible) {
    return null;
  }

  const { t } = useTranslation('utils');
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormType>();

  const selectOptions = useMemo(
    () =>
      expertCategories?.map((category) => ({
        label: `${category.category.name} - ${category.price} ₽`,
        value: category.id.toString(),
      })),
    [expertCategories],
  );

  const onSubmit = ({ category }: FormType) => {
    const userCategoryMetaId = parseInt(category.value, 10);
    handleSubmitModal(userCategoryMetaId);
    onClose();
  };
  // todo оплата токенами
  // const onPayWithToken = async (categoryMetaId: number) => {
  //   if (notUsedPromocode) {
  //     try {
  //       const { data } = await api.V1ConsultationsApi.consultationsControllerCreateOneFromUserMetaWithPromocode({
  //         userCategoryMetaId: categoryMetaId,
  //         userPromocodeId: notUsedPromocode.id,
  //         redirectUrl: '/',
  //       });
  //       dispatch(actions.chatRooms.addChatRoom(data.chatRoom));
  //       dispatch(actions.profile.fetchSelfPromocode());
  //       await router.push({
  //         pathname: routes.chat,
  //         query: {
  //           activeTab: 'consult',
  //           roomId: data.chatRoomId,
  //         },
  //       });
  //     } catch (e: any) {
  //       captureError(e);
  //     }
  //   }
  // };

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <Title>{IsExpertInRoom ? t('modals.createConsult.title') : t('modals.createConsult.titleClient')}</Title>
        <Description>
          {IsExpertInRoom ? t('modals.createConsult.description.expert') : t('modals.createConsult.description.client')}
        </Description>
        {!IsExpertInRoom && <SubDescription>{t('modals.createConsult.subDescription')}</SubDescription>}
        <Controller
          control={control}
          name="category"
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              options={selectOptions}
              placeholder={t('modals.createConsult.selectPlaceholder')}
              instanceId="category-meta-select"
              maxMenuHeight={100}
            />
          )}
        />

        <SubmitButton block type="submit" disabled={!isDirty}>
          {IsExpertInRoom ? t('modals.createConsult.button.expert') : t('modals.createConsult.button.client')}
        </SubmitButton>
      </Container>
    </Modal>
  );
};

const Container = styled.form`
  text-align: center;
`;
const Title = styled.h3`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 15px;
`;
const Description = styled.p`
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 20px;
`;
const SubmitButton = styled(Button)`
  margin-top: 30px;
`;

const SubDescription = styled(Description)`
  margin-top: -20px;
`;
