import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { Button } from 'shared/ui';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

type ExitStepModalProps = {
  isVisible: boolean;
  onClose: () => void;
  handleClick: () => void;
};

export const ExitStepModal: FC<ExitStepModalProps> = ({ isVisible = false, onClose, handleClick }) => {
  const { t } = useTranslation('utils');
  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <Container>
        <Title>{t('modals.exitStepModal.title')}</Title>
        <Description>{t('modals.exitStepModal.description')}</Description>
        <SubmitButton block onClick={onClose}>
          {t('modals.exitStepModal.buttonYes')}
        </SubmitButton>
        <NoButton onClick={handleClick}>{t('modals.exitStepModal.buttonNo')}</NoButton>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  text-align: center;
`;
const Title = styled.h3`
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 15px;
  white-space: pre-line;
`;
const Description = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 25px;
`;

const SubmitButton = styled(Button)`
  margin-top: 30px;
`;

const NoButton = styled.div`
  margin-top: 10px;
  color: var(--purple);
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
`;
