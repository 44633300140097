import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import { containImageStyle } from '../../utils/containImageStyle';

const StyledCloseButton = styled.div({
  boxSizing: 'border-box',
  position: 'absolute',
  top: -30,
  right: 0,
  width: 15,
  height: 15,
  cursor: 'pointer',
  outline: 'none',
  ...containImageStyle('/landing/close.svg'),
});

type Props = {
  onClick: () => void;
};

const CloseButton: FC<Props> = ({ onClick }) => (
  <StyledCloseButton onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0} />
);

export default CloseButton;
