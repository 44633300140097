import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/redux';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CONDITION_DESKTOP, HEADER_HEIGHT_DESKTOP } from 'shared/common/constants';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';

import { RequestBody, RequestHeader } from './ui';

export const RequestRoom: FC = () => {
  const dispatch = useAppDispatch();
  const currentRequestRoomId = useSelector(selectors.consultationRequests.selectCurrentRequestRoomId);
  const currentRequestRoom = useSelector(selectors.consultationRequests.selectRequestRoomById(currentRequestRoomId));

  useEffect(() => {
    if (currentRequestRoom) {
      dispatch(
        actions.consultationRequests.resetRequestUnreadCountLocal({ categoryId: currentRequestRoom.categoryId }),
      );
    }
    return () => {
      if (currentRequestRoom) {
        dispatch(
          actions.consultationRequests.resetRequestUnreadCountLocal({ categoryId: currentRequestRoom.categoryId }),
        );
        dispatch(
          actions.consultationRequests.resetRequestUnreadCount({
            id: currentRequestRoom.id,
            lastReadTime: dayjs().toISOString(),
          }),
        );
      }
    };
  }, [dispatch, currentRequestRoom]);

  return (
    <Container>
      <Wrapper>
        <StyledRequestHeader categoryId={currentRequestRoomId} />
        <StyledRequestBody categoryId={currentRequestRoomId} />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  overflow: hidden;
  background-color: var(--white);
  ${CONDITION_DESKTOP} {
    z-index: 0;
  }
`;
const Wrapper = styled.div`
  height: 100%;
  display: grid;
  align-content: space-around;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr;
  grid-template-areas:
    'header'
    'body';
`;
const StyledRequestHeader = styled(RequestHeader)`
  grid-area: header;
`;
const StyledRequestBody = styled(RequestBody)`
  grid-area: body;
  overflow-y: auto;
  /* for Safari browser */
  /* max-height: calc(100vh - 60px - 60px); */
  ${CONDITION_DESKTOP} {
    /* max-height: calc(100vh - ${HEADER_HEIGHT_DESKTOP} - 60px - 75px); */
  }
`;
