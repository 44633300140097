import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CreateCustomRequestDtoTypeEnum, CustomRequest, UpdateCustomRequestDtoTypeEnum } from 'shared/api';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { captureError } from 'shared/helpers/captureError';
import { linkPattern } from 'shared/helpers/patterns';
import { ActionButtonStatusNames, Button, Input } from 'shared/ui';
import { actions, selectors } from 'store/ducks/profile';
import { fetchGetManyBaseCustomRequest } from 'store/ducks/profile/actions';
import styled from 'styled-components';

interface PromoCodeFormValues {
  link: string;
}

interface PromoCodeFormProps {
  status: ActionButtonStatusNames;
  request: CustomRequest[];
  comment?: string;
}

export const PromoCodeForm: FC<PromoCodeFormProps> = ({
  status = ActionButtonStatusNames.no,
  request,
  comment = '',
}) => {
  const promoCode = useAppSelector(selectors.selectExpertPromocode);

  const { t } = useTranslation('profile.page');
  const dispatch = useAppDispatch();
  const { t: error } = useTranslation('errors.messages');

  const { control, handleSubmit, setValue } = useForm<PromoCodeFormValues>({
    defaultValues: {
      link: request ? request[0]?.payload.socialSiteUrl : '',
    },
  });

  useEffect(() => {
    if (request) {
      setValue('link', request[0]?.payload.socialSiteUrl);
    }
  }, [request]);
  const onSubmit = async ({ link }: PromoCodeFormValues) => {
    try {
      if (status === ActionButtonStatusNames.no) {
        await dispatch(
          actions.createOneCustomRequestAsync({
            type: CreateCustomRequestDtoTypeEnum.PersonalPromocode,
            payload: { socialSiteUrl: link },
          }),
        );
      } else {
        await dispatch(
          actions.updateOneCustomRequestAsync({
            id: request[0].id,
            dto: { type: UpdateCustomRequestDtoTypeEnum.PersonalPromocode, payload: { socialSiteUrl: link } },
          }),
        );
      }

      toast.success(t('requestHasBeenSend2'));
      await dispatch(fetchGetManyBaseCustomRequest({ join: ['promocode'] }));
    } catch (e) {
      captureError(e);
      toast.error(t('somethingWrong'));
    }
  };

  const renderStatusMessage = useMemo(() => {
    switch (status) {
      case ActionButtonStatusNames.processing:
        return <ProcessingMessageText>{t('promoCodeForm.processing')}</ProcessingMessageText>;
      case ActionButtonStatusNames.fail:
        return (
          <ProcessingMessageTextError>
            {t('promoCodeForm.error')} {comment}
          </ProcessingMessageTextError>
        );
      case ActionButtonStatusNames.success:
        return (
          <ProcessingMessageText>
            {promoCode?.code
              ? `${t('promoCodeForm.success.promocode')}: ${promoCode.code}`
              : `${t('promoCodeForm.success.noPromocode')}`}
          </ProcessingMessageText>
        );
      case ActionButtonStatusNames.expired:
        return <ProcessingMessageTextError>{t('promoCodeForm.expired')}</ProcessingMessageTextError>;
      default:
        return <></>;
    }
  }, [status]);

  return (
    <Container>
      <Label>{t('sale')}</Label>
      <Title>{t('promoCodeForSubscribers')}</Title>
      <Note style={{ color: '#79818c' }}>{t('largeSubsPromoCode')}</Note>
      <Note>{t('promoCodeGet')}</Note>
      {/* <Note style={{ color: '#79818c', fontWeight: 'bold', paddingBottom: 30 }}>{t('promoCodeDate')}</Note> */}
      <Controller
        name="link"
        control={control}
        rules={{
          required: { value: true, message: error('required') },
          pattern: { value: linkPattern, message: error('incorrectLink') },
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <Input
              value={value}
              onChange={onChange}
              disabled={promoCode?.isActive}
              error={error?.message}
              placeholder="Ссылка на ваш профиль в соцсетях"
              style={{ maxWidth: 420 }}
            />
            {!promoCode?.isActive && (
              <ButtonWrapper>
                <StyledButton onClick={handleSubmit(onSubmit)} disabled={value?.length === 0}>
                  {t('sendVerification')}
                </StyledButton>
              </ButtonWrapper>
            )}
            <ProcessingMessagesWrapper>{renderStatusMessage}</ProcessingMessagesWrapper>
          </>
        )}
      />
    </Container>
  );
};

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  max-width: 560px;
  letter-spacing: normal;
  padding-bottom: 20px;
  color: var(--gray);

  & > a {
    color: var(--purple);
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`;

const Container = styled.div`
  margin-top: 90px;

  input {
    width: 100%;
    ${CONDITION_DESKTOP} {
      width: 60%;
    }
  }
  button {
    margin-top: 40px;
    width: 100%;
    ${CONDITION_DESKTOP} {
      width: unset;
    }
  }
`;
const Label = styled.span`
  background-image: linear-gradient(to right, #ae4cfe 5%, #993dfa 51%, #812df6);
  border-radius: 12.5px;
  color: var(--white);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 3px 9px;
  text-align: center;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black2);
  padding-bottom: 10px;
  padding-top: 17px;
`;

const ProcessingMessagesWrapper = styled.div`
  margin-top: 10px;
`;

const ProcessingMessageText = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #8b33ff;
`;

const ProcessingMessageTextError = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ff3366;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  width: 220px;
`;

const StyledButton = styled(Button)`
  span {
    padding: 2px 4px;
    margin: 0;
  }
`;
