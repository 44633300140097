import { useAppDispatch } from 'hooks/redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CONDITION_DESKTOP, SUPPORT_EMAIL, SUPPORT_TELEGRAM } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { selectors } from 'store/ducks/profile';
import { fetchSelfPaymentMethods, fetchWallet } from 'store/ducks/profile/actions';
import styled from 'styled-components';
import { CardsForWithdrawal } from 'widgets/common';

import { ExpertWalletConsultations, ExpertWalletWithdravals } from './ui';

interface ExpertWalletProps {}

enum WalletTabsEnum {
  Withdraval = 'Withdrawal',
  AnotherOperations = 'AnotherOperations',
}

export const ExpertWallet: FC<ExpertWalletProps> = () => {
  const user = useSelector(selectors.selectUser);
  const wallet = useSelector(selectors.selectWallet);

  const [activeTab, setActiveTab] = useState(WalletTabsEnum.AnotherOperations);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('profile.page');

  useEffect(() => {
    if (!user.isExpert) {
      router.push(routes.profile);
    }
    // dispatch(fetchSelfContract());
    dispatch(fetchSelfPaymentMethods());
    dispatch(fetchWallet());
  }, []);

  if (!user.isExpert) {
    return null;
  }

  return (
    <Container>
      <Title>{t('walletTab.title')}</Title>

      <Block>
        <SmallTitle>{t('walletTab.myBill')}</SmallTitle>

        <WalletValue>{t('walletTab.count', { count: parseFloat(wallet.balance?.toFixed(2)) })}</WalletValue>
      </Block>

      <Block>
        <SmallTitle>{t('walletTab.withdrawnTitle')}</SmallTitle>
        <Note>
          {t('walletTab.withdrawnNote')}
          <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
          {t('walletTab.withdrawnNote2')}
          <a href={`https://t.me/${SUPPORT_TELEGRAM}`}>Telegram</a>
          {'. '}
          {t('walletTab.withdrawnNote3')}
        </Note>
        {/* <Button bordered hasBottomRadius={false} onClick={() => console.log('click')}>
          {t('walletTab.withdrawn')}
        </Button> */}
      </Block>

      <Block>
        <CardsForWithdrawal wallet={wallet} />
      </Block>

      <Block>
        <SmallTitle>{t('walletTab.operationsHistory')}</SmallTitle>
        <TabsContainer>
          <Tab
            onClick={() => setActiveTab(WalletTabsEnum.AnotherOperations)}
            isActive={activeTab === WalletTabsEnum.AnotherOperations}
          >
            Консультации
          </Tab>
          <Tab
            onClick={() => setActiveTab(WalletTabsEnum.Withdraval)}
            isActive={activeTab === WalletTabsEnum.Withdraval}
          >
            Выплаты
          </Tab>
        </TabsContainer>
        {activeTab === WalletTabsEnum.Withdraval && <ExpertWalletWithdravals />}
        {activeTab === WalletTabsEnum.AnotherOperations && <ExpertWalletConsultations />}
      </Block>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 90px;
  padding-left: 0;

  ${CONDITION_DESKTOP} {
    padding-left: 75px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 40px;
`;

const SmallTitle = styled(Title)`
  font-size: 17px;
  margin-bottom: 10px;
`;

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  max-width: 500px;
  color: var(--gray);
  margin-bottom: 10px;
  a {
    color: var(--purple);
  }
`;

const WalletValue = styled.div`
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--purple);
`;

const Tab = styled.span<{ isActive: boolean }>`
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? 'var(--purple)' : 'var(--paleText)')};
  border-bottom: ${({ isActive }) => (isActive ? 'solid 0.5px var(--purple)' : '')};
`;

const TabsContainer = styled.div`
  margin: 23px 0;
`;

const Block = styled.div`
  margin-bottom: 60px;
`;
