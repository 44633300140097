import React, { Dispatch, SetStateAction } from 'react';

import { AuthFormSteps } from './types';
import * as forms from './ui';

export function renderAuthForm(
  step: AuthFormSteps,
  setStep: Dispatch<SetStateAction<AuthFormSteps>>,
  closeModal: () => void,
  currentPhone: string,
  setCurrentPhone: Dispatch<SetStateAction<string>>,
) {
  switch (step) {
    case AuthFormSteps.number:
      return <forms.AuthNumberForm setStep={setStep} currentPhone={currentPhone} setCurrentPhone={setCurrentPhone} />;
    case AuthFormSteps.smsCode:
      return <forms.AuthSmsCodeForm setStep={setStep} closeModal={closeModal} />;
    case AuthFormSteps.promoCode:
      return <forms.AuthPromoCodeForm setStep={setStep} />;
    case AuthFormSteps.final:
      return <forms.AuthFinalStepForm closeModal={closeModal} setStep={setStep} />;
    default:
      return null;
  }
}
