import { unwrapResult } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/redux';
import { useIsExpertInRoom } from 'hooks/useIsExpertInRoom';
import { TFunction, useTranslation } from 'next-i18next';
import React, { FC, HTMLAttributes, useCallback, useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Consultation, ConsultationInvoiceTypeEnum, InvoiceStatusEnum, MessageTypeEnum, News } from 'shared/api';
import { CONDITION_DESKTOP, LIMIT_CHANNEL_NEWS, LIMIT_CHAT_MESSAGES } from 'shared/common/constants';
import { MessageExtended } from 'shared/common/types';
import { captureError } from 'shared/helpers/captureError';
import { Button, ChatWidget, Spinner } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';

import { ChannelMessage } from '../channel-message';
import { FirstPost } from '../channel-message/components/first-post';
import { SecondPost } from '../channel-message/components/second-post';
import { ThirdPost } from '../channel-message/components/third-post';
import { ChatMessage } from '../chat-message';
import { ChatSystemMessage } from '../chat-system-message';
import { useChatPagination } from './hooks/useChatPagination';
import { useMessagesLimit } from './hooks/useMessagesLimit';
import { useTimeLimit } from './hooks/useTimeLimit';
import { useWidgetHandlers } from './hooks/useWidgetsHandlers';

export interface makeSystemMessagesFuncArgs {
  consultsToBeAdded: Consultation[];
  tChatPage: TFunction;
  isExpertInRoom: boolean;
}

const UsedLocales = {
  ru: 'ru',
  en: 'en',
};

interface RoomBodyProps extends HTMLAttributes<HTMLDivElement> {
  chatRoomId: number | null;
}

export const RoomBody: FC<RoomBodyProps> = ({ chatRoomId, ...props }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('chat.page');
  const { t: tUtils } = useTranslation('utils');

  const user = useSelector(selectors.profile.selectUser);
  const chatRoom = useSelector(selectors.chatRooms.selectChatRoomById(chatRoomId));

  const pendingStartConsultation = useSelector(selectors.consultationsChatRoom.selectPendingStartConsultation);
  const pendingPaymentConsultation = useSelector(selectors.consultationsChatRoom.selectPendingPaymentConsultation);
  const pendingPayProlongationConsultation = useSelector(
    selectors.consultationsChatRoom.selectPendingProlongationPaymentConsultation,
  );
  const pendingPayExtraServiceConsultation = useSelector(
    selectors.consultationsChatRoom.selectPendingExtraServicePaymentConsultation,
  );

  const isChannelRoom = () => (chatRoomId !== null && +chatRoomId) === 0;

  const messages: MessageExtended[] | News[] = isChannelRoom()
    ? useSelector(selectors.channelNews.selectChannelNews)
    : useSelector(selectors.chatMessages.selectChatMessages);
  const fetchingChatMessageStatus = useSelector(selectors.chatMessages.selectFetchingChatMessagesStatus);

  useMessagesLimit(chatRoom);
  useTimeLimit(chatRoom);

  const groupChat = useMemo(() => {
    const groups = {};
    messages.forEach((m) => {
      const date = dayjs(m.createdAt).startOf('day').locale(UsedLocales.ru).format('DD MMM');
      if (!Object.prototype.hasOwnProperty.call(groups, date)) {
        Object.defineProperty(groups, date, {
          value: [],
          enumerable: true,
        });
      }
      // @ts-ignore
      groups[date].push(m);
    });
    return groups;
  }, [messages, chatRoom]);

  const isUserExpertInRoom = useIsExpertInRoom(chatRoom);

  const { page, pageCount, loadMoreMessages } = useChatPagination({ chatRoomId });

  const {
    handleStartConsultation,
    handleRejectConsultationOffer,
    handleRejectProlongation,
    isSendingRequest,
    handleRejectExtraService,
  } = useWidgetHandlers();

  useEffect(() => {
    if (!chatRoom?.id || chatRoom.isTempRoom) {
      return;
    }

    const fetchChatMessages = async () => {
      try {
        const actionResult = await dispatch(
          actions.chatMessages.getChatMessages({
            chatRoomId: chatRoom.id,
            limit: LIMIT_CHAT_MESSAGES,
            page: 1,
          }),
        );
        unwrapResult(actionResult);
      } catch (error: any) {
        captureError(error);
      }
    };

    const getOneChatRoom = async () => {
      try {
        const actionResult = await dispatch(actions.chatRooms.getOneChatRoom({ chatRoomId: chatRoom.id }));
        unwrapResult(actionResult);
      } catch (error: any) {
        captureError(error);
        toast.error(error.message);
      }
    };

    fetchChatMessages();
    getOneChatRoom();
  }, [chatRoom?.id, chatRoom?.isTempRoom]);

  useEffect(() => {
    const fetchChannelNews = async () => {
      try {
        const actionResult = await dispatch(
          actions.channelNews.getChannelNews({
            limit: LIMIT_CHANNEL_NEWS,
            page: 1,
          }),
        );
        unwrapResult(actionResult);
      } catch (error: any) {
        captureError(error);
        toast.error(error.message);
      }
    };

    fetchChannelNews();
  }, [chatRoom?.id]);

  const renderLastMessageDivider = useCallback(
    (message: MessageExtended) => {
      if (
        user.id !== message.authorId &&
        message.id !== chatRoom?.lastMessageId &&
        message.id ===
          (user.id === chatRoom?.expertId ? chatRoom.expertLastReadMessageId : chatRoom?.clientLastReadMessageId)
      ) {
        return (
          <LastMessageDivider>
            <span>{t('unreadMessages')}</span>
          </LastMessageDivider>
        );
      }
    },
    [user, chatRoom, t],
  );

  const renderDateDivider = useCallback((date: any) => {
    if (date) {
      return <DateDivider key={date.toString()}>{date}</DateDivider>;
    }
  }, []);

  const renderMessagesList = (list: MessageExtended[] | News[]) => {
    if ((chatRoomId !== null && +chatRoomId) !== 0) {
      return list.map((m) => {
        switch (m.type) {
          case MessageTypeEnum.Default:
            return (
              <React.Fragment key={m.id}>
                {renderLastMessageDivider(m)}
                <ChatMessage
                  message={m}
                  avatar={isUserExpertInRoom ? chatRoom?.client.avatarUrl : chatRoom?.expert.avatarUrl}
                  variant={user.id === m.authorId ? 'me' : 'companion'}
                />
              </React.Fragment>
            );

          case MessageTypeEnum.System:
            return (
              <ChatSystemMessage
                message={m}
                isUserExpertInRoom={isUserExpertInRoom}
                expertId={chatRoom?.expertId!}
                key={m.id}
              />
            );

          case 'temporary':
            return <ChatMessage message={m} variant="system" key={m.createdAt} />;
          default:
            return null;
        }
      });
    }

    return (list as any[])
      .filter((item) => item.authorId !== user.id)
      .map((m) => {
        switch (m.type) {
          case MessageTypeEnum.Default:
            return (
              <React.Fragment key={m.id}>
                {renderLastMessageDivider(m)}
                <ChannelMessage
                  message={m}
                  avatar="/img/ava-consulty-support-user_square.png"
                  variant={user.id === m.authorId ? 'me' : 'companion'}
                />
              </React.Fragment>
            );

          case MessageTypeEnum.System:
            return (
              <ChatSystemMessage
                message={m}
                isUserExpertInRoom={isUserExpertInRoom}
                expertId={chatRoom?.expertId!}
                key={m.id}
              />
            );

          case 'temporary':
            return <ChatMessage message={m} variant="system" key={m.createdAt} />;

          case 'wellcome':
            return <FirstPost key={m.createdAt} createdAt={m.createdAt} />;
          case 'after_3_hours':
            return <SecondPost key={m.createdAt} createdAt={m.createdAt} />;
          case 'after_3_days':
            return <ThirdPost key={m.createdAt} createdAt={m.createdAt} />;
          default:
            return null;
        }
      });
  };

  const renderWidgets = () => {
    if (isUserExpertInRoom && pendingStartConsultation && pendingStartConsultation.invoices) {
      return (
        <ChatWidget
          key={pendingStartConsultation.id}
          title={t('chatRoom.widgets.pendingStart.title')}
          theme={pendingStartConsultation.category?.name}
          price={pendingStartConsultation.invoices[0].invoice.amount}
          duration={t('defaultDurationConsultation')}
          actionBlock={
            <Button
              block
              onClick={() => handleStartConsultation(pendingStartConsultation.id)}
              disabled={isSendingRequest}
              loading={isSendingRequest}
            >
              {t('chatRoom.widgets.pendingStart.button')}
            </Button>
          }
        />
      );
    }

    if (!isUserExpertInRoom && pendingPaymentConsultation && pendingPaymentConsultation.invoices) {
      const { amount, checkoutUrl } = pendingPaymentConsultation.invoices[0].invoice;

      return (
        <ChatWidget
          key={pendingPaymentConsultation.id}
          title={t('chatRoom.widgets.pendingPayment.title')}
          theme={pendingPaymentConsultation?.category?.name}
          price={amount}
          description={t('chatRoom.widgets.pendingPayment.description')}
          duration={t('defaultDurationConsultation')}
          variant="companion"
          actionBlock={
            <>
              <a href={checkoutUrl}>
                <WidgetButton block disabled={isSendingRequest} loading={isSendingRequest}>
                  {t('chatRoom.widgets.pendingPayment.buttons.pay', {
                    price: amount,
                    currency: typeof amount === 'number' ? '₽' : '',
                  })}
                </WidgetButton>
              </a>
              {pendingPaymentConsultation.consultationOffer && (
                <WidgetButton
                  block
                  bordered
                  disabled={isSendingRequest}
                  loading={isSendingRequest}
                  onClick={() =>
                    handleRejectConsultationOffer(
                      pendingPaymentConsultation.id,
                      pendingPaymentConsultation.consultationOffer?.id!,
                    )
                  }
                >
                  {t('chatRoom.widgets.pendingPayment.buttons.cancel')}
                </WidgetButton>
              )}
            </>
          }
        />
      );
    }

    if (!isUserExpertInRoom && pendingPayProlongationConsultation && pendingPayProlongationConsultation.invoices) {
      const prolongationInvoice = pendingPayProlongationConsultation.invoices.find(
        (consultationInvoice) =>
          consultationInvoice.type === ConsultationInvoiceTypeEnum.Prolongation &&
          consultationInvoice.invoice &&
          consultationInvoice.invoice.status === InvoiceStatusEnum.Pending,
      );

      if (!prolongationInvoice || !prolongationInvoice.invoice) {
        return null;
      }

      const { amount, isFree, checkoutUrl } = prolongationInvoice.invoice;

      return (
        <ChatWidget
          key={pendingPayProlongationConsultation.id}
          title={t('chatRoom.widgets.pendingProlongation.title')}
          theme={pendingPayProlongationConsultation.category?.name}
          price={isFree ? (tUtils('forFree') as string) : amount}
          description={t('chatRoom.widgets.pendingProlongation.description')}
          duration={t('defaultDurationConsultation')}
          variant="companion"
          actionBlock={
            <>
              <a href={checkoutUrl}>
                <WidgetButton block disabled={isSendingRequest} loading={isSendingRequest}>
                  {isFree
                    ? t('chatRoom.widgets.pendingProlongation.buttons.payFree')
                    : t('chatRoom.widgets.pendingProlongation.buttons.pay', {
                        price: amount,
                        currency: '₽',
                      })}
                </WidgetButton>
              </a>
              <WidgetButton
                block
                bordered
                disabled={isSendingRequest}
                loading={isSendingRequest}
                onClick={() => handleRejectProlongation(prolongationInvoice.id)}
              >
                {t('chatRoom.widgets.pendingProlongation.buttons.cancel')}
              </WidgetButton>
            </>
          }
        />
      );
    }

    if (!isUserExpertInRoom && pendingPayExtraServiceConsultation && pendingPayExtraServiceConsultation.extraServices) {
      const extraService = pendingPayExtraServiceConsultation.extraServices.find(
        (extraService) => extraService.invoice.status === InvoiceStatusEnum.Pending,
      );

      if (!extraService) {
        return null;
      }

      return (
        <ChatWidget
          key={pendingPayExtraServiceConsultation.id}
          variant="companion"
          title={t('chatRoom.widgets.pendingExtraService.title')}
          price={extraService.invoice.amount}
          description={extraService.description}
          actionBlock={
            <>
              <a href={extraService.invoice.checkoutUrl}>
                <WidgetButton block disabled={isSendingRequest} loading={isSendingRequest}>
                  {t('chatRoom.widgets.pendingExtraService.buttons.pay', {
                    price: extraService.invoice.amount,
                    currency: '₽',
                  })}
                </WidgetButton>
              </a>
              <WidgetButton
                block
                bordered
                disabled={isSendingRequest}
                loading={isSendingRequest}
                onClick={() => handleRejectExtraService(pendingPayExtraServiceConsultation.id, extraService.id)}
              >
                {t('chatRoom.widgets.pendingExtraService.buttons.cancel')}
              </WidgetButton>
            </>
          }
        />
      );
    }
  };

  const renderGroupChat = () => {
    const elements = [];
    for (const key in groupChat) {
      // @ts-ignore
      elements.push(renderMessagesList(groupChat[key]));
      elements.push(renderDateDivider(key));
    }
    return elements;
  };

  return (
    <Container {...props} id="chat-room-body">
      {!isChannelRoom() && renderWidgets()}
      <InfiniteScroll
        dataLength={messages.length}
        hasMore={pageCount ? pageCount > page : false}
        loader={<Spinner />}
        next={loadMoreMessages}
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
        inverse
        scrollableTarget="chat-room-body"
      >
        {renderGroupChat()}
      </InfiniteScroll>
      {fetchingChatMessageStatus === 'pending' && <Spinner />}
    </Container>
  );
};

const DateDivider = styled.span`
  margin: 20px auto;
  color: var(--gray);
  font-size: 14px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 0 15px 15px;
  ${CONDITION_DESKTOP} {
    padding: 0 35px 35px;
  }
`;
const LastMessageDivider = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 1.25;
  color: var(--gray);
  position: relative;
  overflow: hidden;
  ${CONDITION_DESKTOP} {
    font-style: italic;
  }
  & > span {
    flex-shrink: 0;
    margin: 0 10px;
  }
  &::before,
  &::after {
    content: '';
    position: relative;
    display: inline-block;
    width: 50%;
    height: 1px;
    vertical-align: middle;
    background: var(--gray2);
  }
`;
const WidgetButton = styled(Button)`
  &:not(:first-child) {
    margin-top: 10px;
  }
`;
