import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { News, NewsTypeEnum } from 'shared/api';
import { CONDITION_DESKTOP, DEFAULT_AVATAR, LIMIT_CHANNEL_NEWS } from 'shared/common/constants';
import { ChatRoomExtended } from 'shared/common/types';
import { coverImageStyle, makeMessageTime } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';

interface ChannelSidebarRoomButtonProps extends HTMLAttributes<HTMLDivElement> {
  isActive: boolean;
  chatRoom: ChatRoomExtended | Partial<ChatRoomExtended>;
  onClick: () => void;
  isExpertInRoom: boolean;
  unreadCount: number;
}

export const ChannelSidebarRoomButton: FC<ChannelSidebarRoomButtonProps> = ({
  isActive,
  chatRoom,
  unreadCount,
  ...props
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('chat.page');

  const dispatch = useAppDispatch();
  // const onlineMembers = useSelector(selectors.chatRooms.selectOnlineMembersIds);
  const currentChatRoomId = useSelector(selectors.chatRooms.selectCurrentChatRoomId);
  const unreadChatRoomsIds = useSelector(selectors.chatRooms.selectUnreadRoomsIds);
  // const consultationsStatuses = useSelector(selectors.chatRooms.selectConsultationStatusesByRoomId(chatRoom.id));

  const isShowUnreadCount: boolean = useSelector(selectors.channelNews.selectIsShowUnreadCount);

  const news: News[] = useSelector(selectors.channelNews.selectChannelNews);

  // const isCompanionOnline = useMemo(
  //   () => onlineMembers.includes(isExpertInRoom ? (chatRoom.clientId as string) : (chatRoom.expertId as string)),
  //   [chatRoom, isExpertInRoom, onlineMembers],
  // );

  const renderLastMessage = () => {
    if (news.length && news[0].content && news[0].type === NewsTypeEnum.Default) {
      setLastMessage(news[0].content);
    }
    if (news.length && news[0].type === NewsTypeEnum.Wellcome) {
      setLastMessage('Рады, что вы с Consulty!');
    }
    if (news.length && news[0].type === NewsTypeEnum.After3Hours) {
      setLastMessage(
        'Чтобы ваш профиль увидели другие пользователи, вам нужно заключить с нами договор (допсоглашение к оферте).',
      );
    }
    if (news.length && news[0].type === NewsTypeEnum.After3Days) {
      setLastMessage(
        'Напоминаем, что сейчас ваш профиль не видят другие пользователи. Они не смогут обратиться к вам за консультацией.',
      );
    }
  };

  useEffect(() => {
    renderLastMessage();
  }, [news]);

  const getMessageDate = () => {
    if (news.length) {
      return new Date(news[0].createdAt);
    }
  };

  const [lastMessage, setLastMessage] = useState('');

  useEffect(() => {
    const fetchChannelNews = async () => {
      try {
        const actionResult = await dispatch(
          actions.channelNews.getChannelNews({
            limit: LIMIT_CHANNEL_NEWS,
            page: 1,
          }),
        );
        unwrapResult(actionResult);
      } catch (error: any) {
        captureError(error);
      }
    };

    fetchChannelNews();
  }, [chatRoom?.id]);

  return (
    <Container $isActive={isActive} {...props}>
      <Avatar $url="/img/ava-consulty-support-user_square.png" />
      <Middle>
        <Name>{t('aside.channel.consulty')}</Name>
        <LastMessage dangerouslySetInnerHTML={{ __html: lastMessage }} />
      </Middle>
      <Right>
        <Time>{makeMessageTime(getMessageDate()?.toString(), language)}</Time>
        {isShowUnreadCount && <UnreadCount />}
        {chatRoom.id !== currentChatRoomId && unreadChatRoomsIds.includes(chatRoom.id as number) && (
          <UnreadCount>{unreadCount < 100 ? unreadCount : <MoreCount>99+</MoreCount>}</UnreadCount>
        )}
      </Right>
    </Container>
  );
};

const Container = styled.div<{ $isActive: boolean }>`
  padding: 10px;
  margin: 5px 0;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 60px calc(100% - 105px) 45px;
  align-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background-color: ${({ $isActive }) => $isActive && 'rgba(245, 246, 250, 0.9)'};
  ${CONDITION_DESKTOP} {
    grid-template-columns: 50px 190px 45px;
  }
`;
const Avatar = styled.div<{ $url?: string }>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  align-self: center;
  position: relative;
  ${({ $url }) => ({ ...coverImageStyle($url || DEFAULT_AVATAR) })}
  ${CONDITION_DESKTOP} {
    width: 50px;
    height: 50px;
  }
`;

const Middle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 5px 0 10px;
`;
const Name = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  ${CONDITION_DESKTOP} {
    font-size: 14px;
  }
`;
const LastMessage = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  height: 40px;
  font-size: 15px;
  line-height: 1.33;
  color: var(--gray);
  color: inherit;

  ${CONDITION_DESKTOP} {
    font-size: 13px;
    height: 32px;
  }
  * {
    color: var(--gray);
    font-size: 13px;
  }
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;
const Time = styled.span`
  font-size: 12px;
  color: var(--gray3);
`;
const UnreadCount = styled.div`
  font-size: 11px;
  line-height: 22px;
  color: var(--white);
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--purple);
`;

const MoreCount = styled.span`
  font-size: 9px;
  top: -1px;
  position: relative;
`;
