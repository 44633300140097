import { useRouter } from 'next/router';
import * as React from 'react';
import { FC } from 'react';
import { routes } from 'shared/common/routes';
import styled from 'styled-components';

export const ExpertMobileBanner: FC = () => {
  const router = useRouter();

  const goMobilePage = () => {
    router.push(routes.mobile);
  };

  return (
    <Container>
      <StyledTitle>Скачайте приложение</StyledTitle>
      <StyledText>
        Вы уже можете оказывать консультации в мобильном приложении - это удобно, как общение в обычном мессенджере.
        <br />
        <br />
        Пока вы не можете там настраивать и редактировать профиль: это можно сделать на сайте, позже мы добавим эти
        функции и в приложении.
        <br />
        <br />
        Скачайте приложение, чтобы моментально получать заявки на консультации и оказывать их.
      </StyledText>
      <StyledBtn onClick={goMobilePage}>Скачать приложение</StyledBtn>
    </Container>
  );
};

const Container = styled.div`
  background-color: #8b33ff;
  width: 700px;
  color: #fff;
  padding: 40px;
  border-radius: 12px;
  margin-bottom: 70px;
  margin-top: 50px;
`;

const StyledTitle = styled.span`
  font-weight: bold;
  font-size: 30px;
`;

const StyledText = styled.div`
  margin: 20px 0;
  font-size: 20px;
  font-weight: 100;
`;

const StyledBtn = styled.button`
  margin-top: 20px;
  box-shadow: none;
  border: 1px solid #fff;
  border-radius: 25px;
  width: 220px;
  height: 50px;
  color: #fff;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: #fff;
    color: #8b33ff;
  }
`;
